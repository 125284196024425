import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TimeArrivalCard } from './TimeArrivalCard';
import { TimeDepartureReturnCard } from './TimeDepartureReturnCard';
import { TimeWaitingHoursCard } from './TimeWaitingHoursCard';
import { PilotAssignmentCommands, PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import { Card } from '../../../../components/layout/card/Card';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import Slide from '../../../../components/layout/Slide';
import { errorsContainsFromObject } from '../../../../components/helpers/ObjectHelpers';

export const TimeCard = forwardRef((
    {
        onPilotAssignmentChanged,
        assignmentService
    }, ref) => {

    const timeArrivalRef = useRef();
    const timeDepartureReturnRef = useRef();
    const timeWaitingHoursRef = useRef();

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeIsDirty();

            timeArrivalRef.current.onPilotageChanged();
            timeDepartureReturnRef.current.onPilotageChanged();
            timeWaitingHoursRef.current.onPilotageChanged();
        },
        onLocationChanged() {
            initialize();
            timeWaitingHoursRef.current.onLocationChanged();
        },
        onValidationChanged(codeStrings) {
            const errorCodes = {
                PilotArrivalTimeIsMissing: PilotAssignmentErrorCodes.PilotArrivalTimeIsMissing,
                PilotDepartureTimeIsMissing: PilotAssignmentErrorCodes.PilotDepartureTimeIsMissing,
                ReturnTimeBeforeDepartureTime: PilotAssignmentErrorCodes.ReturnTimeBeforeDepartureTime,
                PilotStartBeforePilotArrivalTime: PilotAssignmentErrorCodes.PilotStartBeforePilotArrivalTime,
                PilotReturnTimeIsMissing: PilotAssignmentErrorCodes.PilotReturnTimeIsMissing,
                WaitingHourReasonIsMissing: PilotAssignmentErrorCodes.WaitingHourReasonIsMissing,
                WaitingHourReasonRemarkIsEmpty: PilotAssignmentErrorCodes.WaitingHourReasonRemarkIsEmpty
            };

            setHasValidationError(errorsContainsFromObject(codeStrings, errorCodes));

            timeArrivalRef.current.onValidationChanged(codeStrings)
            timeDepartureReturnRef.current.onValidationChanged(codeStrings)
        }
    }));

    const [isWastedTrip, setIsWastedTrip] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card
            properties={{
                ...CardProperties,
                title: "Tidspunkt",
                hasValidationError: hasValidationError,
                isDirty: isDirty
            }}>

            <TimeArrivalCard
                ref={timeArrivalRef}
                initializeIsDirty={initializeIsDirty}
                onArrivalTimeChanged={onArrivalTimeChanged}
                assignmentService={assignmentService}
            />

            <Slide show={isWastedTrip}>
                <TimeDepartureReturnCard
                    ref={timeDepartureReturnRef}
                    initializeIsDirty={initializeIsDirty}
                    assignmentService={assignmentService}
                />
            </Slide>

            <TimeWaitingHoursCard
                ref={timeWaitingHoursRef}
                initializeIsDirty={initializeIsDirty}
                assignmentService={assignmentService}
            />
        </Card>
    )

    function onArrivalTimeChanged() {
        timeWaitingHoursRef.current.onArrivalTimeChanged();
    }


    function initialize() {
        const pilotAssignment = assignmentService.assignment;

        setIsWastedTrip(pilotAssignment.isWastedTrip);

        initializeIsDirty();
    }

    function initializeIsDirty() {

        const hasArrivalTimeCommand = assignmentService.hasCommandType(PilotAssignmentCommands.PilotArrivalTime);
        const hasDepartureTimeCommand = assignmentService.hasCommandType(PilotAssignmentCommands.PilotDepartureTime);
        const hasReturnTimeCommand = assignmentService.hasCommandType(PilotAssignmentCommands.PilotReturnTime);

        if (assignmentService.assignment.isWastedTrip) {
            setIsDirty(hasArrivalTimeCommand || hasDepartureTimeCommand || hasReturnTimeCommand);
        } else {
            setIsDirty(hasArrivalTimeCommand);
        }

        onPilotAssignmentChanged();
    }
})
