
import React, { useState } from 'react';
import { isNullOrEmpty, isNumeric } from '../../../components/helpers/ObjectHelpers';
import { DateTimePickerModes } from '../../../components/layout/dateTimePicker/DateTimePicker';
import DateTimePickerFieldWrapper from '../../../components/layout/fieldWrappers/DateTimePickerFieldWrapper';
import TextBoxFieldWrapper from '../../../components/layout/fieldWrappers/TextBoxFieldWrapper';
import Spacer from '../../../components/layout/Spacer';
import EvaluationDetails from './EvaluationDetails';
import { EvaluationSearchStates } from '../services/EvaluationHelpers';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';

export default function EvaluationSearch({
    archivedEvaluations,
    onClick,
    onShowDocumentClick
}) {

    const [searchState, setSearchState] = useState(EvaluationSearchStates.None);
    const [searchFromDate, setSearchFromDate] = useState(null)
    const [searchToDate, setSearchToDate] = useState(null);
    const [searchPilotageNo, setSearchPilotageNo] = useState("");
    const [searchedEvaluations, setSearchedEvaluations] = useState([]);

    return (
        <>
            <Card properties={{
                ...CardProperties,
                title: "Arkivsøk"
            }}>
                <div className="container row-gap-1">
                    <div className="row">
                        <div className="col-6 col-sm-4">
                            <DateTimePickerFieldWrapper
                                mode={DateTimePickerModes.Year}
                                title="Dato fra"
                                canDelete={true}
                                disabled={searchState === EvaluationSearchStates.PilotageNo}
                                value={searchFromDate}
                                onChange={onSearchFromChanged}
                            />
                        </div>
                        <div className="col-6 col-sm-4">
                            <DateTimePickerFieldWrapper
                                mode={DateTimePickerModes.Year}
                                title="Dato til"
                                canDelete={true}
                                disabled={searchState === EvaluationSearchStates.PilotageNo}
                                value={searchToDate}
                                onChange={onSearchToChanged}
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <TextBoxFieldWrapper
                                title="Bestillingsnr"
                                icon="number"
                                disabled={searchState === EvaluationSearchStates.Dates}
                                onChange={onSearchPilotageNoChange}
                                value={searchPilotageNo}
                            />
                        </div>
                    </div>
                </div>
            </Card>
            <Spacer height={40} />
            {
                searchedEvaluations.map((evaluation, index) =>
                    <div key={index}>
                        <EvaluationSearchListItem
                            evaluation={evaluation}
                            index={index}
                            onClick={onClick}
                            onShowDocumentClick={onShowDocumentClick}
                        />
                        <Spacer height={20} />
                    </div>

                )
            }

        </>
    )

    function onSearchFromChanged(e) {
        const value = e.value === null ? null : e.value;

        setSearchFromDate(value);
        validateSearchState(value, searchToDate, null);
    }

    function onSearchToChanged(e) {
        const value = e.value === null ? null : e.value;

        setSearchToDate(value);
        validateSearchState(searchFromDate, value, null);
    }

    function onSearchPilotageNoChange(e) {
        const value = e.target.value;

        if (!isNullOrEmpty(value)) {
            if (isNumeric(e.target.value)) {
                setSearchPilotageNo(value);
            }
        } else {
            setSearchPilotageNo("");
        }

        validateSearchState(null, null, e.target.value);
    }

    function validateSearchState(fromDate, toDate, pilotageNo) {

        let searchState = EvaluationSearchStates.None;
        if (fromDate !== null || toDate !== null) {
            searchState = EvaluationSearchStates.Dates;
        }
        else if (isNullOrEmpty(pilotageNo) === false) {
            searchState = EvaluationSearchStates.PilotageNo;
        }
        search(searchState, fromDate, toDate, pilotageNo);
    }

    function search(searchState, fromDate, toDate, pilotageNo) {

        setSearchState(searchState);

        let items = [];

        switch (searchState) {
            case EvaluationSearchStates.Dates:
                const searchFromDate = fromDate === null ? new Date("1976-01-01T00:00:00+00:00") : new Date(fromDate);
                const searchToDate = toDate === null ? new Date("2076-01-01T00:00:00+00:00") : new Date(toDate);

                items = archivedEvaluations.filter(e => new Date(e.examTime) >= searchFromDate && new Date(e.examTime) <= searchToDate);
                break;
            case EvaluationSearchStates.PilotageNo:
                items = archivedEvaluations.filter(e => e.pilotageNo === Number(pilotageNo));
                break;
            default:
                break;
        }

        setSearchedEvaluations(items);
    }
}

const EvaluationSearchListItem = ({ evaluation, index, onClick, onShowDocumentClick }) => {
    return (
        <div>
            <EvaluationDetails
                evaluation={evaluation}
                isArchive={true}
                onClick={() => onClick(evaluation)}
                onShowDocumentClick={() => onShowDocumentClick(evaluation, index)}
            />
            <div id={`eval-item-${index}`} />
        </div>
    )
}
