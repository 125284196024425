import { Card } from "../../../../components/layout/card/Card";
import { CardProperties } from "../../../../components/layout/card/components/CardProperties";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Spacer from "../../../../components/layout/Spacer";
import Slide from "../../../../components/layout/Slide";
import { PilotAssignmentCommands } from "../../services/DispatcherActions";
import Switch from "../../../../components/layout/switch";

export const BothPilotAndExaminerCard = forwardRef((
    {
        onPilotAssignmentChanged,
        assignmentService
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        }
    }));

    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [description, setDescription] = useState("");
    const [couldBeExaminer, setCouldBeExaminer] = useState(false);

    useEffect(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Slide show={show}>
            <Spacer height={30} />
            <Card properties={{
                ...CardProperties,
                title: "Var du b&aring;de sensor og los på oppdraget?",
                backgroundColor: "var(--card-question-bg)"
            }}>
                <p>
                    {description}
                </p>
                {
                    !couldBeExaminer &&
                    <p>
                        <i>For å kunne registere deg som sensor på oppdraget, så må det være krysset av for "Sensor" under Grunnlagsdata Person.</i>
                    </p>
                }
                <Switch
                    onLabel="Ja"
                    offLabel="Nei"
                    onChange={onChange}
                    checked={isChecked}
                    disabled={!isEditable}
                />
            </Card>
        </Slide>
    )

    async function initializeAsync() {
        const hasCommand = assignmentService.hasCommandType(PilotAssignmentCommands.BothPilotAndExaminer);
        setShow(
            assignmentService.hasPecExams &&
            !assignmentService.canConvertToPilotIncl &&
            !assignmentService.isExaminer &&
            !assignmentService.isTrainee &&
            !assignmentService.hasExaminers
        );

        setIsEditable(assignmentService.isEditable);
        setIsChecked(hasCommand);
        setCouldBeExaminer(assignmentService.couldBeExaminer);

        if (!assignmentService.hasPecExams) return;
        const pecExamsCount = assignmentService.pecExamsCount;

        setDescription(`Oppdraget inneholder ${pecExamsCount} ${pecExamsCount === 1 ? "farledsprøve" : "farledsprøver"}.`);
    }

    function onChange(e) {
        setIsChecked(e.value);
        onUpdateAsync(e.value);
    }

    async function onUpdateAsync(isBoth) {
        await assignmentService.onBothPilotAndExaminerAsync(isBoth);
        onPilotAssignmentChanged();
    }
})
