/*eslint unicode-bom: ["error", "always"]*/
import './minicard.css'
import React from 'react';


export default function MiniCardList({ items }) {

    return (
        <div className="short-list">
            {
                items.map((item, index) =>
                    <MiniCard
                        key={index}
                        item={item}/>
                )
            }
        </div>
    );
}

export const MiniCard = ({ item }) => {
    return (
        <div className="short-list-item"
            style={{
                borderColor: item.borderColor,
                backgroundColor: item.backgroundColor
            }}>
            <div>
                {`${item.text}`}
            </div>
            <div>
                {
                    isExaminer() &&
                    <div className="pp-type-small pp-examiner"><div>S</div></div>
                }
                {
                    isAssignedAsTrainee() && !isTrainee() &&
                    <div className="pp-type-small pp-trainee"><div>L</div></div>
                }
                {
                    isTrainee() &&
                    <div className="pp-type-small pp-trainee"><div>A</div></div>
                }

            </div>
        </div>
    )

    function isExaminer() {
        if (!Object.hasOwn(item, "isExaminer")) return false;
        return item.isExaminer;
    }

    function isAssignedAsTrainee() {
        if (!Object.hasOwn(item, "isTrainee")) return false;
        return item.isTrainee;
    }

    function isTrainee() {
        if(!Object.hasOwn(item, "isApprentice")) return false;
        return item.isApprentice;
    }
}
