import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Moment from 'moment';
import { Card } from '../../../../components/layout/card/Card';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import { isNullOrEmpty } from '../../../../components/helpers/ObjectHelpers';
import { getPilotAssignmentStatusColor } from '../helpers/PilotAssignmentHelpers';

export const DurationCard = forwardRef((
    {
        assignmentService
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onLocationsChanged() {
            initialize();
        }
    }));

    const borderColor = getPilotAssignmentStatusColor(assignmentService.assignment)
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <Card
            properties={{
                ...CardProperties,
                className: 'pp-card',
                borderColor: borderColor,
                isBorderLeftFat: true
            }}
            style={{
                opacity: 1
            }}>
            <div className="title p-t-10">Losingstid: {duration} timer</div>
        </Card>
    )
    
    function initialize() {
        const fromToTime = assignmentService.fromAndToTime;
        if (isNullOrEmpty(fromToTime.fromTime) || isNullOrEmpty(fromToTime.toTime)) {
            setDuration(0);
        } else {
            const diff = Moment(fromToTime.toTime).diff(Moment(fromToTime.fromTime), 'minutes');
            setDuration(Math.ceil(diff/60));
        }
    }
})

