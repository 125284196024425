import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';
import Switch from '../../../../components/layout/switch';
import { PilotAssignmentCommands } from '../../services/DispatcherActions';
import PubSub from 'pubsub-js';

export const WastedTripCard = forwardRef(({
    onWastedTripChanged,
    assignmentService
}, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initialize();
        }
    }));

    const [isWastedTrip, setIsWastedTrip] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic);
        initialize()
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, []);

    return (
        <Switch
            disabled={!isEditable}
            onLabel="Bomtur"
            onChange={onChangeAsync}
            checked={isWastedTrip}
            isDirty={isDirty}
        />
    )

    function initialize() {
        const pilotAssignment = assignmentService.assignment;
        setIsWastedTrip(pilotAssignment.isWastedTrip);
        setIsEditable(assignmentService.isEditable);
        initializeIsDirty(pilotAssignment);
    }

    function handlePubSubTopic() {
        initialize();
    }
    
    async function onChangeAsync(e) {
        setIsWastedTrip(e.value);

        await assignmentService.onIsWastedTripAsync(e.value);

        onWastedTripChanged(e.value);

        initializeIsDirty();
    }

    function initializeIsDirty() {
        setIsDirty(assignmentService.hasCommandType(PilotAssignmentCommands.IsWastedTrip));
    }
})
