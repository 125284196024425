import { useState } from 'react';
import WorkTimeApiRepository from '../../../repositories/api/WorkTimeApiRepository';
import { isArrayEmpty, isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';
import { useEffect } from 'react';
import moment from 'moment';
import { formatMomentToDefault, formatMomentToHourMinutes } from '../../../components/helpers/DateTimeHelpers';
import { useNavigate } from 'react-router-dom';
import { HomeContent, HomeOnlineCard } from './HomeComponents';

export const HomeWorkTimeCard = ({ busyTimeout, isPilot }) => {

    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [period, setPeriod] = useState(null);
    const [isWorking, setIsWorking] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        if (!isPilot) return;

        const notBusy = () => {
            setTimeout(() => {
                setIsBusy(false);
            }, busyTimeout);
        };

        const initializeAsync = async () => {
            setIsBusy(true);
            setShow(true);

            const response = await WorkTimeApiRepository.getRecentAsync();
            if (response.ok) {
                const data = await response.json();
                if (!isArrayEmpty(data)) {
                    const orderedData = data.sort(orderDateByDecending);

                    setPeriod(orderedData[0]);
                    setIsWorking(
                        orderedData[0].workTimePeriodType.type ===
                            "WORKING_TIME",
                    );
                }
                notBusy();
            } else {
                notBusy();
            }
        };

        const initializeOffline = () => {
            setShow(false);
        };

        if (navigator.onLine) {
            initializeAsync();
        } else {
            initializeOffline();
        }

        window.addEventListener("online", initializeAsync);
        window.addEventListener("offline", initializeOffline);

        return () => {
            window.removeEventListener("online", initializeAsync);
            window.removeEventListener("offline", initializeOffline);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        show && (
            <HomeOnlineCard canShow={true} onClick={onClick}>
                <HomeContent icon="stopwatch" isBusy={isBusy}>
                    <div>
                        <h2 className="home-content-title">Arbeidstid</h2>
                        <div role="note">
                            {isNullOrEmpty(period) ? (
                                <>Kunne ikke hente arbeidstid</>
                            ) : (
                                <>
                                    Du har vært på{" "}
                                    <b>{isWorking ? "arbeid" : "hvile"}</b>{" "}
                                    siden {getPeriodStartTime(period)}
                                </>
                            )}
                        </div>
                    </div>
                </HomeContent>
            </HomeOnlineCard>
        )
    );

    function onClick() {
        navigate('/worktime/register');
    }

    function getPeriodStartTime(period) {
        const today = moment();
        const startTime = moment(period.periodStartTime);

        if (today.month() === startTime.month() && today.date() === startTime.date())
            return formatMomentToHourMinutes(period.periodStartTime);

        return formatMomentToDefault(period.periodStartTime);
    }

    function orderDateByDecending(a, b) {
        return new Date(b.periodStartTime).getTime() - new Date(a.periodStartTime).getTime();
    }
}
