import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Switch from '../../../../components/layout/switch';
import { PilotAssignmentCommands } from '../../services/DispatcherActions';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const ReimbursementCard = forwardRef((
    {
        onPilotAssignmentChanged,
        assignmentService
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initialize();
        }
    }));

    const [isEditable, setIsEditable] = useState(false);
    const [isReimbursementExcluded, setIsReimbursementExcluded] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    
    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)
        initialize()
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, [])
    
    return (
        <Switch
            disabled={!isEditable}
            onLabel="Ingen reiseregning"
            onChange={onChangeAsync}
            checked={isReimbursementExcluded}
            isDirty={isDirty}
        />
    )

    function initialize() {
        const pilotAssignment = assignmentService.assignment;
        setIsReimbursementExcluded(!pilotAssignment.pilotagePilotCompensation.isReimbursementIncluded)
        setIsEditable(assignmentService.isEditable);

        initializeIsDirty();
    }

    function handlePubSubTopic() {
        initialize();
    }
    
    async function onChangeAsync(e) {
        setIsReimbursementExcluded(e.value);

        await assignmentService.onUpdateReimbursmentStatusAsync(e.value);

        onPilotAssignmentChanged();

        initializeIsDirty();
    }

    function initializeIsDirty() {
        setIsDirty(assignmentService.hasCommandType(PilotAssignmentCommands.UpdateReimbursmentStatus));
    }
})
