import { useState } from 'react';
import VariableCompensationOverrideReasonTypeIdbRepository from '../../../../../repositories/idb/VariableCompensationOverrideReasonTypeIdbRepository';
import { useEffect } from 'react';
import { NumericTextBox } from '../../../../../components/layout/NumericTextBox';
import Spacer from '../../../../../components/layout/Spacer';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
    Error,
} from "@progress/kendo-react-labels";
import { PilotagePilotVariableCompensationOverrideReasonTypeEnums } from '../../../../../services/SystemNames';
import { Dialog } from '../../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../../components/layout/dialogs/DialogProperties';
import TextBoxFieldWrapper from '../../../../../components/layout/fieldWrappers/TextBoxFieldWrapper';
import TextAreaFieldWrapper from '../../../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import { deepCopyObject, isNullOrEmpty, isNumeric } from '../../../../../components/helpers/ObjectHelpers';


export const VariableCompensationBoardingDialog = ({
    onClose,
    compensation = null,
    callback }) => {

    const maxOverflow = 2;
    const [maxOverriddenNumber, setMaxOverriddenNumber] = useState(0);

    const [title, setTitle] = useState("");
    const [canSave, setCanSave] = useState(false);
    const [exceeded, setExceeded] = useState(false);
    const [overriddenNumber, setOverriddenNumber] = useState(null);
    const [remark, setRemark] = useState("");

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: title,
                onClose: onClose,
                actions: [
                    {
                        onClick: onClose,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        onClick: onSaveClickAsync,
                        disabled: !canSave,
                        icon: "ok",
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            <div className="dialog-form">


                <TextBoxFieldWrapper
                    title="Beregnet antall"
                    value={compensation.calculatedNumber}
                    readOnly={true}
                    disabled={true}
                />

                <Spacer height={20} />

                <FieldWrapper>
                    <Label className="k-form-label">
                        Overstyrt av los:
                    </Label>
                    <div className="k-form-field-wrap">
                        <div className="row align-items-center">
                            <div className="col">
                                <NumericTextBox value={overriddenNumber} onChange={onOverriddenNumberChange} />
                            </div>
                        </div>
                    </div>
                    {
                        exceeded &&
                            <Error>
                                Overstyring kan ikke overstige {maxOverriddenNumber}
                            </Error>
                    }
                </FieldWrapper>

                <Spacer height={20} />

                <TextAreaFieldWrapper
                    title="Begrunnelse"
                    value={remark}
                    onChange={onDescriptionChange}
                />

            </div>
        </Dialog>
    ) 

    async function initializeAsync() {

        setOverriddenNumber(compensation.overridenNumber);
        setTitle(compensation.pilotagePilotVariableCompensationName);

        if (isNullOrEmpty(compensation.pilotagePilotVariableCompensationSystemName)) return;

        const maximum = compensation.calculatedNumber + maxOverflow;
        setMaxOverriddenNumber(maximum);

        if (isNumeric(compensation.overridenNumber)) {
            setExceeded(compensation.overridenNumber > maximum);
        }

        if (!isNullOrEmpty(compensation.pilotagePilotVariableCompensationOverrideReasonRemark)) {
            setRemark(compensation.pilotagePilotVariableCompensationOverrideReasonRemark);
        } else {
            setRemark("");
        }
    }

    async function onSaveClickAsync() {
        if (!canSave) return;

        const reasonType = await VariableCompensationOverrideReasonTypeIdbRepository.getBySystemName(PilotagePilotVariableCompensationOverrideReasonTypeEnums.Other);

        const compensationCopy = deepCopyObject(compensation);
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonRemark = remark;
        compensationCopy.overridenNumber = overriddenNumber;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonTypeId = reasonType.pilotagePilotVariableCompensationOverrideReasonTypeId;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonType = reasonType.systemName;

        callback(compensationCopy);
        onClose();
    }

    function onDescriptionChange(e) {
        setRemark(e.value);
        validate(e.value, overriddenNumber);
    }

    function onOverriddenNumberChange(e) {
        setOverriddenNumber(e.value);
        validate(remark, e.value);
    }

    function validate(text = remark, number) {
        const exceeded = number > maxOverriddenNumber;
        setCanSave(!isNullOrEmpty(text) && !exceeded);
        setExceeded(exceeded);
    }
}
