import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import PcsApiRepository from '../../../../repositories/api/PcsApiRepository';
import { defaultFillColor, defaultOpacity, defaultStrokeColor, mouseOverStrokeColor, pcsGroupFeatureType, selectedFillColor, selectedStrokeColor, setMapTitle, setTilesLoadedTimer, unselectFeatures, updateZoomByUrlParameter } from '../GoogleMapHelpers';

export const MapPilotCoastalSegmentGroups = forwardRef(({
    mapId,
    properties,
    focusPcsGroupId = 0
}, ref) => {

    const map = useMap();

    useImperativeHandle(ref, () => ({
        onReset() {
            localRef.initialized = false;
            initializeAsync();
        },
        onBoundsChanged() {
            if (!properties.showPcsGroups) return;
            initializeAsync();
        },
        onSelected() {
            if (!map) return;
            if (!properties.showPcsGroups) return;
            unselectFeatures(map, pcsGroupFeatureType);
        },
        onFocus(pcsGroupId) {
            if (pcsGroupId === 0) {
                unselectFeatures(map, pcsGroupFeatureType);
            } else {
                onFeatureFocus(pcsGroupId);
            }
        }
    }));

    const componentRef = useRef({
        isBusy: false,
        mouseOverListenerHandle: null,
        mouseOutListenerHandle: null,
        clickListenerHandle: null,
        initialized: false,
        hasFocused: false
    });
    const { current: localRef } = componentRef;
    
    useEffect(() => {
        if (!map) return;
        if (properties.showPcsGroups) return;
        clearAllFeatures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties.showPcsGroups]);

    async function initializeAsync() {

        if (localRef.isBusy || localRef.initialized) return;
        
        clearAllFeatures();

        localRef.isBusy = true;
        
        const response = await PcsApiRepository.getSummaryAsync();
        if (response.ok) {
            const data = await response.json();
            
            for (let feature of data.groupsGeoJson.geoJson.features) {
                feature.properties.featureType = pcsGroupFeatureType;
            }

            map.data.addGeoJson(data.groupsGeoJson.geoJson);

            map.data.setStyle(function (feature) {
                const featureType = feature.getProperty("featureType");
                if (featureType === pcsGroupFeatureType) {
                    const isSelected = feature.getProperty("isSelected");
                    return {
                        fillColor: defaultFillColor,
                        fillOpacity: defaultOpacity,
                        strokeWeight: 2,
                        strokeColor: isSelected ? selectedStrokeColor : defaultStrokeColor
                    };
                }
            });

            localRef.mouseOverListenerHandle = map.data.addListener("mouseover", onMouseOver);
            localRef.mouseOutListenerHandle = map.data.addListener("mouseout", onMouseOut);
            localRef.clickListenerHandle = map.data.addListener("click", onClick);

            setTilesLoadedTimer(properties, map);
        }

        updateZoomByUrlParameter(map);

        initializeFocus();

        localRef.isBusy = false;
        localRef.initialized = true;
    }

    function initializeFocus() {
        if (focusPcsGroupId === 0 || localRef.hasFocused) return;
        onFeatureFocus(focusPcsGroupId);
        localRef.hasFocused = true;
        
    }

    function clearAllFeatures() {
        map.data.forEach(function (feature) {
            const featureType = feature.getProperty("featureType");
            if (featureType === pcsGroupFeatureType) {
                map.data.remove(feature);
            }
        });

        window.google.maps.event.clearListeners(map, "mouseover");

        localRef.mouseOverListenerHandle?.remove();
        localRef.mouseOutListenerHandle?.remove();
        localRef.clickListenerHandle?.remove();

        localRef.mouseOutListenerHandle = null;
        localRef.clickListenerHandle = null;
        localRef.initialized = false;
    }

    function showFeatureName(name) {
        setMapTitle(mapId, name);
    }

    function onMouseOver(event) {
        showFeatureName(event.feature.getProperty("segmentName"));
        map.data.overrideStyle(event.feature,
            {
                strokeColor: mouseOverStrokeColor,
                fillColor: mouseOverStrokeColor,
                strokeWeight: 4,
                zIndex: 1000
            }
        );

        const groupId = event.feature.getProperty("segmentGroupId");
        properties.handleOnFeatureMouseOver(groupId);
    }

    function onMouseOut(event) {
        setFeatureStyle(event.feature);

        let featureName = "";

        map.data.forEach(function (feature) {
            const isSelected = feature.getProperty("isSelected");
            if (isSelected) {
                featureName = feature.getProperty("segmentName");
            }
        });

        showFeatureName(featureName);

        properties.handleOnFeatureMouseOut();
    }

    function onClick(event) {
        const groupId = event.feature.getProperty("segmentGroupId");

        if (properties.showPcsGroups) {
            deselectFeatures(groupId);
        }
        
        event.feature.setProperty("isSelected", true);
        properties.handleOnFeatureClick(groupId);

        if (!properties.showPcsGroups) return;

        fitFeatureBounds(event.feature);
    }

    function deselectFeatures(skipId = 0) {
        map.data.forEach(function (feature) {
            const groupId = feature.getProperty("segmentGroupId");
            if (groupId === skipId) return;
            feature.setProperty("isSelected", false);
            setFeatureStyle(feature);
        });
    }

    function setFeatureStyle(feature, mouseover = false) {
        const isSelected = feature.getProperty("isSelected");
        map.data.overrideStyle(feature,
            {
                fillColor: isSelected ? selectedFillColor : mouseover ? mouseOverStrokeColor : defaultFillColor,
                fillOpacity: defaultOpacity,
                strokeWeight: 2,
                strokeColor: isSelected ? selectedStrokeColor : mouseover ? mouseOverStrokeColor : defaultStrokeColor
            }
        );
    }

    function processPoints(geometry, callback, thisArg) {
        if (geometry instanceof window.google.maps.LatLng) {
            callback.call(thisArg, geometry);
        } else if (geometry instanceof window.google.maps.Data.Point) {
            callback.call(thisArg, geometry.get());
        } else {
            geometry.getArray().forEach(function (g) {
                processPoints(g, callback, thisArg);
            });
        }
    }

    function onFeatureFocus(pcsGroupId) {
        map.data.forEach(function (feature) {
            const groupId = feature.getProperty("segmentGroupId");
            if (Number(groupId) !== Number(pcsGroupId)) return;
            feature.setProperty("isSelected", true);
            setFeatureStyle(feature);
            fitFeatureBounds(feature);
        });
    }

    function fitFeatureBounds(feature) {
        let bounds = new window.google.maps.LatLngBounds();
        processPoints(feature.getGeometry(), bounds.extend, bounds);
        map.fitBounds(bounds);
    }
})
