import "./styles/pcs.css";

import { useEffect, useRef, useState } from "react";
import { GoogleMapCard } from "../../components/layout/map/GoogleMapCard"
import { GoogleMapProperties } from "../../components/layout/map/GoogleMapProperties";
import { useSearchParams } from "react-router-dom";
import { getSearchParam } from "../../components/helpers/UrlHelpers";
import { PcsBottomMenu, PcsMenus } from "./components/PcsBottomMenu";
import { Groups } from "./components/Groups";
import NoAccess from "../../components/access/NoAccess";
import { hasApplicationRights } from "../../components/helpers/AuthHelpers";
import { ApplicationRightSystemNames } from "../../services/SystemNames";
import { useLazyGetApplicationRightsQuery } from "../../reducers/slices/api.slice";
import { Group } from "./components/Group";
import { isNullOrEmpty } from "../../components/helpers/ObjectHelpers";
import { NoEdit } from "./components/NoEdit";
import { publishHeaderTopic, publishOnFeatureMouseOut, publishOnFeatureMouseOver, publishOnPcsFeatureFocus } from "../../components/helpers/PubSubHelpers";
import FloatingButtonContainer from "../../components/layout/FloatingButtonContainer";
import FloatingActionButton from "../../components/layout/FloatingActionButton";
import { PlusIcon } from "../../components/layout/icons/Icons";
import { Evaluation } from "./components/Evaluation";
import { ContentTitle } from "./components/ContentTitle";
import { Locations } from "./components/Locations";
import PcsApiRepository from "../../repositories/api/PcsApiRepository";
import Overlay from "../../components/layout/overlay/Overlay";

export const Index = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const mapRef = useRef();

    const initialState = {
        menu: getSearchParam(searchParams, "menu", PcsMenus.Groups),
        mapChanges: null,
        isEditing: getSearchParam(searchParams, "groupId", 0) > 0,
        groupId: getSearchParam(searchParams, "groupId", 0),
        groupName: "",
        mouseOverGroupId: 0
    };

    const [
        {
            menu,
            mapChanges,
            isEditing,
            groupId,
            groupName,
            mouseOverGroupId
        }
        , setState] = useState(initialState);

    const setValue = (name, value) => {
        setState((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const setMenu = (e) => {
        setState((prev) => ({
            ...prev,
            menu: e,
            isEditing: false
        }));
    };

    const setMapChanges = (e) => {
        setValue("mapChanges", e);
    };

    const onClose = () => {
        setSearchParams({
            menu: menu
        }, { replace: true });

        setState((prev) => ({
            ...prev,
            groupId: 0,
            isEditing: false
        }));

        publishOnPcsFeatureFocus(0);
    }

    const onEdit = async (e, n = "") => {
        setSearchParams({
            menu: menu,
            groupId: e,
        }, { replace: true });
        
        setState((prev) => ({
            ...prev,
            groupId: e,
            groupName: n,
            isEditing: true
        }));
    }

    const onAdd = (e) => {
        setState((prev) => ({
            ...prev,
            groupId: 0,
            groupName: "",
            isEditing: true
        }));
    }

    const setMouseOverGroupId = (e) => {
        setState((prev) => ({
            ...prev,
            mouseOverGroupId: e
        }));
    }

    useEffect(() => {
        publishHeaderTopic("Sertifikatomr&aring;der");

        const initializeAsync = async () => {
            if (groupId === 0) return;
            const response = await PcsApiRepository.getGroupAsync(groupId);
            if (response.ok) {
                const data = await response.json();
                setValue("groupName", data.groupName);
            }
        }
        initializeAsync();
    }, []);

    return (
        <ValidatedContent menu={menu}>
            <div className="pcs-grid">
                <div className={`pcs-container`}>
                    <div className="pcs-content">
                        <div>
                            {
                                !isEditing && <ContentTitle title={getTitle()}/>
                            }
                        </div>
                        <div className="overflow-auto">
                            <ListContent
                                mapChanges={mapChanges}
                                isEditing={isEditing}
                                onClick={onEdit}
                                onAddClick={onAdd}
                                menu={menu}
                                mouseOverGroupId={mouseOverGroupId}
                            />

                            <EditContent
                                menu={menu}
                                groupId={groupId}
                                groupName={groupName}
                                isEditing={isEditing}
                                onClose={onClose}
                                mapChanges={mapChanges}
                            />
                        </div>
                    </div>
                    <div>
                        <MapContent
                            mapRef={mapRef}
                            setMouseOverGroupId={setMouseOverGroupId}
                            onMapChange={onMapChange}
                            isEditing={isEditing}
                            onClick={onEdit}
                            menu={menu}
                            groupId={groupId}
                        />
                    </div>
                </div>

                <PcsBottomMenu
                    onClick={onMenuClick}
                    menu={menu} />
            </div>
        </ValidatedContent>
    )

    function onMenuClick(e) {
        if (menu === e) return;
        setMenu(e);
    }

    function onMapChange(e) {
        //console.log(e);
        setMapChanges(e);
    }

    function getTitle() {
        switch (menu) {
            case PcsMenus.Evaluations:
                return "Evalueringer";
            case PcsMenus.Locations:
                return "Lokasjoner";
            default:
                return "Sertifikatområder";
        }
    }
}

const ListContent = ({
    isEditing = false,
    mapChanges,
    onClick,
    onAddClick,
    menu,
    mouseOverGroupId
}) => {

    return (
        (!isEditing) &&
        <>
            <Groups
                mouseOverGroupId={mouseOverGroupId}
                onClick={onClick}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                mapChanges={mapChanges}
                addSpacer={true}
            />

            {
                (menu === PcsMenus.Groups) &&
                <FloatingButtonContainer
                    position="left"
                    className="pcs-add-button"
                    hasBottomNavigation={true}>
                    <FloatingActionButton
                        themeColor="primary"
                        svgIcon={PlusIcon}
                        onClick={onAddClick}
                    />
                </FloatingButtonContainer>
            }
        </>
    )

    function onMouseOver(groupId) {
        publishOnFeatureMouseOver({
            property: "segmentGroupId",
            value: groupId
        });
    }

    function onMouseOut() {
        publishOnFeatureMouseOut();
    }
}

const EditContent = ({
    groupId = 0,
    groupName = "",
    menu,
    isEditing = false,
    mapChanges,
    onClose,
}) => {
    return (
        isEditing &&
        (() => {
            switch (menu) {
                case PcsMenus.Groups:
                    return (<Group
                        groupId={groupId}
                        mapChanges={mapChanges}
                        onClose={onClose}
                    />);
                case PcsMenus.Evaluations:
                    return (<Evaluation
                        groupId={groupId}
                        onClose={onClose}
                        groupName={groupName}
                    />);
                case PcsMenus.Locations:
                    return (<Locations
                        groupId={groupId}
                        onClose={onClose}
                        groupName={groupName}
                        />)
                default:
                    return null
            }
        })()
    )
}

const MapContent = ({
    mapRef,
    onMapChange,
    onClick,
    isEditing = false,
    menu,
    groupId,
    setMouseOverGroupId,
}) => {

    const initialState = {
        showPcsGroups: false,
        showLocations: false,
        showPcs: false,
        multiSelectControl: false,
        currentIsEditing: null,
        currentGroupId: 0,
        isBusy: false
    };

    const [
        {
            showPcsGroups,
            showLocations,
            showPcs,
            multiSelectControl,
            currentIsEditing,
            currentGroupId,
            isBusy
        }
        , setState] = useState(initialState);

    const updateProperties = async (showPcsGroups, showLocations, showPcs, multiSelectControl, currentGroupId = 0) => {
        setState((prev) => ({
            ...prev,
            showPcsGroups: showPcsGroups,
            showLocations: showLocations,
            showPcs: showPcs,
            multiSelectControl: multiSelectControl,
            currentGroupId: currentGroupId,
        }));
    }

    useEffect(() => {

        if (currentIsEditing === isEditing) return;


        const initializeListProperties = () => {
            updateProperties(true, true, false, false);
            onReset();
        }

        const initializeEditProperties = () => {
            switch (menu) {
                case PcsMenus.Groups:
                    updateProperties(false, true, true, true, groupId);
                    break;

                default:
                    updateProperties(true, true, false, false);
                    publishOnPcsFeatureFocus(groupId);
                    break;
            }
        }
        
        if (isEditing) {
            initializeEditProperties();
        } else {
            initializeListProperties();
        }

        setState((prev) => ({
            ...prev,
            currentIsEditing: isEditing
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu, isEditing]);

    return (
        <>
            <GoogleMapCard
                ref={mapRef}
                properties={{
                    ...GoogleMapProperties,
                    onDragEnd: onMapChange,
                    onZoomChanged: onMapChange,
                    onTilesLoaded: onMapChange,
                    onResize: onMapChange,
                    onGeoJsonLoaded: onMapChange,
                    onFeatureClick: handleOnClick,
                    onFeatureMouseOver: onMouseOver,
                    onFeatureMouseOut: onMouseOut,
                    showPcsGroups: showPcsGroups,
                    showLocations: showLocations,
                    showPcs: showPcs,
                    multiSelectControl: multiSelectControl,
                    pcsGroupId: currentGroupId,
                    isInline: false,
                    onSetIsBusy: onSetIsBusy
                }}
            />
            <Overlay isBusy={isBusy} showSpinner={true} />
        </>
    )

    function onMouseOver(e) {
        setMouseOverGroupId(e);
    }

    function onMouseOut() {
        setMouseOverGroupId();
    }

    async function handleOnClick(e) {
        if (isEditing) {
            onMapChange(e);
        } else {
            const response = await PcsApiRepository.getGroupAsync(e);
            if (response.ok) {
                const data = await response.json();
                onClick(data.groupId, data.groupName);
            }
            
        }
    }

    function onReset() {
        setTimeout(() => {
            if (isNullOrEmpty(mapRef.current)) return;
            mapRef.current?.onResetPilotCoastalSegmentGroups();
        }, 250);
    }

    function onSetIsBusy(busy) {
        setState((prev) => ({
            ...prev,
            isBusy: busy
        }));
    }
}

const ValidatedContent = ({ children, menu }) => {

    const [getApplicationRightsQuery] = useLazyGetApplicationRightsQuery();
    const [hasAccess, setHasAccess] = useState(true);;

    useEffect(() => {

        const initialize = (applicationRights = []) => {
            switch (menu) {
                case PcsMenus.Groups:
                    setHasAccess(hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegments]));
                    break;
                case PcsMenus.Evaluations:
                    setHasAccess(hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsEvaluation]));
                    break;
                case PcsMenus.Locations:
                    setHasAccess(hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsLocations]));
                    break;
                default:
                    setHasAccess(false);
                    break;
            }
        }

        getApplicationRightsQuery()
            .unwrap()
            .then(data => initialize(data))
            .catch(() => initialize());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu]);

    return (
        hasAccess ?
            <>
                {children}
                <NoEdit/>
            </>
            :
            <NoAccess/>
    )
}
