import React, { useEffect } from 'react';
import { useState } from 'react';
import { getElementById, removeClassFromElementsByClassName } from '../../../components/helpers/ElementHelpers';
import { isArrayEmpty, isArraysEqual, isNullOrEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import Overlay from '../../../components/layout/overlay/Overlay';
import PcsApiRepository from '../../../repositories/api/PcsApiRepository';
import { NoResult } from './NoResult';

export const Groups = (
    {
        onClick = null,
        mapChanges = null,
        onMouseOver,
        onMouseOut,
        mouseOverGroupId = 0
    }) => {

    const [groups, setGroups] = useState([]);
    const [isBusy, setIsBusy] = useState(true);
    const [filteredGroups, setFilteredGroups] = useState([]);

    useEffect(() => {
        initializeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        deactivateGroupItems();
        activateGroupItem(mouseOverGroupId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mouseOverGroupId]);

    useEffect(() => {
        if (isObjectNull(mapChanges)) return;
        onFilterGroups(mapChanges, groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapChanges]);

    return (
        <>
            {
                (!isArrayEmpty(filteredGroups)) ?
                    <div className="k-d-grid k-gap-1 k-m-2">
                        {
                            filteredGroups.map((group, index) =>
                                <GroupItem
                                    key={index}
                                    group={group}
                                    handleOnClick={handleOnClick}
                                    onMouseOver={onMouseOver}
                                    onMouseOut={onMouseOut}/>
                            )
                        }
                    </div>
                    :
                    <NoResult text="Det finnes ikke noen sertifikatomr&aring;der i valgt kartutsnitt."/>
            }

            <Overlay isBusy={isBusy} />
        </>
    );

    async function initializeAsync() {

        setIsBusy(true);

        const response = await PcsApiRepository.getSummaryAsync();
        if (response.ok === true) {
            const data = await response.json();
            setGroups(data.groupsGeoJson.groups);
            onFilterGroups(mapChanges, data.groupsGeoJson.groups);
        } else {
            publishWarningNotificationTopic("Kunne ikke hente inn sertifikatomr&aring;der");
        }

        setIsBusy(false);
    }

    function onFilterGroups(changes, groups) {
        let result = []
        if(!isObjectNull(changes)) {
            if (Object.hasOwn(changes, "groupIds")) {
                result = groups.filter(x => changes.groupIds.includes(x.groupId));
            }
        }

        if (isArraysEqual(result, filteredGroups)) return;
        setFilteredGroups(result);
    }

    function deactivateGroupItems() {
        removeClassFromElementsByClassName('pcs-list-item-active', 'pcs-list-item-active');
    }

    function activateGroupItem(groupId) {
        const elem = getElementById(`pcs-list-item-${groupId}`);
        if (isNullOrEmpty(elem)) return;

        elem.classList.add("pcs-list-item-active");
    }

    function handleOnClick(group) {
        onClick(group.groupId, group.groupName);
    }
}

const GroupItem = ({ group, handleOnClick, onMouseOver, onMouseOut }) => {
    return (
        <div
            id={`pcs-list-item-${group.groupId}`}
            className={`pcs-list-item pcs-list-item-${group.groupType}`}
            onClick={() => handleOnClick(group)}
            onMouseOver={handleOnMouseOver}
            onMouseOut={handleOnMouseOut}>
            <div className="prevent-selection">
                <div className={`pcs-list-item-score pcs-list-item-type-${group.groupType}`}>
                    &nbsp;
                </div>
                <div className="pcs-list-item-name elipsis">
                    {group.groupName}
                </div>
                <div className="pcs-list-item-ship-sizes">
                    {
                        group.shipSizes.map((shipSize, sIndex) =>
                            <GroupItemShipSize key={sIndex} shipSize={shipSize}/>
                        )
                    }
                </div>
            </div>
        </div>
    )

    function handleOnMouseOver() {
        if (isObjectNull(onMouseOver)) return;
        onMouseOver(group.groupId);
    }

    function handleOnMouseOut() {
        if (isObjectNull(onMouseOut)) return;
        onMouseOut();
    }
}

const GroupItemShipSize = ({ shipSize }) => {
    return (
        <div className={`pcs-circle pcs-list-item-type-${shipSize.groupType}`}>{shipSize.shipSizeDisplayName.charAt(0)}</div>
    )
}
