import { useNavigate } from 'react-router-dom';
import { HomeContent, HomeOnlineCard } from './HomeComponents';

export const HomeAllPilotagesCard = ({ canShow, isPilot }) => {

    const navigate = useNavigate();

    return (
        <HomeOnlineCard
            canShow={canShow}
            onClick={onClick}
            className={isPilot ? "" : "sm-col-span-2"}
        >
            <HomeContent icon="ship">
                <div className="k-d-grid">
                    <h2 className="home-content-title text-truncate">
                        Alle oppdrag
                    </h2>
                </div>
            </HomeContent>
        </HomeOnlineCard>
    );

    function onClick() {
        navigate('/pilotages/current');
    }
}
