import { isNullOrEmpty, isNumeric } from "./ObjectHelpers";

function getStringUrlParam(param) {
    const queryParameters = new URLSearchParams(window.location.search);
    return queryParameters.get(param);
}

function getNumericUrlParam(param) {
    return Number(getStringUrlParam(param));
}

function cleanUrl(url) {
    return url.replace('&amp;', '&');
}

function getNumericSearchParam(searchParams, param, defaultValue = 0) {
    const val = searchParams.get(param);
    if (!isNumeric(val)) return defaultValue;
    return Number(val);
}

function getSearchParam(searchParams, param, defaultValue = null) {
    const val = searchParams.get(param);
    if (isNullOrEmpty(val)) return defaultValue;
    return val;
}

function getBooleanSearchParam(searchParams, param) {
    const val = searchParams.get(param);
    
    if (isNullOrEmpty(val)) return false;
    return val.toLowerCase() === "true" ? true : false;
}

export {
    getStringUrlParam,
    getNumericUrlParam,
    cleanUrl,
    getSearchParam,
    getBooleanSearchParam,
    getNumericSearchParam

}
