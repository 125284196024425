import { useEffect } from "react";
import { useState } from "react";
import { isPilotAssignmentTentative } from "../../pilot/pilot-assignment/helpers/PilotAssignmentHelpers";
import { PilotAssignmentStatusEnums } from "../../../services/SystemNames";
import PilotApiRepository from "../../../repositories/api/PilotApiRepository";
import PilotAssignmentIdbRepository from "../../../repositories/idb/PilotAssignmentIdbRepository";
import { useNavigate } from "react-router-dom";
import { HomeCard, HomeContent } from "./HomeComponents";

export const HomePilotagesCard = ({ busyTimeout, isPilot }) => {

    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [countTotal, setCountTotal] = useState(0);
    const [countTentative, setCountTentative] = useState(0);
    const [countConfirmed, setCountConfirmed] = useState(0);
    const [countCorrectionNeeded, setCountCorrectionNeeded] = useState(0);
    const [countAssigned, setCountAssigned] = useState(0);

    useEffect(() => {
        if (!isPilot) return;

        setShow(true);
        setIsBusy(true);

        const notBusy = () => {
            setTimeout(() => {
                setIsBusy(false);
            }, busyTimeout);
        }

        const initialize = (pilotAssignments) => {
            let countTentative = 0;
            let countConfirmed = 0;
            let countCorrectionNeeded = 0;
            let countAssigned = 0;

            for (const pilotAssignment of pilotAssignments) {
                if (isPilotAssignmentTentative(pilotAssignment)) {
                    countTentative += 1;
                } else {
                    switch (pilotAssignment.pilotagePilotStatus.systemName) {
                        case PilotAssignmentStatusEnums.Confirmed:
                            countConfirmed += 1;
                            break;
                        case PilotAssignmentStatusEnums.CorrectionNeeded:
                            countCorrectionNeeded += 1;
                            break;
                        default:
                            countAssigned += 1;
                            break;
                    }
                }
            }
            setCountTotal(countTentative + countConfirmed + countCorrectionNeeded + countAssigned);
            setCountTentative(countTentative);
            setCountConfirmed(countConfirmed);
            setCountCorrectionNeeded(countCorrectionNeeded);
            setCountAssigned(countAssigned);

            notBusy();
        }

        const initializeOnlineAsync = async () => {
            const response = await PilotApiRepository.getAssignedPilotagesAsync();
            if (response.ok) {
                const data = await response.json();
                initialize(data);
            } else {
                notBusy();
            }
        }

        const initializeOfflineAsync = async () => {
            const pilotAssignments = await PilotAssignmentIdbRepository.getAllActualAsync();
            initialize(pilotAssignments);
        }

        if (navigator.onLine) {
            initializeOnlineAsync();
        } else {
            initializeOfflineAsync();
        }

        window.addEventListener('online', initializeOnlineAsync);
        window.addEventListener('offline', initializeOfflineAsync);

        return () => {
            window.removeEventListener('online', initializeOnlineAsync);
            window.removeEventListener('offline', initializeOfflineAsync);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        show && (
            <HomeCard onClick={onClick}>
                <HomeContent icon="ship" isBusy={isBusy}>
                    <div className="k-d-grid">
                        <h2 className="home-content-title">
                            {countTotal > 0
                                ? `Mine oppdrag (${countTotal})`
                                : "Mine oppdrag"}
                        </h2>
                        <div role="note">
                            {countTentative > 0 && (
                                <>
                                    {countTentative} tentativt tildelt
                                    <br />
                                </>
                            )}

                            {countAssigned > 0 && (
                                <>
                                    {countAssigned} tildelt
                                    <br />
                                </>
                            )}

                            {countConfirmed > 0 && (
                                <>
                                    {countConfirmed} bekreftet
                                    <br />
                                </>
                            )}

                            {countCorrectionNeeded > 0 && (
                                <>
                                    {countCorrectionNeeded} krever korrigering
                                    <br />
                                </>
                            )}

                            {countTentative === 0 &&
                                countConfirmed === 0 &&
                                countCorrectionNeeded === 0 &&
                                countAssigned === 0 && (
                                    <>Du har ingen aktuelle oppdrag</>
                                )}
                        </div>
                    </div>
                </HomeContent>
            </HomeCard>
        )
    );

    function onClick() {
        navigate('/pilot/pilotages/assigned');
    }
}
