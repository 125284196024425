import { useEffect, useState } from "react";
import { isEmptyObject } from "jquery";
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import { publishSuccessNotificationTopic, publishWarningNotificationTopic } from "../../../components/helpers/PubSubHelpers";
import MetaIdbRepository from "../../../repositories/idb/MetaIdbRepository";
import PilotApiRepository from "../../../repositories/api/PilotApiRepository";
import { DialogConfirm } from "../../../components/layout/dialogs/components/DialogConfirm";
import {
    getLoggedInPersonId,
    onGuestCheckAsync,
} from "components/helpers/AuthHelpers";
import { HomeCard, HomeContent } from "./HomeComponents";

export const HomeGuestDepartment = () => {
    const [guestPilotStation, setGuestPilotStation] = useState(null);
    const [showDialog, setShowDialog] = useState(false);

    const idbPrimaryKey = `GuestPilotStation-${getLoggedInPersonId()}`;

    useEffect(() => {
        if (navigator.onLine) {
            initializeOnlineAsync();
        } else {
            initializeOfflineAsync();
        }

        window.addEventListener("online", initializeOnlineAsync);
        window.addEventListener("offline", initializeOfflineAsync);

        return () => {
            window.removeEventListener("online", initializeOnlineAsync);
            window.removeEventListener("offline", initializeOfflineAsync);
        };
    }, []);

    return (
        <>
            {!isEmptyObject(guestPilotStation) && (
                <HomeCard
                    backgroundColor="var(--home-warning-bg)"
                    onClick={() => {
                        if (!navigator.onLine) return;
                        setShowDialog(true);
                    }}
                >
                    <HomeContent icon="identificationBadge">
                        <div>
                            <h2 className="home-content-title">Gjestelos</h2>
                            <div role="note">
                                {navigator.onLine ? (
                                    <>
                                        Du er gjestelos på{" "}
                                        <b>{guestPilotStation.displayName}</b>.
                                        Klikk her for å avslutte.
                                    </>
                                ) : (
                                    <>
                                        Du er gjestelos på{" "}
                                        <b>{guestPilotStation.displayName}</b>.
                                        For å avslutte må du være på nett.
                                    </>
                                )}
                            </div>
                        </div>
                    </HomeContent>
                </HomeCard>
            )}

            {showDialog && (
                <DialogConfirm
                    properties={{
                        ...DialogProperties,
                        title: "Avslutte som gjestelos",
                        onClose: () => setShowDialog(false),
                        onClick: onConfirmRemove,
                    }}
                >
                    Ønsker du å avslutte som gjestelos på{" "}
                    {guestPilotStation.displayName}?<br />
                    Når du bekrefter, vil losformidler igjen se deg under{" "}
                    {guestPilotStation.sourceDisplayName}.
                </DialogConfirm>
            )}
        </>
    );

    async function initializeOnlineAsync() {
        const data = await onGuestCheckAsync();
        setGuestPilotStation(data);
    }

    async function initializeOfflineAsync() {
        const localGuestPilotStation =
            await MetaIdbRepository.getGuestPilotStationAsync(idbPrimaryKey);
        if (!isEmptyObject(localGuestPilotStation)) {
            setGuestPilotStation(localGuestPilotStation.value);
        } else {
            setGuestPilotStation(null);
        }
    }

    async function onConfirmRemove() {
        setShowDialog(false);

        const loggedInPersonId = getLoggedInPersonId();
        const response =
            await PilotApiRepository.removeGuestPilotStationAsync(
                loggedInPersonId,
            );
        if (response.ok) {
            await MetaIdbRepository.deleteGuestPilotStationAsync(idbPrimaryKey);
            publishSuccessNotificationTopic(
                `Du ble fjernet som gjestelos fra ${guestPilotStation.displayName}`,
            );
        } else {
            publishWarningNotificationTopic(
                `Kunne ikke fjerne deg som gjestelos fra ${guestPilotStation.displayName}`,
            );
        }

        initializeOnlineAsync();
    }
};
