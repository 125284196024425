import { isArrayEmpty, isNullOrEmpty } from '../../components/helpers/ObjectHelpers';
import {
    IndexedDbNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';

const Store = IndexedDbNames.LocationStore;

const LocationIdbRepository = {

    async clearAsync() {
        await BaseIdbRepository.clearAsync(Store);
    },

    async setAsync(location) {
        await BaseIdbRepository.setAsync(Store, location.locationId, location);
    },

    async getAllAsync() {
        const result = await BaseIdbRepository.getAllAsync(Store);
        return this.orderByNameAscending(result);
    },

    async getByIdAsync(id) {
        const result = await BaseIdbRepository.getAllAsync(Store);
        return result.find(l => l.locationId === id);
    },

    async searchAsync(searchText, types = []) {
        if (isNullOrEmpty(searchText)) return [];

        const all = await BaseIdbRepository.getAllAsync(Store);
        const result = all.filter((e) => {
            let success = false;
            const lowerCaseName = e.name.toLowerCase();

            if (Object.hasOwn(e, "locationCode")) {
                const lowerCaseCode = e.locationCode.toLowerCase();
                success = lowerCaseName.startsWith(searchText.toLowerCase()) ||
                    lowerCaseCode.startsWith(searchText.toLowerCase()) ||
                    `no${lowerCaseCode}`.startsWith(searchText.toLowerCase());
            } else {
                success = lowerCaseName.startsWith(searchText.toLowerCase());
            }

            if (!isArrayEmpty(types)) {
                success = types.includes(e.systemName);
            }

            return success;
        });

        return this.orderByNameAscending(result);
    },

    orderByNameAscending(result) {
        return result
            .sort((a, b) => {
                let fa = a.name.toLowerCase();
                let fb = b.name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
    }
};

export default LocationIdbRepository;
