import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import { Label } from "../../../../../components/layout/Label";
import DateTimePicker, { DateTimePickerModes } from "../../../../../components/layout/dateTimePicker/DateTimePicker";
import { useState, useEffect } from "react";

export const LocationStartStopCard = (
    {
        title,
        subTitle,
        time,
        show,
        onShow,
        onClose,
        onChange,
        hasValidationError,
        isEditable
    }) => {

    const [value, setValue] = useState(null);

    // This useEffect is not necessary but prevents a bug where date is inadvertedly set to datetime of end location
    useEffect(() => {
        setValue(time);
    }, [time]);

    return (
        <FieldWrapper>
            <Label hasValidationError={hasValidationError} >
                {title}:
            </Label>
            <div className="k-form-field-wrap">
                <DateTimePicker
                    disabled={!isEditable}
                    hasValidationError={hasValidationError}
                    onClick={onShow}
                    title={subTitle}
                    value={value}
                    show={show}
                    onClose={onClose}
                    onChange={handleOnChange}
                    mode={DateTimePickerModes.DateTime}
                />
            </div>
        </FieldWrapper>
    )

    function handleOnChange(e) {
        setValue(e.value);
        onChange(e);
    }
}
