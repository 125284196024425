import React from 'react';

export function NoEdit() {
    return (
        <div className="pcs-no-edit">
            Evaluering av sertifikatomr&aring;der krever en bredde p&aring; mer enn 768px.
        </div>
    );

}
