import { useEffect } from 'react';
import { useState } from 'react';
import VariableCompensationOverrideReasonTypeIdbRepository from '../../../../../repositories/idb/VariableCompensationOverrideReasonTypeIdbRepository';
import { NumericTextBox } from '../../../../../components/layout/NumericTextBox';
import Spacer from '../../../../../components/layout/Spacer';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import { PilotagePilotVariableCompensationOverrideReasonTypeEnums } from '../../../../../services/SystemNames';
import { Dialog } from '../../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../../components/layout/dialogs/DialogProperties';
import TextBoxFieldWrapper from '../../../../../components/layout/fieldWrappers/TextBoxFieldWrapper';
import TextAreaFieldWrapper from '../../../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import { deepCopyObject, isNullOrEmpty, isNumeric, isObjectNull } from '../../../../../components/helpers/ObjectHelpers';

export const VariableCompensationBridgeEditDialog = ({
    onClose,
    compensation = null,
    callback }) => {

    const [canSave, setCanSave] = useState(false);
    const [hours, setHours] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [overriddenNumber, setOverriddenNumber] = useState(compensation.overridenNumber);
    const [remark, setRemark] = useState("");

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: compensation.pilotagePilotVariableCompensationName,
                onClose: onClose,
                actions: [
                    {
                        onClick: onClose,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        onClick: onSaveClickAsync,
                        disabled: !canSave,
                        icon: "ok",
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            <div className="dialog-form">

                <TextBoxFieldWrapper
                    title="Beregnet antall"
                    value={getCalculatedNumber()}
                    disabled={true}
                    readOnly={true}
                />

                <Spacer height={20}/>

                <FieldWrapper>
                    <Label className="k-form-label">
                        Overstyrt antall:
                    </Label>
                    <div className="k-form-field-wrap">
                        <div className="dialog-hours-minutes">
                            <div><NumericTextBox value={hours} onChange={onHoursChange} /></div>
                            <div>timer</div>
                            <div><NumericTextBox value={minutes} onChange={onMinutesChange} /></div>
                            <div>minutter</div>
                        </div>
                    </div>
                </FieldWrapper>

                <Spacer height={20} />

                <TextAreaFieldWrapper
                    title="Begrunnelse"
                    value={remark}
                    onChange={onDescriptionChange}
                />

            </div>
        </Dialog>
    )

    async function initializeAsync() {
        if (!isNullOrEmpty(compensation.overridenNumber)) {
            const tmpHours = Math.floor(compensation.overridenNumber);
            const tmpMinutes = Math.round((compensation.overridenNumber - tmpHours) * 60);

            setHours(tmpHours);
            setMinutes(tmpMinutes);
        }

        if (!isNullOrEmpty(compensation.pilotagePilotVariableCompensationOverrideReasonRemark)) {
            setRemark(compensation.pilotagePilotVariableCompensationOverrideReasonRemark);
        } else {
            setRemark("");
        }
    }

    async function onSaveClickAsync() {
        if (!canSave) return;

        const reasonType = await VariableCompensationOverrideReasonTypeIdbRepository.getBySystemName(PilotagePilotVariableCompensationOverrideReasonTypeEnums.Other);

        const compensationCopy = deepCopyObject(compensation);
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonRemark = remark;
        compensationCopy.overridenNumber = overriddenNumber;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonTypeId = reasonType.pilotagePilotVariableCompensationOverrideReasonTypeId;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonType = reasonType.systemName;

        callback(compensationCopy);
        onClose();
    }

    function onDescriptionChange(e) {
        setRemark(e.value);
        validate(e.value);
    }

    function onHoursChange(e) {
        setHours(e.value);
        updateOverriddenNumber(e.value, minutes);
    }

    function onMinutesChange(e) {
        setMinutes(e.value);
        updateOverriddenNumber(hours, e.value);
    }

    function updateOverriddenNumber(hours, minutes) {
        let overriddenNumber = null;

        if (isNumeric(hours) && isNumeric(minutes)) {
            overriddenNumber = (hours + (minutes / 60)).toFixed(2);
        } else {
            if (isNumeric(hours)) {
                overriddenNumber = hours;
            }
            if (isNumeric(minutes)) {
                overriddenNumber = (minutes / 60).toFixed(2);
            }
        }

        setOverriddenNumber(!isNumeric(overriddenNumber) ? null : Number(overriddenNumber));
        validate(remark, hours, minutes);
    }

    function validate(text = remark, overrideHours = hours, overrideMinutes = minutes) {
        if (!isNumeric(overrideHours) && !isNumeric(overrideMinutes)) {
            setCanSave(true);
        } else {
            setCanSave(!isNullOrEmpty(text));
        }
    }

    function getCalculatedNumber() {
        if (
            isObjectNull(compensation) ||
            (compensation.calculatedNumber === 0 || !isNumeric(compensation.calculatedNumber))
        )
        {
            return "0t 0min (0.00)";
        }

        const tmpHours = Math.floor(compensation.calculatedNumber);
        const tmpMinutes = Math.round((compensation.calculatedNumber - tmpHours) * 60);

        return `${tmpHours}t ${tmpMinutes}min (${compensation.calculatedNumber.toFixed(2)})`;
    }
}
