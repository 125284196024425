import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../dialogs/Dialog';
import { DialogProperties } from '../../../dialogs/DialogProperties';
import { Card } from '../../../card/Card';
import Spacer from '../../../Spacer';
import { CardProperties } from '../../../card/components/CardProperties';
import TextFieldWrapper from '../../../fieldWrappers/TextFieldWrapper';
import ShipApiRepository from '../../../../../repositories/api/ShipApiRepository';
import { MiniCard } from '../../../card/MiniCard';
import { BusySpinner } from '../../../overlay/components/Busy';
import Icons from '../../../icons/Icons';
import parse from 'html-react-parser';
import Moment from 'moment';
import { isArrayEmpty, isNullOrEmpty, isObjectNull } from '../../../../helpers/ObjectHelpers';
import { getPilotAssignmentStatusColorBySystemName, getPilotAssignmentStatusColorRgbaBySystemName, getPilotAssignmentTransportOrderBorderColor } from '../../../../../pages/pilot/pilot-assignment/helpers/PilotAssignmentHelpers';
import { formatShipTypeIdentifierName } from '../../GoogleMapHelpers';
import ShipImage from '../../../ShipImage';

export const PilotageDetailsDialog = ({
    marker,
    onClose
}) => {

    const [isBusy, setIsBusy] = useState(true);
    const [shipDetails, setShipDetails] = useState(null);

    useEffect(() => {

        async function initializeShipDetailAsync() {

            if (!Object.hasOwn(marker, "shipId")) return null;
            if (marker.shipId === 0) return null;

            const response = await ShipApiRepository.getShipDetailAsync(marker.shipId);
            if (response.ok) {
                const data = await response.json();
                return data;
            }

            return null;
        }

        async function initializeAsync() {
            const shipDetails = await initializeShipDetailAsync();

            setShipDetails(shipDetails);
            setIsBusy(false);
        }

        initializeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        <Dialog properties={{
            ...DialogProperties,
            size: "large",
            title: getTitle(),
            onClose: onClose,
            actions: [
                {
                    icon: "ok",
                    onClick: onClose,
                    themeColor: "primary",
                    text: "Ok"
                }
            ]
        }}>
            {
                    isBusy ?
                        <div className="google-map-dialog-busy">
                            <BusySpinner />
                        </div>
                        :
                        <>
                            <ShipInformation
                                marker={marker}
                                shipDetails={shipDetails}
                            />

                            <AssignmentInformation
                                shipDetails={shipDetails}/>

                            <PilotTransportOrderInformation
                                shipDetails={shipDetails}/>

                            <PilotInformation shipDetails={shipDetails} />
                        </>
            }

            </Dialog>
        </>
    )

    function getTitle() {
        if (isBusy || isObjectNull(shipDetails)) return marker.description;

        return shipDetails.shipInformation.name;
    }
}

const ShipInformation = ({ marker, shipDetails }) => {
    return (
        <>
            <ShipImage imoNo={marker.imoNo} description={marker.description} spaceBottom={15} />

            <Card properties={{
                ...CardProperties,
                title: hasShipDetails() ? formatShipTypeIdentifierName(shipDetails.shipInformation.type) : ""
            }}>
                <div className="container map-dialog-container">
                    {
                        hasShipDetails() ?
                            <>
                                <div className="row">
                                    <div className="col">
                                        <TextFieldWrapper
                                            title="Kj.sign"
                                            boldTitle={true}
                                            text={shipDetails.shipInformation.callSign} />
                                    </div>
                                    <div className="col">
                                        <TextFieldWrapper
                                            title="Land"
                                            boldTitle={true}
                                            text={shipDetails.shipInformation.country} />
                                    </div>
                                    <div className="col">
                                        <TextFieldWrapper
                                            title="BT"
                                            boldTitle={true}
                                            text={shipDetails.shipInformation.grossTonnage} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TextFieldWrapper
                                            title="Lengde"
                                            boldTitle={true}
                                            text={shipDetails.shipInformation.length} />
                                    </div>

                                    <div className="col">
                                        <TextFieldWrapper
                                            title="Bredde"
                                            boldTitle={true}
                                            text={shipDetails.shipInformation.breadth} />
                                    </div>

                                    <div className="col">
                                        <TextFieldWrapper
                                            title="Maks DPG"
                                            boldTitle={true}
                                            text={shipDetails.shipInformation.draught} />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="row">
                                    <div className="col">
                                        <TextFieldWrapper
                                            title="Kj.sign"
                                            boldTitle={true}
                                            text={marker.callSign} />
                                    </div>
                                    <div className="col"/>
                                    <div className="col"/>
                                </div>
                                <Spacer height={10} />
                                <div className="row">
                                    <div className="col-auto">
                                        <Icons iconName="warning"/>
                                    </div>
                                    <div className="col" style={{
                                        paddingTop: 3
                                    } }>
                                        <b>Fartøyinformasjon er ikke registrert i grunnlagsdata.</b>
                                    </div>
                                </div>
                            </>
                    }

                </div>
                {
                    hasShipContactInformation() &&
                        <>
                            <Spacer height={10} />
                            <div className="container map-dialog-container">
                                <div className="row">
                                <div className="col">
                                    <TextFieldWrapper
                                        title="Telefon"
                                        boldTitle={true}
                                        text={formatContactInformation(shipDetails.shipContactInformation.shipPhoneNo)}
                                        textIsLink={!isNullOrEmpty(shipDetails.shipContactInformation.shipPhoneNo)}
                                    />
                                </div>
                                <div className="col">
                                    <TextFieldWrapper
                                        title="Mobil"
                                        boldTitle={true}
                                        text={formatContactInformation(shipDetails.shipContactInformation.shipMobilePhoneNo)}
                                        textIsLink={!isNullOrEmpty(shipDetails.shipContactInformation.shipMobilePhoneNo)}
                                    />
                                </div>
                                <div className="col">
                                    <TextFieldWrapper
                                        title="Vakttelefon"
                                        boldTitle={true}
                                        text={formatContactInformation(shipDetails.shipContactInformation.shipAssistancePhoneNo)}
                                        textIsLink={!isNullOrEmpty(shipDetails.shipContactInformation.shipAssistancePhoneNo)}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Card>

            <Spacer height={15} />
        </>
    )

    function hasShipDetails() {
        return !isObjectNull(shipDetails);
    }

    function hasShipContactInformation() {
        if (!hasShipDetails()) return false;

        const shipContactInformation = shipDetails.shipContactInformation;
        if (isObjectNull(shipContactInformation)) return false;

        return !isNullOrEmpty(shipContactInformation.shipPhoneNo) ||
            !isNullOrEmpty(shipContactInformation.shipMobilePhoneNo) ||
            !isNullOrEmpty(shipContactInformation.shipAssistancePhoneNo);
    }

    function formatContactInformation(info) {
        if (isNullOrEmpty(info)) return "Ikke registrert";
        return parse(info.replace(' ', '&nbsp;'));
    }
}

const AssignmentInformation = ({ shipDetails }) => {
    return (
        (hasPilotage() || hasAgentInformation()) &&
        <Card properties={{
            ...CardProperties,
            title: "Losoppdrag"
        }}>
            {
                hasPilotage() &&
                    <>
                        <div className="container map-dialog-container">
                            <div className="row">
                                <div className="col-auto">
                                    Fra
                                </div>
                                <div className="col text-end">
                                    {shipDetails.pilotageInformation.pilotageFromLocation}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-auto">
                                    Til
                                </div>
                                <div className="col text-end">
                                    {shipDetails.pilotageInformation.pilotageToLocation}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-auto">
                                    Start losing
                                </div>
                                <div className="col text-end">
                                    {Moment(shipDetails.pilotageInformation.pilotageStartTime).format("DD.MM HH:mm")}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-auto">
                                    Aktuell DPG
                                </div>
                                <div className="col text-end">
                                    {shipDetails.pilotageInformation.actualDraft}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-auto">
                                    Airdraft
                                </div>
                                <div className="col text-end">
                                    {shipDetails.pilotageInformation.airDraft}
                                </div>
                            </div>
                        </div>
                        <Spacer height={10}/>
                    </>
            }
            {
                hasAgentInformation() &&
                    <div className="container map-dialog-container">
                        <div className="row">
                            <div className="col-auto">
                                Agent
                            </div>
                            <div className="col text-end">
                                {shipDetails.agentInformation.agentName}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                Vakttelefon
                            </div>
                            <div className="col text-end">
                                <a href={`tel: ${shipDetails.agentInformation.agentAssistancePhone}`} className="phone-link">
                                    {`${shipDetails.agentInformation.agentAssistancePhone}`}
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                Kontaktperson
                            </div>
                            <div className="col text-end">
                                {formatAgentInformation(shipDetails.agentInformation.agentContactPerson)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                Telefon
                            </div>
                            <div className="col text-end">
                                {formatAgentInformation(shipDetails.agentInformation.agentContactPhone)}
                            </div>
                        </div>
                    </div>
            }
        </Card>
    )

    function hasAgentInformation() {
        if (isObjectNull(shipDetails)) return false;
        if (isObjectNull(shipDetails.agentInformation)) return false;

        const agentInformation = shipDetails.agentInformation;

        return  !isNullOrEmpty(agentInformation.agentName) ||
            !isNullOrEmpty(agentInformation.agentAssistancePhone) ||
            !isNullOrEmpty(agentInformation.agentContactPerson) ||
            !isNullOrEmpty(agentInformation.agentContactPhone);
    }

    function hasPilotage() {
        if (isObjectNull(shipDetails)) return false;
        return !isObjectNull(shipDetails.pilotageInformation);
    }

    function formatAgentInformation(info) {
        if (!isNullOrEmpty(info)) return info;
        return "Ikke registrert";
    }
}

const PilotTransportOrderInformation = ({ shipDetails }) => {

    if (isObjectNull(shipDetails)) return;

    if (isArrayEmpty(shipDetails.transportOrderInformation)) return;

    return (
        shipDetails.transportOrderInformation.map((transportOrder, index) =>
            <div key={index}>
                <Spacer height={15} />
                <Card properties={{
                    ...CardProperties,
                    title: "Tilbringerordre"
                }}>
                    <div className="container map-dialog-container">
                        <div className="row m-b-10">
                            <div className="col-auto">
                                <MiniCard
                                    item={{
                                        borderColor: getPilotAssignmentTransportOrderBorderColor(transportOrder.transportOrderStatus),
                                        text: `${getTimeType(transportOrder)} - ${transportOrder.transportOrderStatusName.toLowerCase()}`
                                    }}
                                />
                            </div>
                            <div className="col text-end">
                                <a href={`tel: ${transportOrder.transportContactMobilePhone}`} className="pilot-phone-link">
                                    {`${transportOrder.mobilePhone}`}
                                </a>
                            </div>
                        </div>
                        <div className="row m-b-5">
                            <div className="col-auto">
                                Fra: {transportOrder.fromLocationName}
                            </div>
                        </div>
                        <div className="row m-b-5">
                            <div className="col-auto">
                                Til: {transportOrder.toLocationName}
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    )

    function getTimeType(transportOrder) {
        if (transportOrder.transportOrderTimeType === "DELIVER_TIME") return "Levering";
        return "Henting";
    }
}

const PilotInformation = ({ shipDetails }) => {

    if (isObjectNull(shipDetails)) return;
    if (isArrayEmpty(shipDetails.pilotInformation)) return;

    return (
        <>
            <Spacer height={15} />
            <Card properties={{
                ...CardProperties,
                title: "Loser"
            }}>
                <div className="container map-dialog-container">
                    {
                        shipDetails.pilotInformation.map((pilot, index) =>
                            <div className="row m-b-5" key={index}>
                                <div className="col-auto">
                                    <MiniCard
                                        item={{
                                            borderColor: getPilotAssignmentStatusColorBySystemName(pilot.pilotagePilotStatus),
                                            backgroundColor: getPilotAssignmentStatusColorRgbaBySystemName(pilot.pilotagePilotStatus),
                                            text: `${pilot.lastname} ${pilot.initials}`,
                                            isExaminer: pilot.isExaminer,
                                            isTrainee: pilot.isTrainee,
                                            isApprentice: pilot.isApprentice
                                        }}
                                    />
                                </div>
                                <div className="col text-end">
                                    <a href={`tel: ${pilot.phoneNumber}`} className="pilot-phone-link">
                                        {`${pilot.phoneNumber}`}
                                    </a>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Card>
        </>
    )
}
