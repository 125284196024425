import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import { MapMarker } from './MapMarker';
import SourceApiRepository from '../../../../repositories/api/SourceApiRepository';
import { isNullOrEmpty, isObjectNull } from '../../../helpers/ObjectHelpers';
import { ShipTypes, createShipPositionInformationObject, hideLoader, markersVisibleAtZoom, showLoader } from '../GoogleMapHelpers';
import { getSearchParam } from '../../../helpers/UrlHelpers';
import { useSearchParams } from 'react-router-dom';

export const MapAis = forwardRef(({
    properties,
    toggleMarkerInfoWindows,
    toggleBoundsChanged,
    toggleDragEnd,
    toggleZoomChanged,
    toggleResize,
    setToggleMarkerInfoWindows,
}, ref) => {

    useImperativeHandle(ref, () => ({
        onBoundsChanged() {
            if (!map || localRef.hasInitialized) return;

            setTimeout(() => {
                searchAisAsync();
            }, 250);

            localRef.hasInitialized = true;
        },
        onDragEnd() {
            if (!map) return;

            searchAisAsync();
        },
        onZoomChanged() {
            if (!map) return;

            setCurrentZoom(map.getZoom());
            searchAisAsync();
        },
        onResize() {
            if (!map) return;
            searchAisAsync();
        },
        onUnselect() {
            handleOnUnselect();
        }
    }));

    const map = useMap();
    const [searchParams] = useSearchParams();
    const markerRefs = useRef([]);

    const [shipMarkers] = useState([]);
    const [currentZoom, setCurrentZoom] = useState(0);
    const [toggleSelected, setToggleSelected] = useState(false);

    const componentRef = useRef({
        hasInitialized: false,
        isBusy: false
    });
    const { current: localRef } = componentRef;
    
    useEffect(() => {
        if (!map ||
            localRef.hasInitialized ||
            isNullOrEmpty(toggleBoundsChanged)) return;

        setTimeout(() => {
            searchAisAsync();
        }, 250);

        localRef.hasInitialized = true;
        

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties, toggleBoundsChanged]);

    useEffect(() => {
        if (!map ||
            isNullOrEmpty(toggleDragEnd)) return;

        searchAisAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties, toggleDragEnd]);

    useEffect(() => {
        if (!map ||
            isNullOrEmpty(toggleZoomChanged)) return;

        setCurrentZoom(map.getZoom());
        searchAisAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties, toggleZoomChanged]);

    useEffect(() => {
        if (!map ||
            isNullOrEmpty(toggleResize)) return;
            
        searchAisAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties, toggleResize]);

    useEffect(() => {
        const mmsi = Number(getSearchParam(searchParams, "mmsi", 0));
        if (mmsi === 0) return;
        for (const ref of markerRefs.current) {
            if (isNullOrEmpty(ref)) continue;
            ref.onSelectedShip(mmsi);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleSelected]);

    return (
        shipMarkers.map((marker, index) =>
            <MapMarker
                key={index}
                ref={(e) => (markerRefs.current[index] = e)}
                marker={marker}
                currentZoom={currentZoom}
                toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                onUnselect={handleOnUnselect}
            />
        )
    )

    async function searchAisAsync() {

        const canSearch = map.getZoom() >= markersVisibleAtZoom;

        if (!canSearch || localRef.isBusy) return;
        
        busy(true);

        const bounds = map.getBounds();
        const southWest = bounds.getSouthWest();
        const nothEast = bounds.getNorthEast();
        const width = nothEast.lat() - southWest.lat();
        const height = nothEast.lng() - southWest.lng();
        const excludeShipId = Number(properties.shipId);

        const response = await SourceApiRepository.getShipPositionsWithinAreaAsync(southWest.lat(), southWest.lng(), width, height);
        if (response.ok) {
            const data = await response.json();
            
            for (const position of data) {
                if (excludeShipId > 0 && position.shipID === excludeShipId) continue;

                const latLng = new window.google.maps.LatLng(position.latitude, position.longitude);
                const existing = shipMarkers.find(s => s.mmsi === position.mmsi);
                
                if (!isObjectNull(existing)) {
                    existing.position = latLng;
                    existing.courseOverGround = position.courseOverGround;
                    existing.speedOverGround = position.speedOverGround;
                } else {
                    const shipMarker = createShipPositionInformationObject(position, position.shipTypeIdentifier === ShipTypes.PilotVessel);
                    shipMarker.shipTypeGroupIdentifier = position.shipTypeGroupIdentifier;
                    shipMarker.shipTypeIdentifier = position.shipTypeIdentifier;
                    shipMarker.length = position.length;
                    shipMarkers.push(shipMarker);
                }
            }
        }

        setToggleSelected(!toggleSelected);

        busy(false);
    }

    function handleOnUnselect() {
        for (const ref of markerRefs.current) {
            if (isNullOrEmpty(ref)) continue;
            ref.onSelectedShip(null);
        }
    }

    function busy(val) {
        localRef.isBusy = val;
        properties.handleOnSetIsBusy(busy);
        if (val) {
            showLoader();
        } else {
            hideLoader();
        }
    }
})
