import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { VariableCompensationItem } from './VariableCompensationCard';
import { VariableCompensationBoardingDialog } from './dialogs/VariableCompensationBoardingDialog';
import { Card } from '../../../../components/layout/card/Card';
import { PilotagePilotVariableCompensationTypeEnums, PilotBoardingTypeEnums } from '../../../../services/SystemNames';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import { PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import { deepCopyObject, errorsContainsFromObject, isArrayEmpty, isNullOrEmpty, isNumeric, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { isPilotAssignmentCompensationEqual } from '../helpers/PilotAssignmentHelpers';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

const initialState = {
    hasBoardingVesselValidationError: false,
    hasHelicopterValidationError: false,
    showDialog: false,
    boardingVessel: {},
    boardingHelicopter: {},
    isEditable: false,
    currentCompensation: null,
    isDirty: false
}

export const VariableCompensationBoardingCard = forwardRef((
    {
        onPilotAssignmentChanged,
        assignmentService
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initialize();
        }, 
        onLocationChanged() {
            initialize();
        },
        onValidationChanged(codeStrings) {
            setState((prev) => ({
                ...prev,
                hasBoardingVesselValidationError: errorsContainsFromObject(codeStrings, {
                    BoardingVessel: PilotAssignmentErrorCodes.BoardingVessel,
                }),
                hasHelicopterValidationError: errorsContainsFromObject(codeStrings, {
                    BoardingHelicopter: PilotAssignmentErrorCodes.BoardingHelicopter,
                })
            }));
        }
    }));

    const [{
        hasBoardingVesselValidationError,
        hasHelicopterValidationError,
        showDialog,
        boardingVessel,
        boardingHelicopter,
        isEditable,
        currentCompensation,
        isDirty

    }, setState] = useState(initialState);

    const setShowDialog = (e) => {
        setState((prev) => ({
            ...prev,
            showDialog: e
        }));
    }

    const setBoardingVessel = (e) => {
        setState((prev) => ({
            ...prev,
            boardingVessel: e
        }));
    }

    const setBoardingHelicopter = (e) => {
        setState((prev) => ({
            ...prev,
            boardingHelicopter: e
        }));
    }

    useEffect(() => {
        initialize();
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, initialize);


        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Card
                properties={{
                    ...CardProperties,
                    title: "Bording / kvitting tillegg",
                    hasValidationError: (hasBoardingVesselValidationError || hasHelicopterValidationError),
                    isDirty: isDirty
                }}>
                <div className="row">
                    <VariableCompensationItem
                        title="Losb&aring;t"
                        hasValidationError={hasBoardingVesselValidationError}
                        isEditable={isEditable}
                        compensation={boardingVessel}
                        onEdit={onEditBoarding}
                        onDelete={onDeleteBoarding}
                        decimals={0}
                    />
                    <VariableCompensationItem
                        title="Helikopter"
                        hasValidationError={hasHelicopterValidationError}
                        isEditable={isEditable}
                        compensation={boardingHelicopter}
                        onEdit={onEditBoarding}
                        onDelete={onDeleteBoarding}
                        decimals={0}
                    />
                </div>
            </Card>

            {
                showDialog && 
                <VariableCompensationBoardingDialog
                    onClose={() => setShowDialog(false)}
                    compensation={currentCompensation}
                    callback={onBoardingCallback}
                />
            }
            
        </>
    )

    function initialize() {

        const pilotAssignment = assignmentService.assignment;
        const calculated = getCalculatedBoardingTypes(pilotAssignment);

        setState((prev) => ({
            ...prev,
            isEditable: assignmentService.isEditable,
            isDirty: getIsDirty(pilotAssignment),
            boardingVessel: getCompensation(pilotAssignment, PilotagePilotVariableCompensationTypeEnums.BoardingVessel, calculated.calculatedPilotBoat),
            boardingHelicopter: getCompensation(pilotAssignment, PilotagePilotVariableCompensationTypeEnums.BoardingHelicopter, calculated.calculatedHelicopter)
        }));
    }

    function getIsDirty(pilotAssignment) {
        const originalArr = pilotAssignment.original.variableCompensations;
        const updatedArr = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;

        let isEqual = isPilotAssignmentCompensationEqual(originalArr, updatedArr, "BOARDING_VESSEL");
        
        if (isEqual) {
            isEqual = isPilotAssignmentCompensationEqual(originalArr, updatedArr, "BOARDING_HELICOPTER");
        }

        return !isEqual;
    }

    function getCompensation(pilotAssignment, systemName, calculatedNumber) {
        const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;
        if (isArrayEmpty(compensations)) return null;

        const compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === systemName);

        if (isObjectNull(compensation)) return null;

        compensation.calculatedNumber = calculatedNumber;
        
        return compensation;
    }

    function onEditBoarding(compensation) {
        if (!isEditable) return;

        setState((prev) => ({
            ...prev,
            currentCompensation: compensation,
            showDialog: true
        }));
    }

    function onDeleteBoarding(compensation) {
        const compensationCopy = deepCopyObject(compensation);
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonRemark = null;
        compensationCopy.overridenNumber = null;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonTypeId = null;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonType = null;

        onBoardingCallback(compensationCopy);
    }

    async function onBoardingCallback(compensation) {
        if (compensation.pilotagePilotVariableCompensationSystemName === PilotagePilotVariableCompensationTypeEnums.BoardingVessel) {
            setBoardingVessel(compensation);
        } else {
            setBoardingHelicopter(compensation);
        }

        await assignmentService.onUpdateVariableCompensationOverriddenNumberAsync(compensation);
        onPilotAssignmentChanged();
    }

    function getCalculatedBoardingTypes(pilotAssignment) {

        const legs = [];
        for (let i = 0; i < pilotAssignment.locations.length - 1; i++) {
            legs.push({
                from: pilotAssignment.locations[i],
                to: pilotAssignment.locations[i + 1]
            });
        }

        return {
            calculatedPilotBoat: getCalculatedByBoardingType(PilotBoardingTypeEnums.PilotBoat, legs),
            calculatedHelicopter: getCalculatedByBoardingType(PilotBoardingTypeEnums.Helicopter, legs)
        }
    }

    function getCalculatedByBoardingType(pilotBoardingType, legs) {

        if (isArrayEmpty(legs)) return 0;

        const onBoarding = legs.filter(l =>
            !isNullOrEmpty(l.from.fromTime) &&
            !l.from.isPilotBoardingTypeOffBoarding &&
            !isObjectNull(l.from.pilotBoardingType) &&
            l.from.pilotBoardingType.systemName === pilotBoardingType).length;

        let offBoarding = legs.filter(l =>
            !isNullOrEmpty(l.to.toTime) &&
            l.to.isPilotBoardingTypeOffBoarding &&
            !isObjectNull(l.to.pilotBoardingType) &&
            l.to.pilotBoardingType.systemName === pilotBoardingType).length;

        if (isNumeric(onBoarding) && !isNumeric(offBoarding)) {
            return onBoarding;
        }
        if (!isNumeric(onBoarding) && isNumeric(offBoarding)) {
            return offBoarding;
        }
        if (!isNumeric(onBoarding) && !isNumeric(offBoarding)) {
            return 0;
        }

        return onBoarding + offBoarding;
        
    }
    
})
