import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Card } from '../../../../components/layout/card/Card';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import Spacer from '../../../../components/layout/Spacer';
import { TextBox } from "@progress/kendo-react-inputs";
import { PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import { errorsContainsFromObject, isNumeric } from '../../../../components/helpers/ObjectHelpers';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const TowingCard = forwardRef((
    {
        onPilotAssignmentChanged,
        assignmentService
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initialize();
        },
        onValidationChanged(codeStrings) {
            const errorCodes = {
                GrossTonnageIsMissing: PilotAssignmentErrorCodes.GrossTonnageIsMissing,
                GrossTonnageMustBeLarger: PilotAssignmentErrorCodes.GrossTonnageMustBeLarger,
                GrossTonnageCannotBeLarger: PilotAssignmentErrorCodes.GrossTonnageCannotBeLarger
            };

            setHasValidationError(errorsContainsFromObject(codeStrings, errorCodes));
        }
    }));

    const [isEditable, setIsEditable] = useState(false);
    const [show, setShow] = useState(false);
    const [value, setValue] = useState("");
    const [hasValidationError, setHasValidationError] = useState(false);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic);
        initialize()
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, [])

    return (
        show &&
        <>
            <Card
                properties={{
                ...CardProperties,
                    title: "Samlet BT ved slep",
                    hasValidationError: hasValidationError,
            }}>
                <TextBox
                    disabled={!isEditable}
                    value={value}
                    onChange={onChangeAsync}
                    maxLength={10}
                    style={{
                        width: 200
                    } }
            />
            </Card>
            <Spacer height={30} />
        </>
    )

    function initialize() {
        const pilotAssignment = assignmentService.assignment;
        
        setShow(pilotAssignment.pilotage.isTowing);
        setIsEditable(assignmentService.isEditable);

        if (!isNumeric(pilotAssignment.pilotage.towingGrossTonnage)) return;

        setValue(`${pilotAssignment.pilotage.towingGrossTonnage}`);
    }

    function handlePubSubTopic() {
        initialize();
    }

    async function onChangeAsync(e) {
        
        if (!isEditable) return;

        await assignmentService.onAddTowingTonnageAsync(e.value);

        setValue(e.value);
        onPilotAssignmentChanged();
    }
})
