import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { LocationCard } from './LocationCard';
import PubSub from 'pubsub-js';
import Spacer from '../../../../components/layout/Spacer';
import { LocationSearchDialog } from '../../../../components/layout/dialogs/LocationSearchDialog';
import { DurationCard } from './DurationCard';
import Slide from '../../../../components/layout/Slide';
import { deepCopyObject, isNullOrEmpty, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const StandardTripCard = forwardRef((
    {
        onLocationChanged,
        assignmentService,
    }, ref) => {

    const locationRefs = useRef([]);
    const durationRef = useRef();

    const [codeStrings, setCodeStrings] = useState("");

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initialize(true);
        },
        async onWastedTripChanged(isWasted) {
            setShow(!isWasted);
            const pilotAssignment = assignmentService.assignment;
            onLocationChanged(pilotAssignment);
        },
        onValidationChanged(codeStrings) {
            setCodeStrings(codeStrings);
        }
    }));

    const [show, setShow] = useState(false);
    const [locations, setLocations] = useState(deepCopyObject(assignmentService.locations))
    const [isEditable, setIsEditable] = useState(false);
    const [showSearchDialog, setShowSearchDialog] = useState(false);
    const [locationBefore, setLocationBefore] = useState(null);
    const [locationAfter, setLocationAfter] = useState(null);

    useEffect(() => {

        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
        
    }, []);

    useEffect(() => {
        for (const ref of locationRefs.current) {
            if (isNullOrEmpty(ref)) continue;
            ref.onInitializeAsync();
        }
    }, [locations])

    return (
        <>
            <Slide show={show}>
                <div className={`locations`}>
                    {
                        locations.map((location, index) => {
                            return (<LocationCard
                                ref={(e) => (locationRefs.current[index] = e)}
                                key={`${index}-${location.locationId}`}
                                index={index}
                                assignmentService={assignmentService}
                                codeStrings={codeStrings}
                                isEditable={isEditable}
                                onSearch={onSearchLocations}
                                onAfterChange={onAfterChange}
                                onDelete={onDeleteLocationAsync}
                            />);
                        })
                    }
                    <Spacer height={30} />
                    <DurationCard
                        ref={durationRef}
                        assignmentService={assignmentService}
                    />

                </div>

                <Spacer height={30} />
        </Slide >

            {
                showSearchDialog &&
                <LocationSearchDialog
                    onClose={() => setShowSearchDialog(false)}
                    callback={onSearchLocationsCallbackAsync}
                    locationBefore={locationBefore}
                    locationAfter={locationAfter}
                />
            }
        </>
    )

    function handlePubSubTopic() {
        initialize();
    }

    function initialize(notifyLocations = false) {
        const pilotAssignment = assignmentService.assignment;
        setShow(!pilotAssignment.isWastedTrip);
        setIsEditable(assignmentService.isEditable);

        onUpdateLocations();

        if (!notifyLocations) return;

        onLocationsChanged();
    }

    function onLocationsChanged() {
        if (!isObjectNull(durationRef.current)) {
            durationRef.current.onLocationsChanged();
        }

        for (const ref of locationRefs.current) {
            if (isNullOrEmpty(ref)) continue;
             ref.onLocationsChanged();
        }
    }

    function onAfterChange() {
        if (isObjectNull(durationRef.current)) return;
        
        durationRef.current.onLocationsChanged();
        onLocationChanged();
    }

    function onSearchLocations(obj) {
        const locationBefore = assignmentService.getLocationBySequenceNo(obj.sequenceNo - 1);

        setLocationBefore(locationBefore);
        setLocationAfter(obj);

        setShowSearchDialog(true);
    }

    async function onSearchLocationsCallbackAsync(obj, objBefore) {
        await assignmentService.onAddLocationAsync(obj, objBefore);

        onUpdateLocations();
        onLocationChanged();
    }

    async function onDeleteLocationAsync(obj) {
        await assignmentService.onDeleteLocationAsync(obj);

        onUpdateLocations();
        onLocationChanged();
    }

    function onUpdateLocations() {
        setLocations(deepCopyObject(assignmentService.locationsSortedBySequenceNo));
    }
})
