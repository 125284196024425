import React from 'react';
import {
    CardHeader as KendoCardHeader,
} from "@progress/kendo-react-layout";
import parse from 'html-react-parser';
import { DropDownButton } from "@progress/kendo-react-buttons";
import Icons from '../../icons/Icons';
import { Button } from "@progress/kendo-react-buttons";
import { CopyToClipboard as CTC } from 'react-copy-to-clipboard';
import { isNullOrEmpty, stopPropagation } from '../../../helpers/ObjectHelpers';
import { publishSuccessNotificationTopic } from '../../../helpers/PubSubHelpers';
import { DropDownButtonActionRender } from '../../render/DropDownButtonActionRender';

export const CardContentHeader = (
    {
        properties = {},
        isSwipeLeftActive = false,
        toggleIsSwipeLeftActive,
        touchHandlers
    }) => {
        
    return (
        (properties.hasHeader()) &&
            <KendoCardHeader>
                <div
                    className={`card-item-content-header ${properties.hasHeaderContent() ? '' : 'card-item-content-header-empty'}`}
                    style={{ paddingLeft: properties.isBorderLeftFat ? 10 : 2 }}>

                    <div
                        {...touchHandlers}
                        className={`card-item-content-header-title ${properties.titleClassName}`}>
                        <div>
                            {parse(properties.title)}
                        </div>
                        <div>
                            {
                                (!isNullOrEmpty(properties.clipboardText)) &&
                                <CTC text={properties.clipboardText} onCopy={() => publishSuccessNotificationTopic("Teksten ble kopiert til utklippstavlen.")}>
                                    <Icons iconName="copy" dimensions={14} />
                                </CTC>
                            }
                        </div>
                    </div>
                    <div
                        {...touchHandlers}
                        className={`${!isNullOrEmpty(properties.subTitle) ? "card-item-content-header-sub-title" : ""} ${properties.subTitleClassName}`}>
                        {
                            !isNullOrEmpty(properties.subTitle) &&
                            properties.subTitle
                        }
                    </div>
                    <div
                        id="card-item-content-header-actions"
                        className="card-item-content-header-actions">
                        {
                            properties.actions.map((action, index) => {
                                switch (action.type) {
                                    case "items":
                                        return (
                                            <div key={index} style={{ background: properties.borderColor }}>
                                                <DropDownButton
                                                    iconClass="fa-solid fa-ellipsis-vertical"
                                                    className="header-action"
                                                    items={action.items}
                                                    itemRender={DropDownButtonActionRender}
                                                    textField="name"
                                                    themeColor="light"
                                                    fillMode="flat"
                                                    borderRadius="large"
                                                    onItemClick={handleItemsActionOnClick}
                                                    size="medium"
                                                />
                                            </div>
                                        )
                                    default:
                                        return (
                                            <div key={index} style={{ background: properties.borderColor }} className={action.className}>
                                                <Button
                                                    key={index}
                                                    onClick={(e) => handleActionOnClick(e, action)}
                                                    className="header-action"
                                                    fillMode="flat">
                                                    <Icons
                                                        iconName={action.icon}
                                                        color={action.iconColor}
                                                        className="header-action"
                                                        dimensions={24} />
                                                </Button>
                                            </div>
                                        )
                                }
                            })
                        }
                        {
                            (properties.canDelete() && !isSwipeLeftActive) &&
                                <div style={{ background: properties.borderColor }}>
                                    <Button
                                        onClick={handleOnDeleteClick}
                                        className="header-action card-item-content-header-action-delete"
                                        fillMode="flat">
                                        <Icons
                                            iconName="trash"
                                            color={properties.iconColor}
                                            className="header-action"
                                            dimensions={24} />
                                    </Button>
                                </div>
                        }
                    </div>
                </div>
            </KendoCardHeader>
    );

    function handleItemsActionOnClick(e) {
        stopPropagation(e);
        toggleIsSwipeLeftActive(false);
        e.item.onClick();
    }

    function handleActionOnClick(e, action) {
        stopPropagation(e);
        toggleIsSwipeLeftActive(false);
        action.onClick();
    }

    function handleOnDeleteClick(e) {
        stopPropagation(e);
        properties.onDeleteClick();
    }
};
