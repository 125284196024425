import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import DateTimePicker, { DateTimePickerModes } from '../../../../components/layout/dateTimePicker/DateTimePicker';
import { PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import Spacer from '../../../../components/layout/Spacer';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import { Label } from '../../../../components/layout/Label';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const TimeDepartureReturnCard = forwardRef((
    {
        initializeIsDirty,
        assignmentService
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initialize();
        },
        onValidationChanged(codeStrings) {
            setHasDepartureTimeValidationError(codeStrings.includes(PilotAssignmentErrorCodes.PilotDepartureTimeIsMissing));
            setHasReturnTimeValidationError(codeStrings.includes(PilotAssignmentErrorCodes.PilotReturnTimeIsMissing));
        }
    }));

    const [showDepartureTime, setShowDepartureTime] = useState(false);
    const [showReturnTime, setShowReturnTime] = useState(false);
    const [departureTime, setDepartureTime] = useState(null);
    const [returnTime, setReturnTime] = useState(null);
    const [hasDepartureTimeValidationError, setHasDepartureTimeValidationError] = useState(false);
    const [hasReturnTimeValidationError, setHasReturnTimeValidationError] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, [])

    return (
        <>
            <Spacer height={10} />

            <FieldWrapper>
                <Label hasValidationError={hasDepartureTimeValidationError}>
                    Avreise:
                </Label>
                <div className="k-form-field-wrap">
                    <DateTimePicker
                        disabled={!isEditable}
                        hasValidationError={hasDepartureTimeValidationError}
                        onClick={() => setShowDepartureTime(true)}
                        title="Velg avreise tid"
                        value={departureTime}
                        show={showDepartureTime}
                        onClose={() => setShowDepartureTime(false)}
                        mode={DateTimePickerModes.DateTime}
                        onChange={onDepartureTimeChangeAsync} />
                </div>
            </FieldWrapper>

            <Spacer height={5} />

            <FieldWrapper>
                <Label hasValidationError={hasReturnTimeValidationError}>
                    Retur:
                </Label>
                <div className="k-form-field-wrap">
                    <DateTimePicker
                        disabled={!isEditable}
                        hasValidationError={hasReturnTimeValidationError}
                        onClick={() => setShowReturnTime(true)}
                        title="Velg returtid"
                        value={returnTime}
                        show={showReturnTime}
                        onClose={() => setShowReturnTime(false)}
                        mode={DateTimePickerModes.DateTime}
                        onChange={onReturnTimeChangeAsync} />
                </div>
            </FieldWrapper>
        </>
    )

    function initialize() {
        const pilotAssignment = assignmentService.assignment;

        setDepartureTime(pilotAssignment.departureTime);
        setReturnTime(pilotAssignment.returnTime);
        setIsEditable(assignmentService.isEditable);
    }

    function handlePubSubTopic() {
        initialize();
    }

    async function onDepartureTimeChangeAsync(e) {
        setDepartureTime(e.value);
        await assignmentService.onPilotDepartureTimeAsync(e.value);
        initializeIsDirty();
    }

    async function onReturnTimeChangeAsync(e) {
        setReturnTime(e.value);
        await assignmentService.onPilotReturnTimeAsync(e.value);
        initializeIsDirty();
    }
})
