
import PubSub from 'pubsub-js';
import uuid from 'react-uuid';

function publishLoggedInTopic() {
    PubSub.publish(PubSubTopics.UpdateHeader, {
        action: PubSubTopics.LoggedIn
    });
    PubSub.publish(PubSubTopics.BurgerMenu, {
        action: PubSubTopics.LoggedIn
    });
}

function publishLogoutTopic() {
    PubSub.publish(PubSubTopics.UpdateHeader, {
        action: PubSubTopics.Logout
    });
    PubSub.publish(PubSubTopics.BurgerMenu, {
        action: PubSubTopics.Logout
    });
    PubSub.publish(PubSubTopics.Logout);
}

function publishReloadAlertsTopic() {
    PubSub.publish(PubSubTopics.ReloadAlerts);
}

function publishHeaderTopic(
    title,
    subTitle = "",
    canGoBack = true,
    canCopyTitle = false,
    canCopySubTitle = false) {
    PubSub.publish(PubSubTopics.UpdateHeader, {
        action: PubSubTopics.UpdateHeader,
        title: title,
        canGoBack: canGoBack,
        canCopyTitle: canCopyTitle,
        canCopySubTitle: canCopySubTitle,
        subTitle: subTitle
    });
}

function publishSuccessNotificationTopic(content) {
    publishNotificationTopic(content, "success", 0, true);
}

function publishInfoNotificationTopic(content) {
    publishNotificationTopic(content, "info", 0, true);
}

function publishWarningNotificationTopic(content) {
    publishNotificationTopic(content, "warning");
}

function publishErrorNotificationTopic(content, status = 0) {
    publishNotificationTopic(content, "error", status);
}

function publishErrorNotificationTopicWithFadeout(content, status = 0) {
    publishNotificationTopic(content, "error", status, true);
}

function publishNotificationTopic(content, type, status = 0, fadeout = false) {
    PubSub.publish(PubSubTopics.Notification, {
        id: uuid(),
        text: content,
        type: type,
        status: status,
        fadeout: fadeout
    });
}

function publishCloseMenuTopic() {
    PubSub.publish(PubSubTopics.CloseMenu);
}

function publishRefreshedDataTopic() {
    PubSub.publish(PubSubTopics.RefreshedData);
}

function publishUpdateServiceWorkerTopic(registration) {
    PubSub.publish(PubSubTopics.UpdateServiceWorker, registration);
}

function publishPilotAssignmentIsEdibleChangedTopic() {
    PubSub.publish(PubSubTopics.PilotAssignmentIsEdibleChanged);
}

function publishOnFeatureMouseOver(data) {
    PubSub.publish(PubSubTopics.MapEvent,
        {
            event: PubSubTopics.onFeatureMouseOver,
            data: data
        }
    );
}

function publishOnFeatureMouseOut() {
    PubSub.publish(PubSubTopics.MapEvent, {
        event: PubSubTopics.onFeatureMouseOut,
    });
}

function publishOnPcsFeatureFocus(data) {
    PubSub.publish(PubSubTopics.MapEvent, {
        event: PubSubTopics.onPcsFeatureFocus,
        data: data
    });
}

export {
    publishLoggedInTopic,
    publishLogoutTopic,
    publishReloadAlertsTopic,
    publishHeaderTopic,
    publishSuccessNotificationTopic,
    publishInfoNotificationTopic,
    publishWarningNotificationTopic,
    publishErrorNotificationTopic,
    publishErrorNotificationTopicWithFadeout,
    publishCloseMenuTopic,
    publishRefreshedDataTopic,
    publishUpdateServiceWorkerTopic,
    publishPilotAssignmentIsEdibleChangedTopic,
    publishOnFeatureMouseOver,
    publishOnFeatureMouseOut,
    publishOnPcsFeatureFocus
}

export const PubSubTopics = {
    LoggedIn: 'LoggedIn',
    UpdateServiceWorker: 'UpdateServiceWorker',
    OverlayWasHidden: 'OverlayWasHidden',
    ClickedBurger: 'ClickedBurger',
    UpdateHeader: 'UpdateHeader',
    Logout: 'Logout',
    Notification: 'Notification',
    CloseMenu: 'CloseMenu',
	PilotAssignmentIsEdibleChanged: 'PilotAssignmentIsEdibleChanged',
    ReloadAlerts: 'ReloadAlerts',
    RefreshedData: 'RefreshedData',
    BurgerMenu: 'BurgerMenu',
    MapEvent: 'MapEvent',
    onFeatureMouseOver: 'onFeatureMouseOver',
    onFeatureMouseOut: 'onFeatureMouseOut',
    onPcsFeatureFocus: 'onPcsFeatureFocus'
}
