import React from 'react';
import './overlay.css'
import Busy from './components/Busy';
import Reload from './components/Reload';

export default function Overlay({
    isBusy = false,
    busyText = "",
    busyType = "",
    hasErrors = false,
    errorText = "",
    statusCode = 0,
    onReloadClick,
    showSpinner = false
}) {
    
    return (
        <>
            <Busy isBusy={isBusy} text={busyText} type={busyType} showSpinner={showSpinner} />
            <Reload hasErrors={hasErrors} onClick={onReloadClick} errorText={errorText} statusCode={statusCode} />
        </>
    );
}
