import React, { useEffect, useState } from 'react';
import Spacer from '../../../components/layout/Spacer';
import RadioButton from '../../../components/layout/RadioButton';
import PcsApiRepository from '../../../repositories/api/PcsApiRepository';
import "../styles/pcs.css"
import TextBoxFieldWrapper from '../../../components/layout/fieldWrappers/TextBoxFieldWrapper';
import { isArrayEmpty, isArraysEqual, isBoolean, isNullOrEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { loggedInId } from '../../../components/helpers/AuthHelpers';
import { DialogConfirm } from '../../../components/layout/dialogs/components/DialogConfirm';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import { Button } from "@progress/kendo-react-buttons";
import Switch from '../../../components/layout/switch';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from "../../../components/layout/card/components/CardProperties";
import Overlay from '../../../components/layout/overlay/Overlay';
import { publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { ContentTitle } from './ContentTitle';

const initialState = {
    showDeleteDialog: false,
    showConfirmDialog: false,
    isBusy: false,
    hasErrors: false,
    keepExistingEvaluations: null,
    isSegmentsValid: false,
    canSave: false,
    deleteAll: false,
    group: {},
    groupName: "",
    groupTypeDisplayName: "",
    coastalSegmentIds: [],
    coastalSegmentIdsCopy: [],
    evaluationCount: 0
};

export const Group = (
    {
        groupId,
        mapChanges,
        onClose
    }) => {

    const [
        {
            showDeleteDialog,
            showConfirmDialog,
            isBusy,
            hasErrors,
            keepExistingEvaluations,
            isSegmentsValid,
            canSave,
            deleteAll,
            group,
            groupName,
            groupTypeDisplayName,
            coastalSegmentIds,
            coastalSegmentIdsCopy,
            evaluationCount
        }
        , setState] = useState(initialState);

    const setValue = (name, value) => {
        setState((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const setShowDeleteDialog = (e) => {
        setValue("showDeleteDialog", e);
    };

    const setKeepExistingEvaluations = (e) => {
        setValue("keepExistingEvaluations", e);
    };

    const setIsBusy = (e) => {
        setValue("isBusy", e);
    };

    const setHasErrors = (e) => {
        setValue("hasErrors", e);
    };

    const setShowConfirmDialog = (e) => {
        setValue("showConfirmDialog", e);
    };

    useEffect(() => {
        initializeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isObjectNull(mapChanges)) return;
        if (!Object.hasOwn(mapChanges, "isSegmentsValid")) return;

        setState((prev) => ({
            ...prev,
            coastalSegmentIds: mapChanges.segmentIds,
            isSegmentsValid: mapChanges.isSegmentsValid,
            canSave: !isNullOrEmpty(groupName) && !isArrayEmpty(mapChanges.segmentIds) && mapChanges.isSegmentsValid
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapChanges]);

    return (
        <>
            <div className="pcs-evaluation-grid">
                <ContentTitle title={groupId === 0 ? "Nytt sertifikatomr�de" : "Rediger sertifikatomr&aring;de" } />
                <div className="grid-r-3 k-m-2">
                    <Card>
                        <Spacer height={10} />
                        <div>
                            <TextBoxFieldWrapper
                                title="Navn"
                                boldTitle={true}
                                value={groupName}
                                onChange={onTextChange}
                            />
                            <Spacer height={5} />
                            <div className="pcs-edit-info">
                                <div>
                                    Antall ruter: {coastalSegmentIds.length}
                                </div>
                            </div>
                            <Spacer height={20} />
                            {
                                (evaluationCount > 0) &&
                                <Switch
                                    size="medium"
                                    onLabel="Slett alle tilh&oslash;rende evalueringer"
                                    onChange={onDeleteAllChanged}
                                    checked={deleteAll}
                                />
                            }

                        </div>
                        <Spacer height={10} />
                    </Card>
                    {
                        (groupId > 0) &&
                        <>
                            <Spacer height={10} />
                            <Card properties={{
                                ...CardProperties,
                                title: "Vanskelighetsgrad"
                            }}>
                                <div>
                                    <div>
                                        Total: {groupTypeDisplayName}
                                    </div>
                                    {
                                        (!isArrayEmpty(group.shipSizes)) &&
                                        <>
                                            <Spacer height={10} />
                                            <div>
                                                Pr fart&oslash;yst&oslash;rrelse:
                                            </div>
                                            {
                                                group.shipSizes.map((shipSize, index) =>
                                                    <div key={index}>
                                                        {shipSize.shipSizeDisplayName}: {shipSize.groupTypeDisplayName}
                                                    </div>
                                                )
                                            }
                                        </>
                                    }
                                </div>
                            </Card>
                        </>
                    }

                </div>

                <div className="pcs-evaluation-form-controls grid-r-4">
                    <div className="pcs-controls-grid">
                        <div>
                            {
                                (groupId > 0) &&
                                <Button
                                    themeColor="error"
                                    onClick={() => setShowDeleteDialog(true)}>
                                    Slett
                                </Button>
                            }
                        </div>
                        <div>
                            <Button
                                onClick={() => onClose(false)}>
                                Avbryt
                            </Button>
                        </div>
                        <div>
                            <Button
                                disabled={!canSave || hasErrors}
                                onClick={handleSaveAsync}
                                themeColor="primary">
                                Lagre
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {
                showConfirmDialog &&
                <GroupConfirmChanges
                    deleteAll={deleteAll}
                    keepExistingEvaluations={keepExistingEvaluations}
                    setKeepExistingEvaluations={setKeepExistingEvaluations}
                    onCancelClick={onCancelConfirmClick}
                    saveAsync={saveAsync} />
            }

            {
                showDeleteDialog &&
                <GroupDelete
                    onCancelClick={() => {
                        setShowDeleteDialog(false);
                    }}
                    onSaveClick={() => {
                        setShowDeleteDialog(false);
                        deleteAsync();
                    }
                    } />
            }

            <Overlay isBusy={isBusy}/>
        </>
    );

    async function initializeAsync() {
        if (groupId === 0) return;

        setIsBusy(true);

        const response = await PcsApiRepository.getGroupAsync(groupId);

        if (response.ok === true) {
            const data = await response.json();
            if (isNullOrEmpty(data.groupName)) {
                data.groupName = "";
            }

            setState((prev) => ({
                ...prev,
                group: data,
                groupName: data.groupName,
                groupTypeDisplayName: data.groupTypeDisplayName,
                evaluationCount: data.evaluationCount,
                coastalSegmentIds: data.coastalSegmentIds,
                coastalSegmentIdsCopy: data.coastalSegmentIds,
                isSegmentsValid: true
            }));

        } else {
            publishWarningNotificationTopic("Kunne ikke hente inn sertifikatomr&aring;de");
            setHasErrors(true);
        }

        setIsBusy(false);
    }

    function onCancelConfirmClick() {
        setShowConfirmDialog(false);
    }

    async function handleSaveAsync() {
        const personId = loggedInId();

        let evaluationsByOthers = []
        let canSave = true;

        if (groupId > 0 && evaluationCount > 0) {
            const response = await PcsApiRepository.getGroupEvaluationsAsync(groupId);
            if (response.ok === true) {
                const data = await response.json();
                evaluationsByOthers = data.filter(e => e.personId !== Number(personId) && e.isCompleted === true);
            } else {
                canSave = false;
            }
        }

        if (!canSave && !deleteAll) return;

        if ((!isArraysEqual(coastalSegmentIdsCopy, coastalSegmentIds) && evaluationsByOthers.length > 0) || deleteAll) {
            setShowConfirmDialog(true);
        } else {
            await saveAsync();
        }
    }

    async function saveAsync(resetEvaluations = false) {
        if (!canSave) return;

        setIsBusy(true);

        group.groupName = groupName;
        group.coastalSegmentIds = coastalSegmentIds;
        group.resetEvaluations = resetEvaluations;

        const response = await PcsApiRepository.setGroupAsync(group);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Sertifikatomr&aringdet ${group.groupName} ble lagret.`);
            setIsBusy(false);
            onClose();
        } else {
            setIsBusy(false);
            publishWarningNotificationTopic(`Sertifikatomr&aringdet ${group.groupName} ble ikke lagret.`);
        }
    }

    async function deleteAsync() {
        setIsBusy(true);
        const response = await PcsApiRepository.deleteGroupAsync(group);
        if (response.ok === true) {
            setIsBusy(false);
            publishSuccessNotificationTopic(`Sertifikatomr&aringdet ${group.groupName} ble slettet.`);
            onClose(true);
        } else {
            setIsBusy(false);
            publishWarningNotificationTopic(`Sertifikatomr&aringdet ${group.groupName} ble ikke slettet.`);
        }
    }

    function onTextChange(e) {
        setState((prev) => ({
            ...prev,
            groupName: e.target.value,
            canSave: !isNullOrEmpty(e.target.value) && !isArrayEmpty(coastalSegmentIds) && isSegmentsValid
        }));
    }

    function onDeleteAllChanged(e) {
        setState((prev) => ({
            ...prev,
            deleteAll: e.value,
            canSave: !isNullOrEmpty(groupName) && !isArrayEmpty(coastalSegmentIds) && isSegmentsValid
        }));
    }
}

const GroupDelete = (
    {
        onCancelClick,
        onSaveClick
    }
) => {

    return (
        <DialogConfirm properties={{
            ...DialogProperties,
            title: "Slett sertifikatomr&aring;de",
            size: "small",
            text: "&Oslash;nsker du &aring; slette sertifikatomr&aring;det? Alle evalueringer knyttet til dette sertifikatomr&aring;det vil ogs&aring; bli slettet.",
            onClose: onCancelClick,
            onClick: onSaveClick
        }} />
    );
}

const GroupConfirmChanges = ({
    deleteAll,
    keepExistingEvaluations,
    setKeepExistingEvaluations,
    onCancelClick,
    saveAsync
}) => {

    return (
        <DialogConfirm properties={{
            ...DialogProperties,
            title: "Bekreft endringer",
            onClose: onCancelClick,
            onClick: onSaveClickAsync,
            isClickActive: isBoolean(keepExistingEvaluations)
        } }>
            <div className="container">
                <div className="pcs-edit">
                    {
                        deleteAll ?
                            <>
                                Vil du ta vare p&aring; eller slette eksisterende evalueringer?
                            </>
                            :
                            <>
                                Det er gjort endringer p&aring; sertifkatomr&aring;det og det finnes evalueringer gjort av andre loser.<br />
                                Vil du ta vare p&aring; eller slette eksisterende evalueringer?
                            </>
                    }
                    <Spacer height={30} />
                    <RadioButton
                        label="Ta vare p&aring; eksisterende"
                        onClick={() => setKeepExistingEvaluations(true)}
                        checked={keepExistingEvaluations === true} />
                    <Spacer height={15} />
                    <RadioButton
                        label="Slett eksisterende"
                        onClick={() => setKeepExistingEvaluations(false)}
                        checked={keepExistingEvaluations === false} />
                </div>
            </div>
        </DialogConfirm>
    );

    async function onSaveClickAsync() {
        await saveAsync(!keepExistingEvaluations);
    }
}
