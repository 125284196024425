import '../styles/pilot.css';
import React, { useEffect,useRef,useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { convertPilotAssignmentApiDtoToIdb } from '../pilot-assignment/helpers/PilotAssignmentHelpers';
import PilotAssignment from '../pilot-assignment/PilotAssignment';
import PilotAssignmentMap from '../pilot-assignment/PilotAssignmentMap';
import PilotageDetailsDefault from '../pilotage-details/PilotageDetailsDefault';
import PilotageDetailsShip from '../components/PilotageDetailsShip';
import PilotageDetailsObservations from '../components/PilotageDetailsObservations';
import Overlay from '../../../components/layout/overlay/Overlay';
import { PilotAssignmentUpdatedDialog } from '../pilot-assignment/components/dialogs/PilotAssignmentUpdatedDialog';
import { PilotagDetailsMenuStates, PilotageDetailsBottomMenu } from '../components/PilotageDetailsBottomMenu';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { isNumeric, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { processPilotAssignment } from '../pilot-assignment/helpers/PilotAssignmentProcessHelpers';
import PilotAssignmentIdbRepository from '../../../repositories/idb/PilotAssignmentIdbRepository';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import { getSearchParam } from '../../../components/helpers/UrlHelpers';

const initialState = {
    pageState: PilotagDetailsMenuStates.Information,
    hasErrors: false,
    isBusy: true,
    pilotAssignment: null,
    showUpdatedDialog: false
};

function Details() {

    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const menuRef = useRef();

    const [{
        pageState,
        hasErrors,
        isBusy,
        pilotAssignment,
        showUpdatedDialog
    }, setState] = useState(initialState);

    const setStateValue = (property, e) => {
        setState((prev) => ({
            ...prev,
            [property]: e
        }));
    }

    const onInitialized = (e) => {
        setState((prev) => ({
            ...prev,
            pilotAssignment: e,
            hasErrors: false,
            isBusy: false
        }));
    }

    const setPageState = (e) => {
        setStateValue("pageState", e);
    }

    const setShowUpdatedDialog = (e) => {
        setStateValue("showUpdatedDialog", e);
    }

    useEffect(() => {
        const menuParam = getSearchParam(searchParams, "menu", PilotagDetailsMenuStates.Information);
        setPageState(menuParam);

        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            {
                (!isObjectNull(pilotAssignment)) &&
                    (() => {
                    switch (pageState) {
                        case PilotagDetailsMenuStates.PilotAssignment:
                            return (<PilotAssignment
                                pilotageId={id}
                                onChange={onPilotAssignmentChange}
                            />);
                        case PilotagDetailsMenuStates.Map:
                            return (
                                navigator.onLine &&
                                <PilotAssignmentMap
                                    pilotageId={pilotAssignment.pilotage.pilotageId}
                                    shipId={pilotAssignment.pilotage.pilotageShip.shipId}
                                />
                            )
                        case PilotagDetailsMenuStates.Ship:
                            return (
                                <PilotageDetailsShip
                                    pilotage={pilotAssignment.pilotage}
                                />
                            )

                        case PilotagDetailsMenuStates.Observations:
                            return (
                                <PilotageDetailsObservations
                                    pilotage={pilotAssignment.pilotage}
                                />
                            )

                        default:
                            return (
                                <PilotageDetailsDefault
                                    onReload={onUpdatePilotAssignmentAsync}
                                    state={pilotAssignment.pilotage}
                                    isReadOnly={false}
                                />
                            );
                    }
                })()
            }

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} isActive={hasErrors} />

            {
                showUpdatedDialog && 
                <PilotAssignmentUpdatedDialog
                    onClose={onClosePilotAssignmentUpdatedDialogClickAsync}
                    onClick={onClosePilotAssignmentUpdatedDialogClickAsync} />
            }

            {
                (!isObjectNull(pilotAssignment)) &&
                <PilotageDetailsBottomMenu
                    ref={menuRef}
                    pageState={pageState}
                    pilotAssignment={pilotAssignment}
                    onClick={onBottomMenuClick}
                />
            }

        </>
    );
    

    async function initializeAsync() {

        if (!isNumeric(id)) {
            publishWarningNotificationTopic("Kunne ikke laste inn losoppdraget da oppgitt id ikke er gyldig.");
            navigate('/pilot/pilotages/assigned');
        } else {

            const pilotageId = Number(id);

            if (navigator.onLine) {
                await initializeOnlineAsync(pilotageId);
            } else {
                await initializeOfflineAsync(pilotageId);
            }
        }
    }

    async function initializeOnlineAsync(pilotageId) {

        const pilotAssignment = await fetchPilotageAsync(pilotageId);
        if (!isObjectNull(pilotAssignment)) {
            const idb = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);
            let data = pilotAssignment;

            if (!isObjectNull(idb)) {
                const processedPilotAssignment = processPilotAssignment(idb, pilotAssignment);

                await PilotAssignmentIdbRepository.setAsync(processedPilotAssignment);

                data = processedPilotAssignment;

                if (processedPilotAssignment.wasServerUpdated) {
                    setShowUpdatedDialog(false);
                }

            } else {
                await PilotAssignmentIdbRepository.setAsync(pilotAssignment);
            }

            onInitialized(data);
            initializeHeader(data);

        } else {
            await initializeOfflineAsync(pilotageId);
        }

    }

    async function initializeOfflineAsync(pilotageId) {
        const data = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);
        if (!isObjectNull(data)) {
            onInitialized(data);
            initializeHeader(data);
        } else {
            publishWarningNotificationTopic("Kunne ikke laste inn losoppdraget.");
            navigate('/pilot/pilotages/assigned');
        }
    }

    async function initializeHeader(pilotAssignment) {
        publishHeaderTopic(
            pilotAssignment.pilotage.pilotageShip.shipName,
            `${pilotAssignment.pilotage.pilotageNo}`,
            true, false, true);
    }

    async function fetchPilotageAsync(pilotageId) {
        return await PilotApiRepository.getPilotageByIdAsync(pilotageId)
            .then((response) => response.json())
            .then((result) => {
                publishHeaderTopic(result.pilotage.pilotageShip.shipName, result.pilotage.pilotageNo.toString());
                const converted = convertPilotAssignmentApiDtoToIdb(result);
                return converted;
            })
            .catch((error) => {
                console.error(error);
                publishWarningNotificationTopic("Kunne ikke hente inn oppdraget fra serveren.");
            })
    }

    function onBottomMenuClick(newPageState) {
        if (pageState === newPageState || hasErrors) return;
        setPageState(newPageState);
    }

    function onReloadClick() {
        initializeAsync();
    }

    async function onClosePilotAssignmentUpdatedDialogClickAsync() {
        const pilotageId = Number(id);
        const data = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);

        data.wasServerUpdated = false;

        await PilotAssignmentIdbRepository.setAsync(data);

        setShowUpdatedDialog(false);
    }

    async function onUpdatePilotAssignmentAsync(pilotagePilot) {
        await PilotAssignmentIdbRepository.setAsync(pilotagePilot);
        initializeAsync();
    }

    function onPilotAssignmentChange() {
        menuRef.current.onPilotAssignmentChange();
    }
}

export default Details;
