import { Dialog } from '../../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../../components/layout/dialogs/DialogProperties';

export const PilotAssignmentUpdatedDialog = ({ onClose, onClick }) => {

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                onClose: onClose,
                title: "Til info",
                actions: [
                    {
                        onClick: onClick,
                        icon: "ok",
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            <p>Skipperbeviset ditt har blitt oppdatert med verdier fra serveren.</p><p>Dermed kan deler ha endret seg siden sist du var inne.</p>
        </Dialog>
    )

}
