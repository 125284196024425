import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Spacer from '../../../../components/layout/Spacer';
import Switch from '../../../../components/layout/switch';
import { isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { PilotAssignmentCommands } from '../../services/DispatcherActions';
import { publishPilotAssignmentIsEdibleChangedTopic } from '../../../../components/helpers/PubSubHelpers';
import { isPilotAssignmentCompleted } from '../helpers/PilotAssignmentHelpers';

export const CompleteCard = forwardRef((
    {
        assignmentService,
        onPilotAssignmentChanged
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initialize(true);
        }
    }));

    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        initialize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        show &&
        <>
            <Spacer height={20} />
            <hr />
            <Spacer height={10} />
            <Switch
                onLabel="Utf&oslash;rt"
                onChange={onChange}
                checked={isChecked}
                isDirty={isDirty}
            />
        </>
    )

    function initialize() {
        const completeCommand = assignmentService.getCommandByCommandType(PilotAssignmentCommands.CompletePilotageReceipt);

        if (!assignmentService.isPilotageIncl) {
            setShow(!isPilotAssignmentCompleted(assignmentService.assignment));
        } else {
            setShow(!assignmentService.hasInvoices || (assignmentService.isTrainee && !isPilotAssignmentCompleted(assignmentService.assignment)));
        }

        if (isObjectNull(completeCommand)) {
            setIsChecked(isPilotAssignmentCompleted(assignmentService.assignment));
        } else {
            setIsChecked(completeCommand.BoolValueLevel1);
        }
        initializeIsDirty();
    }

    function onChange(e) {
        onUpdateAsync(e.value);
    }

    async function onUpdateAsync(isChecked) {

        setIsChecked(isChecked);

        await assignmentService.onCompleteAsync(isChecked);

        onPilotAssignmentChanged();
		publishPilotAssignmentIsEdibleChangedTopic();
        initializeIsDirty();
    }

    function initializeIsDirty() {
        setIsDirty(assignmentService.hasCommandType(PilotAssignmentCommands.CompletePilotageReceipt));
    }
})
