import { Dialog } from '../../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../../components/layout/dialogs/DialogProperties';

export const VariableCompensationBridgeOverrideDialog = ({
    onCancel,
    onClick }) => {

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: "Utenom regelverk",
                onClose: onCancel,
                actions: [
                    {
                        onClick: onCancel,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        onClick: onClick,
                        icon: "ok",
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            <div>
                <p>
                    Overstyr kun dersom oppdraget inneholdt losing.
                </p>
                <p>
                    obs! Brotid for utf&oslash;rt farledsbevisoppdrag settes automatisk til null.
                </p>
            </div>
        </Dialog>
    )
    
}
