import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Spacer from '../../../components/layout/Spacer';
import PcsApiRepository from '../../../repositories/api/PcsApiRepository';
import "../styles/pcs.css"
import PanelBar from '../../../components/layout/PanelBar';
import { RadioButton } from "@progress/kendo-react-inputs";
import { isNullOrEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { DialogConfirm } from '../../../components/layout/dialogs/components/DialogConfirm';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import { Button } from "@progress/kendo-react-buttons";
import Switch from '../../../components/layout/switch';
import Overlay from '../../../components/layout/overlay/Overlay';
import { ContentTitle } from './ContentTitle';
import { Info } from 'phosphor-react-sc';
import DropDownListFieldWrapper from '../../../components/layout/fieldWrappers/DropDownListFieldWrapper';
import { Card } from '../../../components/layout/card/Card';
import uuid from 'react-uuid';

export const Evaluation = (
    {
        groupId,
        groupName,
        onClose
    }) => {

    const [isBusy, setIsBusy] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [canComplete, setCanComplete] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [evaluation, setEvaluation] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [shipSizes, setShipSizes] = useState([]);
    const [selectedShipSize, setSelectedShipSize] = useState(null);

    useEffect(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId]);

    useEffect(() => {
        if (isNullOrEmpty(selectedShipSize)) return;
        initializeEvaluationAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedShipSize]);

    useEffect(() => {
        updateTotalCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaluation]);

    return (
        <>
            <div className="pcs-evaluation-grid">
                <ContentTitle title={`Evaluering: ${groupName}`} />
                <div className="k-m-2">
                    <Card>
                        <DropDownListFieldWrapper
                            title="Fart&oslash;yst&oslash;rrelse"
                            data={shipSizes}
                            textField="shipSizeName"
                            value={selectedShipSize}
                            onChange={(e) => {
                                setSelectedShipSize(e.value);
                            }}
                        />
                    </Card>
                </div>
                <div className="pcs-evaluation-form overflow-auto">
                    {(!isObjectNull(evaluation)) &&
                        evaluation.sections.map((section) =>
                            <EvaluationSection
                                key={section.guid}
                                section={section}
                                onSelectAlternative={onSelectAlternative} />
                        )
                    }
                    <Spacer height={15} />
                </div>
                <div className="pcs-evaluation-form-controls">
                    {(!isObjectNull(evaluation)) &&
                        <>
                            <div>
                                <Switch
                                    onLabel="Fullf&oslash;r"
                                    disabled={!canComplete}
                                    checked={isCompleted}
                                    onChange={onCompleteChange}
                                />
                            </div>
                            <div>
                                <Button
                                    onClick={onClose}>
                                    Avbryt
                                </Button>
                            </div>
                            <div>
                                <Button
                                    disabled={!canSave}
                                    onClick={onSaveClick}
                                    themeColor="primary">
                                    Lagre
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </div>

            <Overlay isBusy={isBusy} />

            {
                showDialog &&
                <EvaluationDelete
                    onCancelClick={() => setShowDialog(false)}
                    onSaveClick={deleteAsync} />
            }
        </>

    );

    async function initializeAsync() {
        setIsBusy(true);
        const response = await PcsApiRepository.getShipSizesAsync();
        if (response.ok) {
            const data = await response.json();
            setShipSizes(data);
            setSelectedShipSize(data[0]);
        
        } else {
            publishWarningNotificationTopic("En feil oppstod ved henting av evaluering data.", response.status);
        }
        setIsBusy(false);
    }

    async function initializeEvaluationAsync() {
        const response = await PcsApiRepository.getGroupEvaluationAsync(groupId, selectedShipSize.systemName);
        if(response.ok) {
            const data = await response.json();
            for (const section of data.sections) {
                section.guid = uuid();
            }
            setEvaluation(data);
            setIsCompleted(data.isCompleted);
            setCanSave(false);
        } else {
            publishWarningNotificationTopic("En feil oppstod ved henting av evalueringen.", response.status);
        }
    }

    function onSelectAlternative(section, subject, alternative) {
        let currentSection = evaluation.sections.find(s => s.id === section.id);
        let currentSubject = currentSection.subjects.find(s => s.id === subject.id);

        currentSubject.selectedAlternative = alternative;

        updateTotalCount();
        setCanSave(true);
    }

    function onSaveClick() {
        if (!canSave) return;
        saveAsync();
    }

    function onCompleteChange(e) {
        setIsCompleted(e.value);
        evaluation.isCompleted = e.value;
        setCanSave(true);
    }

    async function saveAsync() {
        setIsBusy(true);

        evaluation.shipSize = selectedShipSize;
        const response = await PcsApiRepository.setGroupEvaluationAsync(evaluation);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Evaluering for sertifikaromr&aring;det ${groupName} ble lagret.`);
            onClose();
        } else {
            setIsBusy(false);
            publishWarningNotificationTopic(`Evaluering for sertifikaromr&aring;det ${groupName} ble ikke lagret.`);
        }
    }

    async function deleteAsync() {

        setShowDialog(false);
        setIsBusy(true);

        const response = await PcsApiRepository.deleteGroupEvaluationAsync(evaluation);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Evaluering for sertifikaromr&aring;det ${groupName} ble slettet.`);
            onClose();

        }
        setIsBusy(false);
    }

    function updateTotalCount() {
        if (isNullOrEmpty(evaluation)) return;

        const sections = evaluation.sections;
        let current = 0;
        let total = 0;

        for (const section of sections) {
            const subjects = section.subjects;
            for (const subject of subjects) {
                current += subject.selectedAlternative === null ? 0 : 1;
                total += 1;
            }
        }
        setCanComplete(current === total);
    }
}

const EvaluationSection = ({
    section,
    onSelectAlternative
}) => {

    const totalSubjects = section.subjects.length;
    const [subjectsCompleted, setSubjectsCompleted] = useState(getSubjectsCompleted());

    return (
        <div>
            <Spacer height={10} />
            <PanelBar
                title={section.sectionName}
                subTitle={`${subjectsCompleted}/${totalSubjects}`}>
                <div>
                    {section.subjects.map((subject, index) =>
                        <EvaluationSubject
                            key={index}
                            subject={subject}
                            onSelectAlternative={onSelect} />
                    )}
                </div>    
            </PanelBar>
        </div>
    );

    function onSelect(subject, alternative, increase) {
        if (increase) {
            setSubjectsCompleted(subjectsCompleted + 1);
        }

        onSelectAlternative(section, subject, alternative);
    }

    function getSubjectsCompleted() {
        let completedCount = 0;

        for (const subject of section.subjects) {
            if (subject.selectedAlternative !== null) {
                completedCount = completedCount + 1;
            }
        }

        return completedCount;
    }
}

const EvaluationSubject = ({
    subject,
    onSelectAlternative
}) => {

    const [selectedAlternativeId, setSelectedAlternativeId] =
        useState(subject.selectedAlternative !== null ? subject.selectedAlternative.id : 0);
    const [tooltip, setTooltip] = useState(false);

    return (
        <div className="pcs-e-s">
            <div className="pcs-e-s-n">
                {parse(subject.subjectName)}
            </div>
            <div className="pcs-e-s-d">
                {parse(subject.description)}
            </div>
            <div className="pcs-e-s-a">
                <div>
                    {subject.alternatives.map((alternative, index) =>
                        <EvaluationSubjectAlternative
                            key={index}
                            alternative={alternative}
                            onMouseOver={setTooltip}
                            onMouseOut={() => setTooltip("")}
                            selectedAlternativeId={selectedAlternativeId}
                            onSelect={onSelect}
                        />
                    )}
                </div>
                {
                    (!isNullOrEmpty(tooltip)) &&
                    <div className="pcs-evaluation-form-subject-info">
                        <div>
                            {tooltip}
                        </div>
                    </div>
                }
            </div>
        </div>
    );

    function onSelect(alternative) {
        setSelectedAlternativeId(alternative.id)
        onSelectAlternative(subject, alternative, selectedAlternativeId === 0);
    }
}

const EvaluationSubjectAlternative = ({ alternative, selectedAlternativeId, onSelect, onMouseOver, onMouseOut }) => {
    return (
        <div>
            <Spacer height={10} />
            <div
                className="pcs-evaluation-radiobutton"
                onMouseOver={() => onMouseOver(alternative.description)}
                onMouseOut={onMouseOut}>
                <div>
                    <RadioButton
                        size="large"
                        label={alternative.alternativeName}
                        checked={(alternative.id === selectedAlternativeId)}
                        onClick={() => onSelect(alternative)}
                        />
                </div>
                {
                    (!isNullOrEmpty(alternative.description)) &&
                        <div>
                            <Info size={32} />
                        </div>  
                }
            </div>
        </div>
    )
}

const EvaluationDelete = (
    {
        onCancelClick,
        onSaveClick
    }
) => {
    return (
        <DialogConfirm properties={{
            ...DialogProperties,
            title: "Slett evaluering",
            text: "&Oslash;nsker du &aring; slette evalueringen?",
            onClose: onCancelClick,
            onClick: onSaveClick
        } }/>
    );
}
