import { useNavigate } from "react-router-dom";
import { HomeContent, HomeOnlineCard } from "./HomeComponents";

export const HomePilotStationCard = ({ isPilot }) => {

    const navigate = useNavigate();

    return (
        <HomeOnlineCard canShow={isPilot} onClick={onClick}>
            <HomeContent icon="pilotNotRelevant">
                <h2 className="home-content-title text-truncate">
                    Losoversikt
                </h2>
            </HomeContent>
        </HomeOnlineCard>
    );

    function onClick() {
        navigate('/pilot/station');
    }
}
