/* eslint-disable react-hooks/exhaustive-deps */
import "../styles/pilot.css";
import React, { useEffect, useState } from "react";
import {
    PageStateAssigned,
    PageStateExecuted,
} from "../components/PilotagesPageStates";
import { PageStates } from "../../../services/SystemNames";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import PilotApiRepository from "../../../repositories/api/PilotApiRepository";
import PilotAssignmentIdbRepository from "../../../repositories/idb/PilotAssignmentIdbRepository";
import { useSearchParams } from "react-router-dom";
import { PilotAssignmentUpdatedDialog } from "../pilot-assignment/components/dialogs/PilotAssignmentUpdatedDialog";
import PubSub from "pubsub-js";
import BottomNavigation from "../../../components/layout/BottomNavigation";
import Overlay from "../../../components/layout/overlay/Overlay";
import { DialogConfirm } from "../../../components/layout/dialogs/components/DialogConfirm";
import { DialogProperties } from "../../../components/layout/dialogs/DialogProperties";
import AppInsightsService from "../../../services/AppInsightsService";
import {
    deepCopyObject,
    isArrayEmpty,
    isNumeric,
    isObjectNull,
    isTrue,
} from "../../../components/helpers/ObjectHelpers";
import {
    publishHeaderTopic,
    publishWarningNotificationTopic,
    PubSubTopics,
} from "../../../components/helpers/PubSubHelpers";
import {
    addWindowEventListener,
    hasWindowScrolledToBottom,
    removeWindowEventListener,
    scrollCardElementIntoView,
} from "../../../components/helpers/ElementHelpers";
import { processPilotAssignment } from "../pilot-assignment/helpers/PilotAssignmentProcessHelpers";
import {
    convertExecutedPilotAssignmentApiDtoToIdb,
    convertPilotAssignmentApiDtoToIdb,
    getPilotAssignmentUrl,
} from "../pilot-assignment/helpers/PilotAssignmentHelpers";
import PilotAssignmentService from "../pilot-assignment/services/PilotAssignmentService";

const headerHeightWithMargin = 70 + 20;

const initialState = {
    pageState: PageStates.Default,
    isBusy: true,
    hasErrors: false,
    pilotAssignments: [],
    executedPilotAssignments: [],
    isFetchingMore: false,
    canFetchMore: false,
    toggle: false,
    showDialog: false,
    showConfirmDialog: false,
    pilotAssignment: null,
    pilotAssignmentIds: [],
    hasImplementedSearchParams: false,
};

export default function Assigned() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [
        {
            pageState,
            isBusy,
            hasErrors,
            pilotAssignments,
            executedPilotAssignments,
            isFetchingMore,
            canFetchMore,
            toggle,
            showDialog,
            showConfirmDialog,
            pilotAssignment,
            pilotAssignmentIds,
            hasImplementedSearchParams,
        },
        setState,
    ] = useState(initialState);

    const setStateValue = (property, e) => {
        setState((prev) => ({
            ...prev,
            [property]: e,
        }));
    };

    const onReset = () => {
        setState((prev) => ({
            ...prev,
            pilotAssignments: [],
            executedPilotAssignments: [],
            isFetchingMore: false,
            canFetchMore: false,
        }));
    };

    const setIsBusy = (e) => {
        setStateValue("isBusy", e);
    };

    const setIsFetchingMore = (e) => {
        setStateValue("isFetchingMore", e);
    };

    const setPageState = (e) => {
        setStateValue("pageState", e);
    };

    const setPilotAssignments = (e) => {
        setStateValue("pilotAssignments", e);
    };

    const setPilotAssignment = (e) => {
        setStateValue("pilotAssignment", e);
    };

    const setExecutedPilotAssignments = (e) => {
        setStateValue("executedPilotAssignments", e);
    };

    const setHasImplementedSearchParams = (e) => {
        setStateValue("hasImplementedSearchParams", e);
    };

    const setPilotAssignmentIds = (e) => {
        setStateValue("pilotAssignmentIds", e);
    };

    const setShowConfirmDialog = (e) => {
        setStateValue("showConfirmDialog", e);
    };

    const setShowDialog = (e) => {
        setStateValue("showDialog", e);
    };

    const setCanFetchMore = (e) => {
        setStateValue("canFetchMore", e);
    };

    const setToggle = (e) => {
        setStateValue("toggle", e);
    };

    const onHasErrors = (e) => {
        setState((prev) => ({
            ...prev,
            hasErrors: true,
            isBusy: false,
        }));
    };

    const onInitialized = (e) => {
        setState((prev) => ({
            ...prev,
            pilotAssignments: e,
            hasErrors: false,
            isBusy: false,
        }));
    };

    const onDeleted = (e) => {
        setState((prev) => ({
            ...prev,
            pilotAssignments: e,
            pilotAssignmentIds: [],
            showConfirmDialog: false,
            toggle: !toggle,
        }));
    };

    // Initialize function
    useEffect(() => {
        publishHeaderTopic("Mine oppdrag");
        PubSub.subscribe(PubSubTopics.RefreshedData, initializeAsync);

        initializeAsync();

        return () => {
            PubSub.unsubscribe(PubSubTopics.RefreshedData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        addWindowEventListener("scroll", onScroll);
        return () => {
            removeWindowEventListener("scroll", onScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onScroll]);

    useEffect(() => {
        window.addEventListener("offline", handleOnOffline);
        window.addEventListener("online", handleOnOffline);

        return () => {
            window.removeEventListener("offline", handleOnOffline);
            window.removeEventListener("online", handleOnOffline);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleOnOffline]);

    useEffect(() => {
        const pilotagePilotIdParam = searchParams.get("pilotagePilotId");
        if (isNumeric(pilotagePilotIdParam)) {
            scrollCardElementIntoView(
                pilotagePilotIdParam,
                headerHeightWithMargin,
            );
        }
    }, [executedPilotAssignments]);

    return (
        <>
            {(() => {
                switch (pageState) {
                    case PageStates.Default:
                        return (
                            <PageStateAssigned
                                pilotAssignments={pilotAssignments}
                                toggle={toggle}
                                isBusy={isBusy}
                                onClick={onClickAsync}
                                initialize={initializeAsync}
                                onDelete={onDeleteAsync}
                                onDeleteMultible={onDeleteMultibleAsync}
                            />
                        );
                    case PageStates.Executed:
                        return (
                            <PageStateExecuted
                                executedPilotAssignments={
                                    executedPilotAssignments
                                }
                                isFetchingMore={isFetchingMore}
                                onClick={onClickAsync}
                                onPullToRefresh={() => {
                                    fetchExecutedAsync(false, true);
                                }}
                                onDelete={onDeleteAsync}
                            />
                        );
                    default:
                        return null;
                }
            })()}

            <BottomNavigation
                onCallback={onBottomMenuSelect}
                selectedAction={pageState}
                items={[
                    {
                        text: "Aktuelle",
                        icon: "anchor",
                        action: PageStates.Default,
                    },
                    {
                        text: "Utførte",
                        icon: "ok",
                        action: PageStates.Executed,
                    },
                ]}
            />

            <Overlay
                isBusy={isBusy}
                onReloadClick={onReloadClick}
                hasErrors={hasErrors}
            />

            {showDialog && (
                <PilotAssignmentUpdatedDialog
                    onClose={onDialogClickAsync}
                    onClick={onDialogClickAsync}
                />
            )}

            {showConfirmDialog && (
                <DialogConfirm
                    properties={{
                        ...DialogProperties,
                        title: "Slett losoppdrag",
                        text: "Ønsker du å slette valgte losoppdrag?",
                        onClose: () => {
                            setShowConfirmDialog(false);
                            setPilotAssignmentIds([]);
                        },
                        onClick: onConfirmDeleteCallback,
                    }}
                />
            )}
        </>
    );

    function handleOnOffline() {
        onReset();

        switch (pageState) {
            case PageStates.Executed:
                fetchExecutedAsync(false, true);
                break;
            default:
                initializeAsync();
                break;
        }
    }

    async function initializeAsync() {
        // clear lists
        setPilotAssignments([]);
        setExecutedPilotAssignments([]);

        const completedParam = searchParams.get("completed");

        if (navigator.onLine) {
            let executed = false;
            if (!hasImplementedSearchParams) {
                executed = isTrue(completedParam);
            }

            if (executed) {
                setPageState(PageStates.Executed);
                await fetchExecutedAsync();
            } else {
                await initializeOnlineAsync();
            }
        } else {
            await initializeOfflineAsync();
        }

        await PilotAssignmentIdbRepository.cleanUpAsync();

        setHasImplementedSearchParams(true);
    }

    async function initializeOnlineAsync() {
        setIsBusy(true);

        const response = await PilotApiRepository.getAssignedPilotagesAsync();
        if (response.ok === true) {
            const data = await response.json();

            if (!isArrayEmpty(data)) {
                let result = [];

                for (const dto of data) {
                    const pilotAssignment =
                        convertPilotAssignmentApiDtoToIdb(dto);
                    const idbPilotAssignment =
                        await PilotAssignmentIdbRepository.getAsync(
                            pilotAssignment.pilotagePilotId,
                        );

                    try {
                        if (!isObjectNull(idbPilotAssignment)) {
                            const processedPilotAssignment =
                                processPilotAssignment(
                                    idbPilotAssignment,
                                    pilotAssignment,
                                );
                            await PilotAssignmentIdbRepository.setAsync(
                                processedPilotAssignment,
                            );
                            result.push(processedPilotAssignment);
                        } else {
                            await PilotAssignmentIdbRepository.setAsync(
                                pilotAssignment,
                            );
                            result.push(pilotAssignment);
                        }
                    } catch (e) {
                        console.warn(e);
                        AppInsightsService.trackException(e);
                        const warning = `Kunne ikke behandle losoppdrag ${pilotAssignment.pilotage.pilotageShip.shipName} ${pilotAssignment.pilotage.pilotageNo}.`;
                        if (!isObjectNull(idbPilotAssignment)) {
                            publishWarningNotificationTopic(warning);
                            result.push(idbPilotAssignment);
                        } else {
                            result.push(pilotAssignment);
                        }
                    }
                }

                const idbPilotages =
                    await PilotAssignmentIdbRepository.getAllActualAsync();
                if (!isArrayEmpty(idbPilotages)) {
                    for (const idbPilotage of idbPilotages) {
                        const dto = data.find(
                            (x) =>
                                x.pilotagePilotId ===
                                idbPilotage.pilotagePilotId,
                        );
                        if (isObjectNull(dto)) {
                            await PilotAssignmentIdbRepository.deleteAsync(
                                idbPilotage,
                            );
                        }
                    }
                }

                onSetPilotAssignments(result);
            } else {
                await deleteIdbPilotagesAsync();
            }

            setIsBusy(false);
        } else {
            handleError(response);
            await initializeIdbPilotAssignmentsAsync();
        }

        updateUrl(0, false, 0);
    }

    async function initializeOfflineAsync() {
        setIsBusy(true);
        await initializeIdbPilotAssignmentsAsync();
        setIsBusy(false);
    }

    async function initializeIdbPilotAssignmentsAsync() {
        const pilotAssignments =
            await PilotAssignmentIdbRepository.getAllActualAsync();
        onSetPilotAssignments(pilotAssignments);
    }

    async function deleteIdbPilotagesAsync() {
        const idbPilotages =
            await PilotAssignmentIdbRepository.getAllActualAsync();
        if (isArrayEmpty(idbPilotages)) return;

        for (const idbPilotage of idbPilotages) {
            await PilotAssignmentIdbRepository.deleteAsync(idbPilotage);
        }
    }

    function onSetPilotAssignments(pilotAssignments) {
        onInitialized(pilotAssignments);
        onToggle();
    }

    function fetchMoreExecuted() {
        fetchExecutedAsync(true);
    }

    async function fetchExecutedAsync(
        isFetchingMore = false,
        forceReset = false,
    ) {
        const dtos = await PilotAssignmentIdbRepository.getAllExecutedAsync();
        for (const dto of dtos) {
            await PilotAssignmentIdbRepository.deleteAsync(dto);
        }
        if (!navigator.onLine) return;

        fetchExecutedOnline(isFetchingMore, forceReset);
    }

    async function fetchExecutedOnline(fetchMore = false, forceReset = false) {
        if (isFetchingMore) return;

        if (fetchMore) {
            setIsFetchingMore(true);
        } else {
            setIsBusy(true);
        }

        // batch number
        const toTime = getToTime(forceReset);
        const batchSize = getBatchSize();

        const response = await PilotApiRepository.getExecutedPilotagesAsync(
            toTime,
            batchSize,
        );
        if (response.ok === true) {
            const data = await response.json();
            const result = [];

            data.forEach((dto) => {
                result.push(convertExecutedPilotAssignmentApiDtoToIdb(dto));
            });

            let batchSize = 0;
            if (executedPilotAssignments.length === 0 || forceReset) {
                setExecutedPilotAssignments(result);
                batchSize = result.length;
            } else {
                result.forEach((item) => {
                    executedPilotAssignments.push(item);
                });
                batchSize = executedPilotAssignments.length;
            }
            if (forceReset) {
                updateUrl(batchSize, true, 0);
            } else {
                updateUrl(batchSize, true);
            }

            if (fetchMore) {
                setIsFetchingMore(false);
            } else {
                setPageState(PageStates.Executed);
            }

            setCanFetchMore(data.length > 0);

            onToggle();
        } else {
            handleError(response);
        }

        setIsBusy(false);
    }

    function onBottomMenuSelect(item) {
        const newPageState = item.action;
        if (pageState === newPageState) return;

        setPageState(newPageState);

        switch (newPageState) {
            case PageStates.Executed:
                if (executedPilotAssignments.length === 0) {
                    fetchExecutedAsync();
                } else {
                    updateUrl(executedPilotAssignments.length, true, 0);
                }
                break;
            default:
                if (pilotAssignments.length === 0) {
                    initializeAsync();
                } else {
                    updateUrl(0, false, 0);
                }
                break;
        }
    }

    async function onClickAsync(pilotAssignment) {
        updateUrl(
            executedPilotAssignments.length,
            pageState === PageStates.Executed,
            pilotAssignment.pilotagePilotId,
        );

        if (pilotAssignment.wasServerUpdated === true) {
            setShowDialog(true);
            setPilotAssignment(pilotAssignment);
        } else {
            navigate(getPilotAssignmentUrl(pilotAssignment));
        }
    }

    async function onDialogClickAsync() {
        const dto = await PilotAssignmentIdbRepository.getAsync(
            pilotAssignment.pilotagePilotId,
        );
        dto.wasServerUpdated = false;
        await PilotAssignmentIdbRepository.setAsync(dto);

        navigate(getPilotAssignmentUrl(dto));
    }

    function handleError(response) {
        publishWarningNotificationTopic(
            "Kunne ikke hente inn mine oppdrag fra serveren.",
        );
        onHasErrors();
    }

    function onReloadClick() {
        initializeAsync();
    }

    function onScroll() {
        if (!navigator.onLine) return;
        if (canFetchMore === false || pageState !== PageStates.Executed) return;

        if (!hasWindowScrolledToBottom()) return;

        fetchMoreExecuted();
    }

    function getToTime(forceReset = false) {
        if (isArrayEmpty(executedPilotAssignments) || forceReset)
            return Moment();
        return Moment(
            executedPilotAssignments[executedPilotAssignments.length - 1]
                .pilotage.pilotageDetail.fromTime,
        ).add(-1, "S");
    }

    function getBatchSize() {
        if (hasImplementedSearchParams) return 0;
        const batchSizeParam = searchParams.get("batchSize");
        if (!isNumeric(batchSizeParam)) return 0;

        return batchSizeParam;
    }

    async function onDeleteAsync(pilotagePilot) {
        setPilotAssignmentIds([pilotagePilot.pilotagePilotId]);

        const assignmentService = new PilotAssignmentService(pilotagePilot);
        if (assignmentService.isDirty) {
            setShowConfirmDialog(true);
        } else {
            thenDeleteAsync([pilotagePilot.pilotagePilotId]);
        }
    }

    async function onDeleteMultibleAsync(pilotAssignmentIds) {
        setPilotAssignmentIds(pilotAssignmentIds);

        let hasChanges = false;

        for (let i = 0; i < pilotAssignmentIds.length; i++) {
            const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(
                pilotAssignmentIds[i],
            );
            const assignmentService = new PilotAssignmentService(
                pilotAssignment,
            );
            hasChanges = assignmentService.isDirty;

            if (hasChanges) break;
        }

        if (hasChanges) {
            setShowConfirmDialog(true);
        } else {
            thenDeleteAsync(pilotAssignmentIds);
        }
    }

    async function thenDeleteAsync(pilotAssignmentIds) {
        let filteredPilotAssignments = deepCopyObject(pilotAssignments);

        for (const id of pilotAssignmentIds) {
            const pilotAssignment =
                await PilotAssignmentIdbRepository.getAsync(id);

            if (!isObjectNull(pilotAssignment)) {
                await PilotAssignmentIdbRepository.deleteAsync(pilotAssignment);
            }

            filteredPilotAssignments = filteredPilotAssignments.filter(
                (pp) => pp.pilotagePilotId !== id,
            );
        }
        onDeleted(filteredPilotAssignments);
    }

    async function onConfirmDeleteCallback() {
        if (!isArrayEmpty(pilotAssignmentIds)) {
            thenDeleteAsync(pilotAssignmentIds);
        } else {
        }
    }

    function onToggle() {
        setTimeout(() => {
            setToggle(!toggle);
        }, 100);
    }

    function updateUrl(batchSize = 0, completed = false, pilotagePilotId = -1) {
        const pilotagePilotIdParam = searchParams.get("pilotagePilotId");
        if (pilotagePilotId === -1 && isNumeric(pilotagePilotIdParam)) {
            pilotagePilotId = Number(pilotagePilotIdParam);
        } else {
            searchParams.set("pilotagePilotId", pilotagePilotId);
        }

        navigate(
            `/pilot/pilotages/assigned?batchSize=${batchSize}&completed=${completed}&pilotagePilotId=${pilotagePilotId}`,
            { replace: true },
        );
    }
}
