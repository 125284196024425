import Moment from 'moment';
import ActualWorkContent from '../../../components/ActualWorkContent';
import Spacer from '../../../../../components/layout/Spacer';
import { Dialog } from '../../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../../components/layout/dialogs/DialogProperties';
import { formatMomentToDefault } from '../../../../../components/helpers/DateTimeHelpers';

export const VariableCompensationBridgeCalculateDialog = ({
    onClose,
    pilotagePilot,
    summary,
    dayTime}) => {

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: "Faktisk brotid",
                onClose: onClose,
                actions: [
                    {
                        onClick: onClose,
                        themeColor: "primary",
                        icon: "ok",
                        text: "Ok"
                    }
                ]

            }}>
            <div>
                <div className="row">
                    <div className="col text-center title">
                        Faktisk brotid
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        Losoppdrag {pilotagePilot.pilotage.pilotageNo}
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        {pilotagePilot.pilotage.fromLocation.name} - {pilotagePilot.pilotage.toLocation.name}
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        ({formatMomentToDefault(Moment(summary.startDate))} - {formatMomentToDefault(Moment(summary.endDate))})
                    </div>
                </div>
            </div>
            <Spacer />
            <div>
                <ActualWorkContent
                    title={'Brotid dag'}
                    description={getDayTimeDescription(true)}
                    totalDescription={summary.durationDescriptionDay}
                    data={summary.workTimesDay} />
            </div>
            <div>
                <ActualWorkContent
                    title={'Brotid natt'}
                    description={getDayTimeDescription(false)}
                    totalDescription={summary.durationDescriptionNight}
                    data={summary.workTimesNight} />
            </div>
        </Dialog>
    )

    function getDayTimeDescription(isDay) {
        if (isDay) {
            return `${timeSpanDescription(dayTime.dayStart)} til ${timeSpanDescription(dayTime.dayEnd)}`;
        } else {
            return `${timeSpanDescription(dayTime.dayEnd)} til ${timeSpanDescription(dayTime.dayStart)}`;
        }
    }

    function timeSpanDescription(timeSpan) {
        if (timeSpan === undefined) return "";
        return timeSpan.slice(0, timeSpan.length - 3);
    }
}
