import './pilotassignment.css';
import { useEffect, useRef, useState } from 'react';
import { PageStates } from '../../../services/SystemNames';
import { PilotAssignmentCommands } from '../services/DispatcherActions';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import PilotAssignmentIdbRepository from '../../../repositories/idb/PilotAssignmentIdbRepository';
import { ReceiptDialog } from './components/dialogs/ReceiptDialog';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import Overlay from '../../../components/layout/overlay/Overlay';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import { getFormattedInfoString, isArrayEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishReloadAlertsTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import {
    convertPilotAssignmentApiDtoToIdb,
    isPilotAssignmentCompleted,
    setPilotAssignmentSuggestions
} from './helpers/PilotAssignmentHelpers';
import PilotAssignmentService from './services/PilotAssignmentService';
import { UploadIcon } from '../../../components/layout/icons/Icons';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import FloatingButtonContainer from '../../../components/layout/FloatingButtonContainer';
import Spacer from '../../../components/layout/Spacer';
import { CompleteCard } from './components/CompleteCard';
import { BothPilotAndExaminerCard } from './components/BothPilotAndExaminerCard';
import { ConvertToPilotInclCard } from './components/ConvertToPilotInclCard';
import { RemarkCard } from './components/RemarkCard';
import { ReimbursementCard } from './components/ReimbursementCard';
import { HourCompensationCard } from './components/HourCompensationCard';
import { OvertimeCard } from './components/OvertimeCard';
import { VariableCompensationCard } from './components/VariableCompensationCard';
import { ManeuverResponsibleCard } from './components/ManeuverResponsibleCard';
import { TowingCard } from './components/TowingCard';
import { StandardTripCard } from './components/StandardTripCard';
import { WastedTripCard } from './components/WastedTripCard';
import { TimeCard } from './components/TimeCard';
import ContentGrid from '../../../components/layout/ContentGrid';
import { CorrectionCard } from './components/CorrectionCard';
import { ConnectToPilotCard } from './components/ConnectToPilotCard';

const initialState = {
    hasErrors: false,
    isBusy: false,
    showCompleteDialog: false,
    showReceiptDialog: false,
    isBothPilotAndExaminer: false,
    convertingToPilotIncl: false,
    assignmentService: null,
    isOnline: navigator.onLine,
    canSave: false
};

export default function PilotAssignment({ pilotageId, onChange }) {

    const navigate = useNavigate();

    const wastedTripRef = useRef();
    const timeRef = useRef();
    const standardTripRef = useRef();
    const towingRef = useRef();
    const maneuverResponsibleRef = useRef();
    const variableCompensationRef = useRef();
    const overtimeRef = useRef();
    const hourCompensationRef = useRef();
    const reimbursementRef = useRef();
    const remarkRef = useRef();
    const convertToPilotInclRef = useRef();
    const bothPilotAndExaminerRef = useRef();
    const completeRef = useRef();
    const connectToPilotRef = useRef();

    const [{
        hasErrors,
        isBusy,
        showCompleteDialog,
        showReceiptDialog,
        isBothPilotAndExaminer,
        convertingToPilotIncl,
        assignmentService,
        isOnline,
        canSave
    }, setState] = useState(initialState);

    const setStateValue = (property, e) => {
        setState((prev) => ({
            ...prev,
            [property]: e
        }));
    }

    const setIsBusy = (e) => {
        setStateValue("isBusy", e);
    }

    const setIsOnline = (e) => {
        setStateValue("isOnline", e);
    }

    const setCanSave = (e) => {
        setStateValue("canSave", e);
    }

    const setAssignmentService = (e) => {
        setStateValue("assignmentService", e);
    }

    const initialized = (e) => {
        const assignmentService = new PilotAssignmentService(e);
        setState((prev) => ({
            ...prev,
            assignmentService: assignmentService,
            pageState: PageStates.Default,
            hasErrors: false,
            isBusy: false,
            canSave: assignmentService.isDirty
        }));
    }

    const completing = (e) => {
        setState((prev) => ({
            ...prev,
            showCompleteDialog: e.showCompleteDialog,
            isBothPilotAndExaminer: e.isBothPilotAndExaminer,
            convertingToPilotIncl: e.convertingToPilotIncl,
            showReceiptDialog: false
        }));
    }

    const receipt = (e) => {
        setState((prev) => ({
            ...prev,
            showReceiptDialog: e,
            showCompleteDialog: false
        }));
    }

    useEffect(() => {

        function handleOnOffline() {
            setIsOnline(navigator.onLine);
        }

        window.addEventListener("offline", handleOnOffline);
        window.addEventListener("online", handleOnOffline);

        initializeAsync();

        return ()  => {
            window.removeEventListener("offline", handleOnOffline);
            window.removeEventListener("online", handleOnOffline);
            setAssignmentService(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {
                !isObjectNull(assignmentService) &&
                <>
                    <ContentGrid
                        className="pilotage-pilot-content"
                        title={assignmentService.assignment.pilotage.pilotageShip.shipName}
                        titleIcon="ship"
                        subTitle={`${assignmentService.assignment.pilotage.pilotageNo}`}
                        canCopySubTitle={true}>

                        <div className="pilotage-pilot">
                            <CorrectionCard
                                assignmentService={assignmentService}/>

                            <WastedTripCard
                                ref={wastedTripRef}
                                onWastedTripChanged={onWastedTripChanged}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />
                            <Spacer height={30} />

                            <TimeCard
                                ref={timeRef}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />

                            <Spacer height={30} />

                            <StandardTripCard
                                ref={standardTripRef}
                                onLocationChanged={onLocationChanged}
                                assignmentService={assignmentService}
                            />

                            <TowingCard
                                ref={towingRef}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />

                            <ManeuverResponsibleCard
                                ref={maneuverResponsibleRef}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />

                            <VariableCompensationCard
                                ref={variableCompensationRef}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />

                            <OvertimeCard
                                ref={overtimeRef}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />

                            <Spacer height={15} />

                            <HourCompensationCard
                                ref={hourCompensationRef}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService} />

                            <Spacer height={15} />

                            <ReimbursementCard
                                ref={reimbursementRef}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />

                            <Spacer height={30} />

                            <RemarkCard
                                ref={remarkRef}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />

                            <ConvertToPilotInclCard
                                ref={convertToPilotInclRef}
                                onExaminerOnlyChanged={onExaminerOnlyChanged}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />

                            <ConnectToPilotCard
                                ref={connectToPilotRef}
                                onExaminerOnlyChanged={onExaminerOnlyChanged}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />

                            <BothPilotAndExaminerCard
                                ref={bothPilotAndExaminerRef}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />

                            <CompleteCard
                                ref={completeRef}
                                onPilotAssignmentChanged={onPilotAssignmentChanged}
                                assignmentService={assignmentService}
                            />
                            <Spacer height={30} />
                        </div>
                    </ContentGrid>

                    {
                        (isOnline && isSaveActive()) &&
                        <FloatingButtonContainer hasBottomNavigation={true}>
                            <FloatingActionButton
                                themeColor={canSave ? "primary" : "secondary"}
                                disabled={!canSave}
                                svgIcon={UploadIcon}
                                onClick={onSave}
                            />
                        </FloatingButtonContainer>
                    }
                </>
            }

            {
                showCompleteDialog &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        title: "Send skipperbeviset",
                            onClose: () => completing({
                                    showCompleteDialog: false,
                                    isBothPilotAndExaminer: false,
                                    convertingToPilotIncl: false
                                }),
                        actions: [
                            {
                                onClick: () => completing({
                                        showCompleteDialog: false,
                                        isBothPilotAndExaminer: false,
                                        convertingToPilotIncl: false
                                    }),
                                icon: "close",
                                text: "Avbryt"
                            },
                            {
                                icon: "ok",
                                onClick: () => {
                                    completing({
                                            showCompleteDialog: false,
                                            isBothPilotAndExaminer: false,
                                            convertingToPilotIncl: false
                                        }
                                    );
                                        thenSaveAsync();
                                    },
                                themeColor: "primary",
                                text: "Lagre"
                            }
                        ]
                        }}>
                        <p>
                            Ved &aring; sende inn Utf&oslash;rt skipperbevis, bekrefter jeg at alle tillegg for overf&oslash;ring til l&oslash;nn er kontrollert og korrekt
                        </p>
                        {
                            isBothPilotAndExaminer &&
                                <p>
                                    <b>NB: Du har valgt &aring; ta rollen som b&aring;de los og sensor p&aring; oppdraget.</b>
                                </p>
                        }
                        {
                            convertingToPilotIncl &&
                                <p>
                                    <b>NB: Du har valgt &aring; gj&oslash;re oppdraget om til et losoppdrag slik at losingstimene kan faktureres.</b>
                                </p>
                        }
                    </Dialog>
            }

            {
                showReceiptDialog && 
                <ReceiptDialog
                    onClose={() => {
                        receipt(false);
                        initializeAsync();
                    }}
                    onGoBack={() => navigate("/pilot/pilotages/assigned") }
                    pilotageId={pilotageId}
                />
            }
            
            <Overlay isBusy={isBusy} hasErrors={hasErrors} onReloadClick={onReloadClick} />
        </>
    )

    async function initializeAsync() {

        await setPilotAssignmentSuggestions(Number(pilotageId));
        const data = await PilotAssignmentIdbRepository.getByPilotageIdAsync(Number(pilotageId));
        
        if (!isObjectNull(data)) {
            initialized(data);
        } else {
            publishWarningNotificationTopic("Losoppdraget ligger ikke lokalt.");
            navigate("/pilot/pilotages/assigned");
        }
        
    }

    function onReloadClick() {
        initializeAsync();
    }
    
    function onSave() {
        if (!navigator.onLine) return;

        if (!assignmentService.isDirty) return;

        const command = assignmentService.getCommandByCommandType(PilotAssignmentCommands.CompletePilotageReceipt);
        const isCompleting = !isObjectNull(command) && command.BoolValueLevel1;
        const isBothPilotAndExaminer = assignmentService.hasCommandType(PilotAssignmentCommands.BothPilotAndExaminer);
        const convertingToPilotIncl = assignmentService.hasCommandType(PilotAssignmentCommands.ConvertToPilotageIncl);
        const updatePilotAssignmentConfirmedByPilot = assignmentService.hasCommandType(PilotAssignmentCommands.UpdatePilotAssignmentConfirmedByPilot);

        if (isCompleting) {
            completing({
                showCompleteDialog: true,
                isBothPilotAndExaminer: isBothPilotAndExaminer,
                convertingToPilotIncl: convertingToPilotIncl,
                updatePilotAssignmentConfirmedByPilot: updatePilotAssignmentConfirmedByPilot
            });
        } else {
            thenSaveAsync();
        }
    }

    async function thenSaveAsync() {
        setIsBusy(true);

        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(assignmentService.assignment.pilotagePilotId);
        const command = assignmentService.getCommandByCommandType(PilotAssignmentCommands.CompletePilotageReceipt);
        const hasCompleteCommand = !isObjectNull(command);
        const shouldReload = hasCompleteCommand && (isPilotAssignmentCompleted(pilotAssignment) !== command.BoolValueLevel2);

        const response = await PilotApiRepository.savePilotageReceiptAsync(pilotAssignment);
        const pilotageNo = pilotAssignment.pilotage.pilotageNo;

        if (response.ok) {
            const data = await response.json();
            
            const pilotAssignment = convertPilotAssignmentApiDtoToIdb(data);
            assignmentService.reload(pilotAssignment);

            await PilotAssignmentIdbRepository.deleteAsync(pilotAssignment);
            await PilotAssignmentIdbRepository.setAsync(pilotAssignment);

            publishSuccessNotificationTopic("Skipperbeviset ble oppdatert!");

            if (shouldReload) {
                setAssignmentService(null);
                initializeAsync();

                if (isPilotAssignmentCompleted(pilotAssignment)) {
                    receipt(true);
                }

            } else {
                onValidationChanged([]);

                if (isPilotAssignmentCompleted(pilotAssignment)) {
                    publishReloadAlertsTopic();
                    receipt(true);
                }

                setIsBusy(false);
            }
                
            onPilotageChanged();
            onChange();
        } else {
            const errorMessage = await response.json();
            if (!isArrayEmpty(errorMessage.infoStrings)) {
                let warning = `Kan ikke lagre skipperbeviset for losoppdrag ${pilotageNo}:${getFormattedInfoString(errorMessage.infoStrings)}`;
                publishWarningNotificationTopic(warning);
            } else {
                publishWarningNotificationTopic(`Kan ikke lagre skipperbeviset for losoppdrag ${pilotageNo}.`);
            }

            if (hasCompleteCommand && !isArrayEmpty(errorMessage.codeStrings)) {
                onValidationChanged(errorMessage.codeStrings);
            }

            setIsBusy(false);
        }
    }

    function onPilotageChanged() {
        wastedTripRef.current.onPilotageChanged();
        timeRef.current.onPilotageChanged();
        standardTripRef.current.onPilotageChanged();
        towingRef.current.onPilotageChanged();
        maneuverResponsibleRef.current.onPilotageChanged();
        variableCompensationRef.current.onPilotageChanged();
        overtimeRef.current.onPilotageChanged();
        hourCompensationRef.current.onPilotageChanged();
        reimbursementRef.current.onPilotageChanged();
        remarkRef.current.onPilotageChanged();
        convertToPilotInclRef.current.onPilotageChanged();
        bothPilotAndExaminerRef.current.onPilotageChanged();
        completeRef.current.onPilotageChanged();
        connectToPilotRef.current.onPilotageChanged();
    }

    function onValidationChanged(codeStrings) {
        variableCompensationRef.current.onValidationChanged(codeStrings);
        timeRef.current.onValidationChanged(codeStrings);
        towingRef.current.onValidationChanged(codeStrings);
        standardTripRef.current.onValidationChanged(codeStrings);
        maneuverResponsibleRef.current.onValidationChanged(codeStrings);
    }

    function onPilotAssignmentChanged() {
        setCanSave(assignmentService.isDirty);
        onChange();
    }

    function onLocationChanged() {
        timeRef.current.onLocationChanged();
        variableCompensationRef.current.onLocationChanged();
    }

    function onExaminerOnlyChanged() {
        variableCompensationRef.current.onExaminerOnlyChanged();
    }

    function onWastedTripChanged(isWasted) {
        maneuverResponsibleRef.current.onWastedTripChanged();
        variableCompensationRef.current.onWastedTripChanged();
        standardTripRef.current.onWastedTripChanged(isWasted);
    }

    function isSaveActive() {
        return !isPilotAssignmentCompleted(assignmentService.assignment) ||
            (isPilotAssignmentCompleted(assignmentService.assignment) && !assignmentService.hasInvoices)
    }
}
