import React, { useState } from 'react';

import {
    PilotActualWorkTimeStateEnum
} from '../../../services/SystemNames';

import Moment from 'moment';

import { useEffectOnce } from '../../../components/useEffectOnce';
import ActualWorkContent from '../components/ActualWorkContent';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import ContentGrid from '../../../components/layout/ContentGrid';
import Overlay from '../../../components/layout/overlay/Overlay';
import { isNumeric } from '../../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { getStringUrlParam } from '../../../components/helpers/UrlHelpers';

function Actual() {

    const [isBusy, setIsBusy] = useState(true);
    const [pilotageNo, setPilotageNo] = useState(0);
    const [fromTo, setFromTo] = useState({});
    const [dayTime, setDayTime] = useState({});
    const [summary, setSummary] = useState({});

    // Initialize function
    useEffectOnce(() => {
        publishHeaderTopic("Faktisk brotid");

        initializeAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderPageStateDefault = () => {
        return (
            <ContentGrid title="Faktisk brotid" subTitle={getSubTitle()}>
                <ActualWorkContent
                    title={'Brotid dag'}
                    description={getDayTimeDescription(true)}
                    totalDescription={summary.durationDescriptionDay}
                    data={summary.workTimesDay} />

                <ActualWorkContent
                    title={'Brotid natt'}
                    description={getDayTimeDescription(false)}
                    totalDescription={summary.durationDescriptionNight}
                    data={summary.workTimesNight}/>
            </ContentGrid>
        );
    }

    return (
        <>
            {renderPageStateDefault()}

            <Overlay isBusy={isBusy} />
        </>
    );

    async function initializeAsync() {
        const pilotageId = getStringUrlParam("PilotageId");

        if (isNumeric(pilotageId)) {
            await getActualPilotWorkTimeAsync(pilotageId);
        } else {
            publishWarningNotificationTopic("Mangler PilotageId");
            setIsBusy(false);
        }
    }

    async function getActualPilotWorkTimeAsync(pilotageId) {
        const response = await PilotApiRepository.getWorkTimeActualDetailsSummaryAsync(pilotageId);
        const pilotDayTimeResponse = await PilotApiRepository.getDayTimeAsync();

        if (response.ok === true &&
            pilotDayTimeResponse.ok === true) {

            const data = await response.json();

            switch (data.state) {
                case PilotActualWorkTimeStateEnum.NOT_PILOT:
                    publishWarningNotificationTopic(`Du er ikke registrert som los på losoppdrag ${data.pilotageNo}.`)
                    break;
                case PilotActualWorkTimeStateEnum.MISSING_DATES:
                    publishWarningNotificationTopic(` Kan ikke hente faktisk brotid. Husk å lagre skipperbeviset på losoppdrag ${data.pilotageNo}.`)
                    break;
                default:

                    const pilotDayTimeData = await pilotDayTimeResponse.json();

                    setSummary(data);
                    setPilotageNo(data.pilotageNo);
                    setFromTo(data.pilotageLocations);
                    setDayTime(pilotDayTimeData);

                    break;
            }
            setIsBusy(false);
        } else {
            handleError(response);
        }
    }

    function handleError(response) {
        publishWarningNotificationTopic("Kunne ikke laste inn Faktisk brotid.", response.status);
        setIsBusy(false);
    }

    function formatPilotStartEndDate() {
        return `${formatDate(summary.startDate)} - ${formatDate(summary.endDate) }`;
    }

    function formatDate(date) {
        return Moment(date).format('DD.MM HH:mm');
    }

    function getDayTimeDescription(isDay) {
        if (isDay) {
            return `Brotid dag er fra ${timeSpanDescription(dayTime.dayStart)} til ${timeSpanDescription(dayTime.dayEnd)}`;
        } else {
            return `Brotid natt er fra ${timeSpanDescription(dayTime.dayEnd)} til ${timeSpanDescription(dayTime.dayStart)}`;
        }
    }

    function getSubTitle() {
        if (fromTo.fromLocation === undefined) return "";

        return `Losoppdrag ${pilotageNo}<br />${fromTo.from} - ${fromTo.to}<br />(${formatPilotStartEndDate()})`;
    }

    function timeSpanDescription(timeSpan) {
        if (timeSpan === undefined) return "";
        return timeSpan.slice(0, timeSpan.length - 3);
    }
}

export default Actual;
