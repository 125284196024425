import { getFormattedDateTime } from "../../../../components/helpers/DateTimeHelpers";
import { deepCopyObject, isArrayEmpty, isNullOrEmpty, isNumeric, isObjectNull } from "../../../../components/helpers/ObjectHelpers";
import { PilotAssignmentCommands } from "../../services/DispatcherActions";
import { isPilotAssignmentCompleted } from "../helpers/PilotAssignmentHelpers";

class PilotAssignmentCommandService {

    _assignment = null;
    _commands = [];

    constructor(assignment) {
        this._assignment = assignment;
        this._commands = isObjectNull(assignment.commands) ? [] : deepCopyObject(assignment.commands);
    }

    get commands() { return this._commands; }
    get isDirty() { return !isArrayEmpty(this._commands); }

    createCommandEventId(commandType, obj) {
        switch (commandType) {

            case PilotAssignmentCommands.AddLocation:
            case PilotAssignmentCommands.DeleteLocation:
            case PilotAssignmentCommands.UpdateLocationPilotType:
            case PilotAssignmentCommands.UpdateLocationBoardingType:
            case PilotAssignmentCommands.UpdateLocationDepartureTime:
            case PilotAssignmentCommands.UpdateLocationArrivalTime:
                return `${commandType}-LocationId-${obj.locationId}-SequenceNo-${obj.sequenceNo}`;

            case PilotAssignmentCommands.UpdateVariableCompensationOverriddenNumber:
                return `${commandType}-${obj.pilotagePilotVariableCompensationSystemName}`;

            case PilotAssignmentCommands.AddPilotAssignmentOvertime:
            case PilotAssignmentCommands.AddPilotHourCompensation:
                return `${commandType}-${obj.guid}`;

            case PilotAssignmentCommands.UpdatePilotAssignmentOvertime:
            case PilotAssignmentCommands.DeletePilotAssignmentOvertime:
                return `${commandType}-${obj.pilotagePilotOvertimeId}`;

            case PilotAssignmentCommands.UpdatePilotHourCompensation:
            case PilotAssignmentCommands.DeletePilotHourCompensation:
                return `${commandType}-${obj.pilotagePilotHourCompensationId}`;

            default:
                return `${commandType}`;
        }
    }

    createBaseCommand(eventId, commandType, ensureUnique) {
        const existingCommand = this.getCommandByEventId(eventId);
        if (!isObjectNull(existingCommand) && !ensureUnique) return existingCommand;

        return {
            guid: "",
            eventId: eventId,
            typeValue: commandType,
            pilotageId: this._assignment.pilotage.pilotageId,
            pilotAssignmentId: this._assignment.pilotagePilotId
        };
    }

    createCommand(commandType, obj = null, ensureUnique = false) {
        const eventId = this.createCommandEventId(commandType, obj);
        return this.createBaseCommand(eventId, commandType, ensureUnique);
    }

    getCommandByEventId(eventId) {
        if (isArrayEmpty(this._commands)) return null;
        return this._commands.find(c => c.eventId === eventId);
    }

    getCommandByCommandType(commandType, obj = null) {
        const eventId = this.createCommandEventId(commandType, obj);
        return this.getCommandByEventId(eventId);
    }

    removeCommand(commandType, obj = null) {
        const eventId = this.createCommandEventId(commandType, obj);
        const command = this.getCommandByEventId(eventId);
        if (isObjectNull(command)) return;
        this.appendOrRemoveCommand(command, false);
    }

    appendOrRemoveCommand(command, addCommand = true) {
        const filteredCommands = this._commands.filter(c => c.eventId !== command.eventId);//eventId is not unique 
        const duplicateCommands = this._commands.filter(c => c.eventId === command.eventId);

        if(!isArrayEmpty(duplicateCommands) && duplicateCommands.every(c => c.idLevel1 !== command.idLevel1)) {
            filteredCommands.push(...duplicateCommands);
        }
        if (addCommand) {
            filteredCommands.push(command);
        }

        this._commands = filteredCommands;
    }

    hasCommandType(commandType) {
        return this._commands.some(c => c.typeValue === commandType);
    }

    hasCommandEventId(eventId) {
        return this._commands.some(c => c.eventId === eventId);
    }

    hasLocationCommand(location, type) {
        const eventId = this.createCommandEventId(type, location);
        const hasCommands = this._commands
            .some(c =>
                c.eventId === eventId);
        return hasCommands;
    }

    reorderLocationCommands(sequenceNo, direction = "down") {
        this._commands.forEach(existingCommand => {
            if (!existingCommand.eventId.includes("SequenceNo") || existingCommand.eventId.includes("DeletePilotAssignmentLocation")) return;

            const eventIdArr = existingCommand.eventId.split('-');
            const currentSequenceNo = Number(eventIdArr[eventIdArr.length - 1]);

            if (currentSequenceNo < sequenceNo) return;

            const newSequenceNo = direction === "down" ? currentSequenceNo + 1 : currentSequenceNo - 1;
            existingCommand.eventId = existingCommand.eventId.replace(`SequenceNo-${currentSequenceNo}`, `SequenceNo-${newSequenceNo}`);

            if (existingCommand.intValue) {
                existingCommand.intValue = newSequenceNo;
            }
        });
    }

    onIsWastedTrip(isWasted) {
        const command = this.createCommand(PilotAssignmentCommands.IsWastedTrip);

        command.boolValueLevel1 = isWasted;

        this.appendOrRemoveCommand(command);
    }

    onPilotArrivalTime(obj) {
        const command = this.createCommand(PilotAssignmentCommands.PilotArrivalTime, obj);
        command.dateTimeLevel1 = getFormattedDateTime(obj);

        this.appendOrRemoveCommand(command);
    }

    onPilotDepartureTime(obj) {
        const command = this.createCommand(PilotAssignmentCommands.PilotDepartureTime);
        command.dateTimeLevel1 = getFormattedDateTime(obj);
        this.appendOrRemoveCommand(command);
    }

    onPilotReturnTime(obj) {
        const command = this.createCommand(PilotAssignmentCommands.PilotReturnTime);
        command.dateTimeLevel1 = getFormattedDateTime(obj);
        this.appendOrRemoveCommand(command);
    }

    onWaitingHourReason(systemName, remark) {
        const command = this.createCommand(PilotAssignmentCommands.WaitingHourReason);

        command.systemNameLevel1 = systemName;
        command.textLevel1 = remark;

        this.appendOrRemoveCommand(command);
    }

    onAddLocation(newLocation, locationBefore, pilotType) {

        this.reorderLocationCommands(newLocation.sequenceNo);
        let newCommand = this.createCommand(PilotAssignmentCommands.AddLocation, newLocation);

        newCommand.idLevel1 = newLocation.locationId;
        newCommand.intValue = newLocation.sequenceNo;
        newCommand.systemNameLevel1 = newLocation.pilotType;
        newCommand.systemNameLevel2 = newLocation.pilotBoardingType.systemName;
        newCommand.dateTimeLevel2 = null;
        newCommand.dateTimeLevel1 = null;

        this.appendOrRemoveCommand(newCommand);

        locationBefore.pilotType = pilotType.systemName;
        locationBefore.pilotTypeId = pilotType.pilotagePilotTypeId;

        newCommand = this.getCommandByCommandType(PilotAssignmentCommands.AddLocation, locationBefore);
        if (isObjectNull(newCommand)) {
            newCommand = this.createCommand(PilotAssignmentCommands.UpdateLocationPilotType, locationBefore);
            newCommand.idLevel1 = locationBefore.pilotagePilotLocationId;
        }

        newCommand.systemNameLevel1 = pilotType.systemName;

        this.appendOrRemoveCommand(newCommand);
    }

    removeStaleCommands(obj) {
        const sequenceNoToBeRemoved = obj.sequenceNo;
        this._commands = this._commands.filter(command => {
            const eventItems = command.eventId.split('-');
            const commandSequenceNo = Number(eventItems[eventItems.length - 1]);
            return commandSequenceNo !== sequenceNoToBeRemoved;
        });
    }

    onDeleteLocation(obj) {
        if (obj.pilotagePilotLocationId > 0) {
            const newCommand = this.createCommand(PilotAssignmentCommands.DeleteLocation, obj, true);
            newCommand.idLevel1 = obj.pilotagePilotLocationId;
            this.appendOrRemoveCommand(newCommand);
        } else {
            this.removeStaleCommands(obj);
        }
        this.reorderLocationCommands(obj.sequenceNo, "up");
    }

    onUpdateLocationDepartureTime(obj, location, boardingType) {
        let command = this.getCommandByCommandType(PilotAssignmentCommands.AddLocation, location);
        if (isObjectNull(command)) {
            command = this.createCommand(PilotAssignmentCommands.UpdateLocationDepartureTime, location);
            command.idLevel1 = location.pilotagePilotLocationId;
        }

        command.dateTimeLevel1 = getFormattedDateTime(obj.departureTime);
        command.intValue = location.sequenceNo;

        this.appendOrRemoveCommand(command);

        if (!isNullOrEmpty(obj.departureTime) && location.sequenceNo !== 1) return;
        boardingType.isPilotBoardingTypeOffBoarding = false;

        location.pilotBoardingType = boardingType;
        location.isPilotBoardingTypeOffBoarding = boardingType.isPilotBoardingTypeOffBoarding;

        command = this.getCommandByCommandType(PilotAssignmentCommands.AddLocation, location);
        if (isObjectNull(command)) {
            const commandType = PilotAssignmentCommands.UpdateLocationBoardingType;
            const eventId = this.createCommandEventId(commandType, location);

            command = this.getCommandByEventId(eventId);

            if (isObjectNull(command)) {
                command = this.createCommand(commandType, location);
                command.idLevel1 = location.pilotagePilotLocationId;
            }
        }

        command.systemNameLevel2 = boardingType.systemName;
        command.boolValueLevel1 = boardingType.isPilotBoardingTypeOffBoarding;

        this.appendOrRemoveCommand(command);
    }

    onUpdateLocationArrivalTime(obj, location, lastLocation, boardingType) {
        let command = this.getCommandByCommandType(PilotAssignmentCommands.AddLocation, location);
        if (isObjectNull(command)) {
            command = this.createCommand(PilotAssignmentCommands.UpdateLocationArrivalTime, location);
            command.idLevel1 = obj.location.pilotagePilotLocationId;
        }

        command.dateTimeLevel2 = getFormattedDateTime(obj.arrivalTime);

        this.appendOrRemoveCommand(command);

        if (!isNullOrEmpty(obj.arrivalTime)) return;

        if (location.sequenceNo === lastLocation.sequenceNo) {
            boardingType.isPilotBoardingTypeOffBoarding = true;

            location.pilotBoardingType = boardingType;
            location.isPilotBoardingTypeOffBoarding = boardingType.isPilotBoardingTypeOffBoarding;

            command = this.getCommandByCommandType(PilotAssignmentCommands.AddLocation, location);
            if (isObjectNull(command)) {
                const commandType = PilotAssignmentCommands.UpdateLocationBoardingType;
                const eventId = this.createCommandEventId(commandType, obj);

                command = this.getCommandByEventId(eventId);

                if (isObjectNull(command)) {
                    command = this.createCommand(commandType, location);
                    command.idLevel1 = location.pilotagePilotLocationId;
                }
            }
        }
        
        command.systemNameLevel2 = boardingType.systemName;
        command.boolValueLevel1 = boardingType.isPilotBoardingTypeOffBoarding;

        this.appendOrRemoveCommand(command);
    }

    onUpdateLocationPilotType(location, pilotTypeSystemName) {
        let command = this.getCommandByCommandType(PilotAssignmentCommands.AddLocation, location);

        if (isObjectNull(command)) {
            command = this.createCommand(PilotAssignmentCommands.UpdateLocationPilotType, location);
            command.idLevel1 = location.pilotagePilotLocationId;
        }
        command.systemNameLevel1 = pilotTypeSystemName;

        this.appendOrRemoveCommand(command);
    }

    onUpdateLocationBoardingType(location, boardingTypeSystemName, isPilotBoardingTypeOffBoarding) {
        let command = this.getCommandByCommandType(PilotAssignmentCommands.AddLocation, location);
        if (isObjectNull(command)) {
            const commandType = PilotAssignmentCommands.UpdateLocationBoardingType;
            const eventId = this.createCommandEventId(commandType, location);

            command = this.getCommandByEventId(eventId);

            if (isObjectNull(command)) {
                command = this.createCommand(commandType, location);
                command.idLevel1 = location.pilotagePilotLocationId;
            }
        }

        command.systemNameLevel2 = boardingTypeSystemName;
        command.boolValueLevel1 = isPilotBoardingTypeOffBoarding;

        this.appendOrRemoveCommand(command);
    }

    onAddTowingTonnage(obj) {
        const command = this.createCommand(PilotAssignmentCommands.AddTowingTonnage, obj);
        command.intValue = obj;

        this.appendOrRemoveCommand(command);
    }

    onManueverResponsibleType(obj) {
        const commandType = PilotAssignmentCommands.ManueverResponsibleType;
        const command = this.createCommand(commandType, obj);

        command.systemNameLevel1 = obj.systemName;

        this.appendOrRemoveCommand(command)
    }

    onUpdateVariableCompensationOverriddenNumber(obj) {
        const commandType = PilotAssignmentCommands.UpdateVariableCompensationOverriddenNumber;
        const command = this.createCommand(commandType, obj);

        command.idLevel1 = obj.pilotagePilotCompensationId;
        command.idLevel2 = obj.pilotagePilotVariableCompensationId;
        command.systemNameLevel1 = obj.pilotagePilotVariableCompensationOverrideReasonType;
        command.textLevel1 = obj.pilotagePilotVariableCompensationOverrideReasonRemark;
        command.floatValue2 = obj.overridenNumber;
        command.systemNameLevel2 = obj.pilotagePilotVariableCompensationSystemName;

        this.appendOrRemoveCommand(command);
    }

    onPilotAssignmentOvertime(obj, pilotagePilotOvertimeId) {
        const commandType = obj.command;
        const command = this.createCommand(commandType, obj);
        const defaultType = "OVERTIME_100_PERCENT";

        command.guid = obj.guid;
        command.idLevel1 = this._assignment.pilotagePilotCompensation.pilotagePilotCompensationId;
        command.idLevel2 = pilotagePilotOvertimeId;
        command.dateTimeLevel1 = obj.fromTime;
        command.dateTimeLevel2 = obj.toTime;
        command.boolValueLevel1 = obj.isMinimum;
        command.boolValueLevel2 = obj.isTwoThird;
        command.systemNameLevel1 = defaultType;
        command.textLevel1 = obj.description;

        this.appendOrRemoveCommand(command);
    }

    onDeletePilotAssignmentOvertime(obj) {
        const commandType = PilotAssignmentCommands.DeletePilotAssignmentOvertime;
        const command = this.createCommand(commandType, obj);
        let addCommand = false;

        command.idLevel1 = obj.pilotagePilotCompensationId;
        command.idLevel2 = obj.pilotagePilotOvertimeId;

        if (isNumeric(obj.pilotagePilotOvertimeId)) {
            addCommand = obj.pilotagePilotOvertimeId > 0;
        }

        this.appendOrRemoveCommand(command, addCommand);

        let existingEventId = null;
        if (!addCommand) {
            existingEventId = this.createCommandEventId(PilotAssignmentCommands.AddPilotAssignmentOvertime, obj);
        } else {
            existingEventId = this.createCommandEventId(PilotAssignmentCommands.UpdatePilotAssignmentOvertime, obj);
        }

        const existingCommand = this.getCommandByEventId(existingEventId);
        if (!isObjectNull(existingCommand)) {
            this.appendOrRemoveCommand(existingCommand, false);
        }
    }

    onPilotHourCompensation(obj, pilotagePilotHourCompensationId) {
        const commandType = obj.command;
        const command = this.createCommand(commandType, obj);

        command.guid = obj.guid;
        command.idLevel1 = this._assignment.pilotagePilotCompensation.pilotagePilotCompensationId;
        command.idLevel2 = pilotagePilotHourCompensationId;
        command.systemNameLevel1 = obj.pilotagePilotHourCompensationSystemName;
        command.dateTimeLevel1 = obj.fromTime;
        command.dateTimeLevel2 = obj.toTime;
        command.textLevel1 = obj.description;
        command.floatValue1 = obj.number;

        this.appendOrRemoveCommand(command);
    }

    onDeletePilotHourCompensation(obj) {
        const commandType = PilotAssignmentCommands.DeletePilotHourCompensation;
        const command = this.createCommand(commandType, obj);
        let addCommand = false;

        command.idLevel1 = obj.pilotagePilotCompensationId;
        command.idLevel2 = obj.pilotagePilotHourCompensationId;

        if (isNumeric(obj.pilotagePilotCompensationId)) {
            addCommand = obj.pilotagePilotHourCompensationId > 0;
        }

        this.appendOrRemoveCommand(command, addCommand);

        let existingEventId = null;
        if (!addCommand) {
            existingEventId = this.createCommandEventId(PilotAssignmentCommands.AddPilotHourCompensation, obj);
        } else {
            existingEventId = this.createCommandEventId(PilotAssignmentCommands.UpdatePilotHourCompensation, obj);
        }

        const existingCommand = this.getCommandByEventId(existingEventId);
        if (!isObjectNull(existingCommand)) {
            this.appendOrRemoveCommand(existingCommand, false);
        }
    }

    onUpdateReimbursmentStatus(isReimbursementExcluded) {
        const command = this.createCommand(PilotAssignmentCommands.UpdateReimbursmentStatus);

        command.idLevel1 = this._assignment.pilotagePilotCompensation.pilotagePilotCompensationId;
        command.boolValueLevel1 = !isReimbursementExcluded;

        this.appendOrRemoveCommand(command);
    }

    onPilotRemark(remark) {
        const command = this.createCommand(PilotAssignmentCommands.PilotRemark);
        command.textLevel1 = remark;
        this.appendOrRemoveCommand(command);
    }

    onConvertToPilotageIncl(canConvertToPilotIncl) {
        const commandType = PilotAssignmentCommands.ConvertToPilotageIncl;

        if (canConvertToPilotIncl) {
            const command = this.createCommand(commandType, canConvertToPilotIncl);
            command.IdLevel1 = this._assignment.pilotage.pilotAssignmentCompensationId;
            this.appendOrRemoveCommand(command);

        } else {
            this.removeCommand(commandType);
        }
    }

    onUpdateConfirmedByPilot(canConnectToPilot) {
        const commandType = PilotAssignmentCommands.UpdatePilotAssignmentConfirmedByPilot;

        if (canConnectToPilot) {
            const command = this.createCommand(commandType, canConnectToPilot);
            command.IdLevel1 = this._assignment.pilotage.pilotAssignmentCompensationId;
            this.appendOrRemoveCommand(command);
        } else {
            this.removeCommand(commandType);
        }
    }

    onBothPilotAndExaminer(isBoth) {
        const commandType = PilotAssignmentCommands.BothPilotAndExaminer;

        if (isBoth) {
            const command = this.createCommand(commandType, isBoth);
            command.IdLevel1 = this._assignment.pilotagePilotCompensation.pilotagePilotCompensationId;
            this.appendOrRemoveCommand(command);
        } else {
            this.removeCommand(commandType);
        }
    }

    onCompletePilotageReceipt(isComplete) {
        const commandType = PilotAssignmentCommands.CompletePilotageReceipt;
        const command = this.createCommand(commandType, isComplete);

        command.IdLevel1 = this._assignment.pilotagePilotCompensation.pilotagePilotCompensationId;
        command.BoolValueLevel1 = isComplete;
        command.BoolValueLevel2 = this._assignment.isConfirmedByPilotDispatcher;

        if (isPilotAssignmentCompleted(this._assignment)) {
            if (isComplete) {
                this.removeCommand(commandType);
            } else {
                this.appendOrRemoveCommand(command);
            }
        } else {
            if (isComplete) {
                this.appendOrRemoveCommand(command);
            } else {
                this.removeCommand(commandType);
            }
        }
    }
}

export default PilotAssignmentCommandService;
