import React, { useEffect, useReducer, useRef, useState } from "react";
import { PageStates } from "../../../services/SystemNames";
import ShipApiRepository from "../../../repositories/api/ShipApiRepository";
import { DialogIconTitle, EditObservation, ObservationsList } from "./PilotageDetailsShipDialogContent";
import Overlay from "../../../components/layout/overlay/Overlay";
import { isNullOrEmpty } from "../../../components/helpers/ObjectHelpers";
import { formatDateTimeForDisplay } from "../../../components/helpers/DateTimeHelpers";

const Actions = {
    SetObservation: "setObservation",
    SetType: "setType",
    SetImage: "setImage",
    SetComment: "setComment",
    SetNewComment: "SetNewComment",
    SetNewType: "SetNewType",
    SetNewImage: "SetNewImage",
    ResetNewObservation: "ResetNewObservation",
    SetHttpStatusCode: "SetHttpStatusCode",
};

export const PilotageDetailsShipDialog = ({
    isActive,
    closeModalMenu,
    reloadObservations,
    shipObservations,
    shipObservationTypes,
    shipId,
    isAllowedEdit,
}) => {

    const [observationData, dispatch] = useReducer(observationReducer, {});
    const [state, setState] = useState(PageStates.Default);
    const [modalTitle, setModalTitle] = useState("Observasjoner");
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isStateChanged = useRef(false);

    useEffect(() => {
        window.screen.orientation.addEventListener('change', handleOrientationChange)
        return () => {
            window.screen.orientation.removeEventListener('change', handleOrientationChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function observationReducer(state, action) {
        switch (action.type) {
            case Actions.SetObservation: {
                return { shipObservation: { ...action.newObservation } };
            }
            case Actions.SetType: {
                return {
                    ...state,
                    shipObservation: {
                        ...state.shipObservation,
                        shipObservationType: action.newObservationType,
                    },
                };
            }
            case Actions.SetNewType: {
                return {
                    ...state,
                    newShipObservation: {
                        ...state.newShipObservation,
                        shipObservationType: action.newObservationType,
                    },
                };
            }
            case Actions.SetComment: {
                return {
                    ...state,
                    shipObservation: {
                        ...state.shipObservation,
                        observation: action.newComment,
                    },
                };
            }
            case Actions.SetNewComment: {
                return {
                    ...state,
                    newShipObservation: {
                        ...state.newShipObservation,
                        observation: action.newComment,
                    },
                };
            }
            case Actions.SetImage: {
                return {
                    ...state,
                    shipObservation: {
                        ...state.shipObservation,
                        imageBase64Url: action.newImageUrl,
                        image: {
                            source: action.newImageUrl.split(",")[1],
                            imageHeight: action.newImageDimensions.width,
                            imageWidth: action.newImageDimensions.height,
                        },
                    },
                };
            }
            case Actions.SetNewImage: {
                return {
                    ...state,
                    newShipObservation: {
                        ...state.newShipObservation,
                        imageBase64Url: action.newImageUrl,
                        image: {
                            source: action.newImageUrl.split(",")[1],
                            imageHeight: action.newImageDimensions.width,
                            imageWidth: action.newImageDimensions.height,
                        },
                    },
                };
            }
            case Actions.ResetNewObservation: {
                return {
                    ...state,
                    newShipObservation: {},
                };
            }
            case Actions.SetHttpStatusCode: {
                return {
                    ...state,
                    httpStatusCode: action.httpStatusCode,
                };
            }

            default: {
                console.error(`Unknow action: ${action.type}`);
                setState(PageStates.Error);
            }
        }
    }
    function handleShipObservationChanged(observation) {
        dispatch({
            type: Actions.SetObservation,
            newObservation: observation,
        });
        setState(PageStates.Default);
    };

    function handleImageSelected(imageUrl, imageDimensions) {
        if (state === PageStates.Edit) {
            dispatch({
                type: Actions.SetImage,
                newImageUrl: imageUrl,
                newImageDimensions: imageDimensions,
            });
            isStateChanged.current = true;
            setState(PageStates.Edit);
        }
        else if (state === PageStates.Add) {
            dispatch({
                type: Actions.SetNewImage,
                newImageUrl: imageUrl,
                newImageDimensions: imageDimensions,
            });
        }
    }

    const handleError = (response) => {
        if (response) {
            dispatch({
                type: Actions.SetHttpStatusCode,
                httpStatusCode: response,
            });
            setState(PageStates.Error);
        }
    };

    async function fetchObservationAsync(shipObservation) {

        const desiredImageWidth = Math.floor(window.innerWidth) * 2;
        const desiredImageHeight = Math.floor(window.innerHeight) * 2;

        try {
            var response = await ShipApiRepository.getShipObservationAsync(
                shipObservation.shipId,
                shipObservation.shipObservationId,
                desiredImageWidth,
                desiredImageHeight,
            );
            const data = await response.json();
            console.debug(data);
            return data;

        } catch (error) {
            handleError(response.status);
            console.error("An error occurred when fetching observation", error);
        }
    }

    async function fetchAndStoreObservationAsync(shipObservation) {
        setState(PageStates.Busy);

        const observation = await fetchObservationAsync(shipObservation);
        handleShipObservationChanged(observation);

        setState(PageStates.Edit);
    }

    async function deleteObservationAsync(observationToBeDeleted) {
        try {
            setState(PageStates.Busy);
            const observation = await fetchObservationAsync(observationToBeDeleted);

            if (!isNullOrEmpty(observation)) {
                await ShipApiRepository.deleteShipObservationByIdAsync(
                    observation.shipObservationId,
                    observation.etagValue
                );
            }

            setState(PageStates.Default);
            reloadObservations();

        } catch (error) {
            handleError(0);
            console.error("An error occurred when deleting observation", error);
        }
    }

    async function updateObservationAsync() {
        setState(PageStates.Busy);

        if (
            isNullOrEmpty(shipId) ||
            isNullOrEmpty(observationData.shipObservation.shipObservationType)
        ) {
            console.error("Missing data");
            setState(PageStates.Edit);
            return;
        }

        try {
            var shipObservationUpdate = {
                Observation: observationData.shipObservation.observation,
                ShipObservationType:
                    observationData.shipObservation.shipObservationType
                        .shipObservationTypeEnum,
                imageContent: observationData.shipObservation.image?.source ?? "",
                ETagValue: observationData.shipObservation.etagValue,
            };
            var response = await ShipApiRepository.updateShipObservationAsync(
                observationData.shipObservation.shipObservationId,
                shipObservationUpdate,
            );
            await fetchAndStoreObservationAsync(observationData.shipObservation);

            setState(PageStates.Default);
            reloadObservations();
        } catch (error) {
            handleError(response.status);
            console.error("An error occurred when saving observation", error);
        }
    }

    async function createObservationAsync() {
        setState(PageStates.Busy);

        if (
            isNullOrEmpty(shipId) ||
            isNullOrEmpty(
                observationData.newShipObservation.shipObservationType,
            )
        ) {
            console.error("Missing data");
            setState(PageStates.Add);
            return;
        }
        try {
            var shipObservationRequest = {
                ShipObservationType:
                    observationData.newShipObservation.shipObservationType
                        .shipObservationTypeEnum,
                ObservationComment: observationData.newShipObservation.observation,
                Image: observationData.newShipObservation.image ?? {
                    source: "",
                    imageHeight: 0,
                    imageWidth: 0,
                },
            };

            var response = await ShipApiRepository.createShipObservationAsync(
                shipId,
                shipObservationRequest,
            );
            if (response.ok === true) {
                resetNewObservationState();
                setState(PageStates.Default);
                reloadObservations();
            }
        } catch (error) {
            handleError(response.status);
            console.error("An error occurred when saving observation", error);
        }
    }

    const setTitle = (newTitle) => {
        setModalTitle(newTitle);
    };

    function handleObservationCommentUpdated(e) {
        if (state === PageStates.Edit) {
            dispatch({
                type: Actions.SetComment,
                newComment: e.target.value,
            });
        }
        else if (state === PageStates.Add) {
            dispatch({
                type: Actions.SetNewComment,
                newComment: e.target.value,
            });
        }
        isStateChanged.current = true;
    }

    const handleOrientationChange = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        reloadObservations();
    };

    function handleObservationTypeSelected(newValue) {
        if (state === PageStates.Edit && !isNullOrEmpty(newValue)) {
            dispatch({
                type: Actions.SetType,
                newObservationType: newValue,
            });
            setState(PageStates.Edit);
        }
        else if (state === PageStates.Add) {
            dispatch({
                type: Actions.SetNewType,
                newObservationType: newValue,
            });
        }
        isStateChanged.current = true;
    }

    function resetNewObservationState() {
        dispatch({
            type: Actions.ResetNewObservation,
        });
    }

    const handleDeleteObservation = (observationToBeDeleted) => {
        deleteObservationAsync(observationToBeDeleted);
    };

    const closeAndCleanupState = () => {
        if (resetNewObservationState) resetNewObservationState();
        setState(PageStates.Default);
        isStateChanged.current = false;
        closeModalMenu();
    };

    return (
        <>
            {isActive &&
                (() => {
                    switch (state) {
                        case PageStates.Default:
                            return (
                                <ObservationsList
                                    shipObservations={shipObservations}
                                    onDeleteObservation={handleDeleteObservation}
                                    onAddObservation={() => setState(PageStates.Add)}
                                    isAllowedEdit={isAllowedEdit}
                                    onPullToRefresh={reloadObservations}
                                    onObservationClick={fetchAndStoreObservationAsync}
                                    setTitle={() => setTitle("Observasjoner")}
                                    closeAndCleanupState={closeAndCleanupState}
                                    width={width}
                                    height={height}
                                    title={
                                        <DialogIconTitle
                                            title={modalTitle}
                                        />
                                    }
                                />
                            );
                        case PageStates.Edit:
                            return (
                                <EditObservation
                                    setState={setState}
                                    isAllowedEdit={isAllowedEdit}
                                    isStateChanged={isStateChanged}
                                    onTypeChanged={handleObservationTypeSelected}
                                    shipObservationTypes={shipObservationTypes}
                                    onSelectImage={handleImageSelected}
                                    shipObservation={observationData.shipObservation}
                                    onSaveButtonClicked={updateObservationAsync}
                                    onObservationCommentUpdated={handleObservationCommentUpdated}
                                    resetNewObservationState={resetNewObservationState}
                                    observationDate={formatDateTimeForDisplay(
                                        observationData.shipObservation.modifiedByPerson.modifiedDate,
                                    )}
                                    closeAndCleanupState={closeAndCleanupState}
                                    width={width}
                                    height={height}
                                    title={
                                        <DialogIconTitle
                                            title={"Rediger observasjon"}
                                        />
                                    }
                                />
                            );
                        case PageStates.Add:
                            return (
                                <EditObservation
                                    setState={setState}
                                    isAllowedEdit={isAllowedEdit}
                                    isStateChanged={isStateChanged}
                                    onTypeChanged={handleObservationTypeSelected}
                                    shipObservationTypes={shipObservationTypes}
                                    onSelectImage={handleImageSelected}
                                    shipObservation={observationData.newShipObservation}
                                    onSaveButtonClicked={createObservationAsync}
                                    onObservationCommentUpdated={handleObservationCommentUpdated}
                                    resetNewObservationState={resetNewObservationState}
                                    title={"Opprett ny observasjon"}
                                    closeAndCleanupState={closeAndCleanupState}
                                    width={width}
                                    height={height}
                                />
                            );
                        default: return null;
                    }
                })()
            }
            <Overlay isBusy={state === PageStates.Busy} />
        </>
    );
}