import { useEffect, useState } from "react";
import PilotagePilotTypeIdbRepository from "../../../../../repositories/idb/PilotagePilotTypeIdbRepository";
import SelectOptionFieldWrapper from "../../../../../components/layout/fieldWrappers/SelectOptionFieldWrapper";

export const LocationPilotTypeCard = (
    {
        index,
        assignmentService,
        onAfterChange,
        isEditable,
        hasValidationError
    }) => {

    const [selectedPilotType, setSelectedPilotType] = useState(null);
    const [pilotTypes, setPilotTypes] = useState([]);

    useEffect(() => {

        async function initializeAsync() {
            const location = assignmentService.getLocationByIndex(index);
            let pilotType = {};

            if (location.pilotTypeId > 0) {
                pilotType = await PilotagePilotTypeIdbRepository.getAsync(location.pilotTypeId);
                setSelectedPilotType(pilotType);
            }

            const pilotTypes = []
            const dtos = await PilotagePilotTypeIdbRepository.getAllAsync();

            for (const dto of dtos) {
                pilotTypes.push({
                    name: dto.name,
                    selected: dto.systemName === pilotType.systemName,
                    source: dto
                })
            }

            setPilotTypes(pilotTypes);
        }

        initializeAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SelectOptionFieldWrapper
            hasValidationError={hasValidationError}
            title="Losingstype"
            options={pilotTypes}
            selectedOption={selectedPilotType}
            optionTextField="name"
            onCallback={onCallback}
            disabled={!isEditable}
        />
    )

    async function onCallback(item) {
        setSelectedPilotType(item);

        const location = assignmentService.getLocationByIndex(index);
        const obj = {
            location: location,
            pilotType: item.source
        };

        await assignmentService.onUpdateLocationPilotTypeAsync(obj);

        onAfterChange();
    }
}
