import './googlemap.css'

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    APIProvider,
    Map
} from '@vis.gl/react-google-maps';
import { GoogleMapProperties } from './GoogleMapProperties';
import { MapControls } from './components/MapControls';
import { MapProviderSeaMap } from './components/MapProviderSeaMap';
import { MapSelectedPositionMarker } from './components/MapSelectedPositionMarker';
import { MapSelectedPositionCircle } from './components/MapSelectedPositionCircle';
import { MapPilotageMarkers } from './components/MapPilotageMarkers';
import { MapLegend } from './components/MapLegend';
import { MapClickPosition } from './components/MapClickPosition';
import { MapEvaluation } from './components/MapEvaluation';
import { MapLocationMarkers } from './components/MapLocationMarkers';
import { MapPilotCoastalSegmentGroups } from './components/MapPilotCoastalSegmentGroups';
import { MapListeners } from './components/MapListeners';
import { MapPilotCoastalSegmentGroup } from './components/MapPilotCoastalSegmentGroup';
import SourceApiRepository from '../../../repositories/api/SourceApiRepository';
import { MapEvents } from './components/MapEvents';
import { MapPecExamFairwayAreas } from './components/MapPecExamFairwayAreas';
import { MapAis } from './components/MapAis';
import uuid from 'react-uuid';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import { deepCopyObject, isArrayEmpty, isNullOrEmpty, isNumeric, isObjectNull } from '../../helpers/ObjectHelpers';
import { getNumericUrlParam } from '../../helpers/UrlHelpers';
import { getTileUrl } from '../../helpers/GeometryHelpers';
import { isDarkTheme } from '../../helpers/ThemeHelpers';
import { setMapTitle } from './GoogleMapHelpers';
import { useGetSourceConfigQuery } from '../../../reducers/slices/api.slice';
import { MapPanels } from './components/MapPanels';
import { PubSubTopics } from '../../helpers/PubSubHelpers';
import PubSub from 'pubsub-js';

export const GoogleMapCard = forwardRef((
    {
        properties = { ...GoogleMapProperties }

    }, ref) => {

    useImperativeHandle(ref, () => ({
        onFeatureMouseOver(e) {
            setFeatureMouseOver(e);
        },
        onFeatureMouseOut() {
            setFeatureMouseOver(null);
        },
        onFeatureSelected(e) {
            setFeatureSelected(e);
        },
        onSetSelectedPosition(e) {
            setSelectedPosition(e);
        },
        onToggleSelected() {
            onSelected();
        },
        onUpdatePcsTitle(title) {
            setMapTitle(mapId, title);
        },
        onCancelMultiSelect() {
            pilotCoastalSegmentGroupRef.current?.onCancelMultiSelect();
        },
        onResetPilotCoastalSegmentGroups() {
            pilotCoastalSegmentGroupsRef.current?.onReset();
            setFocusPcsGroupId(0);
        }
    }));

    const [isBusy, setIsBusy] = useState(true);
    const [config, setConfig] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [isSeamapActive, setIsSeamapActive] = useState(false);
    const [isLocationsActive, setIsLocationsActive] = useState(null);
    const [toggleMarkerInfoWindows, setToggleMarkerInfoWindows] = useState(null);
    const [featureMouseOver, setFeatureMouseOver] = useState(null);
    const [featureSelected, setFeatureSelected] = useState(null);
    const [pilotage, setPilotage] = useState(null);
    const [shipPosition, setShipPosition] = useState(null);
    const [mapId, setMapId] = useState("");
    const [focusPcsGroupId, setFocusPcsGroupId] = useState(0);

    const componentRef = useRef({
        circle: null,
        seamap: null,
        isSeamapActive: false,
        isLocationsActive: false
    });
    const { current: localRef } = componentRef;

    const panelsRef = useRef();
    const controlsRef = useRef();
    const pilotageMarkersRef = useRef();
    const locationMarkersRef = useRef();
    const pilotCoastalSegmentGroupsRef = useRef();
    const pilotCoastalSegmentGroupRef = useRef();
    const aisRef = useRef();

    const {
        data: sourceConfig,
        isSuccess: isSourceConfigSuccess
    } = useGetSourceConfigQuery();

    useEffect(() => {
        if (isNullOrEmpty(isSourceConfigSuccess)) return;

        setMapId(uuid());

        const lat = getNumericUrlParam("lat");
        const lng = getNumericUrlParam("lng");
        const config = deepCopyObject(sourceConfig);

        config.defaultMapLocation = JSON.parse(config.defaultMapLocation);

        if (isNumeric(lat) &&
            isNumeric(lng) &&
            lat > 0 && lng > 0) {
            config.defaultMapLocation = { lat: lat, lng: lng };
        }

        setConfig(config);
        
        setIsBusy(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSourceConfigSuccess]);

    useEffect(() => {
        if (isNumeric(properties.pilotageId)) {
            if (Number(properties.pilotageId) > 0) {
                async function initializePilotageAsync() {
                    const pilotageId = Number(properties.pilotageId);
                    const response = await PilotApiRepository.getReadOnlyPilotageByIdAsync(pilotageId);
                    if (response.ok) {
                        const data = await response.json();
                        setPilotage(data);
                    } else {
                        setPilotage(null);
                    }
                }

                initializePilotageAsync();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties]);

    useEffect(() => {

        if (!isNumeric(properties.shipId)) return;
        if (Number(properties.shipId) === 0) return;

        searchShipPositionAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties]);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.MapEvent, handlePubSubTopic);
        return () => {
            PubSub.unsubscribe(PubSubTopics.MapEvent);
        };
    }, []);

    return (
        !isBusy &&
            <div className={`googlemap-container ${getContainerClassName()}`} id={mapId}>
                <APIProvider onLoad={onApiProviderLoad} apiKey={config.googleMapApiKey} language="no">
                    <Map onClick={onMapClick}
                        defaultCenter={config.defaultMapLocation}
                        defaultZoom={properties.zoom}
                        minZoom={properties.minZoom}
                        mapId={isDarkTheme() ? config.googleDarkMapId : config.googleMapId}
                        mapTypeControl={properties.mapTypeControl}
                        zoomControl={properties.zoomControl}
                        fullscreenControl={properties.fullscreenControl}
                        streetViewControl={properties.streetViewControl}
                        rotateControl={properties.rotateControl}>

                        <MapListeners
                            properties={properties}
                            onBoundsChanged={onBoundsChanged}
                            onZoomChanged={onZoomChanged}
                            onDragEnd={onDragEnd}
                            onResize={onResize}
                            setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                        />

                        <MapEvents
                            mapId={mapId}
                            featureMouseOver={featureMouseOver}
                            featureSelected={featureSelected}
                        />

                        <MapControls
                            ref={controlsRef}
                            mapId={mapId}
                            properties={properties}
                            onMultiSelectClick={onMultiSelectClick}
                            onMultiSelectOkClick={onMultiSelectOkClick}
                            isLocationsActive={isLocationsActive}
                            onShowPanel={onShowPanel}
                        />

                        <MapSelectedPositionMarker
                            properties={properties}
                            position={selectedPosition} />

                        <MapSelectedPositionCircle
                            properties={properties}
                            localRef={localRef}
                            position={selectedPosition} />

                        <MapClickPosition
                            properties={properties}
                            selectedPosition={selectedPosition}
                            setSelectedPosition={setSelectedPosition}
                        />
                        <MapProviderSeaMap
                            isSeamapActive={isSeamapActive}
                            localRef={localRef} />

                        {
                            showLegend() &&
                            <MapLegend
                                properties={properties}
                                pilotage={pilotage}
                                shipPosition={shipPosition}
                                mapId={mapId}
                                onSettingsLegend={onSettingsLegend}
                            />
                        }

                        {
                            isLocationsActive &&
                            <MapLocationMarkers
                                ref={locationMarkersRef}
                                properties={properties}
                                toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                                setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                            />
                        }
                        

                        {
                            (showPilotageMarkers()) &&
                            <MapPilotageMarkers
                                ref={pilotageMarkersRef}
                                pilotage={pilotage}
                                shipPosition={shipPosition}
                                toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                                setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                                onUnselectShip={onUnselectShip}
                            />
                        }
                        
                        {
                            (showEvaluation()) &&
                            <MapEvaluation properties={properties} />
                        }

                        {
                            (showPilotCoastalSegments()) &&
                            <>
                                <MapPilotCoastalSegmentGroups
                                    ref={pilotCoastalSegmentGroupsRef}
                                    mapId={mapId}
                                    properties={properties}
                                    focusPcsGroupId={focusPcsGroupId}
                                />

                                <MapPilotCoastalSegmentGroup
                                    ref={pilotCoastalSegmentGroupRef}
                                    mapId={mapId}
                                    properties={properties}
                                    config={config}
                                />
                            </>
                        }

                        {
                            (showPecExamFairwayAreas()) &&
                            <MapPecExamFairwayAreas
                                mapId={mapId}
                                properties={properties}
                            />
                        }

                        {
                            (showAis()) &&
                            <MapAis
                                ref={aisRef}
                                properties={properties}
                                toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                                setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                            />
                        }
                        
                    </Map>

                    <MapPanels
                        ref={panelsRef}
                        mapId={mapId}
                        properties={properties}
                        pilotage={pilotage}
                        shipPosition={shipPosition}
                        onSeaMapClick={toggleSeamap}
                        onLocationsClick={toggleLocations}
                        onUnselectShip={onUnselectShip}
                    />

                </APIProvider>
            </div>
    );

    function handlePubSubTopic(topic, data) {
        if (topic !== PubSubTopics.MapEvent) return;
        switch (data.event) {
            case PubSubTopics.onFeatureMouseOver:
                setFeatureMouseOver(data.data);
                break;
            case PubSubTopics.onFeatureMouseOut:
                setFeatureMouseOver(null);
                break;
            case PubSubTopics.onPcsFeatureFocus:
                if (pilotCoastalSegmentGroupsRef.current) {
                    pilotCoastalSegmentGroupsRef.current.onFocus(data.data);
                } else {
                    setFocusPcsGroupId(data.data);
                }
                break;

            default:
                return;
        }
    }

    async function searchShipPositionAsync() {
        const shipId = Number(properties.shipId);
        const response = await SourceApiRepository.getShipPositionsAsync([shipId]);

        let result = null;

        if (response.ok) {
            const data = await response.json();
            if (!isArrayEmpty(data)) {
                result = data[0];
            }
        }

        setShipPosition(result);
    }

    function onApiProviderLoad() {
        localRef.seamap = new window.google.maps.ImageMapType({
            getTileUrl: getTileUrl,
            name: "Sj�kart",
            alt: "Norge / Sj�kart",
            minZoom: 0,
            maxZoom: 19,
            opacity: 1.0
        });

        setIsLocationsActive(properties.showLocations);
    }

    function onMapClick(e) {

        e.stop();

        setToggleMarkerInfoWindows(uuid());

        if (!properties.canMapClick) return;

        setSelectedPosition(e.detail.latLng);
        properties.handleOnMapClick(e.detail.latLng);
    }

    function toggleSeamap(isActive) {
        localRef.isSeamapActive = isActive;
        setIsSeamapActive(localRef.isSeamapActive);
    }

    function toggleLocations(isActive) {
        localRef.isLocationsActive = isActive;
        setIsLocationsActive(localRef.isLocationsActive);

    }

    function onMultiSelectClick() {
        pilotCoastalSegmentGroupRef.current?.onCancelMultiSelect();
    }

    function onMultiSelectOkClick() {
        pilotCoastalSegmentGroupRef.current?.onOkMultiSelect();
    }

    function onBoundsChanged() {
        pilotageMarkersRef.current?.onBoundsChanged()
        locationMarkersRef.current?.onBoundsChanged();
        pilotCoastalSegmentGroupsRef.current?.onBoundsChanged();
        pilotCoastalSegmentGroupRef.current?.onBoundsChanged();
        aisRef.current?.onBoundsChanged();
    }

    function onZoomChanged() {
        controlsRef.current?.onZoomChanged();
        pilotageMarkersRef.current?.onZoomChanged();
        locationMarkersRef.current?.onZoomChanged();
        pilotCoastalSegmentGroupRef.current?.onZoomChanged();
        aisRef.current?.onZoomChanged();

        onChange();
    }

    function onDragEnd() {
        pilotageMarkersRef.current?.onDragEnd();
        locationMarkersRef.current?.onDragEnd()
        pilotCoastalSegmentGroupRef.current?.onDragEnd();
        aisRef.current?.onDragEnd();

        onChange();
    }

    function onResize() {
        pilotageMarkersRef.current?.onResize();
        aisRef.current?.onResize();
        
        onChange();
    }

    function onSelected() {
        pilotCoastalSegmentGroupsRef.current?.onSelected();
    }

    function onSettingsLegend() {
        panelsRef.current?.deactivateLegend();
    }

    function onChange() {
        if (!isNumeric(properties.shipId)) return;
        if (Number(properties.shipId) === 0) return;

        searchShipPositionAsync();
    }

    function onShowPanel(state) {
        panelsRef.current?.onShow(state);
    }

    function onUnselectShip() {
        aisRef.current?.onUnselect();
    }

    function showLegend() {
        return !isObjectNull(properties.pecExam) ||
            !isObjectNull(pilotage) ||
            !isObjectNull(shipPosition);
    }

    function showPilotageMarkers() {
        return !isObjectNull(pilotage);
    }

    function showEvaluation() {
        return !isObjectNull(properties.evaluation);
    }

    function showPilotCoastalSegments() {
        return properties.showPcsGroups || properties.showPcs;
    }

    function showPecExamFairwayAreas() {
        return !isObjectNull(properties.pecExam);
    }

    function showAis() {
        return properties.isAisActive;
    }

    function getContainerClassName(){
        if (properties.isInline) return "googlemap-container-inline";

        return "";
    }
});
