import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import DateTimePicker, { DateTimePickerModes } from '../../../../components/layout/dateTimePicker/DateTimePicker';
import { PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import { Label } from '../../../../components/layout/Label';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const TimeArrivalCard = forwardRef((
    {
        initializeIsDirty,
        onArrivalTimeChanged,
        assignmentService
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initialize();
        },
        onValidationChanged(codeStrings) {
            setHasValidationError(codeStrings.includes(PilotAssignmentErrorCodes.PilotArrivalTimeIsMissing));
        }
    }));

    const [arrivalTime, setArrivalTime] = useState(null);
    const [show, setShow] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, [])

    return (
        <FieldWrapper>
            <Label hasValidationError={hasValidationError}>
                Oppm&oslash;te:
            </Label>
            <div className="k-form-field-wrap">
                <DateTimePicker
                    disabled={!isEditable}
                    hasValidationError={hasValidationError}
                    onClick={() => setShow(true)}
                    title="Velg oppm&oslash;te tid"
                    value={arrivalTime}
                    show={show}
                    onClose={() => setShow(false)}
                    mode={DateTimePickerModes.DateTime}
                    onChange={onChangedAsync} />
            </div>
        </FieldWrapper>
    )

    function initialize() {
        const pilotAssignment = assignmentService.assignment;
        setArrivalTime(pilotAssignment.arrivalTime);
        setIsEditable(assignmentService.isEditable);
    }

    function handlePubSubTopic() {
        initialize();
    }

    async function onChangedAsync(event) {

        setArrivalTime(event.value);

        const obj = event.value;

        await assignmentService.onPilotArrivalTimeAsync(obj);

        onArrivalTimeChanged();
        initializeIsDirty();
    }
})
