// U+FEFF at the beginning
/*eslint unicode-bom: ["error", "always"]*/

export const DispatcherActions = {
    PageState: 'PageState',
    Initialized: 'Initialized',
    IsFetchingMore: 'IsFetchingMore',
    CanFetchMore: 'CanFetchMore',
    IsBusy: 'IsBusy',
    NotBusy: 'NotBusy',
    HasErrors: 'HasErrors',
    PilotagePilot: 'PilotagePilot',
    IsFetchingObservations: 'IsFetchingObservations',
    FetchedObservations: 'FetchedObservations',
    ShowModalMenu: 'ShowModalMenu',
    CloseModalMenu: 'CloseModalMenu',
    ShowLocationSearch: 'ShowLocationSearch',
    SearchLocationsResult: 'SearchLocationsResult',
    Toggle: 'Toggle',
    Remark: 'Remark',
    PilotAssignments: 'PilotAssignments',
    AddLocation: 'AddLocation',
    SearchLocations: 'SearchLocations',
    Update: 'Update',
    IsOnline: 'IsOnline',
    ExecutedPilotAssignments: 'ExecutedPilotAssignments',
    Reset: 'Reset',
    CanSave: 'CanSave',
    Completing: 'Completing',
    Receipt: 'Receipt',
    PilotAssignment: 'PilotAssignment'
}

export const PilotAssignmentCommands = {
    IsWastedTrip: 'IsWastedTrip',
    PilotDepartureTime: 'PilotDepartureTime',
    PilotArrivalTime: 'PilotArrivalTime',
    PilotReturnTime: 'PilotReturnTime',
    SearchLocations: 'SearchLocations',
    AddLocation: 'AddPilotAssignmentLocation',
    DeleteLocation: 'DeletePilotAssignmentLocation',
    UpdateLocationDepartureTime: 'UpdatePilotAssignmentLocationDepartureTime',
    UpdateLocationArrivalTime: 'UpdatePilotAssignmentLocationArrivalTime',
    UpdateLocationPilotType: 'UpdatePilotAssignmentLocationPilotType',
    UpdateLocationBoardingType: 'UpdatePilotAssignmentLocationBoardingType',
    AddTowingTonnage: 'AddTowingTonnage',
    ManueverResponsibleType: 'ManueverResponsibleType',
    UpdateVariableCompensationOverriddenNumber: 'UpdateVariableCompensationOverriddenNumber',
    AddPilotAssignmentOvertime: 'AddPilotAssignmentOvertime',
    UpdatePilotAssignmentOvertime: 'UpdatePilotAssignemntOvertime',
    DeletePilotAssignmentOvertime: 'DeletePilotAssignemntOvertime',
    AddPilotHourCompensation: 'AddPilotHourCompensation',
    UpdatePilotHourCompensation: 'UpdatePilotHourCompensation',
    DeletePilotHourCompensation: 'DeletePilotHourCompensation',
    PilotRemark: 'PilotRemark',
    CompletePilotageReceipt: 'CompletePilotageReceipt',
    WaitingHourReason: 'WaitingHourReason',
    WaitingHourReasonRemark: 'WaitingHourReasonRemark',
    UpdateReimbursmentStatus: 'UpdateReimbursmentStatus',
    BothPilotAndExaminer: 'BothPilotAndExaminer',
    ConvertToPilotageIncl: 'ConvertToPilotageIncl',
    UpdatePilotAssignmentConfirmedByPilot: 'UpdatePilotAssignmentConfirmedByPilot',
}

export const PilotAssignmentErrorCodes = {
    PilotArrivalTimeIsMissing : "PilotArrivalTimeIsMissing",
    PilotDepartureTimeIsMissing : "PilotDepartureTimeIsMissing",
    ReturnTimeBeforeDepartureTime : "ReturnTimeBeforeDepartureTime",
    PilotStartBeforePilotArrivalTime : "PilotStartBeforePilotArrivalTime",
    PilotReturnTimeIsMissing : "PilotReturnTimeIsMissing",
    WaitingHourReasonIsMissing : "WaitingHourReasonIsMissing",
    WaitingHourReasonRemarkIsEmpty: "WaitingHourReasonRemarkIsEmpty",
    PilotLocationArrivalTimeExistsPreviousDepartureTimeIsMissing: "PilotLocationArrivalTimeExistsPreviousDepartureTimeIsMissing",
    PilotLocationPrevousArrivalTimeExistsDepartureTimeIsMissing: "PilotLocationPrevousArrivalTimeExistsDepartureTimeIsMissing",
    PilotLocationPilotTypeIsMissing: "PilotLocationPilotTypeIsMissing",
    PilotLocationOffboardingNotPossible: "PilotLocationOffboardingNotPossible",
    PilotLocationDepartureTimeMustBeAfterPreviousDepartureTime: "PilotLocationDepartureTimeMustBeAfterPreviousDepartureTime",
    PilotLocationDepartureTimeMustBeAfterPreviousArrivalTime: "PilotLocationDepartureTimeMustBeAfterPreviousArrivalTime",
    PilotLocationArrivalTimeCannotBeInTheFuture: "PilotLocationArrivalTimeCannotBeInTheFuture",
    PilotLocationDepartureTimeCannotBeInTheFuture: "PilotLocationDepartureTimeCannotBeInTheFuture",
    ManeuverResponsibleTypeIsMissing: 'ManeuverResponsibleTypeIsMissing',
    GrossTonnageIsMissing: 'GrossTonnageIsMissing',
    GrossTonnageMustBeLarger: 'GrossTonnageMustBeLarger',
    GrossTonnageCannotBeLarger: 'GrossTonnageCannotBeLarger',
    BoardingVessel: 'BOARDING_VESSEL',
    BoardingHelicopter: 'BOARDING_HELICOPTER',
    BridgeTimeDay: 'BRIDGE_TIME_DAY',
    BridgeTimeNight: 'BRIDGE_TIME_NIGHT'
}