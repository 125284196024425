import { useEffect, useRef, useState } from "react";
import "./pilot-station.css";
import Station from "./station/Station";
import moment from "moment";
import PilotStationApiRepository from "../../repositories/api/PilotStationApiRepository";
import MetaIdbRepository from "../../repositories/idb/MetaIdbRepository";
import { isArrayEmpty } from "../../components/helpers/ObjectHelpers";
import {
    publishInfoNotificationTopic,
    publishWarningNotificationTopic,
} from "../../components/helpers/PubSubHelpers";

const StationList = ({ station = null }) => {
    const [currentTime, setCurrentTime] = useState(moment());
    const indicator = useRef(null);

    const [stations, setStations] = useState([]);

    const [dataLoaded, setDataLoaded] = useState(false);

    const getStations = async () => {
        setDataLoaded(true);
        if (!dataLoaded) {
            if (station === null) {
                var response =
                    await PilotStationApiRepository.getFavouriteStations();
                if (response.ok) {
                    var result = await response.json();

                    setStations(result);

                    if (isArrayEmpty(result)) {
                        publishInfoNotificationTopic(
                            "Du har ingen valgte favoritter.",
                        );
                    }
                } else {
                    publishWarningNotificationTopic(
                        "Det oppstod en feil ved kommunikasjon med tjeneste",
                    );
                }
            } else {
                setStations([station]);
            }
        }
    };

    const loggedInPersonId = useRef(0);

    useEffect(() => {
        getLoggedInPersonId();
    }, []);

    async function getLoggedInPersonId() {
        const whoAmI = await MetaIdbRepository.getWhoAmIAsync();
        loggedInPersonId.current = whoAmI.personId;
    }

    useEffect(() => {
        getStations();

        let timer = setInterval(() => {
            setCurrentTime(moment());
        }, 1000 * 10);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (indicator.current) {
            indicator.current.scrollIntoView({
                behavior: "instant",
                inline: "center",
                block: "start",
            });
        }
    }, [indicator]);

    const from = moment().subtract(1, "day").startOf("day");
    const to = moment().add(2, "day").endOf("day");

    const Day = ({ title }) => {
        let hours = [];

        for (let h = 0; h < 24; h++) {
            hours.push(
                <div key={h} className="list-hour">
                    {h.toString().padStart(2, "0")}:00
                </div>,
            );
        }

        return (
            <div className="list-day">
                <div className="list-date">
                    <div>{title}</div>
                </div>
                <div className="list-hours">{hours}</div>
            </div>
        );
    };

    const Time = () => {
        let duration = moment.duration(to.diff(from));

        let days = [];
        for (let d = 0; d < duration.asDays(); d++) {
            let day = from.clone().add(d, "day");
            days.push(
                <Day key={d} title={day.format("DD.MM.YYYY")} day={day} />,
            );
        }

        return (
            <div className="list-time">
                <div
                    className="list-time-indicator"
                    ref={indicator}
                    style={{
                        left: `calc(var(--initials-width) + (var(--hour-width) * ${moment.duration(currentTime.diff(from)).asHours()}))`,
                    }}
                ></div>
                <div className="list-days">{days}</div>
            </div>
        );
    };

    return (
        <div className="list">
            <Time />
            <div className="stations">
                {stations
                    .filter((station) => station.hasShiftSchedule)
                    .map((station) => (
                        <Station
                            key={station.departmentID}
                            id={station.departmentID}
                            from={from}
                            to={to}
                            loggedInPersonId={loggedInPersonId}
                            currentTime={currentTime}
                            title={station.departmentName}
                        />
                    ))}
            </div>
        </div>
    );
};

export default StationList;
