import './selectoption.css'

import React from 'react';
import DropDownList from './DropDownList';
import { isObjectNull } from '../helpers/ObjectHelpers';

export default function SelectOption(
    {
        options = [],
        selectedOption = null,
        optionTextField = "",
        disabled = false,
        hasValidationError = false,
        onCallback = null,
        adaptive = false,
        adaptiveTitle = ""
    }
) {

    return (
        <div className="p-dropdown-list">
            <DropDownList
                data={options}
                value={getValue()}
                textField={optionTextField}
                hasValidationError={hasValidationError}
                disabled={disabled}
                onChange={onCallback}
                adaptive={adaptive}
                adaptiveTitle={adaptiveTitle}
            />
        </div>
    );

    function getValue() {
        if (isObjectNull(selectedOption)) return null;
        if (Object.hasOwn(selectedOption, "source")) return selectedOption;
        const option = options.find(o => o.source.systemName === selectedOption.systemName);

        return option;
    }
}
