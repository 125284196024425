import './styles/notice-time.css';

import React, { useEffect, useRef, useState } from 'react';
import NoticeTimeApiRepository from '../../repositories/api/NoticeTimeApiRepository';
import uuid from 'react-uuid';
import Overlay from '../../components/layout/overlay/Overlay';
import FloatingButtonContainer from '../../components/layout/FloatingButtonContainer';
import Icons, { ChevronDownIcon, ChevronUpIcon, OverviewIcon, PinIcon, PlusIcon, TrashIcon } from '../../components/layout/icons/Icons';
import Spacer from '../../components/layout/Spacer';
import { CardModes, CardProperties } from '../../components/layout/card/components/CardProperties';
import { IconColors } from '../../services/SystemNames';
import { LocationDialog } from './components/LocationDialog';
import { LocationGroupDialog } from './components/LocationGroupDialog';
import SearchTextbox from '../../components/layout/SearchTextbox';
import parse from 'html-react-parser';
import { NoticeTimeService } from './services/NoticeTimeService';
import { CardList } from '../../components/layout/card/CardList';
import { DialogConfirm } from '../../components/layout/dialogs/components/DialogConfirm';
import { DialogProperties } from '../../components/layout/dialogs/DialogProperties';
import FloatingActionButton from '../../components/layout/FloatingActionButton';
import Slide from '../../components/layout/Slide';
import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import ContentGrid from '../../components/layout/ContentGrid';
import { deepCopyObject, isArrayEmpty, isNullOrEmpty, isObjectNull, stopPropagation } from '../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import Switch from '../../components/layout/switch';
import { useSearchParams } from 'react-router-dom';
import { getBooleanSearchParam, getSearchParam } from '../../components/helpers/UrlHelpers';

const DeleteStates = {
    Location: 'LOCATION',
    Group: 'GROUP',
    Combined: 'COMBINED'
}

export default function Locations({ menu }) {

    const [searchParams, setSearchParams] = useSearchParams();

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [overriddenOnly, setOverriddenOnly] = useState(getBooleanSearchParam(searchParams, "overriddenOnly"));
    const [searchText, setSearchText] = useState(getSearchParam(searchParams, "searchText", ""));
    const [showLocationDialog, setShowLocationDialog] = useState(false);
    const [showLocationGroupDialog, setShowLocationGroupDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [generalNoticeTime, setGeneralNoticeTime] = useState(null);
    const [locationIds, setLocationIds] = useState([]);
    const [cards, setCards] = useState([]);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleteState, setDeleteState] = useState("");
    const [isSelectActive, setIsSelectActive] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const localRef = useRef({
        locations: []
    });
    const { current: currentRef } = localRef;
    
    // Initialize function
    useEffect(() => {
        publishHeaderTopic("Varslingstid per lokasjon");
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSearchParams({
            menu: menu,
            overriddenOnly: overriddenOnly,
            searchText: searchText
        }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overriddenOnly, searchText]);

    return (
        <>
            <ContentGrid
                title="Varslingstid"
                titleIcon="alarmClock"
                canPullToRefresh={true}
                onPullToRefresh={initializeAsync}
                subTitle={"Varslingstid per lokasjon"}
                addContentSpace={false}
                panel={
                    <div className="notice-time-search">
                        <div>
                            <Switch
                                checked={overriddenOnly}
                                onLabel="Vis kun overstyrte lokasjoner"
                                onChange={onOverriddenOnlyChange}
                            />
                            <Spacer height={10} />
                            <div>
                                <SearchTextbox
                                    placeholder="Lokasjon eller kode"
                                    value={searchText}
                                    onChange={onSearchTextChange}
                                />
                            </div>
                        </div>
                    </div>
                }>
                <div className="notice-time-search-result">
                    <CardList
                        mode={CardModes.Multible}
                        cards={cards}
                        toggleActivateSelect={onToggleActivateSelect}
                        toggleSelectItem={onToggleSelectItem}
                    />
                </div>
                <Spacer height={70} />
            </ContentGrid>

            <FloatingButtonContainer hasBottomNavigation={true}>
                {
                    isSelectActive ? 
                        <FloatingActionButton
                            themeColor={isArrayEmpty(selectedItems) ? "secondary" : "error"}
                            svgIcon={TrashIcon}
                            onClick={onDeleteCombined}
                            disabled={isArrayEmpty(selectedItems)}
                        />
                        :
                        <FloatingActionButton
                            themeColor="primary"
                            svgIcon={PlusIcon}
                            onItemClick={onAddItemClick}
                            items={[
                                {
                                    text: "Ny gruppe med lik varslingstid",
                                    svgIcon: OverviewIcon,
                                    action: NoticeTimeService.noticeTypes.group
                                },
                                {
                                    text: parse("Ny lokasjon utenfor sertifikat-omr&#229;det"),
                                    svgIcon: PinIcon,
                                    action: NoticeTimeService.noticeTypes.location
                                },
                            ]}
                        />
                }
                
            </FloatingButtonContainer>

            {
                showLocationDialog &&
                <LocationDialog
                    item={selectedItem}
                    general={generalNoticeTime}
                    onClose={() => setShowLocationDialog(false)}
                    onSave={onSaveLocationAsync}
                    locationIds={locationIds}
                />
            }

            {
                showLocationGroupDialog &&
                <LocationGroupDialog
                    item={selectedItem}
                    general={generalNoticeTime}
                    onClose={() => setShowLocationGroupDialog(false)}
                    onSave={onSaveGroupAsync}
                    locationIds={locationIds}
                />
            }

            {
                showConfirmDelete && 
                <DialogConfirm properties={{
                        ...DialogProperties,
                        title: "Slett varslingstid",
                        onClose: () => {
                            setShowConfirmDelete(false);
                            setDeleteState("");
                            setSelectedItem(null);
                        },
                        onClick: onConfirmDelete
                    }}>
                        
                        <div>
                            {(() => {
                                switch (deleteState)
                                {
                                    case DeleteStates.Location:
                                        return (<div>&Oslash;nsker du &aring; slette varslingstid for lokasjonen <b>{selectedItem.name}</b>?</div>);

                                    case DeleteStates.Group:
                                        return (<div>&Oslash;nsker du &aring; slette varslingstid for gruppen <b>{selectedItem.name}</b>?</div>);

                                    case DeleteStates.Combined:
                                        return (<div>&Oslash;nsker du &aring; slette valgte grupper / lokasjoner?</div>);

                                    default:
                                        return null;
                                }
                            })()}
                        </div>
                    </DialogConfirm>
            }

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    async function initializeAsync() {
        await initializeGeneralAsync();
        await initializeLocationsAsync();
        setIsBusy(false);
    }

    async function initializeGeneralAsync() {
        const response = await NoticeTimeApiRepository.getGeneralAsync();
        if (response.ok) {
            const data = await response.json();
            setGeneralNoticeTime(data);
        } else {
            handleError(response, "En feil oppstod ved henting av generell varslingstid.");
        }
    }

    async function initializeLocationsAsync() {
        const response = await NoticeTimeApiRepository.getLocationSummaryAsync();
        if (response.ok) {
            let data = await response.json();
            data = data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)

            currentRef.locations = deepCopyObject(data);
            onSearchLocations(data, overriddenOnly, searchText);

        } else {
            handleError(response, "En feil oppstod ved henting av varslingstid for lokasjoner.");
        }
    }

    function onOverriddenOnlyChange(e) {
        setOverriddenOnly(e.value);
        onSearchLocations(currentRef.locations, e.value, searchText);
    }

    function onSearchTextChange(e) {
        setSearchText(e.value);
        onSearchLocations(currentRef.locations, overriddenOnly, e.value);
    }

    function onSearchLocations(data, overriddenOnly, text) {

        let filteredData = deepCopyObject(data);
        
        if (overriddenOnly) {
            filteredData = filteredData
                .filter(l => NoticeTimeService.isOverridden(l));
        } 
        
        if (!isNullOrEmpty(text)) {
            const searchText = text.toLowerCase();
            filteredData = filteredData
                .filter(l =>
                    l.name.toLowerCase().startsWith(searchText) ||
                    l.locations.some(n => n.locationName.toLowerCase().startsWith(searchText)) ||
                    l.codes.some(c => c.toLowerCase().startsWith(searchText)) ||
                    l.codes.some(c => c.toLowerCase().startsWith(`no${searchText}`))
                );
        }
        
        const result = [];
        
        for (const item of filteredData) {
            result.push(createCardItem(item));
        }
        
        setCards(result);
    }

    function onItemClick(item) {

        getExcludedLocationIds();

        setSelectedItem(item);

        switch (item.type) {
            case NoticeTimeService.noticeTypes.group:
                setShowLocationGroupDialog(true);
                break;
            case NoticeTimeService.noticeTypes.location:
            case NoticeTimeService.noticeTypes.general:
                setShowLocationDialog(true);
                break;
            default:
                return;
        }
    }

    function onAddItemClick(e) {
        getExcludedLocationIds();

        setSelectedItem(null);

        switch (e.itemProps.action) {
            case NoticeTimeService.noticeTypes.group:
                setShowLocationGroupDialog(true);
                break;
            case NoticeTimeService.noticeTypes.location:
                setShowLocationDialog(true);
                break;
            default:
                return;
        }
    }

    function onReloadClick() {
        setIsBusy(true);
        setHasErrors(false);
        initializeAsync();
    }

    async function onSaveLocationAsync(location) {

        setShowLocationDialog(false);
        setIsBusy(true);

        const response = await NoticeTimeApiRepository.setLocationAsync(location);

        if (response.ok === true) {
            publishSuccessNotificationTopic(`Varslingstid for lokasjonen ${location.name} ble lagret.`);
            initializeAsync();
        } else {
            handleError(response, `En feil oppstod ved lagring av varslingstid for lokasjonen ${location.name}`);
        }
        setIsBusy(false);
    }

    function onDeleteItemClick(item) {
        
        if (!NoticeTimeService.isOverridden(item)) return;

        setSelectedItem(item);

        switch (item.type) {
            case NoticeTimeService.noticeTypes.group:
                setDeleteState(DeleteStates.Group);
                setShowConfirmDelete(true);
                break;

            case NoticeTimeService.noticeTypes.location:
                setDeleteState(DeleteStates.Location);
                setShowConfirmDelete(true);
                break;

            default: return;
        }
    }

    function onConfirmDelete() {

        setShowConfirmDelete(false);
        
        switch (deleteState) {
            case DeleteStates.Location:
                onConfirmDeleteLocationAsync();
                break;
            case DeleteStates.Group:
                onConfirmDeleteGroupAsync();
                break;
            case DeleteStates.Combined:
                onConfirmDeleteCombinedAsync();
                break;
            default: return;
        }

        setDeleteState("");
        setSelectedItem(null);
        setSelectedItems([]);
        setIsSelectActive(false);
    }

    async function onConfirmDeleteLocationAsync() {

        setIsBusy(true);

        const response = await NoticeTimeApiRepository.deleteLocationAsync(selectedItem.sourceId);
        if (response.ok) {
            publishSuccessNotificationTopic(`Varslingstid for lokasjonen ${selectedItem.name} ble slettet.`);
            onReloadClick();
        } else {
            handleError(response, `Kunne ikke slette varslingstid for lokasjonen ${selectedItem.name}`);
            setIsBusy(false)
        }
    }

    async function onSaveGroupAsync(group) {

        setShowLocationGroupDialog(false);
        setIsBusy(true);

        const response = await NoticeTimeApiRepository.setGroupAsync(group);

        if (response.ok === true) {
            publishSuccessNotificationTopic(`Varslingstid for ${group.groupName} ble lagret.`);
            initializeAsync();
        } else {
            handleError(response, `Kunne ikke lagre varslingstid for ${group.groupName}.`);
            setIsBusy(false)
        }
    }

    async function onConfirmDeleteGroupAsync() {
        setIsBusy(true);

        const response = await NoticeTimeApiRepository.deleteGroupAsync(selectedItem.sourceId);
        if (response.ok) {
            publishSuccessNotificationTopic(`Varslingstid for gruppen ${selectedItem.name} ble slettet.`);
            onReloadClick();
        } else {
            handleError(response, `Kunne ikke slette varslingstid for gruppen ${selectedItem.name}`);
            setIsBusy(false)
        }
    }

    async function onConfirmDeleteCombinedAsync() {
        setIsBusy(true);

        const ids = {
            pilotNoticeGroupIds: [],
            pilotLocationNoticeTimeIds: []
        };

        for (const item of selectedItems) {
            if (item.type === NoticeTimeService.noticeTypes.location) {
                ids.pilotLocationNoticeTimeIds.push(item.id);
            } else {
                ids.pilotNoticeGroupIds.push(item.id);
            }
        }

        const response = await NoticeTimeApiRepository.deleteLocationsAsync(ids);
        if (response.ok) {
            publishSuccessNotificationTopic(`Varslingstid for valgte grupper / lokasjoner ble slettet.`);
            onReloadClick();
        } else {
            handleError(response, `Kunne ikke slette varslingstid valgte grupper / lokasjoner`);
            setIsBusy(false)
        }
    }

    function onDeleteCombined() {
        setDeleteState(DeleteStates.Combined);
        setShowConfirmDelete(true);
    }

    function handleError(response, warning = "") {
        publishWarningNotificationTopic(warning, response.status);
        setHasErrors(true);
    }

    function getExcludedLocationIds() {
        const overriddenIds = [];
        const generalIds = []

        for (const item of currentRef.locations) {
            switch (item.type) {
                case NoticeTimeService.noticeTypes.group:
                    for (const location of item.locations) {
                        overriddenIds.push(location.locationId);
                    }
                    break;
                case NoticeTimeService.noticeTypes.location:
                    overriddenIds.push(item.sourceId);
                    break;
                default:
                    generalIds.push(item.sourceId);
                    break;
            }
        }
        
        setLocationIds({
            overriddenIds: overriddenIds,
            generalIds: generalIds
        });
    }

    function createCardItem(item) {
        return {
            guid: uuid(),
            properties: {
                ...CardProperties,
                id: item.id,
                sourceId: item.id,
                title: item.name,
                type: item.type,
                onClick: () => onItemClick(item),
                onDeleteClick: NoticeTimeService.isOverridden(item) ? () => onDeleteItemClick(item) : null,
                isBorderLeftFat: NoticeTimeService.isOverridden(item),
                className: "card-item-content-border-left",
                borderColor: getCardItemBorderColor(item),
                iconColor: "white",
                canSelect: NoticeTimeService.isOverridden(item),
                canToggle: NoticeTimeService.isOverridden(item)
            },
            body: <>
                <NoticeTimeLocationCard
                    item={item}
                    onClick={() => onItemClick(item)}
                />
                <Spacer height={15} />
            </>
        }
    }

    function getCardItemBorderColor(item) {
        if (NoticeTimeService.isOverridden(item)) return "var(--card-notice-time-location-border)";
        return "var(--card-border)"
    }

    function onToggleActivateSelect(e) {
        setIsSelectActive(e);
    }

    function onToggleSelectItem(id, selected, type) {
        const result = deepCopyObject(selectedItems);

        if (selected) {
            result.push({
                id: id,
                type: type
            });
        } else {
            const item = result.find(i => i.id === id && i.type === type);
            if (!isObjectNull(item)) {
                const index = result.indexOf(item);
                result.splice(index, 1);
            }
        }
        setSelectedItems(result);
    }
}

function NoticeTimeLocationCard({ item }) {
    const [show, setShow] = useState(false);

    const LocationSorter = (a, b) => a.locationName.toLowerCase() > b.locationName.toLowerCase() ? 1 : -1;

    return (
        <div className="container location-card">
            <div className="row align-items-center">
                <div className="col-2 col-sm-1">
                    <Icons iconName="day"/>
                </div>
                <div className="col-4 col-sm-5">
                    {item.minutesBeforeToNoticeDay} min
                </div>
                <div className="col-2 col-sm-1">
                    <Icons iconName="night" />
                </div>
                <div className="col-4 col-sm-5">
                    {item.minutesBeforeToNoticeNight} min
                </div>
            </div>
            {
                item.type === NoticeTimeService.noticeTypes.group &&
                <>
                    <Spacer height={15} />
                    <div className="row align-items-center">
                        <div className="col-2 col-sm-1">
                            <Icons iconName="pinFilled" color={IconColors.PinLocation} />
                        </div>
                        <div className="col-10 col-sm-11">
                            <Button
                                style={{
                                    zIndex: 10
                                }}
                                onClick={onClick}
                                endIcon={<SvgIcon icon={show ? ChevronUpIcon : ChevronDownIcon} />}
                                fillMode="flat">
                                {`${item.locations.length} lokasjoner`}
                            </Button>
                        </div>
                    </div>
                    <Slide show={show}>
                        <Spacer height={10} />
                        <div className="row">
                            <div className="col-2 col-sm-1" />
                            <div className="col-10 col-sm-11">
                                <ul>
                                    {
                                        item.locations.sort(LocationSorter).map((location, index) =>
                                            <li key={index}>{location.locationName }</li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </Slide>
                </>
            }
        </div>
        
    )

    function onClick(e) {
        stopPropagation(e);
        setShow(!show);
    }
}
