import { Card } from '../../../../components/layout/card/Card';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import Spacer from '../../../../components/layout/Spacer';
import { getPilotAssignmentStatusColor, getPilotAssignmentStatusColorRgba, isPilotAssignmentCorrectionNeeded } from '../helpers/PilotAssignmentHelpers';

export const CorrectionCard = ({
    assignmentService
}) => {

    const assignment = assignmentService.assignment;
    const borderColor = getPilotAssignmentStatusColor(assignmentService.assignment);
    const backgroundColor = getPilotAssignmentStatusColorRgba(assignmentService.assignment);

    return (
        isPilotAssignmentCorrectionNeeded(assignment) &&
        <>
            <Card
                properties={{
                    ...CardProperties,
                    title: "Begrunnelse for korrigerning",
                    borderColor: borderColor,
                    backgroundColor: backgroundColor
                }}>
                {assignmentService.assignment.correctionRemark}
            </Card>
            <Spacer height={20} />
        </>
    )
    
}
