// U+FEFF at the beginning
/*eslint unicode-bom: ["error", "always"]*/
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EvaluationService from '../../../services/EvaluationService';
import Responsibility from './Responsibility';
import Spacer from '../../../../../components/layout/Spacer';
import Subject from './Subject';
import EvaluationIdbRepository from '../../../../../repositories/idb/EvaluationIdbRepository';
import "../../../styles/evaluation.css"
import { ButtonsGrid } from '../../../../../components/layout/ButtonsGrid';
import { Button } from "@progress/kendo-react-buttons";
import { NextIcon, SaveIcon } from '../../../../../components/layout/icons/Icons';
import { isBoolean } from '../../../../../components/helpers/ObjectHelpers';
import { publishWarningNotificationTopic } from '../../../../../components/helpers/PubSubHelpers';
import { getElementsByClassName } from '../../../../../components/helpers/ElementHelpers';
import Slide from '../../../../../components/layout/Slide';
import { EvaluationAlternativeTypes, EvaluationAssessmentEnum, EvaluationResponsibilityReasonEnum, isBlank } from '../../../services/EvaluationHelpers';

function Index(
    {
        evaluation,
        identifier,
        onSaveClick,
        onSaveNextClick
    }
) {
    const navigate = useNavigate();

    const [evaluationCopy, setEvaluationCopy] = useState(JSON.parse(JSON.stringify(evaluation)));
    const [canSave, setCanSave] = useState(false);
    const [canSaveNext, setCanSaveNext] = useState(false);
    const [showIncomplete, setShowIncomplete] = useState(false);
    const [showResponsibility, setShowResponsibility] = useState(false);
    const [showSections, setShowSections] = useState(false);
    const [subjects, setSubjects] = useState([]);

    useEffect(() => {

        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);

        setShowResponsibility(evaluationCopy.hasMultibleExaminers);
        setSubjects(getSubjects(evaluationCopy.isSvalbardActive));

        if (evaluationCopy.hasMultibleExaminers) {
            if (evaluationCopy.isRenounced) {
                setCanSaveNext(!evaluationCopy.isCompleted && navigator.onLine);
            } else {

                setCanSaveNext(!evaluationCopy.isEvaluationDocumentEmpty && navigator.onLine);
                setCanSave(!evaluationCopy.isEvaluationDocumentEmpty && navigator.onLine);
                setShowSections(!evaluationCopy.isEvaluationDocumentEmpty);
            }

        } else {
            setShowSections(true);
            setCanSaveNext(navigator.onLine);
            setCanSave(navigator.onLine);
        }

        return function cleanupEventListeners() {
            window.removeEventListener("offline", handleOffline);
            window.removeEventListener("online", handleOnline);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="evaluation-form">
                <Spacer height={10} />
                {
                    showResponsibility &&
                        <Responsibility
                            evaluation={evaluation}
                            onCallback={onResponsibilityCallback} />
                }

                <Slide show={showSections}>
                    {
                        subjects.map((subject, index) =>
                            <div key={index}>
                                <Subject
                                    showIncomplete={showIncomplete}
                                    subject={subject} />
                            </div>
                        )
                    }
                </Slide>
            </div>
            <Spacer height={30} />

            <ButtonsGrid>
                <Button
                    themeColor="primary"
                    fillMode="outline"
                    disabled={!canSave}
                    onClick={onInternalSaveClick}
                    svgIcon={SaveIcon}>
                    Lagre
                </Button>
                <Button
                    themeColor="primary"
                    svgIcon={NextIcon}
                    onClick={onInternalSaveNextClick}
                    disabled={!canSaveNext}>
                    Neste
                </Button>
            </ButtonsGrid>
        </>
    );

    function handleOffline() {
        setCanSaveNext(false);
        setCanSave(false);
    }

    function handleOnline() {
        if (evaluationCopy.hasMultibleExaminers) {
            if (evaluationCopy.isRenounced) {
                setCanSaveNext(!evaluationCopy.isCompleted);
            } else {

                setCanSaveNext(!evaluationCopy.isEvaluationDocumentEmpty);
                setCanSave(!evaluationCopy.isEvaluationDocumentEmpty);
            }
        } else {
            setCanSaveNext(true);
            setCanSave(true);
        }
    }

    function getSubjects(isSvalbardActive) {
        let subjectArray = [];

        // PREPARATION
        const preparationSteps = getPreparationSteps();
        subjectArray.push({
            title: 'Før prøven starter',
            initialStepsCompleted: getPreparationStepsCompleted(preparationSteps.length),
            steps: preparationSteps
        });

        // REGULATIONS
        const regulationsSteps = getRegulationsSteps();
        subjectArray.push({
            title: 'Regelverk',
            initialStepsCompleted: getRegulationsStepsCompleted(regulationsSteps.length),
            steps: regulationsSteps
        });

        // ECDIS USAGE
        const ecdisUsageSteps = getEcdisUsageSteps();
        subjectArray.push({
            title: 'Bruk av ECDIS',
            initialStepsCompleted: getEcdisUsageStepsCompleted(ecdisUsageSteps.length),
            steps: ecdisUsageSteps
        });

        // COASTAL VOYAGE
        const coastalVoyageSteps = getCoastalVoyageSteps();
        subjectArray.push({
            title: 'Kystseilas',
            initialStepsCompleted: getCoastalVoyageStepsCompleted(coastalVoyageSteps.length),
            steps: coastalVoyageSteps
        });

        // ADJACENT WATERS
        const adjacentWatersSteps = getAdjacentWatersSteps();
        subjectArray.push({
            title: 'Tilstøtende farvann',
            initialStepsCompleted: getAdjacentWatersStepsCompleted(adjacentWatersSteps.length),
            steps: adjacentWatersSteps
        });

        // DEBRIEF
        const debriefSteps = getDebriefSteps();
        subjectArray.push({
            title: 'Debrief',
            initialStepsCompleted: getDebriefStepsCompleted(debriefSteps.length),
            steps: debriefSteps
        });

        // SVALBARD
        if (isSvalbardActive) {
            const svalbardSteps = getSvalbardSteps();
            subjectArray.push({
                title: 'Kun for Svalbard',
                initialStepsCompleted: getSvalbardStepsCompleted(svalbardSteps.length),
                steps: svalbardSteps
            });
        }

        // DAY NIGHT
        const dayNightSteps = getDayNightSteps();
        subjectArray.push({
            title: 'Tidspunkt',
            initialStepsCompleted: getDayNightStepsCompleted(dayNightSteps.length),
            steps: dayNightSteps
        });

        // RECOMMENDATION
        const recommendationSteps = getRecommendationSteps();
        subjectArray.push({
            title: 'Losens anbefaling',
            initialStepsCompleted: getRecommendationStepsCompleted(recommendationSteps.length),
            steps: recommendationSteps
        });

        return subjectArray;
    }

    //#region PREPARATION
    function getPreparationSteps() {
        const parent = 'preparation';
        return [
            createBoolAssessmentStep(
                parent,
                'idCheck',
                'Sjekk ID på kandidaten',
                EvaluationAlternativeTypes.Boolean),
            createBoolAssessmentStep(
                parent,
                'languageCheck',
                'Avklare med kaptein at all kommunikasjon på broen under prøven skal gå på engelsk eller skandinavisk',
                EvaluationAlternativeTypes.Boolean),
            createBoolAssessmentStep(
                parent,
                'areaCheck',
                'Avklare prøveområde i henhold til kartskisse i prøvetilbudet og hvilke områder kandidaten vil bli " eksaminert" i teoretisk og praktisk',
                EvaluationAlternativeTypes.Boolean
            ),
            createAssessmentStep(
                parent,
                'passagePlan',
                'Gjennomgang av ruteplan (passageplan)',
                EvaluationAlternativeTypes.Level
            ),
            createAssessmentStep(
                parent,
                'englishAssessment',
                'Kandidaten forstår og kan gjøre seg forstått på engelsk',
                EvaluationAlternativeTypes.Level
            )
        ];
    }

    function getPreparationStepsCompleted(totalSteps) {
        const preparation = evaluationCopy.evaluationDocument.preparation;

        let initialStepsCompleted = 0;

        // Id Check
        if (isBoolean(preparation.idCheck.isApproved)) initialStepsCompleted++;

        // Language Check
        if (isBoolean(preparation.languageCheck.isApproved)) initialStepsCompleted++;

        // Area Check
        if (isBoolean(preparation.areaCheck.isApproved)) initialStepsCompleted++;

        // Passage Plan
        if (!isBlank(preparation.passagePlan.assessment)) initialStepsCompleted++;

        // English Assessment
        if (!isBlank(preparation.englishAssessment.assessment)) initialStepsCompleted++;

        preparation.isCompleted = totalSteps === initialStepsCompleted;

        return initialStepsCompleted;
    }

    // #endregion

    //#region REGULATIONS
    function getRegulationsSteps() {
        const parent = 'regulations';
        return [
            createAssessmentStep(
                parent,
                'pecRestingTime',
                'Kunne forklare hviletids- bestemmelsene for bruk av FB',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'pecUsage',
                'Kunne forklare når FB kan benyttes av styrmann og kaptein',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'pecLocalRestrictions',
                'Kunne redegjøre for hvor det er lokale begrensninger for bruk av FB i aktuelt prøveområde',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'vtsAreaLimitations',
                'Kunne redegjøre for begrensninger og føringer gitt i sjøtrafikkforskriften for gjeldende VTS område',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'pecHazMatAllowed',
                'Kandidaten forstår og kan gjøre seg forstått på engelsk',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'pecHazMatNotAllowed',
                'Kunne redegjøre for der hvor FB ikke kan benyttes, ved føring av farlig eller forurenset last',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
        ];
    }

    function getRegulationsStepsCompleted(totalSteps) {
        const regulations = evaluationCopy.evaluationDocument.regulations;
        let initialStepsCompleted = 0;

        // Pec Resting Time
        if (!isBlank(regulations.pecRestingTime.assessment)) initialStepsCompleted++;

        // Pec Usage
        if (!isBlank(regulations.pecUsage.assessment)) initialStepsCompleted++;

        // Pec Local Restrictions
        if (!isBlank(regulations.pecLocalRestrictions.assessment)) initialStepsCompleted++;

        // Vts Area Limitations
        if (!isBlank(regulations.vtsAreaLimitations.assessment)) initialStepsCompleted++;

        // Pec Hazmat Allowed
        if (!isBlank(regulations.pecHazMatAllowed.assessment)) initialStepsCompleted++;

        // Pec Hazmat Not Allowed
        if (!isBlank(regulations.pecHazMatNotAllowed.assessment)) initialStepsCompleted++;

        regulations.isCompleted = totalSteps === initialStepsCompleted;

        return initialStepsCompleted;
    }
    //#endregion

    //#region ECDIS USAGE
    function getEcdisUsageSteps() {
        const parent = 'ecdisUsage';
        return [
            createAssessmentStep(
                parent,
                'sailingWithoutCheckingEcdis',
                'Kunne gjennomføre en sikker seilas uten å kontinuerlig observere seilasen på ECDIS',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createBoolAssessmentStep(
                parent,
                'canNotSeeEcdis',
                'Kandidaten er plassert slik at han ikke kontinuerlig kan observere seilas på ECDIS',
                EvaluationAlternativeTypes.Boolean
            ),
            createBoolAssessmentStep(
                parent,
                'ecdisIsUsedAsMap',
                'ECDIS brukes som kart og ruten vises ikke',
                EvaluationAlternativeTypes.Boolean
            ),
            createBoolAssessmentStep(
                parent,
                'ecdisIsObscured',
                'ECDIS dimmes ned',
                EvaluationAlternativeTypes.Boolean
            ),
            createBoolAssessmentStep(
                parent,
                'ecdisIsCovered',
                'ECDIS dekkes til',
                EvaluationAlternativeTypes.Boolean
            ),
            createBoolAssessmentStep(
                parent,
                'opticalNavigationTestIsNotPossible',
                'Kaptein krever at kandidaten skal kunne observere sin seilas på ECDIS kontinuerlig under hele seilasen og muligheten til å teste optisk navigering kan ikke gjennomføres',
                EvaluationAlternativeTypes.Boolean
            )
        ];
    }

    function getEcdisUsageStepsCompleted(totalSteps) {
        const ecdisUsage = evaluationCopy.evaluationDocument.ecdisUsage;
        let initialStepsCompleted = 0;

        // Sailing Without Checking Ecdis
        if (!isBlank(ecdisUsage.sailingWithoutCheckingEcdis.assessment)) initialStepsCompleted++;

        // Can Not See Ecdis
        if (isBoolean(ecdisUsage.canNotSeeEcdis.isApproved)) initialStepsCompleted++;

        // Ecdis Is Used As Map
        if (isBoolean(ecdisUsage.ecdisIsUsedAsMap.isApproved)) initialStepsCompleted++;

        // Ecdis Is Obscured
        if (isBoolean(ecdisUsage.ecdisIsObscured.isApproved)) initialStepsCompleted++;

        // Ecdis Is Covered
        if (isBoolean(ecdisUsage.ecdisIsCovered.isApproved)) initialStepsCompleted++;

        // Optical Navigation Test Is Not Possible
        if (isBoolean(ecdisUsage.opticalNavigationTestIsNotPossible.isApproved)) initialStepsCompleted++;

        ecdisUsage.isCompleted = totalSteps === initialStepsCompleted;

        return initialStepsCompleted;
    }

    //#endregion

    //#region COASTAL VOYAGE
    function getCoastalVoyageSteps() {
        const parent = 'coastalVoyage';
        return [
            createAssessmentStep(
                parent,
                'placementInFairway',
                'Kunne plassere seg riktig i leden',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'courseAlterationReferences',
                'Kunne forklare hvilke referanser en bruker ved kurs endring',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'lanternSectorUsage',
                'Kunne vise hvordan lyktesektorer kan benyttes for sikker seilas',
                EvaluationAlternativeTypes.LevelWithNotApplicable,
            ),
            createAssessmentStep(
                parent,
                'navigationMarkUsage',
                'Kunne lokalisere og bruke viktige lykter/fyr og andre sjømerker ved hjelp av karakteristikk og vise i kart',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'cardinalMark',
                'Kunne identifisere kardinal merker og forklare faren den markerer',
                EvaluationAlternativeTypes.LevelWithNotApplicable,
            ),
            createAssessmentStep(
                parent,
                'depthsAndBanks',
                'Kjenne til dybder/grunner som har betydning for sikker seilas i leden',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'radarBeacon',
                'Kunne identifisere RACON fyr som har betydning for sikker seilas i leden',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'trafficConditions',
                'Kunne identifisere områder med mye kryssende trafikk, typisk fergesamband',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'manouveringConditions',
                'Kunne forklare strømforhold av betydning for sikker navigering og manøvrering',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'radarNavigationAndUse',
                'Kunne vise god kunnskap om praktisk radarbruk og kunne lokalisere visuelle merker og ta ut posisjon ved hjelp av radar',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'relevantAnchorages',
                'Kunne vise egnede ankerplasser underveis',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'maneuveringSkills',
                'Manøvrering til/fra kai',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'correctUseOfTugs',
                'Bruk av taubåt',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            )
        ];
    }

    function getCoastalVoyageStepsCompleted(totalSteps) {
        const coastalVoyage = evaluationCopy.evaluationDocument.coastalVoyage;
        let initialStepsCompleted = 0;

        // Placement In Fairway
        if (!isBlank(coastalVoyage.placementInFairway.assessment)) initialStepsCompleted++;

        // Course Alteration References
        if (!isBlank(coastalVoyage.courseAlterationReferences.assessment)) initialStepsCompleted++;

        // Lantern Sector Usage
        if (!isBlank(coastalVoyage.lanternSectorUsage.assessment)) initialStepsCompleted++;

        // Navigation Mark usage
        if (!isBlank(coastalVoyage.navigationMarkUsage.assessment)) initialStepsCompleted++;

        // Cardinal Mark
        if (!isBlank(coastalVoyage.cardinalMark.assessment)) initialStepsCompleted++;

        // Depths And Banks
        if (!isBlank(coastalVoyage.depthsAndBanks.assessment)) initialStepsCompleted++;

        // Radar Beacon
        if (!isBlank(coastalVoyage.radarBeacon.assessment)) initialStepsCompleted++;

        // Traffic Conditions
        if (!isBlank(coastalVoyage.trafficConditions.assessment)) initialStepsCompleted++;

        // Manouvering Conditions
        if (!isBlank(coastalVoyage.manouveringConditions.assessment)) initialStepsCompleted++;

        // Radar Navigation And Use
        if (!isBlank(coastalVoyage.radarNavigationAndUse.assessment)) initialStepsCompleted++;

        // Relevant Anchorages
        if (!isBlank(coastalVoyage.relevantAnchorages.assessment)) initialStepsCompleted++;

        // Maneuvering Skills
        if (!isBlank(coastalVoyage.maneuveringSkills.assessment)) initialStepsCompleted++;

        // Correct Use Of Tugs
        if (!isBlank(coastalVoyage.correctUseOfTugs.assessment)) initialStepsCompleted++;

        coastalVoyage.isCompleted = totalSteps === initialStepsCompleted;

        return initialStepsCompleted;
    }

    //#endregion

    //#region ADJACENT WATERS
    function getAdjacentWatersSteps() {
        return [
            createAssessmentStep(
                'adjacentWaters',
                'adjacentWatersKnowledge',
                'Kandidaten kan teoretisk gjøre rede for nødvendig kunnskap om tilstøtende farvann som fremgår av søknaden og som ikke dekkes av prøvestrekningen',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            )
        ];
    }

    function getAdjacentWatersStepsCompleted(totalSteps) {
        const adjacentWaters = evaluationCopy.evaluationDocument.adjacentWaters;
        let initialStepsCompleted = 0;

        // Adjacement Waters Knowledge
        if (!isBlank(adjacentWaters.adjacentWatersKnowledge.assessment)) initialStepsCompleted++;

        adjacentWaters.isCompleted = totalSteps === initialStepsCompleted;

        return initialStepsCompleted;
    }

    //#endregion

    //#region DEBRIEF
    function getDebriefSteps() {
        const parent = 'debrief';
        return [
            createAssessmentStep(
                parent,
                'candidateOwnAssessment',
                'Kandidaten sin egen vurdering av den gjennomførte prøven',
                EvaluationAlternativeTypes.CompleteType
            ),
            createAssessmentStep(
                parent,
                'pilotsVerbalEvaluation',
                'Losen gir kandidaten en muntlig vurdering av den gjennomførte prøven',
                EvaluationAlternativeTypes.CompleteType
            ),
            createAssessmentStep(
                parent,
                'pilotExplainsCaseWorkProgress',
                'Losen informerer om den videre saksgangen',
                EvaluationAlternativeTypes.CompleteType
            )
        ];
    }

    function getDebriefStepsCompleted(totalSteps) {
        const debrief = evaluationCopy.evaluationDocument.debrief;
        let initialStepsCompleted = 0;

        // Candidate Own Assessment
        if (!isBlank(debrief.candidateOwnAssessment.assessment)) initialStepsCompleted++;

        // Pilots Verbal Evaluation
        if (!isBlank(debrief.pilotsVerbalEvaluation.assessment)) initialStepsCompleted++;

        // Pilot Explains Case Work Progress
        if (!isBlank(debrief.pilotExplainsCaseWorkProgress.assessment)) initialStepsCompleted++;

        debrief.isCompleted = totalSteps === initialStepsCompleted;

        return initialStepsCompleted;
    }

    //#endregion

    //#region SVALBARD
    function getSvalbardSteps() {
        const parent = 'svalbard';
        return [
            createAssessmentStep(
                parent,
                'qualityOfTheMaps',
                'Vise kjennskap til kartgrunnlag i området, herunder tiltak for å imøtekomme dette ved navigasjon i området',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'iceNavigation',
                'Ha kjennskap til og ha kunnskap om å ferdes i isforholdene rundt Svalbard, vite hvordan oppdaterte is varsel kan innhentes og brukes i navigasjonssammenheng',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'natureReservations',
                'Vise kjennskap til naturreservater rundt øygruppa og hva som er tillatt innenfor disse områdene av inngrep',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'relics',
                'Vise kjennskap til "fornminnene" som befinner seg på Svalbard og kjenne til regelverket rundt disse',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'reporting',
                'Kjenne til rapporteringsprosedyrene ved seilaser i Svalbards lospliktige farvann',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'governorOfSvalbard',
                'Sysselmannens kontor er offentlig kontaktpunkt på Svalbard. I hvilke tilfeller må man innhente Sysselmannens tillatelser når man driver maritim virksomhet på Svalbard?',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            ),
            createAssessmentStep(
                parent,
                'readinessServices',
                'Redningsmulighetene på Svalbard er begrenset. Hvilke ressurser er tilgjengelige for redning og ivaretakelse ved oljesøl på Svalbard, hvordan rapporterer man en skipsulykke på Svalbard?',
                EvaluationAlternativeTypes.LevelWithNotApplicable
            )
        ];
    }

    function getSvalbardStepsCompleted(totalSteps) {
        const svalbard = evaluationCopy.evaluationDocument.svalbard;
        let initialStepsCompleted = 0;

        // Quality Of The Maps
        if (!isBlank(svalbard.qualityOfTheMaps.assessment)) initialStepsCompleted++;

        // Ice Navigation
        if (!isBlank(svalbard.iceNavigation.assessment)) initialStepsCompleted++;

        // Nature Reservations
        if (!isBlank(svalbard.natureReservations.assessment)) initialStepsCompleted++;

        // Relics
        if (!isBlank(svalbard.relics.assessment)) initialStepsCompleted++;

        // Reporting
        if (!isBlank(svalbard.reporting.assessment)) initialStepsCompleted++;

        // Governor Of Svalbard
        if (!isBlank(svalbard.governorOfSvalbard.assessment)) initialStepsCompleted++;

        // Readiness Services
        if (!isBlank(svalbard.readinessServices.assessment)) initialStepsCompleted++;

        svalbard.isCompleted = totalSteps === initialStepsCompleted;

        return initialStepsCompleted;
    }

    //#endregion

    //#region DAY NIGHT
    function getDayNightSteps() {
        return [
            createAssessmentStep(
                'dayNight',
                'dayNight',
                'Tidspunkt for prøven',
                EvaluationAlternativeTypes.DayNight
            )
        ];
    }

    function getDayNightStepsCompleted(totalSteps) {
        const dayNight = evaluationCopy.evaluationDocument.dayNight;
        let initialStepsCompleted = 0;

        // Day Night
        if (!isBlank(dayNight.dayNight.assessment)) initialStepsCompleted++;

        dayNight.isCompleted = totalSteps === initialStepsCompleted;

        return initialStepsCompleted;
    }

    //#endregion

    //#region RECOMMENDATION
    function getRecommendationSteps() {
        return [
            createAssessmentStep(
                'recommendation',
                'voyageAssessment',
                'Vurdering av den gjennomførte seilasen',
                EvaluationAlternativeTypes.RecommendationType
            )
        ];
    }

    function getRecommendationStepsCompleted(totalSteps) {
        const recommendation = evaluationCopy.evaluationDocument.recommendation;
        let initialStepsCompleted = 0;

        // Voyage Assessment
        if (!isBlank(recommendation.voyageAssessment.assessment)) initialStepsCompleted++;

        recommendation.isCompleted = totalSteps === initialStepsCompleted;

        return initialStepsCompleted;
    }

    //#endregion

    function createAssessmentStep(parent, child, text, alternativeType) {
        return {
            parent: parent,
            child: child,
            text: text,
            alternativeType: alternativeType,
            selectedAlternative: getSelectedAssessment(parent, child),
            comment: getComment(parent, child),
            onUpdateAlternative: onUpdateAssessment,
            onUpdateComment: onUpdateComment
        }
    }
    function createBoolAssessmentStep(parent, child, text, alternativeType) {
        return {
            parent: parent,
            child: child,
            text: text,
            alternativeType: alternativeType,
            selectedAlternative: getSelectedBoolAlternative(parent, child),
            comment: getComment(parent, child),
            onUpdateAlternative: onUpdateBoolAlternative,
            onUpdateComment: onUpdateComment
        }
    }

    function getSelectedBoolAlternative(parent, child) {
        const childObj = getEvaluationDocumentChildObj(parent, child);
        return childObj.isApproved;
    }

    function getSelectedAssessment(parent, child) {
        const childObj = getEvaluationDocumentChildObj(parent, child);
        return childObj.assessment;
    }

    function getComment(parent, child) {
        const childObj = getEvaluationDocumentChildObj(parent, child);
        return childObj.comment;
    }

    function onUpdateBoolAlternative(parent, child, alternative, isCompleted) {

        updateIsCompleted(parent, isCompleted);

        const childObj = getEvaluationDocumentChildObj(parent, child);
        switch (alternative.systemName) {
            case EvaluationAssessmentEnum.Yes:
                childObj.isApproved = true;
                break;
            case EvaluationAssessmentEnum.No:
                childObj.isApproved = false;
                break;
            default:
                break;
        }
        onUpdate();
    }

    function onUpdateAssessment(parent, child, alternative, isCompleted) {
        updateIsCompleted(parent, isCompleted);

        const childObj = getEvaluationDocumentChildObj(parent, child);
        childObj.assessment = alternative.systemName;

        onUpdate();
    }

    function onUpdateComment(parent, child, comment) {
        const childObj = getEvaluationDocumentChildObj(parent, child);
        childObj.comment = comment;
        onUpdate();
    }

    function updateIsCompleted(parent, isCompleted) {
        const parentObj = getEvaluationDocumentParentObj(parent);
        parentObj.isCompleted = isCompleted;
    }

    function getEvaluationDocumentParentObj(parent) {
        if (Object.hasOwn(evaluationCopy.evaluationDocument, parent) === false) {
            throw new Error(`evaluationDocument does not have parent property ${parent}.`)
        }

        return evaluationCopy.evaluationDocument[parent];
    }

    function getEvaluationDocumentChildObj(parent, child) {
        if (Object.hasOwn(evaluationCopy.evaluationDocument, parent) === false) {
            throw new Error(`evaluationDocument does not have parent property ${parent}.`)
        }
        if (Object.hasOwn(evaluationCopy.evaluationDocument[parent], child) === false) {
            throw new Error(`evaluationDocument parent property ${parent} does not have child property ${child}.`);
        }

        return evaluationCopy.evaluationDocument[parent][child];
    }

    function onResponsibilityCallback(renounceType) {

        switch (renounceType) {
            case EvaluationResponsibilityReasonEnum.YES:
                setCanSave(true);
                setCanSaveNext(true);
                break;
            default:
                setCanSaveNext(false);
                setCanSave(navigator.onLine && renounceType !== evaluationCopy.renounceType);
                break;
        }

        evaluationCopy.renounceType = renounceType;
        updateEvaluationCopy(evaluationCopy);

        if (evaluation.isEvaluationDocumentEmpty) return;

        const hasWarning = getRenounceType() !== EvaluationResponsibilityReasonEnum.NONE && renounceType !== getRenounceType();
        setShowSections(!hasWarning)
    }

    function onInternalSaveClick() {
        if (navigator.onLine === false) {
            publishWarningNotificationTopic("Lagring kan kun gj&oslash;res n&aring;r man er på nett.")
            return;
        }

        if (canSave === false) return;
        onSaveClick(evaluationCopy);
    }

    function onInternalSaveNextClick() {
        if (navigator.onLine === false) {
            publishWarningNotificationTopic("Lagring kan kun gj&oslash;res n&aring;r man er på nett.")
            return;
        }

        if (canSaveNext === false) return;

        if (evaluationCopy.isRenounced === true) {
            if (evaluationCopy.renounceType === EvaluationResponsibilityReasonEnum.YES) {
                handleMultibleNext();
            } else {
                navigate(EvaluationService.getEvaluationSummaryUrl(evaluationCopy));
            }
        } else {
            if (evaluationCopy.hasMultibleExaminers === true) {
                handleMultibleNext();
            } else {
                HandleNext();
            }
        }
    }

    async function handleMultibleNext() {

        if (evaluationCopy.isEvaluationDocumentEmpty) {

            evaluationCopy.isEvaluationDocumentEmpty = false;
            evaluationCopy.isRenounced = false;
            evaluationCopy.renounceType = null;
            evaluationCopy.isCompleted = false;

            updateEvaluationCopy(evaluationCopy);

            setShowSections(true);

            await EvaluationIdbRepository.setAsync(identifier, evaluationCopy);

        } else {
            HandleNext();
        }
    }

    function HandleNext() {

        const isValid =
            evaluationCopy.evaluationDocument.preparation.isCompleted === true &&
            evaluationCopy.evaluationDocument.regulations.isCompleted === true &&
            evaluationCopy.evaluationDocument.ecdisUsage.isCompleted === true &&
            evaluationCopy.evaluationDocument.coastalVoyage.isCompleted === true &&
            evaluationCopy.evaluationDocument.adjacentWaters.isCompleted === true &&
            evaluationCopy.evaluationDocument.debrief.isCompleted === true &&
            evaluationCopy.evaluationDocument.dayNight.isCompleted === true &&
            evaluationCopy.evaluationDocument.recommendation.isCompleted === true;

        if (isValid === true) {
            if (!navigator.onLine) {
                publishWarningNotificationTopic("Du må være på nett for å kunne lagre.");
                return;
            }
            onSaveNextClick(evaluationCopy);
        } else {
            setShowIncomplete(true);
            navigateToFirstIncompleteSection();
        }
    }

    function updateEvaluationCopy(newEvaluationCopy) {
        setEvaluationCopy(evaluationCopy => ({
            ...evaluationCopy,
            ...newEvaluationCopy
        }));
    }

    async function onUpdate() {
        evaluationCopy.isEvaluationDocumentEmpty = false;
        evaluationCopy.isCompleted = false;
        evaluationCopy.completedDate = null;
        evaluationCopy.evaluationDocumentJson = JSON.stringify(evaluationCopy.evaluationDocument);

        updateEvaluationCopy(evaluationCopy);

        await EvaluationIdbRepository.setAsync(identifier, evaluationCopy);

        setCanSave(true);
        setCanSaveNext(true);
    }

    function getRenounceType() {
        if (evaluation.isRenounced) return evaluation.renounceType;

        switch (evaluation.renounceType) {
            case "NO_RESPONSIBILITY":
            case "NO_PARTICIPATION":
                return evaluation.renounceType;
            default:
                return EvaluationResponsibilityReasonEnum.YES;
        }
    }

    function navigateToFirstIncompleteSection() {
        setTimeout(function () {
            let elements = getElementsByClassName("evaluation-form-incomplete");
            if (elements.length === 0) return;
            let firstIncompletePanel = elements[0];
            let divAppContent = document.getElementById("appContent");
            divAppContent.scrollTo(0, (firstIncompletePanel.offsetTop - 50));

        }, 100);
    }
}

export default Index;
