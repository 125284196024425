import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import Spacer from '../../../components/layout/Spacer';
import parse from 'html-react-parser';
import Overlay from '../../../components/layout/overlay/Overlay';
import { useGetHseTypeBySystemNameQuery } from '../../../reducers/slices/api.slice';
import TextAreaFieldWrapper from '../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import { DateTimePickerModes } from '../../../components/layout/dateTimePicker/DateTimePicker';
import DateTimePickerFieldWrapper from '../../../components/layout/fieldWrappers/DateTimePickerFieldWrapper';
import Moment from 'moment';
import { deepCopyObject, isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';
import { publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { DialogForm } from '../../../components/layout/DialogForm';

export const ItemEditDialog = ({
    selectedItem,
    onSave,
    onError,
    onClose
}) => {

    const {
        data: typeData,
        isFetching: typeGetIsFetching,
        isSuccess: typeGetIsSuccess,
        isError: typeGetIsError,
    } = useGetHseTypeBySystemNameQuery(selectedItem.systemName);

    const [validTo, setValidTo] = useState(new Date());
    const [remark, setRemark] = useState("");
    const [warningText, setWarningText] = useState("");

    useEffect(() => {
        if (!typeGetIsSuccess) return;

        setValidTo(typeData.validTo !== null ? new Date(typeData.validTo) : new Date());
        setRemark(isNullOrEmpty(typeData.remark) ? "" : typeData.remark);
        setWarningText(typeData.warnByEmailDaysBeforeExpiry > 0 ?
            parse(`Varsel gis ${typeData.warnByEmailDaysBeforeExpiry} dager f&oslash;r utl&oslash;p`)
            :
            "Ingen varsel gis");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeGetIsSuccess])

    useEffect(() => {
        if (!typeGetIsError) return;

        publishWarningNotificationTopic(`Kunne ikke hente HMS-data for ${selectedItem.displayName}.`);

        onError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeGetIsError])
    
    return (
        <>
            {
                (typeGetIsSuccess && !typeGetIsFetching) &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        title: selectedItem.displayName,
                        onClose: onClose,
                        actions: [
                            {
                                onClick: onClose,
                                icon: "close",
                                text: "Avbryt"
                            },
                            {
                                onClick: onSaveClick,
                                icon: "ok",
                                themeColor: "primary",
                                text: "Ok"
                            }
                        ]
                    }}>
                        <DialogForm>
                            <DateTimePickerFieldWrapper
                                mode={DateTimePickerModes.Year}
                                title="Utl&oslash;psdato"
                                onChange={onValidToChange}
                                value={validTo}
                            />

                            <Spacer height={20} />

                            <div>{warningText}</div>

                            <Spacer height={20} />

                            <TextAreaFieldWrapper
                                title="Merknad"
                                value={remark}
                                onChange={onRemarkChange}
                            />

                        <Spacer height={20} />
                    </DialogForm>
                </Dialog>
            }
            <Overlay isBusy={typeGetIsFetching} />
        </>
        
    );

    function onValidToChange(e) {
        setValidTo(e.value);
    }

    function onRemarkChange(e) {
        setRemark(e.value);
    }

    function onSaveClick() {
        const result = deepCopyObject(typeData);
        result.validTo = Moment(validTo).format("yyyy-MM-DDTHH:mm:ss");
        result.remark = remark;
        
        onSave(result);
    }
}
