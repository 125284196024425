import React, { useEffect, useState } from 'react';
import { PilotCoastalSegmentGroupLocationTypes } from '../../../services/SystemNames';
import { TextArea } from "@progress/kendo-react-inputs";
import Spacer from '../../../components/layout/Spacer';
import "../styles/pcs.css"
import { ChevronDownIcon, ChevronUpIcon } from '../../../components/layout/icons/Icons';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import Slide from '../../../components/layout/Slide';
import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { isArrayEmpty, isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';
import RadioButton from '../../../components/layout/RadioButton';
import { publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import PcsApiRepository from '../../../repositories/api/PcsApiRepository';
import { ContentTitle } from './ContentTitle';
import { NoResult } from './NoResult';

export function Locations({
    groupId,
    groupName,
    onClose,
}) {
    const [canSave, setCanSave] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [locations, setLocations] = useState([]);
    const [hasLocations, setHasLocations] = useState(false);

    useEffect(() => {
        initializeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId])

    return (
        (!isBusy) &&
        <>
            <div className="pcs-evaluation-grid">
                <ContentTitle title={`Lokasjoner: ${groupName}`} />
                <div className={`pcs-locations grid-r-3`}>
                    {
                        (!hasLocations) ?
                            <NoResult text="Valg sertifikatomr&aring;der har ingen lokasjoner."/>
                            :
                            locations.map((location, index) =>
                                <LocationDetailsCard key={index} location={location} setCanSave={setCanSave} />
                            )
                    }
                    
                </div>
                <div className="pcs-evaluation-form-controls grid-r-4">
                    {
                        hasLocations &&
                        <>
                            <div />
                            <div>
                                <Button
                                    onClick={onClose}>
                                    Avbryt
                                </Button>
                            </div>
                            <div>
                                <Button
                                    disabled={!canSave}
                                    onClick={onSave}
                                    themeColor="primary">
                                    Lagre
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    );

    async function initializeAsync() {

        setIsBusy(true);

        const response = await PcsApiRepository.getGroupLocationDetailsAsync(groupId);
        if (response.ok) {
            const data = await response.json();
            setLocations(data);
            setHasLocations(!isArrayEmpty(data));
        } else {
            publishWarningNotificationTopic(`Kunne ikke hente lokasjoner for sertifikaromr&aring;det ${groupName}.`);
        }
        setCanSave(false);
        setIsBusy(false);
    }

    async function onSave() {

        setIsBusy(true);

        const response = await PcsApiRepository.setGroupLocationDetailsAsync(locations);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Lokasjoner for sertifikaromr&aring;det ${groupName} ble lagret.`);
            onClose();
        } else {
            publishWarningNotificationTopic(`Kunne ikke lagre lokasjoner for sertifikaromr&aring;det ${groupName}.`);
        }

        setIsBusy(false);
    }
}

export function LocationDetailsCard({
    location,
    setCanSave
}) {
    const [isActive, setIsActive] = useState(isNullOrEmpty(location.pilotCertificateRemark) === false);
    const [pilotCertificateRemark, setPilotCertificateRemark] = useState(isNullOrEmpty(location.pilotCertificateRemark) === true ? '' : location.pilotCertificateRemark);
    const [type, setType] = useState(location.isPilotCertificateGeneral === null ? null :
        location.isPilotCertificateGeneral ? PilotCoastalSegmentGroupLocationTypes.General :
            PilotCoastalSegmentGroupLocationTypes.Special);

    return (
        <>
            <Card properties={{
                ...CardProperties,
                title: location.displayName
            } }>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            Type omr&aring;de:
                        </div>
                    </div>
                    <Spacer height={10}/>
                    <div className="row">
                        <div className="col">
                            <RadioButton
                                label="Spesiell"
                                size="large"
                                checked={type === PilotCoastalSegmentGroupLocationTypes.Special}
                                onClick={() => onTypeChange(PilotCoastalSegmentGroupLocationTypes.Special)}
                            />
                        </div>
                        <div className="col">
                            <RadioButton
                                label="Generell"
                                size="large"
                                checked={type === PilotCoastalSegmentGroupLocationTypes.General}
                                onClick={() => onTypeChange(PilotCoastalSegmentGroupLocationTypes.General)}
                            />
                        </div>
                    </div>
                    <Spacer height={10}/>
                    <div className="row">
                        <div className="col text-end">
                            <div>
                                {
                                    isActive ?
                                        <Button fillMode="flat" onClick={() => setIsActive(!isActive)} endIcon={<SvgIcon icon={ChevronUpIcon}/>}>Beskrivelse</Button>
                                        :
                                        isNullOrEmpty(type) ?
                                            <Button fillMode="flat" disabled={true}> Beskrivelse</Button>
                                            :
                                            <Button fillMode="flat" onClick={() => setIsActive(!isActive)} endIcon={<SvgIcon icon={ChevronDownIcon} />}>Beskrivelse</Button>
                                }
                            </div>
                        </div>
                    </div>
                    <Spacer height={5} />
                    <Slide show={isActive}>
                        <TextArea value={pilotCertificateRemark} onChange={onDescriptionChange} />
                    </Slide>
                </div>
            
            </Card>
            <Spacer height={20} />
        </>
    );

    function onDescriptionChange(e) {
        setPilotCertificateRemark(e.target.value);
        location.pilotCertificateRemark = e.target.value;
        setCanSave(true);
    }

    function onTypeChange(newType) {
        setType(newType);
        location.isPilotCertificateGeneral = newType === PilotCoastalSegmentGroupLocationTypes.General;
        setCanSave(true);
    }
}
