import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const PcsApiRepository = {

    async getSummaryAsync() {
        return await BaseApiRepository.getAsync('pcs/summary');
    },

    async getGroupAsync(id) {
        return await BaseApiRepository.getAsync(`pcs/group/${id}`);
    },

    async getGroupEvaluationAsync(id, shipSize) {
        return await BaseApiRepository.getAsync(`pcs/group/${id}/evaluation/${shipSize}`);
    },

    async setGroupEvaluationAsync(obj) {
        return await BaseApiRepository.putAsync(`pcs/group/evaluation`, obj);
    },

    async deleteGroupEvaluationAsync(obj) {
        return await BaseApiRepository.deleteAsync(`pcs/group/evaluation`, obj);
    },

    async getGroupEvaluationsAsync(id) {
        return await BaseApiRepository.getAsync(`pcs/group/${id}/evaluations`);
    },

    async getGroupLocationDetailsAsync(id) {
        return await BaseApiRepository.getAsync(`pcs/group/${id}/location-details`);
    },

    async setGroupLocationDetailsAsync(obj) {
        return await BaseApiRepository.putAsync(`pcs/group/location-details`, obj);
    },

    async getShipSizesAsync() {
        return await BaseApiRepository.getAsync('pcs/types/ship-sizes');
    },

    async getGroupTypesAsync() {
        return await BaseApiRepository.getAsync('pcs/group-types');
    },

    async getLocationsAsync() {
        return await BaseApiRepository.getAsync('pcs/locations');
    },

    async setGroupAsync(obj) {
        return await BaseApiRepository.putAsync('pcs/group', obj);
    },

    async deleteGroupAsync(obj) {
        return await BaseApiRepository.deleteAsync('pcs/group', obj);
    },

    async searchAsync(query) {
        return await BaseApiRepository.postAsync('pcs/search', query);
    },

    async validateAsync(obj) {
        return await BaseApiRepository.postAsync('pcs/validate', obj);
    },

    async searchByAreaAsync(obj) {
        return await BaseApiRepository.postAsync('pcs/search/by-area', obj);
    }

};
wrapMethodsWithLogging(PcsApiRepository);

export default PcsApiRepository;
