import { getFormattedDateTime } from "../../../../components/helpers/DateTimeHelpers";
import { deepCopyObject, isArrayEmpty, isNullOrEmpty, isNumeric, isObjectNull } from "../../../../components/helpers/ObjectHelpers";
import Moment from 'moment';
import PilotAssignmentIdbRepository from "../../../../repositories/idb/PilotAssignmentIdbRepository";
import PilotBoardingTypeIdbRepository from "../../../../repositories/idb/PilotBoardingTypeIdbRepository";
import PilotagePilotTypeIdbRepository from "../../../../repositories/idb/PilotagePilotTypeIdbRepository";
import { PilotAssignmentStatusEnums, PilotagePilotVariableCompensationTypeEnums } from "../../../../services/SystemNames";
import { PilotAssignmentCommands } from "../../services/DispatcherActions";
import { isPilotAssignmentConfirmed, isPilotAssignmentCorrectionNeeded } from "../helpers/PilotAssignmentHelpers";
import PilotAssignmentCommandService from "./PilotAssignmentCommandService";

class PilotAssignmentService {

    _assignment = null;
    _commandService = null;

    constructor(assignment) {
        this._assignment = assignment;
        this._commandService = new PilotAssignmentCommandService(assignment);
    }

    get assignment() { return this._assignment; }
    get isDirty() { return this._commandService.isDirty; }
    get locations() { return this._assignment.locations; }
    get locationsSortedBySequenceNo() {
        return this._assignment.locations
            .sort((a, b) => {
                if (a.sequenceNo > b.sequenceNo) return 1;
                return -1;
            });;
    }
    get locationsCount() { return this._assignment.locations.length; }

    get lastLocation() {
        const length = this._assignment.locations.length;
        return this._assignment.locations[length - 1];
    }

    get pilotageStartTime() {
        const sortedLocations =
            this.locationsSortedBySequenceNo
                .filter(l => !isNullOrEmpty(l.fromTime));

        if (isArrayEmpty(sortedLocations)) return null;

        return Moment(sortedLocations[0].fromTime).format('YYYY-MM-DDTHH:mm:00');
    }

    get pilotageEndTime() {
        const sortedLocations =
            this.locationsSortedBySequenceNo
                .filter(l => !isNullOrEmpty(l.toTime));

        if (isArrayEmpty(sortedLocations)) return null;

        return Moment(sortedLocations[sortedLocations.length - 1].toTime).format('YYYY-MM-DDTHH:mm:00');
    }

    get fromAndToTime() {
        let fromTime = null;
        let toTime = null;

        for (const location of this.locations) {
            const locationFromTime = location.fromTime;
            const locationToTime = location.toTime;

            // FromTime
            if (!isNullOrEmpty(locationFromTime)) {
                if (isNullOrEmpty(fromTime) || Moment(locationFromTime) < Moment(fromTime)) {
                    fromTime = locationFromTime;
                }
            }

            // ToTime
            if (!isNullOrEmpty(locationToTime)) {
                if (isNullOrEmpty(toTime) || Moment(locationToTime) > Moment(toTime)) {
                    toTime = locationToTime;
                }
            }
        }

        return { fromTime: fromTime, toTime: toTime }
    }

    get isEditable() {
        const command = this.getCommandByCommandType(PilotAssignmentCommands.CompletePilotageReceipt);
        return ((isPilotAssignmentConfirmed(this._assignment) ||
            isPilotAssignmentCorrectionNeeded(this._assignment)) && isObjectNull(command));
    }

    get isPilotageIncl() {
        return this._assignment.pilotage.pilotageOrderType.systemName === "PILOT_INCL";
    }

    get canConvertToPilotIncl() {
        return this._assignment.pilotage.canConvertToPilotIncl
    }

    get isExaminerOnly() {
        const examinerOnly = this._assignment.isExaminer &&
            !this._assignment.isTrainee &&
            !this._assignment.isConfirmedByPilotDispatcher;

        if (examinerOnly) {
            return !this.hasCommandType(PilotAssignmentCommands.ConvertToPilotageIncl) && !this.hasCommandType(PilotAssignmentCommands.UpdatePilotAssignmentConfirmedByPilot);
        }
        return false;
    }

    get isTrainee() {
        return this._assignment.isTrainee;
    }

    get isExaminer() {
        return this._assignment.isExaminer;
    }

    get couldBeExaminer() {
        return this._assignment.couldBeExaminer;
    }

    get hasInvoices() {
        return this._assignment.pilotage.containsInvoices;
    }

    get hasPecExams() {
        return !isArrayEmpty(this._assignment.pilotage.pecExams);
    }

    get pecExamsCount() {
        return this._assignment.pilotage.pecExams.length;
    }

    get hasExaminers() {
        const examiners = this._assignment.pilotage.pilotagePilotsInformation.filter(p => p.isExaminer === true);
        return !isArrayEmpty(examiners);
    }

    get hasMultiplePilotsConfirmedByPilotDispatcher() {

        const pilots = this._assignment
            .pilotage
            .pilotagePilotsInformation
            .filter(p => p.isConfirmedByPilotDispatcher && p.pilotagePilotStatus.systemName !== PilotAssignmentStatusEnums.Rejected);

        if (isObjectNull(pilots)) return false;

        return pilots.length > 1;
    }

    reload(assignment) {
        this._assignment = assignment;
        this._commandService = new PilotAssignmentCommandService(assignment);
    }

    getLocationByIndex(index) { return this._assignment.locations[index]; }

    getLocationBySequenceNo(sequenceNo) {
        return this._assignment.locations.find(l => l.sequenceNo === sequenceNo);
    }

    getFilteredCompensations(compensations, obj, propertyName) {
        let filteredCompensations = [];
        let filterByPropertyName = false;

        if (Object.hasOwn(obj, propertyName)) {
            filterByPropertyName = obj[propertyName] > 0;
        }

        if (filterByPropertyName) {
            filteredCompensations = compensations.filter(c => c[propertyName] !== obj[propertyName]);
        } else {
            filteredCompensations = compensations.filter(c => c.guid !== obj.guid);
        }

        return filteredCompensations;
    }

    hasCommandEventId(eventId) {
        return this._commandService.hasCommandEventId(eventId);
    }

    hasCommandType(commandType) {
        return this._commandService.hasCommandType(commandType);
    }

    hasLocationCommand(location, type) {
        return this._commandService.hasLocationCommand(location, type);
    }

    getCommandByCommandType(commandType, obj = null) {
        return this._commandService.getCommandByCommandType(commandType, obj);
    }

    async addUpdateLocationPilotTypeCommandAsync(location, pilotType) {
        this._commandService.onUpdateLocationPilotType(location, pilotType.systemName);
        await this.updateAsync();
    }

    async addUpdateLocationBoardingTypeCommandAsync(location, boardingType) {
        this._commandService.onUpdateLocationBoardingType(location, boardingType.systemName, boardingType.isPilotBoardingTypeOffBoarding);
        await this.updateAsync();
    }

    async updateAsync() {
        this._assignment.commands = this._commandService.commands;
        await PilotAssignmentIdbRepository.setAsync(this._assignment);
    }

    async onIsWastedTripAsync(isWasted) {
        this._commandService.onIsWastedTrip(isWasted);
        this._assignment.isWastedTrip = isWasted;
        await this.updateAsync();
    }

    async onPilotArrivalTimeAsync(obj) {
        this._commandService.onPilotArrivalTime(obj);
        this._assignment.arrivalTime = getFormattedDateTime(obj);
        await this.updateAsync();
    }

    async onPilotDepartureTimeAsync(obj) {
        this._commandService.onPilotDepartureTime(obj);
        this._assignment.departureTime = getFormattedDateTime(obj);
        await this.updateAsync();
    }

    async onPilotReturnTimeAsync(obj) {
        this._commandService.onPilotReturnTime(obj);
        this._assignment.returnTime = getFormattedDateTime(obj);
        await this.updateAsync();
    }

    async onWaitingHourReasonAsync(systemName, remark) {
        this._commandService.onWaitingHourReason(systemName, remark);
        await this.updateAsync();
    }

    async onAddLocationAsync(obj, objBefore) {
        const locations = this._assignment.locations;
        const sequenceNo = objBefore.sequenceNo;
        const locationBefore = this.getLocationBySequenceNo(sequenceNo);

        for (const location of locations) {
            if (location.sequenceNo <= sequenceNo) continue;
            location.sequenceNo += 1;
        }

        const boardingType = await PilotBoardingTypeIdbRepository.getDefaultAsync();
        let pilotType = null;

        let newLocation = {
            locationId: obj.locationId,
            name: obj.name,
            sequenceNo: sequenceNo + 1,
            canBeDeleted: true,
            fromTime: null,
            toTime: null,
            isPilotBoardingTypeOffBoarding: true,
            pilotBoardingType: boardingType,
            pilotTypeId: 0,
            pilotType: null
        };

        const isMoreThanOnePilotRequired = this._assignment.pilotage.isMoreThanOnePilotRequired;

        if (isMoreThanOnePilotRequired) {
            pilotType = await PilotagePilotTypeIdbRepository.getBySystemNameAsync("PARALLEL_PILOTING");
        } else {
            pilotType = await PilotagePilotTypeIdbRepository.getBySystemNameAsync("SEQUENTIAL_PILOTING");
        }

        newLocation.pilotTypeId = pilotType.pilotagePilotTypeId;
        newLocation.pilotType = pilotType.systemName;

        const index = locations.indexOf(locationBefore);
        locations.splice(index + 1, 0, newLocation);

        this._commandService.onAddLocation(newLocation, locationBefore, pilotType);

        await this.updateAsync();
    }

    async onDeleteLocationAsync(obj) {
        const locations = deepCopyObject(this._assignment.locations);
        const filteredLocations = locations.filter(l => l.sequenceNo !== obj.sequenceNo);

        for (const filteredLocation of filteredLocations) {
            if (filteredLocation.sequenceNo < obj.sequenceNo) continue;
            filteredLocation.sequenceNo -= 1;
        }

        this._assignment.locations = filteredLocations;

        this._commandService.onDeleteLocation(obj);

        await this.updateAsync();
    }

    async onUpdateLocationDepartureTimeAsync(obj) {

        const location = this.getLocationBySequenceNo(obj.location.sequenceNo);
        location.fromTime = getFormattedDateTime(obj.departureTime);

        const boardingType = await PilotBoardingTypeIdbRepository.getDefaultAsync();
        this._commandService.onUpdateLocationDepartureTime(obj, location, boardingType);

        await this.updateAsync();
    }

    async onUpdateLocationArrivalTimeAsync(obj) {
        const location = this.getLocationBySequenceNo(obj.location.sequenceNo);
        location.toTime = getFormattedDateTime(obj.arrivalTime);

        const boardingType = await PilotBoardingTypeIdbRepository.getDefaultAsync();
        this._commandService.onUpdateLocationArrivalTime(obj, location, this.lastLocation, boardingType);

        await this.updateAsync();
    }

    async onUpdateLocationPilotTypeAsync(obj) {
        const location = this.getLocationBySequenceNo(obj.location.sequenceNo);
        location.pilotType = obj.pilotType.systemName;
        location.pilotTypeId = obj.pilotType.pilotagePilotTypeId;

        this._commandService.onUpdateLocationPilotType(location, obj.pilotType.systemName);

        await this.updateAsync();
    }

    async onUpdateLocationBoardingTypeAsync(obj) {
        const location = this.getLocationBySequenceNo(obj.location.sequenceNo);

        location.pilotBoardingType = obj.boardingType;
        location.isPilotBoardingTypeOffBoarding = obj.boardingType.isPilotBoardingTypeOffBoarding;

        this._commandService.onUpdateLocationBoardingType(location, obj.boardingType.systemName, obj.boardingType.isPilotBoardingTypeOffBoarding);

        await this.updateAsync();
    }

    async onAddTowingTonnageAsync(obj) {

        if ((isNullOrEmpty(obj))) {
            this._assignment.pilotage.towingGrossTonnage = null;
        } else {
            if (!isNumeric(obj)) return;
            this._assignment.pilotage.towingGrossTonnage = Number(obj);
        }

        this._commandService.onAddTowingTonnage(obj);

        await this.updateAsync();
    }

    async onManueverResponsibleTypeAsync(obj) {
        this._assignment.maneuverResponsibleType = obj;

        this._commandService.onManueverResponsibleType(obj);

        await this.updateAsync();
    }

    async onUpdateVariableCompensationOverriddenNumberAsync(obj) {
        const compensations = this._assignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;
        const compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === obj.pilotagePilotVariableCompensationSystemName);
        const index = compensations.indexOf(compensation);

        compensations[index] = obj;

        this._commandService.onUpdateVariableCompensationOverriddenNumber(obj);

        await this.updateAsync();
    }

    async onPutPilotAssignmentOvertimeAsync(obj) {
        const compensations = this._assignment.pilotagePilotCompensation.pilotagePilotOvertimes;
        let pilotagePilotOvertimeId = 0

        if (obj.command === PilotAssignmentCommands.AddPilotAssignmentOvertime) {
            const addCompensation = compensations.find(c => c.guid === obj.guid);
            if (!isObjectNull(addCompensation)) {
                const addIndex = compensations.indexOf(addCompensation);
                compensations[addIndex] = obj;
            } else {
                compensations.push(obj);
            }
        } else {
            const updateCompensation = compensations.find(c => c.pilotagePilotOvertimeId === obj.pilotagePilotOvertimeId);
            const updateIndex = compensations.indexOf(updateCompensation);

            compensations[updateIndex] = obj;

            pilotagePilotOvertimeId = obj.pilotagePilotOvertimeId;
        }

        this._commandService.onPilotAssignmentOvertime(obj, pilotagePilotOvertimeId);

        await this.updateAsync();
    }

    async onCalculateBridgeTimeFromWorktimeAsync(bridgeTimeDay, bridgeTimeNight) {
        const compensations = this._assignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;

        if (isArrayEmpty(compensations)) return;

        let compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === PilotagePilotVariableCompensationTypeEnums.BridgeTimeDay);
        let index = 0;
        if (!isObjectNull(compensation)) {
            index = compensations.indexOf(compensation);
            compensations[index] = bridgeTimeDay;
        }

        compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === PilotagePilotVariableCompensationTypeEnums.BridgeTimeNight);
        if (!isObjectNull(compensation)) {
            index = compensations.indexOf(compensation);
            compensations[index] = bridgeTimeNight;
        }

        this._assignment.pilotagePilotCompensation.pilotagePilotVariableCompensations = compensations;

        await this.updateAsync();
    }

    async onDeletePilotAssignmentOvertimeAsync(obj) {
        const compensations = this._assignment.pilotagePilotCompensation.pilotagePilotOvertimes;
        let objs = compensations
            .filter(c =>
                c.pilotagePilotOvertimeId === obj.id ||
                c.guid === obj.id);

        if (isArrayEmpty(objs)) return;

        this._assignment.pilotagePilotCompensation.pilotagePilotOvertimes =
            this.getFilteredCompensations(compensations, obj, "pilotagePilotOvertimeId");

        this._commandService.onDeletePilotAssignmentOvertime(obj);

        await this.updateAsync();
    }

    async onPutPilotHourCompensationAsync(obj) {
        let compensations = this._assignment.pilotagePilotCompensation.pilotagePilotHourCompensations;
        let pilotagePilotHourCompensationId = 0

        if (isObjectNull(compensations)) {
            compensations = [];
        }

        if (obj.command === PilotAssignmentCommands.AddPilotHourCompensation) {
            const addCompensation = compensations.find(c => c.guid === obj.guid);
            if (!isObjectNull(addCompensation)) {
                const addIndex = compensations.indexOf(addCompensation);
                compensations[addIndex] = obj;
            } else {
                compensations.push(obj);
            }
        } else {
            const updateCompensation = compensations.find(c => c.pilotagePilotHourCompensationId === obj.pilotagePilotHourCompensationId);
            const updateIndex = compensations.indexOf(updateCompensation);
            compensations[updateIndex] = obj;
            pilotagePilotHourCompensationId = obj.pilotagePilotHourCompensationId;

        }

        this._commandService.onPilotHourCompensation(obj, pilotagePilotHourCompensationId);

        await this.updateAsync();
    }

    async onDeletePilotHourCompensationAsync(obj) {
        const compensations = this._assignment.pilotagePilotCompensation.pilotagePilotHourCompensations;
        let objs = compensations
            .filter(c =>
                c.pilotagePilotHourCompensationId === obj.id ||
                c.guid === obj.id);


        if (isArrayEmpty(objs)) return;

        this._assignment.pilotagePilotCompensation.pilotagePilotHourCompensations =
            this.getFilteredCompensations(compensations, obj, "pilotagePilotHourCompensationId");

        this._commandService.onDeletePilotHourCompensation(obj);

        await this.updateAsync();
    }

    async onUpdateReimbursmentStatusAsync(isReimbursementExcluded) {
        this._commandService.onUpdateReimbursmentStatus(isReimbursementExcluded);
        this._assignment.pilotagePilotCompensation.isReimbursementIncluded = !isReimbursementExcluded;
        await this.updateAsync();
    }

    async onRemarkAsync(remark) {
        this._commandService.onPilotRemark(remark);
        this._assignment.remark = remark;
        await this.updateAsync();
    }

    async onConvertToPilotageInclAsync(canConvertToPilotIncl) {
        this._commandService.onConvertToPilotageIncl(canConvertToPilotIncl);
        await this.updateAsync();
    }

    async updatePilotRoleAsync(canConnectToPilot) {
        this._commandService.onUpdateConfirmedByPilot(canConnectToPilot);
        await this.updateAsync();
    }

    async onBothPilotAndExaminerAsync(isBoth) {
        this._commandService.onBothPilotAndExaminer(isBoth);
        await this.updateAsync();
    }

    async onCompleteAsync(isComplete) {
        this._commandService.onCompletePilotageReceipt(isComplete);
        await this.updateAsync();
    }
}

export default PilotAssignmentService;
