import React, { useState, useEffect } from 'react'
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import './appointment.css';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import Spacer from '../../../components/layout/Spacer';
import DateTimePicker, { DateTimePickerModes } from '../../../components/layout/dateTimePicker/DateTimePicker';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import TextAreaFieldWrapper from '../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import { isNullOrEmpty, isObjectNull, removeQuotesFromString } from '../../../components/helpers/ObjectHelpers';
import { publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { formatMoment } from '../../../components/helpers/DateTimeHelpers';
import Moment from 'moment';

const initialState = {
    appointmentTypes: [],
    selectedAppointmentType: null,
    fromTime: formatMoment(Moment()),
    toTime: formatMoment(Moment()),
    remark: "",
    canSave: false
};

function AppointmentForm({
    close,
    reload,
    source,
    isBusy,
    setIsBusy
}) {

    const [
        {
            appointment,
            appointmentTypes,
            selectedAppointmentType,
            fromTime,
            toTime,
            remark,
            canSave
        }
        , setState] = useState(initialState);

    const setStateValue = (name, value) => {
        setState((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const onSaveAsync = async () => {
        if (isBusy) return;
        setIsBusy(true);

        const payload = {
            appointmentType: selectedAppointmentType,
            subject: selectedAppointmentType.name,
            remark: remark,
            fromTime: fromTime,
            toTime: toTime
        }
       
        const response = await PilotApiRepository.savePilotAppointmentAsync(payload);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Fraværet ble lagret.`);
            close()
            reload();
        } else {
            let errorMessage = await response.text();
            publishWarningNotificationTopic(removeQuotesFromString(errorMessage));
        }

        setIsBusy(false);
    }

    const onUpdateAsync = async () => {

        if (isBusy) return;
        setIsBusy(true);
        
        const updatePayload = appointment;
        appointment.appointmentType = selectedAppointmentType;
        appointment.subject = selectedAppointmentType.name;
        appointment.remark = remark;
        appointment.fromTime = fromTime;
        appointment.toTime = toTime;

        const response = await PilotApiRepository.updateAppointmentAsync(updatePayload);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Fraværet ble oppdatert.`);
            close();
            reload();
        } else {
            const errorMessage = await response.text();
            publishWarningNotificationTopic(removeQuotesFromString(errorMessage));
        }

        setIsBusy(false);
    }

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: `${isAdding() ? 'Nytt' : 'Rediger'} fravær`,
                onClose: close,
                actions: [
                    {
                        onClick: close,
                        disabled: isBusy,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        icon: "ok",
                        disabled: !canSave,
                        onClick: onSaveClick,
                        themeColor: "primary",
                        text: "Lagre"
                    }
                ]
            }}>
            
            <FieldWrapper>
                <Label className="k-form-label">
                    Type: 
                </Label>
                <div className="k-form-field-wrap">
                    <DropDownList
                        data={appointmentTypes}
                        disabled={!isNullOrEmpty(source)}
                        dataItemKey="appointmentTypeID"
                        textField="name"
                        value={selectedAppointmentType}
                        onChange={(e) => setStateValue("selectedAppointmentType", e.value)}
                    />
                        
                </div>
            </FieldWrapper>

            <Spacer height={20} />

            <FieldWrapper>
                <Label className="k-form-label">
                    Fra:
                </Label>
                <div className="k-form-field-wrap">
                    <DateTimePicker
                        title="Velg fra dato"
                        mode={DateTimePickerModes.DateTime}
                        value={fromTime}
                        onChange={(e) => {
                            setStateValue("fromTime", e.value);
                            validate(e.value, toTime, remark);
                        }}
                    />
                </div>
            </FieldWrapper>

            <Spacer height={20} />

            <FieldWrapper>
                <Label className="k-form-label">
                    Til:
                </Label>
                <div className="k-form-field-wrap">
                    <DateTimePicker
                        title="Velg til dato"
                        mode={DateTimePickerModes.DateTime}
                        value={toTime}
                        onChange={(e) => {
                            setStateValue("toTime", e.value);
                            validate(fromTime, e.value, remark);
                        }}
                    />
                </div>
            </FieldWrapper>

            <Spacer height={20} />

            <TextAreaFieldWrapper
                title="Kommentar"
                rows={3}
                value={remark}
                onChange={(e) => setStateValue("remark", e.value)}
            />

            <Spacer height={20} />
        </Dialog>
    )

    async function initializeAsync() {
        await getAppointmentTypesAsync();
        await getAppointmentByIdAsync();
        
        setIsBusy(false);
    }

    async function getAppointmentTypesAsync() {
        const response = await PilotApiRepository.getAppointmentTypesAsync()
        if (response.ok === true) {
            const data = await response.json();

            setState((prev) => ({
                ...prev,
                appointmentTypes: data,
                selectedAppointmentType: data[0]
            }));
        } else {
            publishWarningNotificationTopic("Feil ved innhenting av frav&aelig;rstyper");
        }
    }

    async function getAppointmentByIdAsync() {
        if (isObjectNull(source)) return;

        const response = await PilotApiRepository.getAppointmentByIdAsync(source.appointmentID);
        if (response.ok === true) {
            const data = await response.json();
            
            setState((prev) => ({
                ...prev,
                fromTime: data.fromTime,
                toTime: data.toTime,
                remark: data.remark,
                appointment: data,
                selectedAppointmentType: data.appointmentType,
                canSave: true
            }));
        } else {
            publishWarningNotificationTopic("Feil ved innhenting av frav&aelig;r");
        }
    }

    function validate(from, to) {
        if (isNullOrEmpty(from) || isNullOrEmpty(to)) {
            setStateValue("canSave", false);
        } else {
            const dateFrom = Moment(from);
            const dateTo = Moment(to);

            setStateValue("canSave", (dateFrom < dateTo));
        }
    }

    function onSaveClick() {
        if (isAdding()) {
            onSaveAsync();
        } else {
            onUpdateAsync();
        }
    }

    function isAdding() {
        return isObjectNull(source);
    }
}

export default AppointmentForm;
