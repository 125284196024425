import parse from 'html-react-parser';

export const ContentTitle = ({ title = "" }) => {

    return (
        <div className="pcs-content-title k-text-ellipsis">
            <div className="k-m-2 ">
                {parse(title)}
            </div>
        </div>
    )
}
