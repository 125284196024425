/* eslint-disable react-hooks/exhaustive-deps */
import './bottomnavigation.css'

import React, { useEffect, useState } from 'react';
import { BottomNavigation as KendoBottomNavigation } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { PageStates } from '../../services/SystemNames';
import {
    AlarmClockIcon,
    AnchorIcon,
    BulletListIcon,
    CalendarIcon,
    ClockIcon,
    DocumentIcon,
    DotsIcon,
    EvaluationIcon,
    EyeIcon,
    HourGlassIcon,
    HseIcon,
    InformationIcon,
    LayersIcon,
    MapIcon,
    MapSegmentsIcon,
    MobileIcon,
    MyPageIcon,
    NextIcon,
    OkIcon,
    PdfIcon,
    PinIcon,
    PulseIcon,
    RotationIcon,
    SettingsIcon,
    ShipIcon,
    StarIcon,
    StarOutlineIcon,
    TraineeIcon
} from './icons/Icons';
import {
    Button
} from "@progress/kendo-react-buttons";
import { IconsContext } from '@progress/kendo-react-common';
import { SvgIcon } from "@progress/kendo-react-common";
import { isArrayEmpty } from '../helpers/ObjectHelpers';
import { addClassToElementById, removeClassFromElementById } from '../helpers/ElementHelpers';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { Asterisk } from '@phosphor-icons/react/dist/ssr';

const BottomMenuIcons = {
    "alarmClock": AlarmClockIcon,
    "anchor": AnchorIcon,
    "bulletList": BulletListIcon,
    "document": DocumentIcon,
    "dots": DotsIcon,
    "calendar": CalendarIcon,
    "clock": ClockIcon,
    "evaluation": EvaluationIcon,
    "eye": EyeIcon,
    "hourglass": HourGlassIcon,
    "hse": HseIcon,
    "layers": LayersIcon,
    "information": InformationIcon,
    "map": MapIcon,
    "mapSegments": MapSegmentsIcon,
    "mobile": MobileIcon,
    "myPage": MyPageIcon,
    "next": NextIcon,
    "ok": OkIcon,
    "pdf": PdfIcon,
    "pin": PinIcon,
    "pulse": PulseIcon,
    "rotation": RotationIcon,
    "settings": SettingsIcon,
    "ship": ShipIcon,
    "star": StarIcon,
    "starOutline": StarOutlineIcon,
    "trainee": TraineeIcon,
};

export default function BottomNavigation({
    selectedAction = "",
    items = [],
    onCallback }) {

    const parentMinimumWidth = 90;

    const anchor = React.useRef(null);
    const [showMore, setShowMore] = useState(false);
    const [parentItems, setParentItems] = useState([]);
    const [childItems, setChildItems] = useState([]);

    useEffect(() => {

        if (isArrayEmpty(items)) {
            removeClassFromElementById("outer-container", "outer-container-with-bottom-menu");
        } else {
            addClassToElementById("outer-container", "outer-container-with-bottom-menu");
        }

        initialize();

        return (() => {
            removeClassFromElementById("outer-container", "outer-container-with-bottom-menu");
        });
    }, [items]);

    useEffect(() => {
        document.body.addEventListener("click", handleBodyClick);
        return () => {
            document.body.removeEventListener("click", handleBodyClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleBodyClick]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleResize]);

    return (
        <>
            <IconsContext.Provider
                value={{
                    type: 'svg',
                    icons: BottomMenuIcons,
                }}>
                <KendoBottomNavigation
                    className="bottom-navigation"
                    ref={anchor}
                    items={parentItems.map((item) => ({
                        ...item,
                        selected: (item.action === selectedAction) || (item.action === PageStates.More && childItems.some(i => i.action === selectedAction)),
                    }))}
                    item={BottomNavItem}
                    onSelect={onSelect}
                />
            </IconsContext.Provider>
            <Popup
                anchor={anchor.current && anchor.current.element}
                anchorAlign={{ horizontal: "right", vertical: "top" }}
                popupAlign={{ horizontal: "right", vertical: "bottom" }}
                show={showMore}
                style={{background: "transparent"} }>
                <div className="bottom-navigation-more-items">
                    {
                        childItems.toReversed().map((item, index) =>
                            <div
                                key={index}>
                                    <Button
                                        fillMode="flat"
                                        className={`bottom-navigation-button ${item.action === selectedAction ? 'bottom-navigation-more-item-selected' : ''}`}
                                        onClick={() => onMoreSelect(item)}>
                                        <div className="bottom-navigation-more-item">
                                            <div>
                                                <SvgIcon icon={BottomMenuIcons[item.icon]} size="xxlarge" />
                                            </div>
                                            <div>
                                                {
                                                    (Object.hasOwn(item, "hasChanges") && item.hasChanges) ?
                                                        <BadgeContainer>
                                                            {item.text}
                                                            <Badge size={'small'} themeColor="base"><Asterisk /></Badge>
                                                        </BadgeContainer>
                                                        :
                                                        item.text
                                                }
                                            </div>
                                        </div>
                                    </Button>
                            </div>
                        )
                    }
                </div>
            </Popup>

        </>
    )

    function initialize() {

        const parents = [];
        const children = [];

        const maxParents = Math.floor(window.innerWidth / parentMinimumWidth);

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (i < maxParents - 1) {
                parents.push(item);
            } else {
                children.push(item);
            }
        }

        if (showMore) {
            setShowMore(false);
        }

        if (!isArrayEmpty(children)) {
            if (children.length === 1) {
                parents.push(children[0]);
                children.pop();
            } else {
                parents.push({
                    text: "Mer",
                    icon: "dots",
                    action: PageStates.More
                });
            }
        }

        setParentItems(parents);
        setChildItems(children);
    }

    function handleBodyClick() {
        setShowMore(false);
    }

    function handleResize() {
        initialize();

    }

    function onSelect(e) {

        switch (e.itemTarget.action) {
            case PageStates.More:
                setShowMore(!showMore);
                break;
            default:
                setShowMore(false);
                onCallback(e.itemTarget);
                break;
        }

        e.nativeEvent.stopPropagation();
    }

    function onMoreSelect(item) {
        setShowMore(false);
        if (item.action === selectedAction) return;
        onCallback(item);
    }
}

const BottomNavItem = (props) => {
    return (
        <div className="k-d-grid k-justify-items-center">
            <div>
                <SvgIcon icon={BottomMenuIcons[props.item.icon]} size="xlarge" />
            </div>
            <div>
                {
                    (Object.hasOwn(props.item, "hasChanges") && props.item.hasChanges) ?
                        <BadgeContainer>
                            {props.item.text}
                            <Badge size={'small'} themeColor="base"><Asterisk /></Badge>
                        </BadgeContainer>
                        :
                        props.item.text
                }

            </div>
        </div>
    )
}
