import ContentGrid from '../../../components/layout/ContentGrid';
import SvgIcon from '../../../components/layout/icons/Icons';
import { PilotageExemptionApplicationStatusColors, PilotageExemptionApplicationStatusEnum } from '../../../services/SystemNames';
import Moment from 'moment';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import { MiniCard } from '../../../components/layout/card/MiniCard';
import { getPilotAssignmentStatusColor } from '../../pilot/pilot-assignment/helpers/PilotAssignmentHelpers';

export const PageStateCurrent = ({ pilotages, onClick, onRefresh }) => {

    return (
        <ContentGrid
            title="Alle oppdrag"
            titleIcon="ship"
            canPullToRefresh={true}
            onPullToRefresh={onRefresh}
        >
            <div className="current-pilotages">
                {
                    pilotages.map((pilotage, index) =>
                        <Pilotage key={index} pilotage={pilotage} onClick={onClick} />
                    )
                }
            </div>
        </ContentGrid>
    )
}

const Pilots = ({ pilotagePilots }) => {
    return (
        pilotagePilots.map((pilotagePilot, index) =>
            <MiniCard
                key={index}
                item={{
                    borderColor: getPilotAssignmentStatusColor(pilotagePilot),
                    text: `${pilotagePilot.initials}`,
                    isExaminer: pilotagePilot.isExaminer,
                    isTrainee: pilotagePilot.isTrainee,
                    isApprentice: pilotagePilot.isApprentice
                }}
            />
        )
    )
};

export const Pilotage = ({ pilotage, onClick }) => {

    return (
        <Card
            properties={{
                ...CardProperties,
                onClick: handleOnClick,
                sourceId: pilotage.pilotageId,
                title: pilotage.pilotageShip.shipName,
                subTitle: Moment(pilotage.pilotageDetail.fromTime).format('DD.MM HH:mm'),
                borderColor: "var(--card-border)",
                className: "pp-card",
                isBorderLeftFat: true,
                canToggle: false
            }}
        >
            <div className="current-pilotage">
                <div>
                    <div>
                        <div>{pilotage.fromLocation.name}</div>
                        <div>{pilotage.pilotageNo}</div>
                    </div>
                    <div> <SvgIcon iconName="arrowTo" dimensions={20} /> </div>
                    <div>{pilotage.toLocation.name}</div>
                </div>
                <div>
                    <Pilots pilotagePilots={pilotage.pilotagePilotsInformation} />
                </div>
                <div>
                    {pilotage.hasHazmat && <SvgIcon iconName="hazmat" dimensions={32} />}
                    {pilotage.hasObservation && <SvgIcon iconName="eye" dimensions={32} />}
                    {pilotage.isTowing && <SvgIcon iconName="towing" dimensions={32} />}
                    {pilotage.isHelicopterRequired && <SvgIcon iconName="helicopter" dimensions={32} />}
                    {pilotage.pilotageDetail.isLocked && <SvgIcon iconName="lock" dimensions={32} />}
                    <ExemptionStatusIcon status={pilotage.pilotageExemptionApplicationStatus} />
                </div>
            </div>
        </Card>
    )

    function handleOnClick() {
        onClick(pilotage);
    }
}

const ExemptionStatusIcon = ({ status }) => {
    switch (status?.systemName) {
        case PilotageExemptionApplicationStatusEnum.APPLIED: {
            return <SvgIcon iconName="exemptionNotSent" dimensions={30} color={PilotageExemptionApplicationStatusColors.APPLIED} />
        }
        case PilotageExemptionApplicationStatusEnum.CONSIDERED_EVIDENTLY_JUSTIFIABLE: {
            return <SvgIcon iconName="exemptionPending" dimensions={30} color={PilotageExemptionApplicationStatusColors.CONSIDERED_EVIDENTLY_JUSTIFIABLE} />
        }
        case PilotageExemptionApplicationStatusEnum.CONSIDERED_NOT_EVIDENTLY_JUSTIFIABLE: {
            return <SvgIcon iconName="exemptionPending" dimensions={30} color={PilotageExemptionApplicationStatusColors.CONSIDERED_NOT_EVIDENTLY_JUSTIFIABLE} />
        }
        case PilotageExemptionApplicationStatusEnum.DISMISSED: {
            return <SvgIcon iconName="exemptionRejected" dimensions={30} color={PilotageExemptionApplicationStatusColors.DISMISSED} />
        }
        case PilotageExemptionApplicationStatusEnum.GRANTED: {
            return <SvgIcon iconName="exemptionGranted" dimensions={30} color={PilotageExemptionApplicationStatusColors.GRANTED} />
        }
        case PilotageExemptionApplicationStatusEnum.REJECTED: {
            return <SvgIcon iconName="exemptionRejected" dimensions={30} color={PilotageExemptionApplicationStatusColors.REJECTED} />
        }
        case PilotageExemptionApplicationStatusEnum.SENT_TO_PILOT_MASTER: {
            return <SvgIcon iconName="exemptionPending" dimensions={30} color={PilotageExemptionApplicationStatusColors.SENT_TO_PILOT_MASTER} />
        }
        case PilotageExemptionApplicationStatusEnum.VOYAGE_ENDED: {
            return <SvgIcon iconName="exemptionRejected" dimensions={30} color={PilotageExemptionApplicationStatusColors.VOYAGE_ENDED} />
        }
        default: return "";
    }
}

