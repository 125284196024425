import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { TextArea } from "@progress/kendo-react-inputs";
import { Card } from '../../../../components/layout/card/Card';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import { isNullOrEmpty } from '../../../../components/helpers/ObjectHelpers';
import { PilotAssignmentCommands } from '../../services/DispatcherActions';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const RemarkCard = forwardRef((
    {
        onPilotAssignmentChanged,
        assignmentService
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        }
    }));

    const [isEditable, setIsEditable] = useState(false);
    const [remark, setRemark] = useState("");
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)
        initializeAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, [])

    return (
        <Card
            properties={{
                ...CardProperties,
                title: "Losens kommentar",
                clipboardText: remark,
                isDirty: isDirty
            }}>
            <TextArea
                disabled={!isEditable}
                value={remark}
                rows={4}
                onChange={onRemarkChangeAsync} />
        </Card>
    )

    async function initializeAsync() {
        const pilotAssignment = assignmentService.assignment;
        setIsEditable(assignmentService.isEditable);

        if (!isNullOrEmpty(pilotAssignment.remark)) {
            setRemark(pilotAssignment.remark)
        }

        setIsDirty(assignmentService.hasCommandType(PilotAssignmentCommands.PilotRemark));
    }

    function handlePubSubTopic() {
        initializeAsync();
    }

    async function onRemarkChangeAsync(e) {

        if (!isEditable) return;

        setIsDirty(true);
        setRemark(e.value);

        await assignmentService.onRemarkAsync(e.value);

        onPilotAssignmentChanged();
    }
})
