import { addClassToElementByClassName, getElementById, removeClassFromElementsByClassName } from "../../helpers/ElementHelpers";
import { isArrayEmpty, isNullOrEmpty, isNumeric, isObjectNull } from "../../helpers/ObjectHelpers";
import { getNumericUrlParam } from "../../helpers/UrlHelpers";
import parse from 'html-react-parser';

const markersVisibleAtZoom = 10,
    minimumSpeed = 0.5;

const mapControlActiveClassName = "google-map-control-active";

export const pcsGroupFeatureType = "pcsGroup",
    defaultFillColor = "#bf9cbc",
    defaultStrokeColor = "#333333",
    mouseOverStrokeColor = "#007d97",
    selectedStrokeColor = "#d60f0f",
    selectedFillColor = "#CE5456",
    defaultOpacity = 0.35,
    pcsFeatureType = "pcsFeature",
    colorInactive = "#FF8484",
    colorAvailable = "yellow",
    colorSelected = "#1CA8DD",
    colorBorder = "#0178D4",
    colorFairwayArea = "#d3d3d3",
    colorFairwayAreaApplied = "#ffc40d",
    colorFairwayAreaStroke = "#424242";

export const ShipTypes = {
    PilotVessel: "PILOT_VESSEL"
}

export const ShipTypeGroups = {
    Cargo: "CARGO",
    Tanker: "TANKER",
    Passenger: "PASSENGER",
    Fish: "FISH",
    Tug: "TUG",
    Auxilliary: "AUXILLIARY"
}

export const MarkerTypes = {
    Ship: "ship",
    Location: "Location"
}

export const FeaturePropertyTypes = {
    Group: "GROUP"
}

export const LocationTypes = {
    Harbour: "HARBOUR",
    Anchorage: "ANCHORAGE",
    LocationAtSea: "LOCATION_AT_SEA",
    PilotBoarding: "PILOT_BOARDING"
}

export const MapClickTypes = {
    Marker: "marker",
    Circle: "circle"
}

let mapTimer = null;

function showLoader() {
    mapTimer = setTimeout(() => {
        addClassToElementByClassName("google-map-loader", "google-map-loader-active");
    }, 2000);
}

function hideLoader() {
    clearTimeout(mapTimer);
    removeClassFromElementsByClassName("google-map-loader", "google-map-loader-active");
}

function isMultiSelectActive(mapId) {
    const button = getElementById(`googleMapControlMultiButton-${mapId}`);
    if (isNullOrEmpty(button)) return false;
    const attribute = button.getAttribute("data-active");
    if (isNullOrEmpty(attribute)) return false;
    return attribute.toLowerCase() === "true";
}

function containsFeature(array, bounds) {

    let contains = false;
    for (const obj of array) {
        if (!Object.hasOwn(obj, "Eg")) continue;
        const latLngs = obj.Eg;
        for (const latLng of latLngs) {
            contains = bounds.contains(latLng);
            if (contains === true) break;
        }
    }

    return contains;
}

function getBoundsRectangle(map) {
    const ne = map.getBounds().getNorthEast(); // Coords of the northeast corner
    const sw = map.getBounds().getSouthWest(); // Coords of the southwest corner
    const nw = new window.google.maps.LatLng(ne.lat(), sw.lng()); // Coords of the NW corner
    const se = new window.google.maps.LatLng(sw.lat(), ne.lng()); // Coords of the SE corner

    return {
        northEast: { lat: ne.lat(), lng: ne.lng() },
        northWest: { lat: nw.lat(), lng: nw.lng() },
        southWest: { lat: sw.lat(), lng: sw.lng() },
        southEast: { lat: se.lat(), lng: se.lng() }
    };
}

function createPolygon(paths) {
    return new window.google.maps.Polygon({
        paths: paths,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "Purple",
        fillOpacity: 0.35
    })
}

function createPolyline(path, weight) {
    return new window.google.maps.Polyline({
        path: path,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: weight
    });
}

function createShipPositionInformationObject(shipPosition, topmost = false) {
    const position = new window.google.maps.LatLng(shipPosition.latitude, shipPosition.longitude);
    return {
        currentPilotageId: shipPosition.currentPilotageId,
        nextPilotageId: shipPosition.nextPilotageId,
        description: shipPosition.shipName,
        position: position,
        courseOverGround: shipPosition.courseOverGround,
        speedOverGround: shipPosition.speedOverGround,
        callSign: shipPosition.callSign,
        type: "ship",
        mmsi: shipPosition.mmsi,
        imoNo: shipPosition.imoNo,
        topmost: topmost,
        shipId: shipPosition.shipID,
        shipTypeGroupIdentifier: shipPosition.shipTypeGroupIdentifier,
        shipTypeIdentifier: shipPosition.shipTypeIdentifier
    };
}

function updateZoomByUrlParameter(map) {
    const zoom = getNumericUrlParam("zoom");
    if (isNumeric(zoom) && zoom > 0) {
        map.setZoom(zoom);
    }
}

function getChangesWithinBounds(map, features) {

    const bounds = map.getBounds();
    const segmentIds = [];
    const groupIds = []

    features.forEach(function (feature) {

        if (isObjectNull(bounds)) return;
        const geometry = feature.getGeometry();

        if (isObjectNull(geometry)) return;

        const array = geometry.getArray();
        if (isArrayEmpty(array)) return;

        if (containsFeature(array, bounds) === true) {
            const segmentId = feature.getProperty("segmentId");
            const groupId = feature.getProperty("segmentGroupId");

            if (isNumeric(segmentId) && segmentId > 0) {
                if (!segmentIds.includes(segmentId)) {
                    segmentIds.push(segmentId);
                }
            }

            if (isNumeric(groupId) && groupId > 0) {
                if (!groupIds.includes(groupId)) {
                    groupIds.push(groupId);
                }
            }
        }
    });

    return {
        center: map.getCenter(),
        zoom: map.getZoom(),
        bounds: bounds,
        segmentIds: segmentIds,
        groupIds: groupIds
    };
}

function getFeaturesCount(map) {
    let count = 0;
    map.data.forEach(function () {
        count += 1;
    });

    return count;
}

function setTilesLoadedTimer(properties, map) {
    let counter = 0;
    const timer = setInterval(() => {
        const featuresCount = getFeaturesCount(map);
        if (featuresCount > 0 || counter === 8) {
            clearTimeout(timer);
            const changes = getChangesWithinBounds(map, map.data);
            properties.handleOnTilesLoaded(changes);
        }
        counter += 1;
    }, 250);
}

function setMapTitle(mapId, title) {
    const elemId = `googleMapTitle-${mapId}`;
    const elem = getElementById(elemId);
    if (isObjectNull(elem)) return;
    const child = elem.querySelector("div");
    child.innerHTML = title;

    if (!isNullOrEmpty(title)) {
        child.classList.add("google-map-title-content-active");
    } else {
        child.classList.remove("google-map-title-content-active");
    }
}

function unselectFeatures(map, type) {
    map.data.forEach(function (feature) {
        const featureType = feature.getProperty("featureType");
        if (featureType === type) {
            const isSelected = feature.getProperty("isSelected");

            if (!isSelected) return;
            map.data.overrideStyle(feature,
                {
                    fillColor: defaultFillColor,
                    fillOpacity: defaultOpacity,
                    strokeWeight: 2,
                    strokeColor: defaultStrokeColor
                }
            );
            feature.setProperty("isSelected", false);
        }
    });
}

function showMapControl(elemId) {
    const elem = getElementById(elemId);
    if (isObjectNull(elem)) return;
    if (elem.classList.contains(mapControlActiveClassName)) return;
    elem.classList.add(mapControlActiveClassName);
}

function hideMapControl(elemId) {
    const elem = getElementById(elemId);
    if (isObjectNull(elem)) return;
    if (!elem.classList.contains(mapControlActiveClassName)) return;
    elem.classList.remove(mapControlActiveClassName);
}

function getShipTypeDescription(obj) {
    if (isObjectNull(obj)) return "";
    if (obj.shipTypeIdentifier === ShipTypes.PilotVessel) return "Tilbringer";
    return formatShipTypeIdentifierName(obj.shipTypeIdentifier);
}

function formatShipTypeIdentifierName(name) {
    if (name === "UNKNOWN") return "Annet/Ukjent";
    // Replaces underscore with space
    var nameWithoutUnderScore = name.replace(/_/g, ' ').toLowerCase();

    // Uppercases first letter of each word in name
    return nameWithoutUnderScore.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}

function getShipTypeDescriptionBySystemName(systemName) {
    switch (systemName) {
        case ShipTypeGroups.Cargo:
            return parse("Fraktb&aring;t");
        case ShipTypeGroups.Tanker:
            return parse("Tankb&aring;t");
        case ShipTypeGroups.Passenger:
            return parse("Passasjerb&aring;t");
        case ShipTypeGroups.Fish:
            return parse("Fiskeb&aring;t");
        case ShipTypeGroups.Tug:
            return parse("Taub&aring;t");
        default:
            return "Annet/Ukjent";
    }
}


export {
    minimumSpeed,
    markersVisibleAtZoom,
    showLoader,
    hideLoader,
    createPolyline,
    createPolygon,
    createShipPositionInformationObject,
    getBoundsRectangle,
    getChangesWithinBounds,
    isMultiSelectActive,
    updateZoomByUrlParameter,
    getFeaturesCount,
    setTilesLoadedTimer,
    setMapTitle,
    unselectFeatures,
    showMapControl,
    hideMapControl,
    getShipTypeDescription,
    getShipTypeDescriptionBySystemName,
    formatShipTypeIdentifierName
}
