import { deepCopyObject, isArrayEmpty, isArraysSameSize, isObjectNull, isObjectsEqual } from "../../../../components/helpers/ObjectHelpers";
import { PilotagePilotVariableCompensationTypeEnums } from "../../../../services/SystemNames";
import { PilotAssignmentCommands } from "../../services/DispatcherActions";
import PilotAssignmentService from "../services/PilotAssignmentService";
import { isPilotAssignmentAssignedAndNotProcessed, isPilotAssignmentCompleted, isPilotAssignmentTentative } from "./PilotAssignmentHelpers";

function processPilotAssignment(local, source) {

    let copy = deepCopyObject(source);

    if (!isPilotAssignmentCompleted(source) &&
        !isPilotAssignmentTentative(source) &&
        !isPilotAssignmentAssignedAndNotProcessed(source)) {

        if (isObjectNull(local.original.locations)) {
            copy.original.locations = deepCopyObject(source.locations);
        } else {
            copy.original.locations = deepCopyObject(local.original.locations);
        }

        if (isObjectNull(local.original.hourCompensations)) {
            copy.original.hourCompensations = deepCopyObject(source.pilotagePilotCompensation.pilotagePilotHourCompensations);
        } else {
            copy.original.hourCompensations = deepCopyObject(local.original.hourCompensations);
        }

        if (isObjectNull(local.original.variableCompensations)) {
            copy.original.variableCompensations = deepCopyObject(copy.pilotagePilotCompensation.pilotagePilotVariableCompensations);
        } else {
            copy.original.variableCompensations = deepCopyObject(local.original.variableCompensations);
        }

        if (isObjectNull(local.original.overtimes)) {
            copy.original.overtimes = deepCopyObject(source.pilotagePilotCompensation.pilotagePilotOvertimes);
        } else {
            copy.original.overtimes = deepCopyObject(local.original.overtimes);
        }

        copy.original.arrivalTime = local.original.arrivalTime;
        copy.original.departureTime = local.original.departureTime;
        copy.original.returnTime = local.original.returnTime;
        copy.original.isWastedTrip = local.original.isWastedTrip;
        copy.original.waitingHoursReasonRemark = local.original.waitingHoursReasonRemark;
        copy.original.remark = local.original.remark;
        copy.original.isReimbursementIncluded = local.original.isReimbursementIncluded;

        copy.commands = deepCopyObject(local.commands);
        copy.locations = deepCopyObject(local.locations);
        copy.pilotagePilotCompensation = deepCopyObject(local.pilotagePilotCompensation);
        copy.isWastedTrip = local.isWastedTrip;
        copy.arrivalTime = local.arrivalTime;
        copy.departureTime = local.departureTime;
        copy.returnTime = local.returnTime;
        copy.maneuverResponsibleType = deepCopyObject(local.maneuverResponsibleType);
        copy.waitingHoursReasonRemark = local.waitingHoursReasonRemark;
        copy.waitingHoursReasonType = deepCopyObject(local.waitingHoursReasonType);
        copy.remark = local.remark;
        copy.wasServerUpdated = local.wasServerUpdated;

        copy = processLocations(copy, source);
        copy = processHourCompensations(copy, source);
        copy = processOvertime(copy, source);
        copy = processReimbursmentStatus(copy, source);
        copy = processVariableCompensation(copy, source, PilotagePilotVariableCompensationTypeEnums.BoardingVessel);
        copy = processVariableCompensation(copy, source, PilotagePilotVariableCompensationTypeEnums.BoardingHelicopter);
        copy = processVariableCompensation(copy, source, PilotagePilotVariableCompensationTypeEnums.BridgeTimeDay);
        copy = processVariableCompensation(copy, source, PilotagePilotVariableCompensationTypeEnums.BridgeTimeNight);

        copy = processChildObject(copy, source, "maneuverResponsibleType", "systemName", PilotAssignmentCommands.ManueverResponsibleType);
        copy = processChildObject(copy, source, "waitingHoursReasonType", "systemName", PilotAssignmentCommands.WaitingHourReason);

        copy = processObject(copy, source, "isWastedTrip", PilotAssignmentCommands.IsWastedTrip);
        copy = processObject(copy, source, "waitingHoursReasonRemark", PilotAssignmentCommands.WaitingHourReasonRemark);
        copy = processObject(copy, source, "remark", PilotAssignmentCommands.PilotRemark);
        copy = processObject(copy, source, "arrivalTime", PilotAssignmentCommands.PilotArrivalTime);
        copy = processObject(copy, source, "departureTime", PilotAssignmentCommands.PilotDepartureTime);
        copy = processObject(copy, source, "returnTime", PilotAssignmentCommands.PilotReturnTime);
    }

    return copy;
}

function processLocations(copy, source) {

    let swap = true;

    const copyMaxIndex = copy.locations.length - 1;
    const sourceMaxIndex = source.locations.length - 1;

    if (copyMaxIndex > 0 && sourceMaxIndex > 0) {

        copy.locations[0].locationId = source.locations[0].locationId;
        copy.locations[0].name = source.locations[0].name;
        copy.locations[0].locationType = source.locations[0].locationType;

        copy.locations[copyMaxIndex].locationId = source.locations[sourceMaxIndex].locationId;
        copy.locations[copyMaxIndex].name = source.locations[sourceMaxIndex].name;
        copy.locations[copyMaxIndex].locationType = source.locations[sourceMaxIndex].locationType;

        if (isArraysSameSize(copy.original.locations, source.locations)) {
            swap = !isArraysEqual(copy.original.locations, source.locations);
        }
    }

    if (swap) {
        copy = filterCommandsByTypeValue(
            copy,
            [
                PilotAssignmentCommands.AddLocation,
                PilotAssignmentCommands.DeleteLocation,
                PilotAssignmentCommands.UpdateLocationDepartureTime,
                PilotAssignmentCommands.UpdateLocationArrivalTime,
                PilotAssignmentCommands.UpdateLocationPilotType,
                PilotAssignmentCommands.UpdateLocationBoardingType
            ]);

        copy.wasServerUpdated = true;
        copy.locations = source.locations;
    }

    copy.original.locations = source.locations;

    return copy;
}

function processHourCompensations(copy, source) {

    const copyCompensations = copy.original.hourCompensations;
    const sourceCompensations = source.pilotagePilotCompensation.pilotagePilotHourCompensations;

    const swap = swapContent(copyCompensations, sourceCompensations);

    if (swap) {
        copy = filterCommandsByTypeValue(
            copy,
            [
                PilotAssignmentCommands.AddPilotHourCompensation,
                PilotAssignmentCommands.UpdatePilotHourCompensation,
                PilotAssignmentCommands.DeletePilotHourCompensation
            ]);

        copy.wasServerUpdated = true;
        copy.pilotagePilotCompensation.pilotagePilotHourCompensations = source.pilotagePilotCompensation.pilotagePilotHourCompensations;
    }

    copy.original.hourCompensations = source.pilotagePilotCompensation.pilotagePilotHourCompensations;

    return copy;
}

function processOvertime(copy, source) {

    const copyOvertimes = copy.original.overtimes;
    const sourceOvertimes = source.pilotagePilotCompensation.pilotagePilotOvertimes;

    const swap = swapContent(copyOvertimes, sourceOvertimes);

    if (swap) {
        copy = filterCommandsByTypeValue(
            copy,
            [
                PilotAssignmentCommands.AddPilotAssignmentOvertime,
                PilotAssignmentCommands.UpdatePilotAssignmentOvertime,
                PilotAssignmentCommands.DeletePilotAssignmentOvertime
            ]);

        copy.wasServerUpdated = true;
        copy.pilotagePilotCompensation.pilotagePilotOvertimes = source.pilotagePilotCompensation.pilotagePilotOvertimes;
    }

    copy.original.overtimes = source.pilotagePilotCompensation.pilotagePilotOvertimes;

    return copy;
}

function processReimbursmentStatus(copy, source) {
    if (copy.original.isReimbursementIncluded === source.pilotagePilotCompensation.isReimbursementIncluded) return copy;

    copy = filterCommandsByTypeValue(copy, [PilotAssignmentCommands.UpdateReimbursmentStatus]);

    copy.original.isReimbursementIncluded = source.pilotagePilotCompensation.isReimbursementIncluded;
    copy.pilotagePilotCompensation.isReimbursementIncluded = source.pilotagePilotCompensation.isReimbursementIncluded;
    copy.wasServerUpdated = true;

    return copy;
}

function processVariableCompensation(copy, source, systemName) {

    const assignmentService = new PilotAssignmentService(copy);
    if (assignmentService.hasCommandEventId(`${PilotAssignmentCommands.UpdateVariableCompensationOverriddenNumber}-${systemName}`))
        return copy;

    if (isArrayEmpty(copy.pilotagePilotCompensation.pilotagePilotVariableCompensations))
        return copy;

    const copyCompensation = copy.pilotagePilotCompensation.pilotagePilotVariableCompensations.find(v => v.pilotagePilotVariableCompensationSystemName === systemName);
    const sourceCompensation = source.pilotagePilotCompensation.pilotagePilotVariableCompensations.find(v => v.pilotagePilotVariableCompensationSystemName === systemName);

    if (isObjectNull(copyCompensation) || isObjectNull(sourceCompensation))
        return copy;

    const index = copy.pilotagePilotCompensation.pilotagePilotVariableCompensations.indexOf(copyCompensation);

    if (
        copyCompensation.overridenNumber === sourceCompensation.overridenNumber &&
        copyCompensation.pilotagePilotVariableCompensationOverrideReasonRemark === sourceCompensation.pilotagePilotVariableCompensationOverrideReasonRemark &&
        copyCompensation.pilotagePilotVariableCompensationOverrideReasonTypeId === sourceCompensation.pilotagePilotVariableCompensationOverrideReasonTypeId
    ) return copy;

    copy.pilotagePilotCompensation.pilotagePilotVariableCompensations[index] = sourceCompensation;
    copy.wasServerUpdated = true;

    return copy;
}

function swapContent(copies, sources) {
    let swap = false;

    if (isArrayEmpty(copies) &&
        !isArrayEmpty(sources)) {
        swap = true;
    }

    if (!isArrayEmpty(copies) &&
        isArrayEmpty(sources)) {
        swap = true;
    }

    if (!isArrayEmpty(copies) &&
        !isArrayEmpty(sources)) {
        if (isArraysSameSize(copies, sources)) {
            swap = !isArraysEqual(copies, sources);
        } else {
            swap = true;
        }
    }

    return swap;
}

function processChildObject(copy, source, parentPropertyName, childPropertyName, command) {
    const assignmentService = new PilotAssignmentService(copy);
    if (assignmentService.hasCommandEventId(command)) return copy;

    if (isObjectNull(source[parentPropertyName])) return copy;

    if (isObjectNull(copy[parentPropertyName])) {
        copy[parentPropertyName] = source[parentPropertyName];
        copy.wasServerUpdated = true;
    }

    if (copy[parentPropertyName][childPropertyName] === source[parentPropertyName][childPropertyName]) return copy;

    copy[parentPropertyName] = source[parentPropertyName];
    copy.wasServerUpdated = true;

    return copy;
}

function processObject(copy, source, propertyName, command) {

    if (copy.original[propertyName] === source[propertyName]) return copy;

    copy = filterCommandsByTypeValue(copy, [command]);

    copy.original[propertyName] = source[propertyName];
    copy[propertyName] = source[propertyName];
    copy.wasServerUpdated = true;

    return copy;
}

function isArraysEqual(copies, sources) {
    let isEqual = true;

    for (let i = 0; i < copies.length; i++) {
        if (!isEqual) continue;

        isEqual = isObjectsEqual(copies[i], sources[i]);
    }

    return isEqual;
}

function filterCommandsByTypeValue(pilotAssignment, typeValues) {

    const assignmentService = new PilotAssignmentService(pilotAssignment);
    if (!assignmentService.isDirty) return pilotAssignment;

	const filteredCommands = pilotAssignment.commands
		.filter(c => !typeValues.includes(c.typeValue));

	pilotAssignment.commands = filteredCommands;
    pilotAssignment.hasLocalChanges = assignmentService.isDirty;

	return pilotAssignment;
}

export {
    processPilotAssignment
}
