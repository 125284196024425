import React, { useEffect, useState } from 'react';
import HourCompensationTypeIdbRepository from '../../../../repositories/idb/HourCompensationTypeIdbRepository';
import Moment from 'moment';
import Spacer from '../../../../components/layout/Spacer';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DateTimePickerModes } from '../../../../components/layout/dateTimePicker/DateTimePicker';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import {
    TextBox,
} from "@progress/kendo-react-inputs";
import { Dialog } from '../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../components/layout/dialogs/DialogProperties';
import CompensationApiRepository from '../../../../repositories/api/CompensationApiRepository';
import { deepCopyObject, isNullOrEmpty, isObjectNull, removeQuotesFromString } from '../../../../components/helpers/ObjectHelpers';
import { formatMoment } from '../../../../components/helpers/DateTimeHelpers';
import { publishErrorNotificationTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../../components/helpers/PubSubHelpers';
import TextAreaFieldWrapper from '../../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import DateTimePickerFieldWrapper from '../../../../components/layout/fieldWrappers/DateTimePickerFieldWrapper';
import { DialogForm } from '../../../../components/layout/DialogForm';

export const PilotHourCompensationDialog = ({ onClose, compensation = null, canEdit, reload}) => {
    const [selectedOption, setSelectedOption] = useState({ name: "" });
    const [options, setOptions] = useState([]);
    const [description, setDescription] = useState("");
    const [number, setNumber] = useState(0);
    const [canSave, setCanSave] = useState(false);
    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);
    const [warnings, setWarnings] = useState([]);

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: "C-Tillegg",
                onClose: onClose,
                className: "dialog-std",
                showActionBar: canSave,
                actions: [
                    {
                        onClick: onClose,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        onClick: onSaveClick,
                        disabled: !canSave,
                        icon: "ok",
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            <DialogForm>
                {compensation && compensation.feedbackRemark &&
                    <TextAreaFieldWrapper 
                        title="Tilbakemelding"
                        autoSize={true}
                        value={compensation.feedbackRemark}
                        readOnly={true}
                        canCopyToClipboard={false}
                        className={"text-box-correction-message"}
                    />
                }
                <FieldWrapper>
                    <Label className="k-form-label">
                        Type:
                    </Label>
                    <div className="k-form-field-wrap">
                        <DropDownList
                            data={options}
                            dataItemKey="pilotagePilotHourCompensationTypeId"
                            textField="name"
                            adaptive={true}
                            adaptiveTitle="Velg type"
                            value={selectedOption}
                            onChange={handleChange}
                            disabled={!canEdit}
                        />
                    </div>
                </FieldWrapper>

                <Spacer height={20} />
                <DateTimePickerFieldWrapper
                    title="Fra dato"
                    mode={DateTimePickerModes.DateTime}
                    value={fromTime}
                    onChange={onFromTimeChange}
                    disabled={!canEdit}
                />
                <Spacer height={20} />
                <DateTimePickerFieldWrapper
                    title="Til dato"
                    mode={DateTimePickerModes.DateTime}
                    value={toTime}
                    onChange={onToTimeChange}
                    disabled={!canEdit}
                    warnings={warnings}
                />
                <Spacer height={20} />

                <FieldWrapper>
                    <Label className="k-form-label">
                        Antall timer:
                    </Label>
                    <div className="k-form-field-wrap">
                        <TextBox value={number} disabled={true} readOnly={true} />
                    </div>
                </FieldWrapper>

                <Spacer height={20} />
                <TextAreaFieldWrapper
                    title="Beskrivelse"
                    rows={3}
                    value={description}
                    onChange={onDescriptionChange}
                    disabled={!canEdit}
                />
            </DialogForm>
        </Dialog>
    )

    async function initializeAsync() {
        const dtos = await HourCompensationTypeIdbRepository.getAllAsync();
        setOptions(dtos);
        setSelectedOption(dtos[0]);

        if (isObjectNull(compensation)) return;

        const selectedDto = dtos.find(d => d.systemName === compensation.type.systemName);
        setSelectedOption(selectedDto);

        const fromTime = Moment(compensation.fromTime);
        let toTime = Moment(compensation.fromTime).add(compensation.number, "hours");

        if (!isNullOrEmpty(compensation.toTime) && Moment(compensation.toTime) > fromTime) {
            toTime = Moment(compensation.toTime);
        }

        setFromTime(fromTime.toDate());
        setToTime(toTime.toDate());
        setNumber(compensation.number.toFixed(2));
        setDescription(compensation.description);
        setCanSave(canEdit);
    }

    function onDescriptionChange(e) {
        setDescription(e.value);
        validate(e.value, fromTime, toTime);
    }

    function onFromTimeChange(e) {
        setFromTime(e.value);
        validate(description, e.value, toTime);

        calculateNumber(e.value, toTime);
    }

    function onToTimeChange(e) {
        setToTime(e.value);
        validate(description, fromTime, e.value);

        calculateNumber(fromTime, e.value);
    }

    async function onSaveClick() {
        if (!canSave) return;

        let compensationCopy = {};
        if (!isObjectNull(compensation)) {
            compensationCopy = deepCopyObject(compensation);
        }

        const from = Moment(fromTime);
        const to = Moment(toTime);

        compensationCopy.fromTime = formatMoment(from);
        compensationCopy.toTime = formatMoment(to);
        compensationCopy.number = Number(number);
        compensationCopy.description = description;
        compensationCopy.type = {
            systemName: selectedOption.systemName,
            value: selectedOption.hourCompensationType
        }

        if (compensation === null) {
            const response = await CompensationApiRepository.addHourCompensationAsync(compensationCopy)
            if (response.ok) {
                onClose();
                publishSuccessNotificationTopic("C-tillegget ble lagret");
                reload();
            } else {
                publishErrorNotificationTopic("C-tillegget overlapper med en eksisterende");
            }
        } else {
            const response = await CompensationApiRepository.updateHourCompensation(compensationCopy);
            if (response.ok) {
                publishSuccessNotificationTopic("C-tillegget ble lagret");
                compensation = compensationCopy;
                onClose();
                reload();
            } else {
                const errorMessage = await response.text();
                publishWarningNotificationTopic(removeQuotesFromString(errorMessage));
            }
        }
    }

    function handleChange(e) {
        setSelectedOption(e.target.value);
    }

    function validate(description = "", fromTime = null, toTime = null) {
        setCanSave(
            !isNullOrEmpty(description) &&
            !isNullOrEmpty(fromTime) &&
            !isNullOrEmpty(toTime));
    }

    function calculateNumber(fromTime, toTime) {

        const warnings = [];

        if (isObjectNull(fromTime) || isObjectNull(toTime)) {
            if (isNullOrEmpty(fromTime)) {
                warnings.push("Fra-dato kan ikke v&aelig;re tom.");
            }
            if (isNullOrEmpty(toTime)) {
                warnings.push("Til-dato kan ikke v&aelig;re tom.");
            }

            setNumber("");
            setWarnings(warnings);
            return;
        }

        const from = Moment(fromTime);
        const to = Moment(toTime);

        if (from >= to) {
            warnings.push("Til-dato kan ikke v&aelig;re f&oslash;r fra-dato.")
        }

        const diff = to.diff(from, 'minutes');
        let hours = (diff / 60);

        setNumber(hours.toFixed(2));
        setWarnings(warnings);
    }
}
