import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Moment from 'moment';
import ContentGrid from '../../../components/layout/ContentGrid';
import Icons, { TrashIcon } from '../../../components/layout/icons/Icons';
import { IconColors, PilotageExemptionApplicationStatusColors, PilotageExemptionApplicationStatusEnum } from '../../../services/SystemNames';
import Spacer from '../../../components/layout/Spacer';
import { CardList } from '../../../components/layout/card/CardList';
import { Card } from '../../../components/layout/card/Card';
import SvgIcon from '../../../components/layout/icons/Icons';
import { CardListEmpty } from '../../../components/layout/card/CardListEmpty';
import FloatingButtonContainer from '../../../components/layout/FloatingButtonContainer';
import MiniCardList from '../../../components/layout/card/MiniCard';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { CardModes, CardProperties } from '../../../components/layout/card/components/CardProperties';
import { BusySpinner } from '../../../components/layout/overlay/components/Busy';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import FloatingActionButton from '../../../components/layout/FloatingActionButton';
import { deepCopyObject, isArrayEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import {
    createPilotAssignmentPilotsShortList,
    getPilotAssignmentStatusColor,
    getPilotAssignmentStatusIconColor,
    isPilotAssignmentAssignedAndNotProcessed,
    isPilotAssignmentCompleted,
    isPilotAssignmentCorrectionNeeded
} from '../pilot-assignment/helpers/PilotAssignmentHelpers';
import PilotAssignmentService from '../pilot-assignment/services/PilotAssignmentService';
import { DialogForm } from '../../../components/layout/DialogForm';

export const PageStateAssigned = ({
    pilotAssignments,
    toggle,
    isBusy,
    onClick,
    initialize,
    onDelete,
    onDeleteMultible
}) => {

    const [cards, setCards] = useState([]);
    const [isDeleteActive, setIsDeleteActive] = useState(false);
    const [canDelete, setCanDelete] = useState(true);
    const [pilotAssignmentsToDelete, setPilotAssignmentsToDelete] = useState([]);

    useEffect(() => {
        function generateCards() {
            const result = [];

            for (let i = 0; i < pilotAssignments.length; i++) {
                const pilotAssignment = pilotAssignments[i];

                result.push({
                    guid: pilotAssignment.guid,
                    properties: {
                        ...CardProperties,
                        id: pilotAssignment.guid,
                        sourceId: pilotAssignment.pilotagePilotId,
                        title: pilotAssignment.pilotage.pilotageShip.shipName,
                        subTitle: Moment(pilotAssignment.pilotage.pilotageDetail.fromTime).format('DD.MM HH:mm'),
                        borderColor: getPilotAssignmentStatusColor(pilotAssignment),
                        iconColor: getPilotAssignmentStatusIconColor(pilotAssignment),
                        isBorderLeftFat: true,
                        className: "pp-card",
                        onClick: () => handleOnClick(pilotAssignment),
                        onDeleteClick: () => handleDelete(pilotAssignment),
                    },
                    body: <PilotagePilotCardBody pilotagePilot={pilotAssignment} />
                });
            }
            
            setCards(result);
        }

        generateCards();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggle]);

    return (
        <>
            <ContentGrid
                title="Mine losoppdrag"
                titleIcon="ship"
                canPullToRefresh={true}
                onPullToRefresh={() => {
                    setCanDelete(false);
                    setIsDeleteActive(false);
                    setPilotAssignmentsToDelete([]);

                    initialize();
                }}>
                {
                    isBusy ?
                        <CardListEmpty />
                        :
                        (!isArrayEmpty(cards)) ?
                            <CardList
                                mode={CardModes.Multible}
                                cards={cards}
                                toggleActivateSelect={onToggleActivateSelect}
                                toggleSelectItem={onToggleSelectItem}
                            />
                            :
                            <CardListEmpty text={isBusy ? '' : 'Du har ingen tildelte losoppdrag.'} />
                }



            </ContentGrid>
            {
                isDeleteActive &&
                <FloatingButtonContainer hasBottomNavigation={true}>
                    <FloatingActionButton
                        themeColor={canDelete ? "error" : "secondary"}
                        disabled={!canDelete}
                        svgIcon={TrashIcon}
                        onClick={onDeleteMultibleAsync}
                    />
                </FloatingButtonContainer>
            }
        </>
    );

    function handleOnClick(pilotagePilot) {
        onClick(pilotagePilot);
    }

    function handleDelete(pilotagePilot) {
        onDelete(pilotagePilot);
    }

    function onToggleActivateSelect(activate) {
        setIsDeleteActive(activate);

        if (activate) return;

        setPilotAssignmentsToDelete([]);
        setCanDelete(false);
    }

    function onToggleSelectItem(id, selected) {
        let result = deepCopyObject(pilotAssignmentsToDelete);
        if (selected) {
            result.push(id);
        } else {
            result = result.filter(i => i !== id);
        }

        setPilotAssignmentsToDelete(result);
        setCanDelete(!isArrayEmpty(result));
    }

    async function onDeleteMultibleAsync() {

        if (isArrayEmpty(pilotAssignmentsToDelete)) return;

        const copy = deepCopyObject(pilotAssignmentsToDelete);

        await onDeleteMultible(copy);

        setPilotAssignmentsToDelete([]);
        setCanDelete(false);
        setIsDeleteActive(cards.length > pilotAssignmentsToDelete.length);
    }
}

export const PageStateExecuted = ({
    executedPilotAssignments,
    isFetchingMore,
    onClick,
    onPullToRefresh
}) => {

    const [show, setShow] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    // Initialize function
    useEffect(() => {

        window.addEventListener('online', handleOnOffline);
        window.addEventListener('offline', handleOnOffline);

        return () => {
            window.removeEventListener('online', handleOnOffline);
            window.removeEventListener('offline', handleOnOffline);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                isOnline ?
                    <ContentGrid
                        title="Fullf&oslash;rte losoppdrag"
                        titleIcon="ship"
                        canPullToRefresh={true}
                        onPullToRefresh={onPullToRefresh}>
                        <CardList>
                            {
                                executedPilotAssignments.map((pilotAssignment, index) =>
                                    <div key={index}>
                                        <Card
                                            properties={{
                                                ...CardProperties,
                                                sourceId: pilotAssignment.pilotagePilotId,
                                                mode: CardModes.Single,
                                                title: pilotAssignment.pilotage.pilotageShip.shipName,
                                                subTitle: Moment(pilotAssignment.pilotage.pilotageDetail.fromTime).format('DD.MM HH:mm'),
                                                onClick: () => handleOnClick(pilotAssignment),
                                                borderColor: getPilotAssignmentStatusColor(pilotAssignment),
                                                isBorderLeftFat: true,
                                                className: "pp-card",
                                                canToggle: false
                                            }}>
                                            <PilotagePilotCardBody pilotagePilot={pilotAssignment} />
                                        </Card>
                                        <Spacer height={20} />
                                    </div>
                                )
                            }
                        </CardList>
                        {
                            isFetchingMore &&
                            <div className="fetching-more">
                                <BusySpinner dimensions={40} type="oval" />
                            </div>
                        }
                        <Spacer height={40} />
                    </ContentGrid>
                    :
                    <ContentGrid
                        title="Fullf&oslash;rte">
                        <Spacer height={40} />
                        <div className="row">
                            <div className="col align-items-center title text-center">
                                <h3>
                                    {parse("Man m&aring; v&aelig;re p&aring; nett for &aring; se utf&oslash;rte oppdrag.")}
                                </h3>
                            </div>
                        </div>
                    </ContentGrid>
            }

            {
                show &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        onClose: () => setShow(false),
                        title: "Fullf&oslash;rte",
                        actions: [
                            {
                                onClick: () => setShow(false),
                                icon: "ok",
                                themeColor: "primary",
                                text: "Ok"
                            }
                        ]
                    }}>
                    <DialogForm>
                        <div className="row">
                            <div className="col text-center">
                                {parse("Man m&aring; v&aelig;re p&aring; nett for &aring; se utf&oslash;rte oppdrag.")}
                            </div>
                        </div>
                    </DialogForm>
                </Dialog>
            }

        </>

    )

    function handleOnOffline() {
        setIsOnline(navigator.onLine);
    }

    function handleOnClick(source) {
        if (navigator.onLine) {
            onClick(source);
        } else {
            setShow(true);
        }

    }
}

export const PageStateCurrent = ({ pilotages, onClick, onPullToRefresh }) => {
    return (
        <ContentGrid title="Alle oppdrag" titleIcon="ship" canPullToRefresh={true} onPullToRefresh={onPullToRefresh}>
            <CardList>
                {
                    pilotages.map((pilotage) =>
                        <div key={pilotage.guid}>
                            <Card
                                properties={{
                                    ...CardProperties,
                                    title: pilotage.pilotageShip.shipName,
                                    subTitle: Moment(pilotage.pilotageDetail.fromTime).format('DD.MM HH:mm'),
                                    onClick: () => onClick(pilotage),
                                    borderColor: "var(--all-pilotages-border)",
                                    backgroundColor: "var(--all-pilotages-bg)"
                                }}>
                                <PilotageCardBody pilotage={pilotage} />
                            </Card>
                            <Spacer height={15} />
                        </div>
                    )
                }
            </CardList>
            <Spacer height={60} />
        </ContentGrid>
    )
}

export const PilotagePilotCardBody = ({ pilotagePilot }) => {
    const pilotage = pilotagePilot.pilotage;
    const assignmentService = new PilotAssignmentService(pilotagePilot);
    return (
        <>
            <div className="pp-card-body-locations">
                <div>
                    <div>
                        {parse(pilotage.fromLocation.name)}
                    </div>
                    <div>
                        {pilotage.pilotageNo}
                    </div>
                </div>
                <div>
                    <div>
                        <Icons iconName="arrowTo" dimensions={20} color={IconColors.Primary} />
                    </div>
                    <div>
                        &nbsp;{parse(pilotage.toLocation.name)}
                    </div>
                </div>
            </div>
            <Spacer height={10} />
            <div className="pp-card-body-info">
                <div>
                    {
                        showPilots() &&
                        <Pilots pilotagePilots={pilotagePilot.pilotage.pilotagePilotsInformation} />
                    }

                </div>
                <div>
                    {
                        pilotagePilot.isTrainee && <div className="pp-type pp-trainee"><div>L</div></div>
                    }
                    {
                        showLocked() && <div className="grid"><Icons iconName="lock" color={IconColors.Primary} /> </div>
                    }
                    {
                        hasExemptionApplication() && <div className="grid"><Icons iconName="hse" color={IconColors.Primary} /> </div>
                    }
                    {
                        pilotagePilot.isExaminer && <div className="pp-type pp-examiner"><div>S</div></div>
                    }
                    {
                        pilotagePilot.pilotage.hasObservation && <div className="grid"><Icons iconName="eye" color={IconColors.Primary} /> </div>
                    }
                    {
                        pilotagePilot.pilotage.isTowing && <div className="grid"><Icons iconName="towing" color={IconColors.Primary} /> </div>
                    }
                    {
                        pilotagePilot.pilotage.isHelicopterRequired && <div className="grid"><Icons iconName="helicopter" color={IconColors.Primary} /> </div>
                    }
                    {
                        pilotagePilot.hasExternalChanges && <div className="pp-external-changes">!&dArr;</div>
                    }
                    {
                        showExam() && <div className="grid"><Icons iconName="exam" /> </div>
                    }
                    {
                        assignmentService.isDirty && <div className="pp-local-changes">!&uArr;</div>
                    }
                </div>
            </div>
            {
                showReplyRequest() && <div className="pp-reply"><span className="reply-link">{getReplyRequestText()}</span></div>
            }
        </>
    );

    function showReplyRequest() {
        return isPilotAssignmentAssignedAndNotProcessed(pilotagePilot) || isPilotAssignmentCorrectionNeeded(pilotagePilot);
    }

    function getReplyRequestText() {
        if (isPilotAssignmentAssignedAndNotProcessed(pilotagePilot)) return parse("Besvar foresp&oslash;rsel");

        return "Korriger skipperbevis";
    }

    function showLocked() {
        return !isPilotAssignmentCompleted(pilotagePilot) && pilotagePilot.pilotage.pilotageDetail.isLocked;
    }

    function hasExemptionApplication() {
        return !isObjectNull(pilotagePilot.pilotage.pilotageExemptionApplicationStatus);
    }

    function showPilots() {
        if (showReplyRequest()) return false;
        if (isArrayEmpty(pilotagePilot.pilotage.pilotagePilotsInformation)) return false;

        return pilotagePilot.pilotage.pilotagePilotsInformation.length > 1;
    }

    function showExam() {
        return !isArrayEmpty(pilotage.pecExams);
    }
}

export const PilotageCardBody = ({ pilotage }) => {
    return (
        <>
            <div>
                {parse(pilotage.fromLocation.name)}
            </div>
            <div className="current-pilotage-to-location">
                <div>
                    <Icons iconName="arrowTo" dimensions={20} color={IconColors.Secondary} />
                </div>
                <div>
                    &nbsp;{parse(pilotage.toLocation.name)}
                </div>
            </div>
            <Spacer height={10} />
            <div className="row">
                <div className="col">
                    <Pilots pilotagePilots={pilotage.pilotagePilotsInformation} />
                </div>
                <div className="col-auto current-pilotage-icons">
                    {pilotage.hasHazmat && <SvgIcon iconName="hazmat" dimensions={32} />}
                    {pilotage.hasObservation && <SvgIcon iconName="eye" dimensions={32} color={IconColors.Secondary} />}
                    {pilotage.isTowing && <SvgIcon iconName="towing" dimensions={32} color={IconColors.Secondary} />}
                    {pilotage.pilotageDetail.isLocked && <SvgIcon iconName="lock" color={IconColors.Secondary} dimensions={32} />}
                    <ExemptionStatusIcon status={pilotage.pilotageExemptionApplicationStatus} />
                </div>
            </div>
        </>
    )
}

const ExemptionStatusIcon = ({ status }) => {
    switch (status?.systemName) {
        case PilotageExemptionApplicationStatusEnum.APPLIED: {
            return <SvgIcon iconName="exemptionNotSent" dimensions={30} color={PilotageExemptionApplicationStatusColors.APPLIED} />
        }
        case PilotageExemptionApplicationStatusEnum.CONSIDERED_EVIDENTLY_JUSTIFIABLE: {
            return <SvgIcon iconName="exemptionPending" dimensions={30} color={PilotageExemptionApplicationStatusColors.CONSIDERED_EVIDENTLY_JUSTIFIABLE} />
        }
        case PilotageExemptionApplicationStatusEnum.CONSIDERED_NOT_EVIDENTLY_JUSTIFIABLE: {
            return <SvgIcon iconName="exemptionPending" dimensions={30} color={PilotageExemptionApplicationStatusColors.CONSIDERED_NOT_EVIDENTLY_JUSTIFIABLE} />
        }
        case PilotageExemptionApplicationStatusEnum.DISMISSED: {
            return <SvgIcon iconName="exemptionRejected" dimensions={30} color={PilotageExemptionApplicationStatusColors.DISMISSED} />
        }
        case PilotageExemptionApplicationStatusEnum.GRANTED: {
            return <SvgIcon iconName="exemptionGranted" dimensions={30} color={PilotageExemptionApplicationStatusColors.GRANTED} />
        }
        case PilotageExemptionApplicationStatusEnum.REJECTED: {
            return <SvgIcon iconName="exemptionRejected" dimensions={30} color={PilotageExemptionApplicationStatusColors.REJECTED} />
        }
        case PilotageExemptionApplicationStatusEnum.SENT_TO_PILOT_MASTER: {
            return <SvgIcon iconName="exemptionPending" dimensions={30} color={PilotageExemptionApplicationStatusColors.SENT_TO_PILOT_MASTER} />
        }
        case PilotageExemptionApplicationStatusEnum.VOYAGE_ENDED: {
            return <SvgIcon iconName="exemptionRejected" dimensions={30} color={PilotageExemptionApplicationStatusColors.VOYAGE_ENDED} />
        }
        default: return "";
    }
}

const Pilots = ({ pilotagePilots }) => {
    return (
        (!isArrayEmpty(pilotagePilots)) &&
        <MiniCardList items={createPilotAssignmentPilotsShortList(pilotagePilots)} />
    )
};
