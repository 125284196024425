import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { PilotAssignmentCommands, PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import Spacer from '../../../../components/layout/Spacer';
import { Card } from '../../../../components/layout/card/Card';
import { Dialog } from '../../../../components/layout/dialogs/Dialog';
import { CardActionTypes, CardProperties } from '../../../../components/layout/card/components/CardProperties';
import { DialogProperties } from '../../../../components/layout/dialogs/DialogProperties';
import { errorsContainsFromObject, isNullOrEmpty } from '../../../../components/helpers/ObjectHelpers';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';
import { LocationBoardingTypeCard } from './location/LocationBoardingTypeCard';
import { LocationPilotTypeAddLocationCard } from './location/LocationPilotTypeAddLocationCard';
import { LocationStartStopCard } from './location/LocationStartStopCard';
import { getPilotAssignmentIconColor, getPilotAssignmentStatusColor } from '../helpers/PilotAssignmentHelpers';

export const LocationCard = forwardRef((
    {
        index,
        assignmentService,
        isEditable = false,
        codeStrings = [],
        onAfterChange,
        onDelete,
        onSearch
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onLocationsChanged() {
            initializeAsync();
        },
        onInitializeAsync() {
            initializeAsync();
            if (isNullOrEmpty(pilotTypeRef.current)) return;
            pilotTypeRef.current.onInitialize();
        },
    }));

    const pilotTypeRef = useRef();

    const location = assignmentService.getLocationByIndex(index);
    const isFirst = index === 0;
    const isLast = index === assignmentService.locationsCount - 1;
    const iconColor = getPilotAssignmentIconColor(assignmentService.assignment);
    const borderColor = getPilotAssignmentStatusColor(assignmentService.assignment);

    useEffect(() => {
            setHasStopTimeValidationError(errorsContainsFromObject(codeStrings, stopTimeTargetErrorCodes));
            setHasStartTimeValidationError(errorsContainsFromObject(codeStrings, startTimeTargetErrorCodes));
            setHasBoardingTypeValidationError(errorsContainsFromObject(codeStrings, boardingTypeTargetErrorCodes));
            setHasPilotTypeValidationError(errorsContainsFromObject(codeStrings, pilotTypeTargetErrorCodes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeStrings])

    const [showDialog, setShowDialog] = useState(false);
    const [hasPilotTypeValidationError, setHasPilotTypeValidationError] = useState(false);
    const [hasStopTimeValidationError, setHasStopTimeValidationError] = useState(false);
    const [hasStartTimeValidationError, setHasStartTimeValidationError] = useState(false);
    const [hasBoardingTypeValidationError, setHasBoardingTypeValidationError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const startTimeTargetErrorCodes = {
        PilotLocationArrivalTimeExistsPreviousDepartureTimeIsMissing: `${PilotAssignmentErrorCodes.PilotLocationArrivalTimeExistsPreviousDepartureTimeIsMissing}_${location.sequenceNo}`,
        PilotLocationArrivalTimeCannotBeInTheFuture: `${PilotAssignmentErrorCodes.PilotLocationArrivalTimeCannotBeInTheFuture}_${location.sequenceNo}`
    };

    const stopTimeTargetErrorCodes = {
        PilotLocationPrevousArrivalTimeExistsDepartureTimeIsMissing: `${PilotAssignmentErrorCodes.PilotLocationPrevousArrivalTimeExistsDepartureTimeIsMissing}_${location.sequenceNo}`,
        PilotLocationDepartureTimeMustBeAfterPreviousDepartureTime: `${PilotAssignmentErrorCodes.PilotLocationDepartureTimeMustBeAfterPreviousDepartureTime}_${location.sequenceNo}`,
        PilotLocationDepartureTimeMustBeAfterPreviousArrivalTime: `${PilotAssignmentErrorCodes.PilotLocationDepartureTimeMustBeAfterPreviousArrivalTime}_${location.sequenceNo}`,
        PilotLocationDepartureTimeCannotBeInTheFuture: `${PilotAssignmentErrorCodes.PilotLocationDepartureTimeCannotBeInTheFuture}_${location.sequenceNo}`
    };

    const boardingTypeTargetErrorCodes = {
        PilotLocationOffboardingNotPossible: `${PilotAssignmentErrorCodes.PilotLocationOffboardingNotPossible}_${location.sequenceNo}`
    };

    const pilotTypeTargetErrorCodes = {
        PilotLocationPilotTypeIsMissing: `${PilotAssignmentErrorCodes.PilotLocationPilotTypeIsMissing}_${location.sequenceNo}`,
    };

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, initializeAsync)
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, []);

    return (
        <>
            {
                !isFirst &&
                <>
                    <Spacer height={30} />
                    <LocationPilotTypeAddLocationCard
                        ref={pilotTypeRef}
                        index={index}
                        assignmentService={assignmentService}
                        onAfterChange={handleOnAfterChange}
                        isEditable={isEditable}
                        hasPilotTypeValidationError={hasPilotTypeValidationError}
                        onSearchLocationsClick={onSearchLocationsClick}
                    />
                    <Spacer height={30} />
                </>
            }
            <Card
                properties={{
                    ...CardProperties,
                    title: location.name,
                    className: "card-item-content-border-left",
                    hasValidationError: (hasStopTimeValidationError || hasStartTimeValidationError || hasBoardingTypeValidationError),
                    borderColor: borderColor,
                    isBorderLeftFat: true,
                    isDirty: isDirty,
                    actions: (!isFirst && !isLast && isEditable) ?
                        [
                            {
                                icon: "trash",
                                iconColor: iconColor,
                                type: CardActionTypes.Delete,
                                onClick: () => setShowDialog(true),
                                hideOnSwipeLeft: true
                            }
                        ] : []
                }}>
                <div className={`pp-location-details-card-body`}>

                    <div className="pp-time-1">
                        {
                            isFirst ?
                                <LocationStartStopCard
                                    isEditable={isEditable}
                                    title="Start"
                                    subTitle="Velg start tid"
                                    time={location.fromTime}
                                    hasValidationError={hasStartTimeValidationError}
                                    onChange={onDateStartTimePickerChanged}
                                />
                                :
                                <LocationStartStopCard
                                    isEditable={isEditable}
                                    title="Stopp"
                                    subTitle="Velg stopp tid"
                                    time={location.toTime}
                                    hasValidationError={hasStopTimeValidationError}
                                    onChange={onDateStopTimePickerChanged}
                                />
                        }
                    </div>

                    {
                        (!isFirst && !isLast) &&
                        <div className="pp-time-2">
                            <LocationStartStopCard
                                isEditable={isEditable}
                                title="Start"
                                subTitle="Velg start tid"
                                time={location.fromTime}
                                hasValidationError={hasStartTimeValidationError}
                                onChange={onDateStartTimePickerChanged}
                            />
                        </div>
                    }

                    <div className="pp-boarding-type">
                        <LocationBoardingTypeCard
                            index={index}
                            assignmentService={assignmentService}
                            onAfterChange={handleOnAfterChange}
                            isEditable={isEditable}
                            hasValidationError={hasBoardingTypeValidationError}
                        />
                    </div>

                </div>
            </Card>

            {
                showDialog &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        onClose: () => setShowDialog(false),
                        onClick: onConfirmDelete,
                        title: "Fjern lokasjon",
                        text: `&Oslash;nsker du &aring; fjerne lokasjonen <b>${location.name}</b>`
                    }}

                />
            }

        </>
    )

    async function initializeAsync() {
        initializeDirty();
    }

    function initializeDirty() {
        const hasAddLocationCmd = assignmentService.hasLocationCommand(location, PilotAssignmentCommands.AddLocation);
        const hasArrivalTimeCommand = assignmentService.hasLocationCommand(location, PilotAssignmentCommands.UpdateLocationArrivalTime);
        const hasDepartureTimeCommand = assignmentService.hasLocationCommand(location, PilotAssignmentCommands.UpdateLocationDepartureTime);
        const hasBoardingTypeCommand = assignmentService.hasLocationCommand(location, PilotAssignmentCommands.UpdateLocationBoardingType);

        if (isFirst) {
            setIsDirty(hasDepartureTimeCommand || hasBoardingTypeCommand);
        } else if (isLast) {
            setIsDirty(hasArrivalTimeCommand || hasBoardingTypeCommand);
        } else {
            setIsDirty(hasAddLocationCmd || hasDepartureTimeCommand || hasArrivalTimeCommand || hasBoardingTypeCommand);
        }
    }

    async function onDateStartTimePickerChanged(event) {
        if (!isEditable) return;
        const obj = {
            location: location,
            departureTime: event.value,
            isPilotBoardingTypeOffBoarding: !isFirst
        }

        await assignmentService.onUpdateLocationDepartureTimeAsync(obj);
        onAfterChange();
    }

    async function onDateStopTimePickerChanged(event) {
        if (!isEditable) return;
        const obj = {
            location: location,
            arrivalTime: event.value,
            isPilotBoardingTypeOffBoarding: !isFirst
        }

        await assignmentService.onUpdateLocationArrivalTimeAsync(obj);
        onAfterChange();
    }

    function onSearchLocationsClick() {
        if (!isEditable) return;

        onSearch(location);
    }

    function onConfirmDelete() {
        if (!isEditable) return;
        setShowDialog(false);
        onDelete(location);
    }

    function handleOnAfterChange() {
        initializeDirty();
        onAfterChange();
    }
})
