import { PcsMenus } from "../../pages/pcs/components/PcsBottomMenu";
import { ApplicationRightSystemNames, NavItemTypes, PersonTypes } from "../../services/SystemNames";
import { handleLoginAsync, handleLogoutAsync, hasApplicationRights, hasPersonType, isBetaUser, isLoggedIn, isLoggedInPerson } from "./AuthHelpers";

async function initializeAsync(hseTypeGroups = [], applicationRights = []) {

    const navItems = [];

    navItems.push(createNavItem({
        to: "/", icon: "home", title: "Hjem"
    }));

    if (isLoggedIn()) {

        if (navigator.onLine) {
            await getOnlineNavItemsAsync(navItems, hseTypeGroups, applicationRights);
        } else {
            await getOfflineNavItemsAsync(navItems);
        }

        navItems.push(generateAboutNavItem());
        navItems.push(createNavItem({
            icon: "logout", title: "Logg ut", type: NavItemTypes.Logout
        }));
    } else {
        navItems.push(generateAboutNavItem());
        navItems.push(createNavItem({
            icon: "login", title: "Logg inn", type: NavItemTypes.Login
        }));
    }

    return navItems;
}

async function getOnlineNavItemsAsync(navItems, hseTypeGroups, applicationRights) {
    const isPersonTypePilot = hasPersonType(PersonTypes.Pilot);

    if (isPersonTypePilot) {
        navItems.push(createNavItem({
            to: "/pilot/pilotages/assigned", icon: "ship", title: "Mine oppdrag"
        }));
    }

    navItems.push(createNavItem({
        to: "/pilotages/current", icon: "ship", title: "Alle oppdrag"
    }));

    if (isPersonTypePilot) {

        navItems.push(createNavItem({
            to: "/worktime/register", icon: "stopwatch", title: "Arbeidstid",
        }));

        navItems.push(createNavItem({
            to: "/pilot/history", icon: "timeReset", title: "Min historikk"
        }));

        navItems.push(createNavItem({
            to: "/pilot/appointments", icon: "calendar", title: "Fravær"
        }));
        navItems.push(createNavItem({
            to: "/pilot/compensation", icon: "compensationHand", title: "Tillegg"
        }));
        navItems.push(createNavItem({
            to: "/pilot/station", icon: "pilotNotRelevant", title: "Losoversikt"
        }));
        navItems.push(createNavItem({
            to: "/pilot/quaymanual", icon: "book", title: "Kaibok"
        }));

        navItems.push(createNavItem({
            to: "/noticetime", icon: "alarmClock", title: "Varslingstid",
        }));

        const hseNavItem = createNavItem({
            to: "/hse/EQUIPMENT", icon: "hse", title: "HMS-data"
        });

        navItems.push(hseNavItem);

        if (isLoggedInPerson()) {
            navItems.push(generateEvaluationNavItem());
        }
    }

    const hasApplicationRightCoastalSegment = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegments, ApplicationRightSystemNames.EditPilotCoastalSegmentsLocations, ApplicationRightSystemNames.EditPilotCoastalSegmentsEvaluation]);
    if (hasApplicationRightCoastalSegment === true) {
        const hasApplicationRightEditCoastalSegment = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegments]);
        const hasApplicationRightEditCoastalSegmentEvaluation = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsEvaluation]);
        const hasApplicationRightEditCoastalSegmentLocation = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsLocations]);

        const parentNavItem = createNavItem({
            to: "/pcs", icon: "mapSegments", title: "Sertifikatomr&aring;der"
        });

        if (hasApplicationRightEditCoastalSegment) {
            parentNavItem.children.push(createNavItem({
                to: `/pcs?menu=${PcsMenus.Groups}`, title: "Registrering"
            }));
        }
        if (hasApplicationRightEditCoastalSegmentEvaluation) {
            parentNavItem.children.push(createNavItem({
                to: `/pcs?menu=${PcsMenus.Evaluations}`, title: "Evaluering"
            }));
        }
        if (hasApplicationRightEditCoastalSegmentLocation) {
            parentNavItem.children.push(createNavItem({
                to: `/pcs?menu=${PcsMenus.Locations}`, title: "Lokasjoner"
            }));
        }
        navItems.push(parentNavItem);
    }

    if (isPersonTypePilot) {
        const hasApplicationRightPilotRotation = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.PilotRotation]);
        if (hasApplicationRightPilotRotation === true) {

            navItems.push(createNavItem({
                to: "/pilot-rotation", icon: "rotation", title: "T&oslash;rn",
                children: [
                    createNavItem({
                        to: "/pilot-rotation/current", title: "Vaktliste"
                    }),
                    createNavItem({
                        to: "/pilot-rotation/next", title: "Neste vakt"
                    }),
                    createNavItem({
                        to: "/pilot-rotation/log", title: "Logg"
                    })
                ]
            }));
        }

        const permissionsChildren = []
        const settingsChildren = [
            createNavItem({
                to: "/settings/personaldata", title: "Persondata"
            }),
            createNavItem({
                to: "/settings/calendar", title: "Kalender"
            }),
            createNavItem({
                to: "/settings/daynight", title: "Definer dag/natt"
            }),
            createNavItem({
                to: "/settings/permissions", title: "Tillatelser",
                children: permissionsChildren,
            }),
            createNavItem({
                title: "Resette data", type: NavItemTypes.RefreshData
            }),
            createNavItem({
                title: "Oppdater til ny versjon", type: NavItemTypes.NewVersion
            })
        ]
        permissionsChildren.push(
            createNavItem({
                to: "/settings/notifications", title: "Push-varsler"
            }));

        permissionsChildren.push(
            createNavItem({
                to: "/settings/notifications/locations", title: "Lokasjonsdeling"
            }));

        navItems.push(createNavItem({
            to: "/settings", icon: "settings", title: "Innstillinger",
            children: settingsChildren
        }));
    }
}

async function getOfflineNavItemsAsync(navItems) {
    if (isLoggedInPerson()) {

        navItems.push(createNavItem({
            to: "/pilot", icon: "ship", title: "Oppdrag",
            children: [
                createNavItem({
                    to: "/pilot/pilotages/assigned", title: "Mine oppdrag"
                })
            ]
        }));

        navItems.push(generateEvaluationNavItem());
    }
}

function generateEvaluationNavItem() {
    return createNavItem({
        to: "/evaluation", icon: "evaluation", title: "Evalueringer"
    })
}

function generateAboutNavItem() {
    return createNavItem({
        icon: "information", title: "Om Njord Pilot Web", type: NavItemTypes.About
    });
}

function createNavItem(properties) {
    return {
        to: ('to' in properties) ? properties.to : "",
        icon: ('icon' in properties) ? properties.icon : "",
        title: ('title' in properties) ? properties.title : "",
        type: ('type' in properties) ? properties.type : "",
        children: ('children' in properties) ? properties.children : [],
        beta: ('beta' in properties) ? properties.beta : false
    }
}

function handleLogin() {
    if (!navigator.onLine) {
        alert("Du kan kun logge inn n\u00E5r du er p\u00E5 nett");
        return;
    }
    handleLoginAsync(window.location.href);
}

function handleLogout(navigate) {
    handleLogoutAsync(navigate);
}

function shouldShowNavItem(navItem) {
    if (navItem.beta) {
        return isBetaUser();
    }
    return true;
}

export {
    initializeAsync as initializeNavAsync,
    handleLogout,
    handleLogin,
    shouldShowNavItem
}
