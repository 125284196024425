import { Card } from "../../../../components/layout/card/Card";
import { CardProperties } from "../../../../components/layout/card/components/CardProperties";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Spacer from "../../../../components/layout/Spacer";
import Slide from "../../../../components/layout/Slide";
import { PilotAssignmentCommands } from "../../services/DispatcherActions";
import Switch from "../../../../components/layout/switch";
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';


export const ConnectToPilotCard = forwardRef((
    {
        onExaminerOnlyChanged,
        onPilotAssignmentChanged,
        assignmentService,
    }, ref) => {
    
        useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initialize();
        }
    }));

    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isEditable, setIsEditable] = useState(false);


    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, []);

    return (
        <Slide show={show}>
            <Spacer height={30} />
            <Card properties={{
                ...CardProperties,
                title: "Var du b&aring;de sensor og los på oppdraget?",
                backgroundColor: "var(--card-question-bg)"
            }}>
                <p>
                    Dersom du ogs&aring; var ansvarlig los på oppdraget, 
                    kan du krysse av her slik at losingstimene kan faktureres.
                </p>
                <Switch
                    onLabel="Ja"
                    offLabel="Nei"
                    onChange={onChange}
                    checked={isChecked}
                    disabled={!isEditable}
                />
            </Card>
        </Slide>
    );

    function initialize() {
        const pilotAssignment = assignmentService.assignment;
        const hasCommand = assignmentService.hasCommandType(PilotAssignmentCommands.UpdatePilotAssignmentConfirmedByPilot);

        setShow(
            pilotAssignment.isExaminer &&
            pilotAssignment.pilotage.pilotagePilotsInformation.length === 1 &&
            !pilotAssignment.pilotage.canConvertToPilotIncl &&
            !pilotAssignment.pilotage.pilotagePilotsInformation[0].isConfirmedByPilotDispatcher
        );
        setIsEditable(assignmentService.isEditable);
        setIsChecked(hasCommand);
    }

    function handlePubSubTopic() {
        initialize();
    }

    function onChange(e) {
        setIsChecked(e.value);
        onUpdateAsync(e.value);
    }

    async function onUpdateAsync(canConnectToPilot) {
        await assignmentService.updatePilotRoleAsync(canConnectToPilot);
        onPilotAssignmentChanged();
        onExaminerOnlyChanged();
    }
});