import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import { Card } from "../../../../../components/layout/card/Card";
import { CardProperties } from '../../../../../components/layout/card/components/CardProperties';
import { MapPinPlus } from "@phosphor-icons/react/dist/ssr";
import { LocationPilotTypeCard } from "./LocationPilotTypeCard";
import { PilotAssignmentCommands } from "../../../services/DispatcherActions";
import { Button } from "@progress/kendo-react-buttons";

export const LocationPilotTypeAddLocationCard = forwardRef(({
    index,
    assignmentService,
    onAfterChange,
    isEditable,
    hasPilotTypeValidationError,
    onSearchLocationsClick,
}, ref) => {

    useImperativeHandle(ref, () => ({
        onInitialize() {
            initialize();
        },
    }));

    const [iconOnly, setIconOnly] = useState(window.innerWidth <= 500 ? true : false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {

        function handleResize() {
            setIconOnly(window.innerWidth <= 500 ? true : false);
        }

        window.addEventListener("resize", handleResize);

        initialize();

        return (() => {
            window.removeEventListener("offline", handleResize);
        });

    }, [])

    return (
        <>
            {
                isEditable &&
                <div className="pp-type-location">
                        <div>
                            <Card
                            properties={{
                                ...CardProperties,
                                borderColor: "var(--card-border)",
                                backgroundColor: "var(--card-body-bg)",
                                hasValidationError: hasPilotTypeValidationError,
                                isDirty: isDirty
                            }}>
                            <div>
                                <LocationPilotTypeCard
                                    index={index}
                                    assignmentService={assignmentService}
                                    onAfterChange={handleOnAfterChange}
                                    isEditable={isEditable}
                                    hasValidationError={hasPilotTypeValidationError}
                                />
                            </div>
                        </Card>

                    </div>

                        <div>
                            <Button
                                onClick={onSearchLocationsClick}
                                themeColor="tertiary"
                                className="pp-add-location-btn">
                                <div className="pp-add-location-btn-content">
                                    <div className="text-truncate">Legg til lokasjon</div>
                                    <div><MapPinPlus size={iconOnly ? 50 : 40} /></div>
                                </div>

                            </Button>
                        </div>
                </div>
            }
        </>
    )

    function initialize() {
        const location = assignmentService.getLocationByIndex(index);
        setIsDirty(assignmentService.hasLocationCommand(location, PilotAssignmentCommands.UpdateLocationPilotType));
    }

    function handleOnAfterChange() {
        setIsDirty(true);
        onAfterChange();
    }
})
