import { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import Spacer from '../../../../components/layout/Spacer';
import { PilotActualWorkTimeStateEnum, PilotagePilotVariableCompensationTypeEnums } from '../../../../services/SystemNames';
import Moment from 'moment';
import PilotApiRepository from '../../../../repositories/api/PilotApiRepository';
import { VariableCompensationItem } from './VariableCompensationCard';
import { VariableCompensationBridgeCalculateDialog } from './dialogs/VariableCompensationBridgeCalculateDialog';
import { VariableCompensationBridgeOverrideDialog } from './dialogs/VariableCompensationBridgeOverrideDialog';
import { VariableCompensationBridgeEditDialog } from './dialogs/VariableCompensationBridgeEditDialog';
import { Card } from '../../../../components/layout/card/Card';
import MetaIdbRepository from '../../../../repositories/idb/MetaIdbRepository';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import { PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import { deepCopyObject, errorsContainsFromObject, isArrayEmpty, isNullOrEmpty, isNumeric, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { publishWarningNotificationTopic } from '../../../../components/helpers/PubSubHelpers';
import { getMinutesOnHold, isPilotAssignmentCompensationEqual } from '../helpers/PilotAssignmentHelpers';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';
import Overlay from '../../../../components/layout/overlay/Overlay';

const itemAction = {
    Calculate: "Calculate",
    View: "View"
}

const dialogStates = {
    Override: "Override",
    Calculated: "Calculated",
    Edit: "Edit"
}


const initialState = {
    hasDayValidationError: false,
    hasNightValidationError: false,
    dialogState: "",
    bridgeTimeDay: null,
    bridgeTimeNight: null,
    currentCompensation: null,
    summary: {},
    dayTime: {},
    isEditable: false,
    isDirty: false,
    canCalculate: false,
    isBusy: false
}

// eslint-disable-next-line react/display-name
export const VariableCompensationBridgeCard = forwardRef((
    {
        onPilotAssignmentChanged,
        assignmentService
    }
    , ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        },
        async onLocationChanged() {
            initializeAsync();
        },
        onExaminerOnlyChanged() {
            initializeAsync();
        },
        onValidationChanged(codeStrings) {
            setState((prev) => ({
                ...prev,
                hasDayValidationError: errorsContainsFromObject(codeStrings, {
                    BridgeTimeDay: PilotAssignmentErrorCodes.BridgeTimeDay,
                }),
                hasNightValidationError: errorsContainsFromObject(codeStrings, {
                    BridgeTimeNight: PilotAssignmentErrorCodes.BridgeTimeNight,
                })
            }));
        }
    }));

    const [{
        hasDayValidationError,
        hasNightValidationError,
        dialogState,
        bridgeTimeDay,
        bridgeTimeNight,
        currentCompensation,
        summary,
        dayTime,
        isEditable,
        isDirty,
        canCalculate,
        isBusy

    }, setState] = useState(initialState)

    const setIsBusy = (e) => {
        setState((prev) => ({
            ...prev,
            isBusy: e
        }));
    }

    const setBridgeTimeDay = (e) => {
        setState((prev) => ({
            ...prev,
            bridgeTimeDay: e
        }));
    }

    const setBridgeTimeNight = (e) => {
        setState((prev) => ({
            ...prev,
            bridgeTimeNight: e
        }));
    }

    const setDialogState = (e) => {
        setState((prev) => ({
            ...prev,
            dialogState: e
        }));
    }

    const componentRef = useRef({
        hasCalculated: false
    });
    const { current: localRef } = componentRef;

    useEffect(() => {

        if (
            isObjectNull(bridgeTimeDay) ||
            isObjectNull(bridgeTimeNight) ||
            localRef.hasCalculated ||
            !canCalculate) return;

        onCalculateBridgeTimeFromWorktimeAsync(true);
        localRef.hasCalculated = true;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bridgeTimeDay, bridgeTimeNight, canCalculate]);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic);
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, []);

    const InfoRow = ({ info }) => {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {info}
                    </div>
                </div>
                <Spacer height={10} />
            </>
        )
    }

    return (
        <>
            <Card
                properties={{
                    ...CardProperties,
                    hasValidationError: (hasDayValidationError || hasNightValidationError),
                    isDirty: isDirty,
                    title: "Brotillegg",
                    actions: (canCalculate && assignmentService.isEditable) ?
                        [
                            {
                                type: "items",
                                items: [
                                    {
                                        name: "Beregn brotimer fra arbeidstid",
                                        action: itemAction.Calculate,
                                        onClick: onCalculateBridgeTimeFromWorktimeAsync
                                    },
                                    {
                                        name: "Se utregnet brotid",
                                        action: itemAction.View,
                                        onClick: onShowCalculatedBridgeTimeFromWorktimeAsync
                                    }
                                ]
                            }
                        ] : []
                }}>
                {
                    assignmentService.isExaminerOnly &&
                    <InfoRow info="Sensor for farledspr&oslash;ve har ikke rett p&aring; brotillegg." />
                }

                {
                    assignmentService.isTrainee &&
                    <InfoRow info="Los p&aring; oppl&aelig;ring har ikke rett p&aring; brotillegg." />
                }

                {
                    (!assignmentService.isExaminerOnly && !assignmentService.isTrainee) &&
                    <>
                        {
                            assignmentService.hasMultiplePilotsConfirmedByPilotDispatcher &&
                            <InfoRow info="For oppdrag med 2 loser er brotillegg beregnet fra registrert arbeidstid." />
                        }

                        <div className="row">
                            <VariableCompensationItem
                                title="Dag"
                                hasValidationError={hasDayValidationError}
                                isEditable={isEditable}
                                compensation={bridgeTimeDay}
                                onEdit={onBridgeTimeClick}
                                onDelete={onDeleteBridgeTime}
                            />
                            <VariableCompensationItem
                                title="Natt"
                                hasValidationError={hasNightValidationError}
                                isEditable={isEditable}
                                compensation={bridgeTimeNight}
                                onEdit={onBridgeTimeClick}
                                onDelete={onDeleteBridgeTime}
                            />
                        </div>
                    </>
                }

            </Card>

            {
                (() => {
                    switch (dialogState) {

                        case dialogStates.Override:
                            return (
                                <VariableCompensationBridgeOverrideDialog
                                    onCancel={() => setDialogState("")}
                                    onClick={() => {
                                        setDialogState(dialogStates.Edit);

                                    }}
                                />);
                        case dialogStates.Calculated:
                            return (
                                <VariableCompensationBridgeCalculateDialog
                                    onClose={() => setDialogState("")}
                                    pilotagePilot={assignmentService.assignment}
                                    summary={summary}
                                    dayTime={dayTime}
                                />
                            );

                        case dialogStates.Edit:
                            return (
                                <VariableCompensationBridgeEditDialog
                                    onClose={() => setDialogState("")}
                                    compensation={currentCompensation}
                                    callback={onBridgeTimeCallback}
                                />
                            );

                        default:
                            return null;
                    }
                })()
            }

            <Overlay isBusy={isBusy}/>
        </>
    )

    async function initializeAsync() {
        const pilotAssignment = assignmentService.assignment;
        const calculated = await getCalculateBridgeTimeAsync();
        const canCalculate =
            assignmentService.hasMultiplePilotsConfirmedByPilotDispatcher &&
            navigator.onLine &&
            !assignmentService.isExaminerOnly &&
            !assignmentService.isTrainee;

        if (!localRef.hasCalculated) {
            setState((prev) => ({
                ...prev,
                isEditable: assignmentService.isEditable,
                isDirty: getIsDirty(pilotAssignment),
                canCalculate: canCalculate,
                bridgeTimeDay: getCompensation(pilotAssignment, PilotagePilotVariableCompensationTypeEnums.BridgeTimeDay, calculated.dayTimeMinutes),
                bridgeTimeNight: getCompensation(pilotAssignment, PilotagePilotVariableCompensationTypeEnums.BridgeTimeNight, calculated.nightTimeMinutes)
            }));
        } else {
            setState((prev) => ({
                ...prev,
                isEditable: assignmentService.isEditable,
                isDirty: getIsDirty(pilotAssignment),
                canCalculate: canCalculate
            }));
        }
    }

    function handlePubSubTopic() {
        initializeAsync();
    }

    function getIsDirty(pilotAssignment) {
        const originalArr = pilotAssignment.original.variableCompensations;
        const updatedArr = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;

        let isEqual = isPilotAssignmentCompensationEqual(originalArr, updatedArr, "BRIDGE_TIME_DAY");

        if (isEqual) {
            isEqual = isPilotAssignmentCompensationEqual(originalArr, updatedArr, "BRIDGE_TIME_NIGHT");
        }

        return !isEqual;
    }

    function getCompensation(pilotAssignment, systemName, calculatedNumber) {
        const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;
        if (isArrayEmpty(compensations)) return null;
        const compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === systemName);
        if (isObjectNull(compensation)) return null;

        compensation.calculatedNumber = (calculatedNumber / 60);

        return compensation;
    }

    function onBridgeTimeClick(compensation) {
        if (!isEditable) return;

        let dialogState = dialogStates.Edit;
        if (assignmentService.assignment.pilotage.canConvertToPilotIncl) {
            dialogState = dialogStates.Override;
        }

        setState((prev) => ({
            ...prev,
            currentCompensation: compensation,
            dialogState: dialogState
        }));
    }

    function onDeleteBridgeTime(compensation) {
        const compensationCopy = deepCopyObject(compensation);
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonRemark = null;
        compensationCopy.overridenNumber = null;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonTypeId = null;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonType = null;

        onBridgeTimeCallback(compensationCopy);
    }

    async function onBridgeTimeCallback(compensation) {
        
        if (compensation.pilotagePilotVariableCompensationSystemName === PilotagePilotVariableCompensationTypeEnums.BridgeTimeDay) {
            setBridgeTimeDay(compensation);
        } else {
            setBridgeTimeNight(compensation);
        }

        await assignmentService.onUpdateVariableCompensationOverriddenNumberAsync(compensation);
        onPilotAssignmentChanged();
    }

    async function onCalculateBridgeTimeFromWorktimeAsync(skipWarning = false) {
        const fromTime = assignmentService.pilotageStartTime;
        const toTime = assignmentService.pilotageEndTime;

        if ((isNullOrEmpty(fromTime) || isNullOrEmpty(toTime)) && !skipWarning) {
            publishWarningNotificationTopic("Start og stopp tid m&aring; v&aelig;re utfylt for &aring; beregne brotid fra arbeidstid.");
            return;
        }

        if ((Moment(fromTime) > Moment(toTime)) && !skipWarning) {
            publishWarningNotificationTopic("Start tid m&aring; v&aelig;re f&oslash;r stopp tid.");
            return;
        }

        setIsBusy(true);

        const response = await PilotApiRepository
            .getWorkTimeActualAsync(Moment(fromTime), Moment(toTime));

        if (response.ok && response.status === 200) {
            const dto = await response.json();
            
            if (isObjectNull(dto) ||
                !isNumeric(dto.dayInMinutes) ||
                !isNumeric(dto.nightInMinutes)) {
                if (!skipWarning) {
                    publishWarningNotificationTopic("Kunne ikke beregne brotid.<br/>Har du relevant arbeidstid for oppdraget?");
                }
            } else {
                if (dto.dayInMinutes === 0 && dto.nightInMinutes === 0 && !skipWarning) {
                    publishWarningNotificationTopic("Kunne ikke beregne brotid.<br/>Har du relevant arbeidstid for oppdraget?");
                }

                let day = 0;
                let night = 0;

                if (dto.dayInMinutes > 0) {
                    day = (dto.dayInMinutes / 60);
                }

                if (dto.nightInMinutes > 0) {
                    night = (dto.nightInMinutes / 60);
                }

                bridgeTimeDay.calculatedNumber = Number(day.toFixed(2));
                bridgeTimeNight.calculatedNumber = Number(night.toFixed(2));

                setState((prev) => ({
                    ...prev,
                    bridgeTimeDay: bridgeTimeDay,
                    bridgeTimeNight: bridgeTimeNight
                }));

                assignmentService.onCalculateBridgeTimeFromWorktimeAsync(bridgeTimeDay, bridgeTimeNight);
            }
        } else {
            if (!skipWarning) {
                publishWarningNotificationTopic("Kunne ikke beregne brotimer fra arbeidstid", response.status);
            }
        }

        setIsBusy(false);
    }

    async function onShowCalculatedBridgeTimeFromWorktimeAsync() {

        setIsBusy(true);

        const pilotAssignment = assignmentService.assignment;
        const response = await PilotApiRepository.getWorkTimeActualDetailsSummaryAsync(pilotAssignment.pilotage.pilotageId);
        const pilotDayTimeResponse = await PilotApiRepository.getDayTimeAsync();

        if (response.ok && pilotDayTimeResponse.ok) {
            const data = await response.json();
            const pilotDayTimeData = await pilotDayTimeResponse.json();
            switch (data.state) {
                case PilotActualWorkTimeStateEnum.NOT_PILOT:
                    publishWarningNotificationTopic(`Du er ikke registrert som los p&aring; losoppdrag ${data.pilotageNo}.`)
                    break;
                case PilotActualWorkTimeStateEnum.MISSING_DATES:
                    publishWarningNotificationTopic(`For &aring; se utregnet brotid, m&aring; skipperbeviset lagres f&oslash;rst.`)
                    break;
                default:

                    if (isArrayEmpty(data.workTimesDay) && isArrayEmpty(data.workTimesNight)) {
                        publishWarningNotificationTopic("Kunne ikke hente faktisk brotid.<br/>Har du relevant arbeidstid for oppdraget?");
                    } else {
                        setState((prev) => ({
                            ...prev,
                            summary: data,
                            dayTime: pilotDayTimeData,
                            dialogState: dialogStates.Calculated
                        }));
                    }
                    break;
            }
        } else {
            publishWarningNotificationTopic("Kunne ikke vise utregnet brotid", response.status);
        }

        setIsBusy(false);
    }

    async function getCalculateBridgeTimeAsync() {
        if (assignmentService.hasMultiplePilotsConfirmedByPilotDispatcher || assignmentService.isTrainee)
            return { dayTimeMinutes: 0, nightTimeMinutes: 0 };

        const dayTime = await MetaIdbRepository.getDayTimeAsync();
        const fromToTime = assignmentService.fromAndToTime;
        var timeWaitedOnStops = await getMinutesOnHold(assignmentService.locations);

        const dayStartHour = Moment(`2000-01-01T${dayTime.dayStart}`).hour();
        const dayEndHour = Moment(`2000-01-01T${dayTime.dayEnd}`).hour();

        let dayTimeMinutes = 0;
        let nightTimeMinutes = 0;

        if (!isNullOrEmpty(fromToTime.fromTime) && !isNullOrEmpty(fromToTime.toTime)) {

            const diffMinutes = Moment(fromToTime.toTime).diff(Moment(fromToTime.fromTime), 'minutes');
            let from = Moment(fromToTime.fromTime);

            for (let i = 1; i <= diffMinutes; i++) {
                const hour = from.hour();
                if (hour >= dayStartHour && hour < dayEndHour) {
                    dayTimeMinutes += 1;
                } else {
                    nightTimeMinutes += 1;
                }
                from = from.add(1, 'm');
            }

        }
        return {
            dayTimeMinutes: dayTimeMinutes - timeWaitedOnStops.minutesOnHoldDay,
            nightTimeMinutes: nightTimeMinutes - timeWaitedOnStops.minutesOnHoldNight
        }
    }
})
