import React, { useEffect, useState } from 'react';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import parse from 'html-react-parser';
import { ApplicationRightSystemNames } from '../../../services/SystemNames';
import { useSearchParams } from 'react-router-dom';
import { isArrayEmpty, isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';
import { useGetApplicationRightsQuery } from '../../../reducers/slices/api.slice';
import { hasApplicationRights } from '../../../components/helpers/AuthHelpers';

export const PcsMenus = {
    Groups: "GROUPS",
    Evaluations: "EVALUATIONS",
    Locations: "LOCATIONS"
}

export function PcsBottomMenu({ onClick, menu }) {

    const [searchParams, setSearchParams] = useSearchParams();
    const [items, setItems] = useState([]);

    const {
        data: applicationRights,
        isSuccess,
    } = useGetApplicationRightsQuery();

    useEffect(() => {
        if (isNullOrEmpty(isSuccess)) return;
        
        const result = [];
        let hasApplicationRight = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegments]);
        if (hasApplicationRight) {
            result.push({
                text: parse("Sertifikatomr&#229;der"),
                icon: "mapSegments",
                action: PcsMenus.Groups
            });
        }

        hasApplicationRight = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsEvaluation]);
        if (hasApplicationRight) {
            result.push({
                text: "Evalueringer",
                icon: "evaluation",
                action: PcsMenus.Evaluations
            });
        }

        hasApplicationRight = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsLocations]);
        if (hasApplicationRight) {
            result.push({
                text: "Lokasjoner",
                icon: "pin",
                action: PcsMenus.Locations
            });
        }

        setItems(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        (!isArrayEmpty(items)) &&
            <BottomNavigation
            items={items}
            selectedAction={menu}
            onCallback={onCallback }
            />
    );

    function onCallback(e) {
        setSearchParams({
            menu: e.action
        }, { replace: true });

        onClick(e.action);
    }
}
