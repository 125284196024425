import { useState } from 'react';
import {
    DatePicker as KendoDatePicker,
    DateInput as KendoDateInput
} from '@progress/kendo-react-dateinputs';
import Moment from 'moment';
import parse from 'html-react-parser';
import Icons from '../../icons/Icons';
import { DropDownButton } from "@progress/kendo-react-buttons";
import { DropDownButtonActionRender } from '../../render/DropDownButtonActionRender';
import { useEffect } from 'react';
import { getValidationErrorClassName } from '../../../helpers/ElementHelpers';
import { DateTimePickerModes } from '../DateTimePicker';

export const DatePickerText = ({
    value,
    defaultValue,
    disabled,
    title,
    hasValidationError,
    mode,
    handlePickerChange,
    getItems,
    renderPicker,
    showPicker,
    onItemClick,
    handleClose
}) => {

    const [localValue, setLocalValue] = useState(null);

    useEffect(() => {
        const date = Moment(value);
        if (date.isValid()) {
            setLocalValue(date.toDate());
        } else {
            setLocalValue(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className={`date-time-picker date-time-picker-text ${getValidationErrorClassName(hasValidationError)}`}>
            <div className="date-time-picker-input">
                <div>
                    {
                        renderPicker &&
                        <KendoDatePicker
                            format={getFormat()}
                            placeholder="Ikke satt"
                            defaultValue={defaultValue}
                            adaptive={true}
                            show={showPicker}
                            adaptiveTitle={parse(title)}
                            onChange={onChange}
                            onClose={handleClose}
                            disabled={disabled}
                        />
                    }
                </div>
                <div className="date-time-picker-components">
                    <div>
                        <KendoDateInput
                            format={getFormat()}
                            placeholder="Ikke satt"
                            disabled={disabled}
                            onChange={onChange}
                            value={localValue}
                        />
                    </div>
                    <div>
                        <DropDownButton
                            className="date-time-picker-button"
                            items={getItems()}
                            disabled={disabled}
                            itemRender={DropDownButtonActionRender}
                            onItemClick={onItemClick}
                            text={<Icons iconName="calendar" dimensions={24} />}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

    function getFormat() {
        if (mode === DateTimePickerModes.Year) return "dd.MM.yyyy";
        return "dd.MM";
    }

    function onChange(e) {
        handlePickerChange(e);
    }
}
