import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import { isPilotAssignmentCompleted, isPilotAssignmentConfirmed, isPilotAssignmentCorrectionNeeded } from '../pilot-assignment/helpers/PilotAssignmentHelpers';
import { deepCopyObject, isNullOrEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import PilotAssignmentIdbRepository from '../../../repositories/idb/PilotAssignmentIdbRepository';

export const PilotagDetailsMenuStates = {
    PilotAssignment: "PILOT_ASSIGNMENT",
    Information: "INFORMATION",
    Ship: "SHIP",
    Observations: "OBSERVATIONS",
    Map: "MAP"
}

export const PilotageDetailsBottomMenu = forwardRef(({
    pilotAssignment,
    pageState,
    onClick }, ref) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [isBusy, setIsBusy] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {

        if (isObjectNull(pilotAssignment)) return;
        setIsBusy(true);
        const menuItems = getBottomMenuItems();
        setItems(menuItems);
        
        setTimeout(() => {
            setIsBusy(false);
        }, 125);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pilotAssignment]);

    useImperativeHandle(ref, () => ({
        async onPilotAssignmentChange() {
            const updatedItems = deepCopyObject(items);
            const item = updatedItems.find(i => i.action === PilotagDetailsMenuStates.PilotAssignment);
            
            if (isObjectNull(item)) return;

            const assignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignment.pilotagePilotId);
            item.hasChanges = isPilotAssignmentDirty(assignment);
            setItems(updatedItems);
        },
    }));

    return (
        !isBusy &&
        <BottomNavigation
            items={items}
            selectedAction={pageState}
            onCallback={onSelect}
        />
    )

    function getBottomMenuItems() {
        if (isNullOrEmpty(pilotAssignment.pilotagePilotId)) return [];

        const items = [
            {
                text: "Info",
                icon: "information",
                action: PilotagDetailsMenuStates.Information
            },
            {
                text: parse("Fart&oslash;y"),
                icon: "ship",
                action: PilotagDetailsMenuStates.Ship
            },
        ];

        if (
            (
                isPilotAssignmentConfirmed(pilotAssignment) ||
                isPilotAssignmentCorrectionNeeded(pilotAssignment) ||
                isPilotAssignmentCompleted(pilotAssignment)
            )
        ) {

            items.push({
                text: "Sk.bevis",
                icon: "document",
                action: PilotagDetailsMenuStates.PilotAssignment,
                hasChanges: isPilotAssignmentDirty(pilotAssignment)
            });
        }

        if (navigator.onLine) {
            // todo: show on next planning
            //items.push({
            //    text: "Observ.",
            //    icon: "eye",
            //    action: PageStates.Observations
            //});
            items.push({
                text: "Kart",
                icon: "map",
                action: PilotagDetailsMenuStates.Map
            });
        }

        return items;
    }

    function onSelect(item) {

        setSearchParams({
            menu: item.action
        }, { replace: true });

        onClick(item.action);
    }

    function isPilotAssignmentDirty(assignment) {
        return assignment.commands.length > 0;
    }

})


