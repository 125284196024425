import { useEffect, useState } from 'react'
import './appointment.css';
import PilotApiRepository from "../../../repositories/api/PilotApiRepository"
import AppointmentCards from "./AppointmenCards"
import AppointmentForm from './AppointmentForm'
import ContentGrid from '../../../components/layout/ContentGrid';
import { PlusIcon, TrashIcon } from '../../../components/layout/icons/Icons';
import uuid from 'react-uuid';
import FloatingButtonContainer from '../../../components/layout/FloatingButtonContainer';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import Overlay from '../../../components/layout/overlay/Overlay';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import FloatingActionButton from '../../../components/layout/FloatingActionButton';
import { isArrayEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';

const deleteModes = {
    Single: "single",
    Multible: "multible"
}

const initialState = {
    appointments: [],
    selectedToEdit: null,
    isBusy: true,
    showDialog: false,
    showConfirmDialog: false,
    isDeleteActive: false,
    canDelete: false,
    appointmentsToDelete: [],
    confirmText: "",
    deleteMode: deleteModes.Single
}

function Appointment() {

    const [{
        appointments,
        selectedToEdit,
        isBusy,
        showDialog,
        showConfirmDialog,
        isDeleteActive,
        canDelete,
        appointmentsToDelete,
        confirmText,
        deleteMode

    }, setState] = useState(initialState)

    const setSelectedToEdit = (appointment) => {
      
        setState((prev) => ({
            ...prev,
            selectedToEdit: appointment,
            isBusy: false,
            showDialog: true
        }))
    }

    const setSelectedToDelete = (appointment) => {
        setState((prev) => ({
            ...prev,
            selectedToEdit: appointment,
            showConfirmDialog: true,
            confirmText: `&Oslash;nsker du &aring; slette frav&aelig;ret ${appointment.subject}?`,
            deleteMode: deleteModes.Single
        }))
    }

    const setIsBusy = (busy) => {
        setState((prev) => ({
            ...prev,
            isBusy: busy
        }));
    }
    const setAppointments = (data) => {
        setState((prev) => ({
            ...prev,
            appointments: data,
            isBusy: false
        }))
    }

    const setIsDeleteActive = (data) => {
        setState((prev) => ({
            ...prev,
            isDeleteActive: data
        }))
    }

    const setCanDelete = (data) => {
        setState((prev) => ({
            ...prev,
            canDelete: data
        }))
    }

    const setAppointmentsToDelete = (data) => {
        setState((prev) => ({
            ...prev,
            appointmentsToDelete: data
        }))
    }

    useEffect(() => {
        publishHeaderTopic("Frav&aelig;r");
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ContentGrid title="Frav&aelig;r" titleIcon="calendar" canPullToRefresh={true} onPullToRefresh={initialize}>
                <AppointmentCards
                    isBusy={isBusy}
                    appointments={appointments}
                    onClickEdit={setSelectedToEdit}
                    onDelete={setSelectedToDelete}
                    toggleActivateSelect={toggleActivateSelect}
                    toggleSelectItem={toggleSelectItem}
                />
            </ContentGrid>

            <FloatingButtonContainer>
                {
                    isDeleteActive &&
                    <FloatingActionButton
                        themeColor={canDelete ? "error" : "secondary"}
                        svgIcon={TrashIcon}
                        disabled={!canDelete}
                        onClick={onDeleteMultibleAsync}
                    />
                }

                <FloatingActionButton
                    themeColor={"primary"}
                    svgIcon={PlusIcon}
                    onClick={() =>
                        setState((prev) => ({
                            ...prev,
                            selectedToEdit: null,
                            showDialog: true
                        }))
                    }
                />
            </FloatingButtonContainer>

            {
                showDialog &&
                <AppointmentForm
                    isBusy={isBusy}
                    setIsBusy={setIsBusy}
                    source={selectedToEdit}
                    reload={initialize}
                    close={() => {
                        if (isBusy) return;
                        setState((prev) => ({
                            ...prev,
                            showDialog: false
                        }));
                    }}
                />
            }
            
            {
                showConfirmDialog && 
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        onClose: () =>
                            setState((prev) => ({
                                ...prev,
                                showConfirmDialog: false
                            })),
                        onClick: onDeleteAppointmentAsync,
                        title: "Slett frav&aelig;r",
                        text: confirmText
                    }}/>
            }
                        
            <Overlay isBusy={isBusy} />
            
        </>
    )

    function initialize() {
        setCanDelete(false);
        setIsDeleteActive(false);
        getAppointmentsAsync();
    }

    async function getAppointmentsAsync() {
        const response = await PilotApiRepository.getAppointmentAsync()
        setIsBusy(true);
        if (response.ok === true) {
            const data = await response.json();
            for (let i = 0; i < data.length; i++) {
                data[i].guid = uuid();
            }
            setAppointments(data);
        } else {
            setAppointments([]);
            setIsBusy(false);
            publishWarningNotificationTopic("Noe gikk galt");
        }

        setAppointmentsToDelete([]);
    }

    async function onDeleteAppointmentAsync() {
        switch (deleteMode) {
            case deleteModes.Multible:
                await onDeleteMultibleAppointmentsAsync();
                break;
            default:
                await onDeleteSingleAppointmentAsync();
                break;
        }
    }

    async function onDeleteSingleAppointmentAsync() {
        setState((prev) => ({
            ...prev,
            isBusy: true,
            showConfirmDialog: false
        }))

        let response = await PilotApiRepository.getAppointmentByIdAsync(selectedToEdit.appointmentID);
        if (response.ok === true) {
            const data = await response.json();

            let deleteResponse = await PilotApiRepository.deleteAppointmentAsync(data);
            if (deleteResponse.ok === true) {
                publishSuccessNotificationTopic(`Frav&aelig;ret ble slettet.`);
                await initialize();
                setIsBusy(false);
            } else {                
                publishWarningNotificationTopic("En feil oppstod ved sletting av frav&aelig;ret");
            }
        } else {
            publishWarningNotificationTopic("En feil oppstod ved sletting av frav&aelig;ret");
        }
        setIsBusy(false);
    }

    async function onDeleteMultibleAppointmentsAsync() {
        setState((prev) => ({
            ...prev,
            isBusy: true,
            showConfirmDialog: false
        }));

        let success = true;

        for (let i = 0; i < appointmentsToDelete.length; i++) {

            const appointment = appointments.find(a => a.appointmentID === appointmentsToDelete[i]);
            if (!isObjectNull(appointment)) {
                let response = await PilotApiRepository.getAppointmentByIdAsync(appointment.appointmentID);
                if (response.ok === true) {
                    const data = await response.json();
                    let deleteResponse = await PilotApiRepository.deleteAppointmentAsync(data);
                    success = deleteResponse.ok === true;
                } else {
                    success = false;
                }
            }
        }

        if (success) {

            publishSuccessNotificationTopic(`Frav&aelig;r ble slettet.`);
        } else {
            publishWarningNotificationTopic("En feil oppstod ved sletting av frav&aelig;r");
        }

        await initialize();
    }

    function toggleActivateSelect(activate) {
        setIsDeleteActive(activate);

        if (activate) return;

        setAppointmentsToDelete([]);
        setCanDelete(false);
    }

    function toggleSelectItem(id, selected) {
        let result = [...appointmentsToDelete];
        if (selected) {
            result.push(id);
        } else {
            result = result.filter(i => i !== id);
        }

        setAppointmentsToDelete(result);
        setCanDelete(!isArrayEmpty(result));
    }

    async function onDeleteMultibleAsync() {
        if (isArrayEmpty(appointmentsToDelete)) return;

        setState((prev) => ({
            ...prev,
            showConfirmDialog: true,
            confirmText: `&Oslash;nsker du &aring; slette valgte frav&aelig;r?`,
            deleteMode: deleteModes.Multible
        }))
    }
}

export default Appointment
