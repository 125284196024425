import PilotAssignmentIdbRepository from "../../../../repositories/idb/PilotAssignmentIdbRepository";
import { IconColors, PilotAssignmentStatusColors, PilotAssignmentStatusEnums } from "../../../../services/SystemNames";
import Moment from 'moment';
import uuid from 'react-uuid';
import hexRgb from 'hex-rgb';
import { deepCopyObject, isNullOrEmpty, isObjectNull } from "../../../../components/helpers/ObjectHelpers";
import { getFormattedDateTime } from "../../../../components/helpers/DateTimeHelpers";
import PilotagePilotTypeIdbRepository from "../../../../repositories/idb/PilotagePilotTypeIdbRepository";
import MetaIdbRepository from "../../../../repositories/idb/MetaIdbRepository";
import PilotAssignmentService from "../services/PilotAssignmentService";

function createEmptyPilotAssignment() {
    return {
        pilotage: {
            pilotageDetail: {
                fromTime: Moment()
            },
            pilotageShip: {},
            fromLocation: {},
            toLocation: {},
            pilotagePilotsInformation: []
        },
        pilotagePilotStatus: {}
    };
}

function convertApiDtoToIdb(dto) {
    dto.guid = uuid();
    dto.hasExternalChanges = false;
    dto.wasServerUpdated = false;
    dto.commands = [];

    dto.original = {
        locations: [],
        hourCompensations: [],
        overtimes: [],
        variableCompensations: [],

        arrivalTime: null,
        departureTime: null,
        returnTime: null,
        isWastedTrip: false,
        waitingHoursReasonRemark: "",
        remark: "",
        isReimbursementIncluded: false
    }

    if (isNullOrEmpty(dto.remark)) {
        dto.remark = "";
    }

    if (isNullOrEmpty(dto.waitingHoursReasonRemark)) {
        dto.waitingHoursReasonRemark = "";
    }

    if (isObjectNull(dto.locations)) {
        dto.locations = [];
    } else {
        dto.original.locations = deepCopyObject(dto.locations);
    }

    if (isObjectNull(dto.pilotagePilotCompensation.pilotagePilotHourCompensations)) {
        dto.pilotagePilotCompensation.pilotagePilotHourCompensations = [];
    } else {
        dto.original.hourCompensations = deepCopyObject(dto.pilotagePilotCompensation.pilotagePilotHourCompensations);
    }

    if (isObjectNull(dto.pilotagePilotCompensation.pilotagePilotOvertimes)) {
        dto.pilotagePilotCompensation.pilotagePilotOvertimes = [];
    } else {
        dto.original.overtimes = deepCopyObject(dto.pilotagePilotCompensation.pilotagePilotOvertimes);
    }
    if (!isObjectNull(dto.pilotagePilotCompensation.pilotagePilotVariableCompensations)) {
        dto.original.variableCompensations = deepCopyObject(dto.pilotagePilotCompensation.pilotagePilotVariableCompensations);
    }

    dto.original.arrivalTime = dto.arrivalTime;
    dto.original.departureTime = dto.departureTime;
    dto.original.returnTime = dto.returnTime;
    dto.original.isWastedTrip = dto.isWastedTrip;
    dto.original.waitingHoursReasonRemark = dto.waitingHoursReasonRemark;
    dto.original.remark = dto.remark;
    dto.original.isReimbursementIncluded = dto.pilotagePilotCompensation.isReimbursementIncluded;

    return dto;
}

function convertExecutedApiDtoToIdb(dto) {
    return {
        pilotagePilotId: dto.pilotagePilotId,
        hasLocalChanges: false,
        hasExternalChanges: false,
        pilotagePilotStatus: dto.pilotagePilotStatus,
        isTrainee: dto.isTrainee,
        isApprentice: dto.isApprentice,
        isExaminer: dto.isExaminer,
        isConfirmedByPilotDispatcher: dto.isConfirmedByPilotDispatcher,
        pilotage: {
            pilotageId: dto.pilotageId,
            pilotageNo: dto.pilotageNo,
            pilotageShip: {
                shipName: dto.shipName
            },
            fromLocation: {
                name: dto.fromLocationName
            },
            toLocation: {
                name: dto.toLocationName
            },
            pilotageDetail: {
                fromTime: dto.fromTime,
                isLocked: dto.isLocked
            },
            hasObservation: dto.hasObservation
        }
    }
}

function isTentative(pilotAssignment) {
    return !pilotAssignment.isConfirmedByPilotDispatcher &&
        !pilotAssignment.isExaminer &&
        !pilotAssignment.isTrainee;
}

function isAssignedAndNotProcessed(pilotAssignment) {
    if (isTentative(pilotAssignment)) return false;

    switch (pilotAssignment.pilotagePilotStatus.systemName) {
        case PilotAssignmentStatusEnums.Confirmed:
        case PilotAssignmentStatusEnums.Rejected:
        case PilotAssignmentStatusEnums.Completed:
        case PilotAssignmentStatusEnums.CorrectionNeeded:
            return false;
        default:
            return true;
    }
}

function isCompleted(pilotAssignment) {
    if (isTentative(pilotAssignment)) return false;
    if (isNullOrEmpty(pilotAssignment.pilotagePilotStatus)) return true;

    switch (pilotAssignment.pilotagePilotStatus.systemName) {
        case PilotAssignmentStatusEnums.Completed:
            return true;
        default:
            return false;
    }
}

function isCorrectionNeeded(pilotAssignment) {
    return pilotAssignment.pilotagePilotStatus.systemName === PilotAssignmentStatusEnums.CorrectionNeeded;
}

function isConfirmed(pilotAssignment) {
    if (isTentative(pilotAssignment)) return false;
    switch (pilotAssignment.pilotagePilotStatus.systemName) {
        case PilotAssignmentStatusEnums.Confirmed:
            return true;
        default:
            return false;
    }
}

function getPilotAssignmentUrl(pilotAssignment) {
    return `/pilot/pilotage/${pilotAssignment.pilotage.pilotageId}`;
}

function getStatusColorRgba(pilotAssignment) {
    if (!pilotAssignment) return;
    var hex = getStatusColor(pilotAssignment);
    var rgba = hexRgb(hex);
    return `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, 0.25)`;
}

function getStatusColorRgbaBySystemName(systemName) {
    var hex = getStatusColorBySystemName(systemName);
    var rgba = hexRgb(hex);
    return `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, 0.25)`;
}

function getStatusColor(pilotAssignment) {

    if (isTentative(pilotAssignment)) return PilotAssignmentStatusColors.Tentative;

    return getStatusColorBySystemName(pilotAssignment.pilotagePilotStatus.systemName);
}

function getStatusIconColor(pilotAssignment) {
    const systemName = pilotAssignment.pilotagePilotStatus.systemName;

    switch (systemName) {
        case PilotAssignmentStatusEnums.Confirmed:
            return IconColors.AssignmentConfirmed;
        case PilotAssignmentStatusEnums.Rejected:
        case PilotAssignmentStatusEnums.Completed:
        case PilotAssignmentStatusEnums.CorrectionNeeded:
            return IconColors.Primary;
        default:
            return IconColors.AssignmentAssigned;
    }
}

function getStatusColorBySystemName(systemName) {
    switch (systemName) {
        case PilotAssignmentStatusEnums.Confirmed:
            return PilotAssignmentStatusColors.Confirmed;
        case PilotAssignmentStatusEnums.Rejected:
            return PilotAssignmentStatusColors.Rejected;
        case PilotAssignmentStatusEnums.Completed:
            return PilotAssignmentStatusColors.Completed;
        case PilotAssignmentStatusEnums.CorrectionNeeded:
            return PilotAssignmentStatusColors.CorrectionNeeded;
        default:
            return PilotAssignmentStatusColors.Assigned;
    }
}

async function getMinutesOnHold(locations) {
    let minutesOnHoldDay = 0;
    let minutesOnHoldNight = 0;

    const dayTime = await MetaIdbRepository.getDayTimeAsync();
    const dayStartHour = Moment(`2000-01-01T${dayTime.dayStart}`).hour();
    const dayEndHour = Moment(`2000-01-01T${dayTime.dayEnd}`).hour();

    for (let i = 1; i < locations.length - 1; i++) {
        const locationFromTime = locations[i].fromTime;
        const locationToTime = locations[i].toTime;

        let dayTimeMinutes = 0;
        let nightTimeMinutes = 0;

        if (!isNullOrEmpty(locationFromTime) && !isNullOrEmpty(locationToTime)) {

            const diffMinutes = Moment(locationFromTime).diff(Moment(locationToTime), 'minutes');
            let from = Moment(locationToTime);

            for (let i = 1; i <= diffMinutes; i++) {
                const hour = from.hour();
                if (hour >= dayStartHour && hour < dayEndHour) {
                    dayTimeMinutes += 1;
                } else {
                    nightTimeMinutes += 1;
                }
                from = from.add(1, 'm');
            }
        }
        minutesOnHoldDay += dayTimeMinutes;
        minutesOnHoldNight += nightTimeMinutes
    }

    return {
        minutesOnHoldDay: minutesOnHoldDay,
        minutesOnHoldNight: minutesOnHoldNight
    };
}

function getIconColor(pilotagePilot) {
    switch (pilotagePilot.pilotagePilotStatus.systemName) {
        case "CONFIRMED":
            return IconColors.Secondary;
        default:
            return IconColors.Primary;
    }
}

function createPilotsShortList(pilotagePilots) {
    const result = [];
    for (const pilotagePilot of pilotagePilots) {
        result.push({
            borderColor: getStatusColor(pilotagePilot),
            text: pilotagePilot.initials,
            isExaminer: pilotagePilot.isExaminer,
            isTrainee: pilotagePilot.isTrainee,
            isApprentice: pilotagePilot.isApprentice,
        });
    }
    return result;
}

function toggleSelectedItem(selectedItems, id, selected) {
    const newItem = { id: id, selected: selected };
    let result = deepCopyObject(selectedItems);
    if (!result.some(c => c.id === id)) {
        result.push(newItem);
    } else {
        result = result.map((item) =>
            item.id === id ? { ...item, ...newItem } : item
        );
    }

    return result;
}

function getCompensationId(compensation, propertyName) {
    if (Object.hasOwn(compensation, propertyName)) return compensation[propertyName];
    if (Object.hasOwn(compensation, "guid")) return compensation.guid;
    return 0;
}

function isLocationAtSea(location) {

    if (Object.hasOwn(location, "locationType")) {
        return isLocationSystemNameAtSea(location.locationType.systemName);
    }

    if (Object.hasOwn(location, "systemName")) {
        return isLocationSystemNameAtSea(location.systemName);
    }

    return false;
}

function isLocationSystemNameAtSea(systemName) {
    return systemName === "PILOT_BOARDING" ||
        systemName === "ANCHORAGE" ||
        systemName === "LOCATION_AT_SEA";
}

function isCompensationEqual(originalArr, updatedArr, systemName) {

    if (isObjectNull(updatedArr) || isObjectNull(originalArr)) return false;

    let original = getCompensationBySystemName(originalArr, systemName);
    let updated = getCompensationBySystemName(updatedArr, systemName);

    return updated.overridenNumber === original.overridenNumber;
}

function getCompensationBySystemName(array, systemName) {
    return array.find(x => x.pilotagePilotVariableCompensationSystemName === systemName);
}

async function setSuggestions(pilotageId) {

    let pilotAssignment = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);
    const assignmentService = new PilotAssignmentService(pilotAssignment);

    if (!assignmentService.isEditable) return;

    const isHelicopterRequired = pilotAssignment.pilotage.isHelicopterRequired;
    const pilotCount = pilotAssignment.pilotage.pilotagePilotsInformation.length;
    const isMoreThanOnePilotRequired = pilotAssignment.pilotage.isMoreThanOnePilotRequired;
    const hasViaLocations = pilotAssignment.locations.length > 2;

    if (isNullOrEmpty(pilotAssignment.arrivalTime)) {
        const arrivalTime = pilotAssignment.pilotage.pilotageDetail.fromTime;
        pilotAssignment.arrivalTime = getFormattedDateTime(arrivalTime);

        await assignmentService.onPilotArrivalTimeAsync(arrivalTime);
        pilotAssignment = assignmentService.assignment;
    }

    for (const location of pilotAssignment.locations) {
        const index = pilotAssignment.locations.indexOf(location);
        const isFirst = index === 0;
        const isLast = index === pilotAssignment.locations.length - 1;

        const boardingOnly = isFirst;
        const offBoardingOnly = isLast;

        if (isObjectNull(location.pilotBoardingType)) {
            if ((isFirst || isLast) && isLocationAtSea(location)) {
                const boardingType = {
                    isPilotBoardingTypeOffBoarding: false,
                    systemName: isHelicopterRequired ? "HELICOPTER" : "PILOT_BOAT"
                };

                if (offBoardingOnly || (!boardingOnly && !offBoardingOnly)) {
                    boardingType.isPilotBoardingTypeOffBoarding = true;
                }

                location.pilotBoardingType = boardingType;
                location.isPilotBoardingTypeOffBoarding = boardingType.isPilotBoardingTypeOffBoarding;

                await assignmentService.addUpdateLocationBoardingTypeCommandAsync(location, boardingType);
                pilotAssignment = assignmentService.assignment;
            }
        }


        if (location.pilotTypeId === 0) {
            let dto = null;
            if (location.sequenceNo === 2) {
                if (pilotCount === 1) {
                    dto = await PilotagePilotTypeIdbRepository.getDefaultAsync();
                } else if (assignmentService.hasMultiplePilotsConfirmedByPilotDispatcher) {
                    if (isMoreThanOnePilotRequired) {
                        dto = await PilotagePilotTypeIdbRepository.getBySystemNameAsync("PARALLEL_PILOTING");
                    } else {
                        dto = await PilotagePilotTypeIdbRepository.getBySystemNameAsync("SEQUENTIAL_PILOTING");
                    }
                }
            }

            if (!isNullOrEmpty(dto)) {
                location.pilotType = dto.pilotType;
                location.pilotTypeId = dto.pilotagePilotTypeId;
                await assignmentService.addUpdateLocationPilotTypeCommandAsync(location, dto);
                pilotAssignment = assignmentService.assignment;
            }
        }

        if (!hasViaLocations) {
            if (isFirst && isNullOrEmpty(location.fromTime)) {
                const startTimeObj = {
                    location: location,
                    departureTime: pilotAssignment.pilotage.pilotageDetail.fromTime,
                    isPilotBoardingTypeOffBoarding: !isFirst
                }

                location.fromTime = getFormattedDateTime(startTimeObj.departureTime);
                await assignmentService.onUpdateLocationDepartureTimeAsync(startTimeObj);
                pilotAssignment = assignmentService.assignment;
            }

            const pilotageEstimatedDurationInMinutes = pilotAssignment.pilotage.pilotageDetail.pilotageEstimatedDurationInMinutes;
            if (isLast && isNullOrEmpty(location.toTime) && !isNullOrEmpty(pilotageEstimatedDurationInMinutes)) {
                const toTime = Moment(pilotAssignment.pilotage.pilotageDetail.fromTime).add(pilotageEstimatedDurationInMinutes, 'minutes');

                const stopTimeObj = {
                    location: location,
                    arrivalTime: toTime.toDate(),
                    isPilotBoardingTypeOffBoarding: !isFirst
                }

                location.toTime = getFormattedDateTime(stopTimeObj.arrivalTime);

                await assignmentService.onUpdateLocationArrivalTimeAsync(stopTimeObj);
                pilotAssignment = assignmentService.assignment;
            }
        }

        await PilotAssignmentIdbRepository.setAsync(pilotAssignment);
    }
}

function getTransportOrderBorderColor(pilotTransportOrderStatus) {
    switch (pilotTransportOrderStatus) {
        case "ORDERED":
            return "var(--pto-status-ordered-boarder)";
        case "CONFIRMED":
            return "var(--pto-status-confirmed-boarder)";
        case "CANCELLED":
            return "var(--pto-status-cancelled-boarder)";
        default:
            return "var(--pto-status-allocated-boarder)";
    }
}

function getFilteredCompensations(compensations, obj, propertyName) {
    let filteredCompensations = [];

    let filterByPropertyName = false;

    if (Object.hasOwn(obj, propertyName)) {
        filterByPropertyName = obj[propertyName] > 0;
    }

    if (filterByPropertyName) {
        filteredCompensations = compensations.filter(c => c[propertyName] !== obj[propertyName]);
    } else {
        filteredCompensations = compensations.filter(c => c.guid !== obj.guid);
    }

    return filteredCompensations;
}

function getLocationBySequenceNo(locations, sequenceNo) {
    return locations.find(l => l.sequenceNo === sequenceNo);
}

function isReadyForInvoicing(pilotAssignment) {
    return pilotAssignment.isReadyForInvoicing
}

export {
    createEmptyPilotAssignment,
    isLocationAtSea,
    toggleSelectedItem,

    convertApiDtoToIdb as convertPilotAssignmentApiDtoToIdb,
    convertExecutedApiDtoToIdb as convertExecutedPilotAssignmentApiDtoToIdb,
    getStatusColorRgba as getPilotAssignmentStatusColorRgba,
    getStatusColorRgbaBySystemName as getPilotAssignmentStatusColorRgbaBySystemName,
    getStatusColor as getPilotAssignmentStatusColor,
    getStatusIconColor as getPilotAssignmentStatusIconColor,
    getStatusColorBySystemName as getPilotAssignmentStatusColorBySystemName,
    isAssignedAndNotProcessed as isPilotAssignmentAssignedAndNotProcessed,
    isCorrectionNeeded as isPilotAssignmentCorrectionNeeded,
    isCompleted as isPilotAssignmentCompleted,
    isConfirmed as isPilotAssignmentConfirmed,
    isTentative as isPilotAssignmentTentative,
    getPilotAssignmentUrl,
    createPilotsShortList as createPilotAssignmentPilotsShortList,
    getCompensationId as getPilotAssignmentCompensationId,
    getFilteredCompensations as getPilotAssignmentFilteredCompensations,
    getLocationBySequenceNo as getPilotAssignmentLocationBySequenceNo,
    getIconColor as getPilotAssignmentIconColor,
    isCompensationEqual as isPilotAssignmentCompensationEqual,
    setSuggestions as setPilotAssignmentSuggestions,
    getTransportOrderBorderColor as getPilotAssignmentTransportOrderBorderColor,
    isReadyForInvoicing as isPilotAssignmentReadyForInvoicing,
    getMinutesOnHold
}
