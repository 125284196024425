import { useEffect, useState } from "react";
import PilotBoardingTypeIdbRepository from "../../../../../repositories/idb/PilotBoardingTypeIdbRepository";
import { isObjectNull } from "../../../../../components/helpers/ObjectHelpers";
import parse from 'html-react-parser';
import uuid from "react-uuid/uuid";
import SelectOptionFieldWrapper from "../../../../../components/layout/fieldWrappers/SelectOptionFieldWrapper";

export const LocationBoardingTypeCard = (
    {
        index,
        assignmentService,
        onAfterChange,
        isEditable,
        hasValidationError
    }) => {

    const isFirst = index === 0;
    const isLast = index === assignmentService.locationsCount - 1;

    const [boardingType, setBoardingType] = useState({});
    const [boardingTypes, setBoardingTypes] = useState([]);

    useEffect(() => {

        async function initializeAsync() {
            const location = assignmentService.getLocationByIndex(index);
            let boardingType = await PilotBoardingTypeIdbRepository.getDefaultAsync();

            const boardingTypes = []
            const isPilotBoardingTypeOffBoarding = location.isPilotBoardingTypeOffBoarding;
            const boardingOnly = isFirst;
            const offBoardingOnly = isLast;

            if (!isObjectNull(location.pilotBoardingType)) {
                boardingType = await PilotBoardingTypeIdbRepository.getBySystemNameAsync(location.pilotBoardingType.systemName);
            }

            if (boardingOnly || (!boardingOnly && !offBoardingOnly)) {
                boardingTypes.push({
                    name: parse("Bording Losb&aring;t"),
                    selected: boardingType.systemName === "PILOT_BOAT" && !isPilotBoardingTypeOffBoarding,
                    guid: uuid(),
                    source: {
                        isPilotBoardingTypeOffBoarding: false,
                        systemName: "PILOT_BOAT"
                    }
                });
                boardingTypes.push({
                    name: "Bording Helikopter",
                    selected: boardingType.systemName === "HELICOPTER" && !isPilotBoardingTypeOffBoarding,
                    guid: uuid(),
                    source: {
                        isPilotBoardingTypeOffBoarding: false,
                        systemName: "HELICOPTER"
                    }
                });
            }
            if (offBoardingOnly || (!boardingOnly && !offBoardingOnly)) {
                boardingTypes.push({
                    name: parse("Kvitting Losb&aring;t"),
                    selected: boardingType.systemName === "PILOT_BOAT" && (isPilotBoardingTypeOffBoarding || isLast),
                    guid: uuid(),
                    source: {
                        isPilotBoardingTypeOffBoarding: true,
                        systemName: "PILOT_BOAT"
                    }
                });
                boardingTypes.push({
                    name: "Kvitting Helikopter",
                    selected: boardingType.systemName === "HELICOPTER" && (isPilotBoardingTypeOffBoarding || isLast),
                    guid: uuid(),
                    source: {
                        isPilotBoardingTypeOffBoarding: true,
                        systemName: "HELICOPTER"
                    }
                });
            }
            boardingTypes.push({
                name: "Ikke relevant",
                selected: boardingType.systemName === "NOT_APPLICABLE",
                guid: uuid(),
                source: {
                    isPilotBoardingTypeOffBoarding: false,
                    systemName: "NOT_APPLICABLE"
                }
            });

            setBoardingTypes(boardingTypes);

            const selectedBoardingType = boardingTypes.find(bt => bt.selected === true);
            if (!isObjectNull(selectedBoardingType)) {
                setBoardingType(selectedBoardingType);
            }
        }

        initializeAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SelectOptionFieldWrapper
            hasValidationError={hasValidationError}
            title={getTitle()}
            options={boardingTypes}
            selectedOption={boardingType}
            optionTextField="name"
            onCallback={onCallback}
            disabled={!isEditable}
        />
    )

    async function onCallback(item) {
        setBoardingType(item);

        const location = assignmentService.getLocationByIndex(index);
        const obj = {
            location: location,
            boardingType: item.source
        };

        await assignmentService.onUpdateLocationBoardingTypeAsync(obj);

        onAfterChange();
    }

    function getTitle() {
        if (!isFirst && !isLast) return "Bording/Kvitting";

        if (isFirst) {
            return "Bording";
        }
        return "Kvitting";
    }
}
